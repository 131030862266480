import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import axios from 'axios';

import store from '@/store';
import { config } from '@base/constants';

const getWidgetUser = () => JSON.parse(document?.body?.dataset.widgetUser || '{}');
const buildTagsData = (event, widgetUser, accountId) => ({
  tags: {
    ...event?.tags,
    ...(widgetUser?.id && { widget: true }),
    ...(accountId && { accountId }),
  },
});
const skippableEvents = (event, hint) => {
  const { originalException } = hint;
  const { level, extra } = event;
  if (axios.isAxiosError(originalException)) return true;
  if (level === 'warning' && 'isAlive' in extra) return true;
  return false;
};

Sentry.init({
  Vue,
  dsn: config.sentry.enviroment === 'development' ? undefined : config.sentry.dsn,
  release: config.appVersion,
  environment: config.sentry.enviroment,
  trackComponents: true,
  hooks: ['mount', 'update', 'destroy'],
  logErrors: true,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    "Can't find variable: ResizeObserver",
  ],

  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

  replaysSessionSampleRate: 0,
  // The quota for this is very low, so collecting only 50% to have variety
  replaysOnErrorSampleRate: 0.5,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.1,

  beforeSend(event, hint) {
    if (skippableEvents(event, hint)) return null;

    const user = store?.getters?.['info/getUser'];
    const widgetUser = getWidgetUser();
    const accountId = store?.getters?.['info/getAccount']?.id || widgetUser?.accountId;

    return {
      ...event,
      user: {
        id: user?.id || widgetUser?.id,
        email: user?.email || widgetUser?.email,
        ...event.user,
      },
      ...buildTagsData(event, widgetUser, accountId),
      contexts: {
        ...event.contexts,
        'Feature Flags': store?.getters?.['info/getToggles'],
      },
    };
  },
});

// Clear @sentry/vue errorHandler to let vue handle errors and enhance the stack trace
if (config.isDev) Vue.config.errorHandler = null;

export const sentry = Sentry;
