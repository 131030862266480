export default {
  SET_LINKS: (state, payload) => {
    Object.assign(state.links, { ...payload });
  },
  SET_ACCESS_TOKENS(state, payload) {
    state.accessTokens = payload;
  },
  SET_MEMBERSHIPS(state, payload) {
    state.memberships = payload;
  },
  SET_API_USER_ID(state, payload) {
    state.apiUserId = payload;
  },
  SET_HOOKS(state, payload) {
    state.hooks = payload;
  },
  SET_PLAN(state, payload) {
    state.plan = payload;
  },
  SET_LINK_UPGRADE_PLAN(state, payload) {
    state.linkUpgradePlan = payload;
  },
  SET_EVENTS(state, payload) {
    state.events = payload;
  },
  SET_WIDGET_EMBEDDED(state, payload) {
    state.widgetEmbedded = payload;
  },
};
