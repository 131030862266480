export default {
  SET_SIGNER_AUTHS(state, payload) {
    state.signerAuths = payload;
  },
  SET_SIGNER_SIGN_AS(state, payload) {
    state.signerSignAs = payload;
  },
  SET_NEW_SIGNER_SIGN_AS(state, payload) {
    state.newSignerSignAs = payload;
  },
  SET_COMMUNICATE_BY(state, payload) {
    state.communicateBy = payload;
  },
  SET_CONTACTS(state, payload) {
    state.contacts = payload;
  },
  UPDATE_CONTACT(state, payload) {
    const contactsList = [...state.contacts];
    const contactToUpdate = contactsList.find((contact) => contact.id === payload.id);
    Object.assign(contactToUpdate, payload);
    state.contacts = contactsList;
  },
  SET_LINKS(state, payload) {
    state.links = payload;
  },
  PATCH_LINKS(state, payload) {
    state.links = { ...state.links, ...payload };
    state.links.setSigner = payload;
  },
};
