import axios from '@/plugins/axios';
// TODO: Remove axiosOriginal when snakeCase problem is fixed
import axiosOriginal from 'axios';

export default {
  setLinks({ commit }, payload) {
    commit('SET_LINKS', payload);
  },

  async fetchOrganizations({ commit, getters }, payload) {
    const response = await axios.get(payload?.url || getters.getLinks.organizations);

    commit('SET_ORGANIZATIONS', response?.data?.organizations);
    commit('SET_USER', response?.data?.user);
  },

  async fetchOrganization({ commit }, payload) {
    const response = await axios.get(payload?.url);

    commit('SET_ORGANIZATION', { ...payload, ...response?.data });
  },

  async fecthWorkspaceAccounts({ commit }, payload) {
    const response = await axios.get(payload.url);

    commit('SET_WORKSPACE', {
      accounts: response?.data?.accounts,
      id: payload.id,
      organizationId: payload.organizationId,
      pagination: response?.data?.pagination,
      order: payload.order || 'asc',
    });
  },

  async createWorkspace({ getters }, payload) {
    try {
      await axios.post(getters.getLinks.workspaces, payload);
    } catch (err) {
      throw err;
    }
  },

  async editWorkspace(_, payload) {
    try {
      await axios.put(payload.requestUrl, { workspace: payload.workspace });
    } catch (err) {
      throw err;
    }
  },

  async deleteWorkspace(_, payload) {
    try {
      await axios.delete(payload.url, { data: payload.body || {} });
    } catch (err) {
      throw err;
    }
  },

  async fetchAccountsOutsideWorkspace(_, payload) {
    try {
      const params = new URLSearchParams();
      if (payload.query) params.append('query', payload.query);
      const requestUrl = `${payload.url}?${params.toString()}`;
      const response = await axios.get(requestUrl);

      return response?.data?.accounts;
    } catch (err) {
      throw err;
    }
  },

  async moveAccount(_, payload) {
    try {
      await axios.post(payload.url, payload.body);
    } catch (err) {
      throw err;
    }
  },

  async updateOrganization({ dispatch }, payload) {
    try {
      await axiosOriginal.put(payload.links.self, { organization: payload.organization });
      await dispatch('fetchOrganization', {
        url: payload.links.workspaces,
        id: payload.id,
        name: payload.organization.name,
        brand: payload.organization.brand_attributes,
      });
    } catch (err) {
      throw err;
    }
  },

  sortWorkspaceAccounts({ commit }, payload) {
    commit('SORT_WORKSPACE_ACCOUNTS', payload);
  },

  setOpenedWorkspaceId({ commit }, payload) {
    commit('SET_OPENED_WORKSPACE_ID', payload);
  },

  clearWorkspaceData({ commit }, payload) {
    commit('CLEAR_WORKSPACE_DATA', payload);
  },
};
