import { axios } from '@/plugins';

const createTemplate = async ({ rootGetters, commit }, payload) => {
  const url = rootGetters['info/getLinks']?.createTemplate;
  commit('SET_IS_UPLOADING_TEMPLATE', true);
  try {
    const {
      data: { template },
    } = await axios.post(url, {
      template: payload,
    });
    commit('SET_TEMPLATE', template);
  } finally {
    commit('SET_IS_UPLOADING_TEMPLATE', false);
  }
};

const createTemplateFromDocuments = async ({ rootGetters, commit }, payload) => {
  const { createFromDocuments } = rootGetters['info/getLinks'];
  const {
    data: { template },
  } = await axios.post(createFromDocuments, { template: payload });

  commit('SET_TEMPLATE', template);
};

const fetchTemplate = async ({ commit }) => {
  const url = window.location.href;
  const {
    data: { template },
  } = await axios.get(url);

  commit('SET_TEMPLATE', template);
};

const updateTemplate = async ({ getters }, payload) => {
  await axios.patch(getters.getTemplate.links.update, payload);
};

const fetchTemplatePreview = async ({ getters }) => {
  let status = 204;
  let data;

  while (status === 204) {
    try {
      const response = await axios.get(getters.getTemplate.links.preview);
      data = response.data;
      status = response.status;
    } catch (error) {
      throw error;
    }

    if (status === 204) {
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
  }

  return data.template;
};

export default {
  createTemplateFromDocuments,
  fetchTemplate,
  updateTemplate,
  fetchTemplatePreview,
  createTemplate,
};
