export default {
  SET_ICP_CLOUD_PROVIDERS: (state, payload) => {
    state.icpCloudProviders = payload;
  },
  SET_ICP_CLOUD_SIGNATURE_JWT: (state, payload) => {
    state.icpCloudSignatureJwt = payload;
  },
  SET_ICP_CLOUD_SIGNATURE_INFO: (state, payload) => {
    state.icpCloudSignatureInfo = payload;
  },
};
