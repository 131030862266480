const operators = {
  settings: {
    accountsLink: 'Regresar a la selección de cuentas',
    title: 'Configuración del perfil:',
    titleNew: 'Configuración del perfil',
    information: {
      title: 'Información',
      action: 'Actualizar la información',
      success: 'Información actualizada con éxito',
      titleNew: 'Datos de acceso',
      name: 'Nombre',
      email: 'Correo electrónico',
      phone: 'Celular',
      cpf: 'CPF',
      birthday: 'Fecha de nacimiento',
      save: 'Guardar',
      emailInfoLink: 'Cómo cambiar el correo electrónico de registro',
      incomplete: 'Completa el registro para que puedas aprovechar mejor nuestros recursos',
      samlAlert:
        'Tu acceso es administrado por tu organización. Para cambiar tus datos, ponte en contacto con el administrador de la cuenta.',
    },
    password: {
      title: 'Contraseña',
      change: 'Cambiar contraseña',
      new: 'Nueva contraseña',
      action: 'Actualizar contraseña',
      success: '¡Contraseña cambiada con éxito!',
      fail: 'La contraseña actual es inválida',
      current: 'Contraseña actual',
      newConfirmation: 'Nueva contraseña',
      addPassword: 'Añadir contraseña',
      noPassword: 'No hay contraseñas guardadas actualmente',
      confirmation: 'Confirma tu contraseña',
      save: 'Guardar contraseña',
      cancelAction: 'Cancelar',
      linkAccount: 'Conectar',
      linkedAccount: 'Conectado',
      ssoTitle: 'Cuentas conectadas',
      ssoAccounts:
        'Conecta tu cuenta de Google o Microsoft a Clicksign. Recuerda: el correo electrónico debe ser el mismo que utilizas para iniciar sesión.',
      googleAccount: 'Cuenta de Google',
      microsoftAccount: 'Cuenta de Microsoft',
      forgotPassword: 'Olvidé mi contraseña',
      accessTooltip: 'Más información sobre cuentas vinculadas',
      forgotPasswordToast:
        'Hemos enviado un correo electrónico con las instrucciones para crear una nueva contraseña',
    },
    multiFactorAuth: {
      title: 'Verificación en dos pasos',
      link: 'Cómo habilitar la verificación en dos pasos',
      enableButton: 'Habilitar',
      resetButton: 'Restablecer',
      disableButton: 'Desactivar',
      incomplete:
        'Recuperación de acceso vía SMS no disponible. Registra tu número de celular en los datos de acceso.',
      status: {
        disabled: 'Deshabilitado',
        enabled: 'Habilitado',
      },
      settings: {
        title: 'Verificación en dos pasos',
        authAppsLink: 'Ver las aplicaciones que puedes usar',
        firstStep: '1. Instala una aplicación de autenticación',
        secondStep: '2. Escanea el código QR o copia el código a continuación:',
        thirdStep: '3. Ingresa el código de seis dígitos generado en la aplicación',
        button: 'Habilitar',
        success: {
          title: 'Verificación en dos pasos habilitada',
          successText: 'Tu cuenta ahora es más segura que nunca',
          codeRecovery: {
            title: 'Códigos de recuperación',
            text: 'Los códigos a continuación te permitirán acceder a Clicksign en caso de tener problemas con la aplicación de autenticación. Guárdalos en un lugar seguro.',
            warn: 'Estos códigos no se volverán a mostrar después de esta etapa.',
            download: 'Descargar códigos de recuperación',
            backupFileName: 'Códigos de Recuperación - Clicksign{format}',
          },
          phoneConfirm: {
            title: 'Confirma tu número de celular',
            text: 'Al confirmar, permites la recuperación de acceso a la cuenta vía SMS',
            phone: 'Celular',
            error: 'Error al actualizar el número de celular. Inténtalo de nuevo más tarde',
          },
          button: 'Finalizar',
        },
        errors: {
          default: 'Error al realizar la autenticación. Inténtalo de nuevo más tarde',
        },
      },
      optinModal: {
        title: 'Habilitar verificación en dos pasos',
        confirm: 'Confirma tu identidad',
        securityReasons:
          'Por razones de seguridad, necesitamos confirmar que la solicitud de habilitación fue realizada por ti.',
        clickToSend: 'Haz clic en "Enviar correo electrónico"',
        dispatchEmail: '{0} para enviar la solicitud de confirmación de identidad a {1}.',
        notReceiveEmail:
          'Si el correo electrónico no está en tu bandeja de entrada, revisa la carpeta de correo no deseado.',
        error:
          'Error al enviar el correo electrónico de confirmación. Actualiza la página e inténtelo de nuevo.',
        success: 'Correo electrónico enviado con éxito',
        resend: 'Enviar de nuevo',
        tryResend: 'Reenviar en {time} segundos',
        close: 'Cerrar',
      },
    },
    resetModal: {
      header: '¿Restablecer la verificación en dos pasos?',
      text: 'Toda la información sobre el dispositivo configurado anteriormente será eliminada. Esta acción no se puede deshacer.',
      confirmCheckbox: 'Selecciona para confirmar la desactivación',
      confirmButton: 'Restablecer',
    },
    disableModal: {
      header: '¿Desactivar la verificación en dos pasos?',
      text: 'La información sobre el dispositivo configurado para la verificación será eliminada.',
      confirmCheckbox: 'Selecciona para confirmar la desactivación',
      confirmButton: 'Desactivar',
      success: 'Verificación en dos pasos deshabilitada con éxito',
    },
    notification: {
      finishedDocumentLabel:
        '¿Quieres recibir un correo electrónico cuando el documento sea completado?',
      refusedDocumentLabel:
        '¿Quieres recibir un correo electrónico cuando un firmante rechace una firma?',
      documentDeadlineLabel:
        '¿Quieres recibir un correo electrónico 3 días antes de que el documento sea completado?',
      emailErrorLabel:
        '¿Quieres recibir un aviso por correo electrónico cuando haya un error en el envío del sobre?',
      options: {
        own: 'Sí, Solo para los creados {type} por mí.',
        all: 'Sí, Para todos los {type}.',
        none: 'No quiero recibir.',
      },
      success: 'Configuraciones de notificaciones cambiadas con éxito',
      section: 'Recordatorios',
      action: 'Guardar recordatorios',
      envelopes: 'sobres',
      documents: 'documentos',
    },
    remoteUpdate: {
      success: 'Cambio realizado con éxito',
    },
  },
  emailConfirmed: {
    subtitle: 'Correo electrónico confirmado. ¿Vamos a enviar el primer documento?',
    dismissButton: 'Ahora no',
    confirmButton: '¡Sí, vamos!',
  },
  emailConfirmation: {
    title: 'Ahora solo falta confirmar tu correo electrónico',
    description: 'Haz click en el enlace que enviamos a {email}',
    resendButton: 'No lo recibí',
    skipButton: 'Confirmar más tarde',
  },
};

export default operators;
