import axios from 'axios';

export const inviteMembership = async ({ params, bodyData }) => {
  try {
    const response = await axios({
      method: 'post',
      url: `/accounts/${params.accountId}/invites`,
      data: bodyData,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateMembership = async ({ params, bodyData }) => {
  try {
    const response = await axios({
      method: 'patch',
      url: `/accounts/${params.accountId}/${params.membershipType}/${params.membershipId}`,
      data: bodyData,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteMembership = async ({ params, bodyData }) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `/accounts/${params.accountId}/${params.membershipType}/${params.membershipId}`,
      data: bodyData,
    });

    const payload = response.data;
    return payload;
  } catch (error) {
    throw error;
  }
};
