const initialState = () => ({
  links: {},
  toggles: {},
  draft: [],
  archives: [],
  rubrics: [],
  drafts: [],
  pagination: {},
  signers: [],
  groups: {},
  fromEnvelope: false,
  sequenceEnabled: false,
  draftLoading: false,
  uploadingArchives: [],
  deletingArchives: [],
  alreadyDeletedArchives: [],
  positionSign: {
    signatures: [],
  },
});

export default {
  initialState,
  ...initialState(),
};
