import { canUseSessionStorage } from '@base/utils/can-use-storage';

const STORAGE_KEY = 'expressSubscription';

/**
 * Express subscription state.
 * @typedef {Object} ExpressSubscriptionState
 * @property {boolean} subscribe - Indicates whether is a express onboarding subuscription or not.
 * @property {string} [plan] - The plan name.
 * @property {number} [planId] - The plan ID.
 */

/**
 * Utilitary function to get onboarding express subscription state
 * @example
 * const expressSubscription = getExpressSubscription();
 * @returns {(ExpressSubscriptionState|null)} Express subscription state if stored in session
 * */
export const getExpressSubscription = () => {
  try {
    if (!canUseSessionStorage()) return null;

    const expressSubscription = window.sessionStorage.getItem(STORAGE_KEY);

    return JSON.parse(expressSubscription);
  } catch (err) {
    return null;
  }
};

/**
 * Utilitary function to set onboarding express subscription state on session storage
 * @param {ExpressSubscriptionState} payload
 * @example
 * const payload = { subscribe: true, plan: 'integration', planId: 1 };
 * setExpressSubscription(payload);
 * */
export const setExpressSubscription = (payload) => {
  if (!canUseSessionStorage() || !payload) return;
  window.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(payload));
};

/**
 * Utilitary function to clear onboarding express subscription state
 * @example
 * clearExpressSubscription(payload);
 * */
export const clearExpressSubscription = () => {
  if (!canUseSessionStorage()) return;
  window.sessionStorage.removeItem(STORAGE_KEY);
};
