import { axios, axiosViaCep } from '@/plugins';
import { parseAddressResponse } from '@base/utils/address-utils';
import { subscriptionUtils } from '@account/utils';

const setLinks = ({ commit }, payload) => {
  commit('SET_LINKS', payload);
};

const fetchPlans = async ({ getters, commit }, plan) => {
  try {
    const requestUrl = _.get(getters, 'getLinks.self');
    const { data } = await axios.get(requestUrl, { params: { plan } });
    commit('SET_LINKS', data.links);
    return data;
  } catch (error) {
    throw error;
  }
};

const fetchAddressByBrasilAPI = async (_, zipCode) => {
  const url = `https://brasilapi.com.br/api/cep/v1/${zipCode}`;
  const { data } = await axios.get(url);
  return data;
};

const fetchAddressByZipCode = async (vuex, zipCode) => {
  try {
    const cepUrl = `https://viacep.com.br/ws/${zipCode}/json/`;
    const { data } = await axiosViaCep.get(cepUrl);
    return data;
  } catch (error) {
    throw error;
  }
};

const fetchAddressOld = async ({ commit, dispatch }, zipCode) => {
  try {
    const data = await dispatch('fetchAddressByZipCode', zipCode);
    const parsedData = parseAddressResponse(data);
    commit('SET_ADDRESS', parsedData);
    return data;
  } catch (error) {
    throw error;
  }
};

const fetchAddress = async ({ dispatch }, zipCode) => {
  try {
    const data = await dispatch('fetchAddressByZipCode', zipCode);
    const parsedData = parseAddressResponse(data);
    return parsedData;
  } catch (error) {
    throw error;
  }
};

const fetchSegments = async ({ getters, commit, rootGetters }) => {
  try {
    const requestUrl = rootGetters['info/getRoutes'].segments || getters.getLinks.segments;
    const { data } = await axios.get(requestUrl);

    commit('SET_SEGMENTS', data.segments);
    return data.segments;
  } catch (error) {
    throw error;
  }
};

const fetchBillingCustomer = async ({ getters, commit }) => {
  try {
    const requestUrl = _.get(getters, 'getLinks.self');
    const { data } = await axios.get(requestUrl);
    const parsedResponseData = subscriptionUtils.parseBillingCustomerResponse(data.billingCustomer);

    commit('SET_LINKS', data.links);
    commit('SET_SUBSCRIPTION', parsedResponseData);
    return parsedResponseData;
  } catch (err) {
    throw err;
  }
};

const fetchFunctionalities = async ({ commit, rootGetters }) => {
  try {
    commit('SET_IS_LOADING_FUNCTIONALITIES', true);
    const requestUrl = _.get(rootGetters, '[info/getLinks].functionalities');
    if (!requestUrl) return {};
    const { data } = await axios.get(requestUrl);

    commit('SET_FUNCTIONALITY', data);
    commit('SET_FUNCTIONALITIES_BLOCKED_BY_ADMIN', data);
    return data;
  } finally {
    commit('SET_IS_LOADING_FUNCTIONALITIES', false);
  }
};

// TOGGLE_MIDAS_CLIENT_INTEGRATION_ENABLED
// TODO: remove submitBillingCustomer
const submitBillingCustomer = async ({ getters, commit }, payload) => {
  try {
    const requestType = payload.mode;
    const requestUrl = _.get(getters, `getLinks.${requestType}`);
    const requestData = subscriptionUtils.buildBillingCustomerRequest(payload);
    const requestMethod = requestType === 'create' ? 'post' : 'patch';
    const { data } = await axios[requestMethod](requestUrl, requestData);
    const parsedResponseData = subscriptionUtils.parseBillingCustomerResponse(data.billingCustomer);

    commit('SET_LINKS', data.links);
    return parsedResponseData;
  } catch (error) {
    throw error;
  }
};

const submitPlanInfo = async ({ rootGetters }, payload) => {
  try {
    const url = rootGetters['info/getLinks'].billingIntegration.create;
    const requestData = subscriptionUtils.buildPlanInfoPayload(payload);
    await axios.post(url, requestData);
  } catch (error) {
    throw error;
  }
};

const fetchSubscription = async ({ getters, commit }) => {
  try {
    const requestUrl = _.get(getters, 'getLinks.self');
    const { data } = await axios.get(requestUrl);
    const parsedResponseData = subscriptionUtils.parseBillingCustomerResponse(
      data.subscription.billingCustomer
    );

    commit('SET_LINKS', data.links);
    commit('SET_SUBSCRIPTION', parsedResponseData);
    return parsedResponseData;
  } catch (error) {
    throw error;
  }
};

const submitSubscription = async ({ getters, commit }, payload) => {
  try {
    const requestUrl = getters?.getLinks?.createSubscription;

    const requestData = {
      billingSubscription: {
        planId: payload.plan.id,
        paymentMethodCode: payload.paymentMethodCode,
      },
    };

    if (payload.planTier) requestData.billingSubscription.planTier = payload.planTier;

    const { data } = await axios.post(requestUrl, requestData);
    commit('SET_LINKS', data.links);
  } catch (error) {
    throw error;
  }
};

export default {
  fetchPlans,
  // TOGGLE_MIDAS_CLIENT_INTEGRATION_ENABLED
  // TODO: remove fetchAddressOld
  fetchAddressOld,
  fetchAddress,
  fetchAddressByZipCode,
  fetchSegments,
  fetchBillingCustomer,
  fetchSubscription,
  fetchFunctionalities,
  // TOGGLE_MIDAS_CLIENT_INTEGRATION_ENABLED
  // TODO: remove submitBillingCustomer
  submitBillingCustomer,
  submitPlanInfo,
  submitSubscription,
  setLinks,
  fetchAddressByBrasilAPI,
};
