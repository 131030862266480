import { sentry } from '@/plugins/sentry';
import { thirdPartyCookieBlockedError } from '@base/constants';

let canUseLs = true;

export const canUseLocalStorage = (reset = false) => {
  if (reset) canUseLs = true;
  if (!canUseLs) return false;

  try {
    localStorage.setItem('clicksignTest', 123);
    localStorage.removeItem('clicksignTest');
  } catch (error) {
    canUseLs = false;

    // send to sentry only when error is not blocked third party cookies
    if (error.name !== thirdPartyCookieBlockedError) sentry.captureException(error);
  }

  return canUseLs;
};

let canUseSession = true;

export const canUseSessionStorage = (reset = false) => {
  if (reset) canUseSession = true;
  if (!canUseSession) return false;

  try {
    sessionStorage.setItem('clicksignTest', 123);
    sessionStorage.removeItem('clicksignTest');
  } catch (error) {
    canUseSession = false;

    // send to sentry only when erro is not blocked third party cookies
    if (error.name !== thirdPartyCookieBlockedError) sentry.captureException(error);
  }

  return canUseSession;
};
