const document = {
  info: 'Documento {documentIndex} de {documentsCount}',
  download: {
    title: 'Baixar documento',
    description: {
      text: 'Por enquanto você só pode',
      link: 'baixar documento original.',
      complement:
        'A via assinada só poderá ser baixada após a finalização do processo de assinatura.',
    },
    option: {
      original: 'Baixar documento original',
      signed: 'Baixar documento assinado',
    },
  },
  order: 'Documento | Documento | Documento {order} de {total}',
  signDue: 'Assinar até',
  summary: {
    title: 'Documento para assinar | Documentos para assinar',
    header: {
      selected:
        'Nenhum documento selecionado | {0} documento selecionado | {0} documentos selecionados',
      toSign:
        'Nenhum documento para assinar | {0} documento para assinar | {0} documentos para assinar',
    },
    actionsTitle: 'Ações',
    optionsTitle: 'Opções',
    selectAll: 'Selecionar tudo',
    refusable: 'Recusável',
    refused: 'Documento pausado - aguardando ação do remetente',
  },
  view: 'Visualizar',
};

export default document;
