import DEFAULT_CONTACT_CONTEXT from './contexts/default-contact-constants';
import ENVELOPE_CONTACT_CONTEXT from './contexts/envelope-contact-constants';
import ENVELOPE_EDIT_CONTACT_CONTEXT from './contexts/envelope-edit-contact-constants';
import ENVELOPE_FLEXIBLE_CONTACT_SIGNER_CONTEXT from './contexts/envelope-edit-flexible-contact-constants';

function GET_CONTACT_STEPS(context) {
  const stepsByContext = {
    DEFAULT: DEFAULT_CONTACT_CONTEXT,
    ENVELOPE_DRAFT_SIGNER: ENVELOPE_CONTACT_CONTEXT,
    ENVELOPE_EDIT_SIGNER: ENVELOPE_EDIT_CONTACT_CONTEXT,
    ENVELOPE_EDIT_FLEXIBLE_SIGNER: ENVELOPE_FLEXIBLE_CONTACT_SIGNER_CONTEXT,
  };
  return stepsByContext[context] || stepsByContext.DEFAULT;
}

const CONTACT_FLEXIBLE_CONTEXTS = [
  'ENVELOPE_DRAFT_SIGNER',
  'ENVELOPE_EDIT_FLEXIBLE_SIGNER',
  'CONTACTS_FLEXIBLE_LIST',
  'ENVELOPE_NEW_SIGNER_CHAT',
  'FLOW_FLEXIBLE_CONTACT',
  'FLOW_FLEXIBLE_SIGNER',
];

export default {
  CONTACT_FLEXIBLE_CONTEXTS,
  CONTACT_STEPS: GET_CONTACT_STEPS,
  CONTACT_FLEXIBLE_BASE_STATE: () => ({
    email: '',
    phoneNumber: null,
    name: '',
    birthday: '',
    hasDocumentation: true,
    documentation: '',
    signAs: '',
    auths: ['email'],
    communicateBy: 'email',
  }),
  CONTACT_BASE_STATE: () => ({
    email: '',
    phoneNumber: null,
    name: '',
    birthday: '',
    hasDocumentation: true,
    documentation: '',
    signAs: '',
    auths: '',
    officialDocumentEnabled: false,
    selfieEnabled: false,
    handwrittenEnabled: false,
    livenessEnabled: false,
    facialBiometricsEnabled: false,
  }),
  AUTH_ICONS: {
    pix: 'pix-open',
    officialDocument: 'id-card',
    selfie: 'camera',
    handwritten: 'signature',
    sms: 'sms',
    email: 'envelope',
    liveness: 'video',
    facialBiometrics: 'facial-biometric',
    icpBrasil: 'file-certificated',
    whatsapp: 'whatsapp',
    addressProof: 'file-alt',
  },
  // Keep the order of the functionalities
  FUNCTIONALITIES: {
    sms: 'sms',
    whatsapp: 'whatsapp',
    liveness: 'liveness',
    facialBiometrics: 'facialBiometrics',
  },
  FUNCTIONALITIES_CODE_SHORT_NAME: {
    signatureTokenBySms: 'sms',
    communicateByWhatsapp: 'whatsapp',
    dynamicSelfieAuthentication: 'liveness',
    biometricsAuthentication: 'facialBiometrics',
  },
};
