// TOGGLE_MIDAS_CLIENT_INTEGRATION_ENABLED
// TODO: remove buildBillingCustomerRequest
export const buildBillingCustomerRequest = (payload) => {
  const data = {
    billingCustomer: {
      accountAttributes: {
        segmentId: _.get(payload, 'billingCustomer.accountAttributes.segment'),
        segment: _.get(payload, 'billingCustomer.accountAttributes.segment'),
        id: _.get(payload, 'billingCustomer.accountAttributes.id'),
      },
      kind: _.get(payload, 'billingCustomer.common.kind'),
      zipCode: _.get(payload, 'billingCustomer.common.zipCode'),
      address: _.get(payload, 'billingCustomer.common.address'),
      addressComplement: _.get(payload, 'billingCustomer.common.addressComplement'),
      number: _.get(payload, 'billingCustomer.common.number'),
      neighborhood: _.get(payload, 'billingCustomer.common.neighborhood'),
      city: _.get(payload, 'billingCustomer.common.city'),
      region: _.get(payload, 'billingCustomer.common.region'),
      email: _.get(payload, 'billingCustomer.common.email'),
      phoneNumber: _.get(payload, 'billingCustomer.common.phoneNumber'),
      companyName: _.get(payload, 'billingCustomer.company.name'),
      companyDocumentation: _.get(payload, 'billingCustomer.company.documentation'),
      tradingName: _.get(payload, 'billingCustomer.company.tradingName'),
      personName: _.get(payload, 'billingCustomer.person.name'),
      personDocumentation: _.get(payload, 'billingCustomer.person.documentation'),
      gatewayToken: payload?.billingCustomer?.gatewayToken,
    },
    plan: _.get(payload, 'plan.name'),
  };

  return data;
};

export const buildPlanInfoPayload = (payload) => {
  const { kind } = payload?.billingCustomer?.common;
  const data = {
    operation: {
      zipCode: _.get(payload, 'billingCustomer.common.zipCode'),
      street: _.get(payload, 'billingCustomer.common.address'),
      transient_gateway_token: _.get(payload, 'billingCustomer.gatewayToken'),
      addressComplement: _.get(payload, 'billingCustomer.common.addressComplement'),
      number: _.get(payload, 'billingCustomer.common.number'),
      neighborhood: _.get(payload, 'billingCustomer.common.neighborhood'),
      city: _.get(payload, 'billingCustomer.common.city'),
      region: _.get(payload, 'billingCustomer.common.region'),
      email: _.get(payload, 'billingCustomer.common.email'),
      phoneNumber: _.get(payload, 'billingCustomer.common.phoneNumber'),
      name: payload?.billingCustomer[kind]?.name,
      registryCode:
        _.get(payload, 'billingCustomer.company.documentation') ||
        _.get(payload, 'billingCustomer.person.documentation'),
      tradingName: _.get(payload, 'billingCustomer.company.tradingName'),
      plan: _.get(payload, 'plan.name'),
      coupon: _.get(payload, 'coupon'),
    },
    account: { segment_id: _.get(payload, 'billingCustomer.accountAttributes.segment') },
  };

  if (payload.planTier) data.operation.planTier = payload.planTier;

  return data;
};

export const parseBillingCustomerResponse = (response) => {
  const data = {
    billingCustomer: {
      accountAttributes: {
        segment: _.get(response, 'accountAttributes.segment'),
        id: _.get(response, 'accountAttributes.id'),
      },
      common: {
        kind: _.get(response, 'kind', 'company'),
        zipCode: _.get(response, 'zipCode', ''),
        address: _.get(response, 'address', ''),
        addressComplement: _.get(response, 'addressComplement', ''),
        number: _.get(response, 'number', ''),
        city: _.get(response, 'city', ''),
        neighborhood: _.get(response, 'neighborhood', ''),
        region: _.get(response, 'region', ''),
        email: _.get(response, 'email', ''),
        phoneNumber: _.get(response, 'phoneNumber', ''),
      },
      company: {
        name: _.get(response, 'companyName', ''),
        tradingName: _.get(response, 'tradingName', ''),
        documentation: _.get(response, 'companyDocumentation', ''),
      },
      person: {
        name: _.get(response, 'personName', ''),
        documentation: _.get(response, 'personDocumentation', ''),
      },
    },
  };
  return data;
};

export const buildSubscriptionRequest = (payload) => {
  const data = {
    billingSubscription: {
      plan_id: payload.plan.id,
    },
  };

  return data;
};
