<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from '@/store';
import { useI18n } from '@/locales';
import { FEATURES } from '@base/constants';
import FlowDisabledContainer from '@flow/containers/disabled/FlowDisabledContainer.vue';
import { useFunctionalities, useGA, useModal, useToast } from '@base/hooks';
import {
  PendingAnswersHeader,
  PendingAnswersDetailsModal,
  PendingAnswersDisapproveModal,
  PendingAnswersWhatsappReminderModal,
} from '@flow/components/pending-answers';

const store = useStore();
const { t } = useI18n();
const toast = useToast();
const { isEnabled } = useFunctionalities();
const { isModalActive, openModal, closeModal } = useModal();

const loading = ref(false);

const HEADERS = [
  {
    label: t('pendingAnswers.table.secondRecipient'),
    value: 'secondRecipient',
  },
  {
    label: t('pendingAnswers.table.name'),
    value: 'name',
  },
  {
    label: t('pendingAnswers.table.createdAt'),
    value: 'createdAt',
  },
  {
    label: t('pendingAnswers.table.action'),
    value: 'action',
    colspan: 2,
  },
];

const pendingAnswers = computed(() => store.getters['pendingAnswers/getPendingAnswers']);
const selectedProcess = computed(() => store.getters['pendingAnswers/getSelectedProcess']);
const pagination = computed(() => store.getters['pendingAnswers/getPagination']);

const fetchPendingAnswers = async () => {
  loading.value = true;
  await store.dispatch('pendingAnswers/fetchPendingAnswers', {
    url: window.location.href,
  });
  await store.dispatch('pendingAnswers/fetchFlowsFilter');
  loading.value = false;
};

const handleShowDetails = (item) => {
  store.commit('pendingAnswers/SET_SELECTED_PROCESS', item);
  openModal('PendingAnswersDetailsModal');
};

const handleShowDisapprove = (item) => {
  store.commit('pendingAnswers/SET_SELECTED_PROCESS', item);
  openModal('PendingAnswersDisapproveModal');
};

const handleDisapproveAnswer = async () => {
  try {
    await store.dispatch(
      'pendingAnswers/fetchActionAnswer',
      selectedProcess.value.links.disapprove
    );
    fetchPendingAnswers();
    useGA('event', 'archive-pendencie-click', 'automation-forms-pendencies-app');
    toast.success(t('pendingAnswers.actionDisapprove'));
    closeModal();
  } catch (err) {
    toast.error(t('pendingAnswers.actionError'));
  }
};

const handleWhatsappReminder = async () => {
  try {
    await store.dispatch('pendingAnswers/fetchActionAnswer', selectedProcess.value.links.reminder);
    useGA('event', 'send-pendencie-link-click', 'automation-forms-pendencies-app');
    toast.success(t('pendingAnswers.actionReminder'));
    closeModal();
  } catch (err) {
    toast.error(t('pendingAnswers.actionError'));
  }
};

const handleShowWhatsappReminder = (item) => {
  store.commit('pendingAnswers/SET_SELECTED_PROCESS', item);
  if (item.notificationByWhatsapp) {
    openModal('PendingAnswersWhatsappReminderModal');
  } else {
    handleWhatsappReminder();
  }
};

onMounted(() => {
  fetchPendingAnswers();
});
</script>

<template>
  <div>
    <div
      v-if="isEnabled(FEATURES.FORM_FLOW)"
      :class="$style.container"
      data-testid="pendingAnswersView"
    >
      <PendingAnswersHeader :loading="loading" />
      <XCard elevation="low">
        <template v-if="pendingAnswers.length > 0 || loading">
          <XTable
            data-testid="pendingAnswersViewTable"
            :class="$style.table"
            :headers="HEADERS"
            :items="pendingAnswers"
            :loading="loading"
          >
            <template #items-col-secondRecipient="{ item }">
              <XLink
                :class="$style.link"
                @click="handleShowDetails(item)"
              >
                {{ item.secondRecipient }}
              </XLink>
            </template>
            <template #items-col-name="{ item }">
              <XLink
                :class="$style.link"
                @click="handleShowDetails(item)"
              >
                {{ item.name }}
              </XLink>
            </template>
            <template #items-col-createdAt="{ item }">
              <XLink
                :class="$style.link"
                @click="handleShowDetails(item)"
              >
                {{ item.createdAt }}
              </XLink>
            </template>
            <template #items-col-action="{ item }">
              <div :class="$style.actions">
                <XTooltip placement="top">
                  <template #activator>
                    <XButton
                      model="icon"
                      data-testid="reminderButton"
                      @click="handleShowWhatsappReminder(item)"
                    >
                      <XIcon icon="paper-plane" />
                    </XButton>
                  </template>
                  {{ $t('pendingAnswers.table.reminder') }}
                </XTooltip>
                <XTooltip placement="top">
                  <template #activator>
                    <XButton
                      model="icon"
                      data-testid="disapproveButton"
                      @click="handleShowDisapprove(item)"
                    >
                      <XIcon icon="trash" />
                    </XButton>
                  </template>
                  {{ $t('pendingAnswers.table.delete') }}
                </XTooltip>
              </div>
            </template>
          </XTable>
          <XPagination
            v-if="pagination.pageCount > 1"
            align="center"
            :next-text="$t('general.action.next')"
            :prev-text="$t('general.action.previous')"
            :total-pages="pagination.pageCount"
            data-testid="paginationContainer"
          />
        </template>
        <i18n
          v-else
          :class="$style.emptyText"
          tag="p"
          path="pendingAnswers.emptyText"
        >
          <br />
        </i18n>
      </XCard>
    </div>
    <FlowDisabledContainer
      v-else
      type="default"
      data-testid="flowDisabledContainer"
    />
    <PendingAnswersDetailsModal
      :show-modal="isModalActive('PendingAnswersDetailsModal')"
      @close="closeModal"
    />
    <PendingAnswersDisapproveModal
      :show-modal="isModalActive('PendingAnswersDisapproveModal')"
      data-testid="disapproveModal"
      @close="closeModal"
      @confirm="handleDisapproveAnswer"
    />
    <PendingAnswersWhatsappReminderModal
      :show-modal="isModalActive('PendingAnswersWhatsappReminderModal')"
      @confirm="handleWhatsappReminder"
      @close="closeModal"
    />
  </div>
</template>

<style lang="scss" module>
.container {
  padding: var(--space-large);

  .table {
    margin-bottom: var(--space-medium);

    .link {
      color: var(--color-neutral-1000);
    }

    .actions {
      display: flex;
      gap: var(--space-small-xx);
    }
  }

  .emptyText {
    margin-top: var(--space-regular);
    text-align: center;
    color: var(--color-neutral-700);
  }
}
</style>
