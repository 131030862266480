<template>
  <component
    :is="currentModal"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot
      v-for="(_, slot) of $slots"
      :slot="slot"
      :name="slot"
    />
  </component>
</template>

<script>
import BaseBackdropModal from './BaseBackdropModal.vue';
import BaseFullscreenModal from './BaseFullscreenModal.vue';

export default {
  name: 'BaseModal',
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentModal() {
      return this.fullscreen ? BaseFullscreenModal : BaseBackdropModal;
    },
  },
};
</script>
