const group = {
  title: 'Grupos',
  create: {
    title: 'Grupo',
  },
  groupModal: {
    create: {
      title: 'Adicionar grupo',
    },
    edit: {
      title: 'Editar grupo',
    },
    alert: {
      title: 'Agora você pode organizar usuários em grupos',
      description: 'Mais eficiência na hora de gerir pessoas e controlar o acesso às pastas',
    },
    form: {
      group: {
        label: 'Nome do grupo',
      },
      users: {
        label: 'Usuários',
      },
      searchPlaceholder: 'Buscar...',
      action: 'Concluir',
      cancel: 'Cancelar',
    },
    toast: {
      create: {
        success: '{name} foi criado com sucesso',
        error: 'Erro ao criar grupo',
      },
      edit: {
        success: '{name} foi atualizado com sucesso',
        error: 'Erro ao atualizar grupo',
      },
      removeGroupUser: {
        success: 'Usuário removido do grupo',
        error: 'Erro ao remover usuário do grupo',
      },
    },
    loading: 'Carregando informações',
    membershipsListError: 'Erro ao carregar usuários do grupo',
  },
  groupCollapse: {
    optionsDropdown: {
      actionButton: 'Mais opções',
      edit: 'Editar grupo',
      remove: 'Remover grupo',
    },
    pagination: {
      previous: 'Anterior',
      next: 'Próxima',
    },
    emptyState: {
      title: 'Nenhum usuário neste grupo',
      action: 'Adicionar usuário',
    },
  },
  removeGroupModal: {
    title: 'Remover o grupo {name} desta conta?',
    description:
      'As permissões concedidas ao grupo {name} serão retiradas dos usuários imediatamente. Nenhum usuário será removido da conta.',
    action: 'Remover',
    cancel: 'Cancelar',
    toast: {
      success: 'Grupo {name} removido com sucesso',
      error: 'Erro ao remover grupo {name}',
    },
  },
};

export default group;
