const download = {
  title: 'Documento disponível',
  subtitle: 'Seu documento já pode ser baixado aqui!',
  description: 'Saiba {0} e como ela pode ajudar no seu dia a dia!',
  whatIs: 'o que é uma assinatura eletrônica',
  availableUntil: 'Documento disponível até {0}',
  action: 'Baixar',
  help: 'Precisa de ajuda? Visite nossa {0} ou envie um e-mail para {1}.',
  helpCenter: 'Central de ajuda',
  helpEmail: 'ajuda@clicksign.com',
  expired: {
    title: 'Prazo para baixar expirado',
    subtitle: 'Poxa, este documento não está mais disponível aqui.',
    description:
      'Entre em contato com quem enviou para assinatura para solicitar o documento assinado',
  },
};

export default download;
