<template>
  <XModal
    :is-open="showModal"
    data-testid="formFlowDeleteModalContainer"
    @close="$emit('close')"
  >
    <template #header>
      {{ $t('flowModule.modalFormFlowDelete.header') }}
    </template>
    <template #default>
      <div :class="$style.info">
        <img
          src="@flow/assets/icons/attention.svg"
          height="64"
          width="64"
        />
        <p :class="$style.text">
          {{ $t('flowModule.modalFormFlowDelete.title') }}
        </p>
      </div>
    </template>
    <template #actions>
      <div :class="$style.actions">
        <XButton
          design="outlined"
          data-testid="modalDeleteCloseButton"
          @click="$emit('close')"
        >
          {{ $t('general.action.close') }}
        </XButton>
        <XButton
          data-testid="modalDeleteButtonSubmit"
          @click="$emit('delete')"
        >
          {{ $t('general.action.delete') }}
        </XButton>
      </div>
    </template>
  </XModal>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FormFlowDeleteModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" module>
.info {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--space-small-xx);

  .text {
    padding: 0 var(--space-regular);
    text-align: center;
  }
}

.actions {
  display: flex;
  gap: var(--space-regular);
}
</style>
