const sortAccounts = (accounts) => {
  return accounts.toSorted((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    return 1;
  });
};
export default {
  SET_LINKS(state, payload) {
    state.links = { ...state.links, ...payload };
  },
  SET_USER(state, payload) {
    state.user = { ...payload };
  },
  SET_ORGANIZATIONS(state, payload) {
    state.organizations = [...payload];
  },
  SET_ORGANIZATION(state, payload) {
    state.organization = { ...payload };
    state.organizations = state.organizations.map((organization) => {
      if (organization.id === payload.id) {
        return {
          ...organization,
          name: payload.name || organization.name,
          brand: payload.brand || organization.brand,
          workspaces: payload.workspaces.map((workspace) => ({
            ...workspace,
            accounts: organization.workspaces?.find((w) => w.id === workspace.id)?.accounts || [],
            pagination:
              organization.workspaces?.find((w) => w.id === workspace.id)?.pagination || {},
          })),
        };
      }
      return organization;
    });
  },
  SET_WORKSPACE(state, payload) {
    state.organizations = state.organizations.map((organization) => {
      if (organization.id === payload.organizationId) {
        return {
          ...organization,
          workspaces: organization.workspaces.map((workspace) => {
            if (workspace.id === payload.id) {
              const previousAccountsWithoutDuplication = workspace.accounts.filter((account) => {
                return !payload.accounts.find((acc) => acc.id === account.id);
              });
              const newAccounts = [...previousAccountsWithoutDuplication, ...payload.accounts];
              const sortedNewAccounts = sortAccounts(newAccounts);
              const accounts =
                payload.order === 'asc' ? sortedNewAccounts : sortedNewAccounts.toReversed();

              return {
                ...workspace,
                accounts,
                pagination: payload.pagination,
              };
            }
            return workspace;
          }),
        };
      }
      return organization;
    });
  },
  SORT_WORKSPACE_ACCOUNTS(state, payload) {
    state.organizations = state.organizations.map((organization) => {
      if (organization.id === payload.organizationId) {
        return {
          ...organization,
          workspaces: organization.workspaces.map((workspace) => {
            if (workspace.id === payload.id) {
              const sortedAccounts = sortAccounts(workspace.accounts);
              const accounts =
                payload.order === 'asc' ? sortedAccounts : sortedAccounts.toReversed();
              return {
                ...workspace,
                accounts,
              };
            }
            return workspace;
          }),
        };
      }
      return organization;
    });
  },
  SET_OPENED_WORKSPACE_ID(state, payload) {
    state.organizations = state.organizations.map((organization) => {
      if (organization.id === payload.organizationId) {
        return {
          ...organization,
          openedWorkspaceId: payload.id,
        };
      }
      return organization;
    });
  },
  CLEAR_WORKSPACE_DATA(state, payload) {
    state.organizations = state.organizations.map((organization) => {
      if (organization.id === payload.organizationId) {
        return {
          ...organization,
          workspaces: organization.workspaces.map((workspace) => {
            if (workspace.id === payload.id) {
              return {
                ...workspace,
                accounts: [],
                pagination: {},
              };
            }
            return workspace;
          }),
        };
      }
      return organization;
    });
  },
};
