import { axios } from '@/plugins';

const createOnboarding = async ({ getters, commit }, payload) => {
  try {
    const postOnboardingUrl = getters?.getLinks?.createOnboarding;
    const { data } = await axios.post(postOnboardingUrl, payload);
    commit('SET_ONBOARDING', data);
    return data;
  } catch (err) {
    throw err;
  }
};

const fetchReasons = async ({ getters, commit }) => {
  try {
    const requestUrl = _.get(getters, 'getLinks.reasons');
    const { data } = await axios.get(requestUrl);

    commit('SET_REASONS', data.reasons);

    return data.reasons;
  } catch (error) {
    throw error;
  }
};

export default {
  createOnboarding,
  fetchReasons,
};
