export default {
  SET_LINKS: (state, payload) => {
    Object.assign(state.links, { ...payload });
  },
  SET_FORMS: (state, payload) => {
    state.forms = payload;
  },
  SET_PAGINATION: (state, payload) => {
    state.pagination = payload;
  },
};
