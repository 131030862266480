import { kebabCase, uniqueId } from 'lodash';

const getIdFromField = (field) => uniqueId(`${kebabCase(field.label)}`);

const mountFieldWithId = (fields) => {
  return fields.map((field) => {
    return {
      ...field,
      id: getIdFromField(field),
    };
  });
};

export default {
  SET_FIELDS(state, payload) {
    state.formFields1 = mountFieldWithId(payload.formFields1);
    state.formFields2 = mountFieldWithId(payload.formFields2);
  },
  UPDATE_FIELD(state, { value, index, form }) {
    state[`formFields${form}`][index] = value;
  },
  DELETE_FIELD(state, { index, form }) {
    state[`formFields${form}`].splice(index, 1);
  },
  ADD_FIELD(state, { form, value }) {
    state[`formFields${form}`].push({ ...value, id: getIdFromField(value) });
  },
  ADD_FIELD_FROM_INDEX(state, { form, value, index }) {
    const fieldArray = state[`formFields${form}`];
    const position = index + 1;

    fieldArray.splice(position, 0, { ...value, id: getIdFromField(value) });
  },
  REORDER_FIELDS(state, { form, fields }) {
    state[`formFields${form}`] = fields;
  },
};
