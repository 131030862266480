import { computed, unref } from 'vue';

import { isReadonly, isObserver, isDownloader, isAdmin } from '@/utils/role';

export const useRole = (currentUser) => {
  const user = computed(() => unref(currentUser));

  return {
    isReadonly: computed(() => isReadonly(user.value.role)),
    isObserver: computed(() => isObserver(user.value.role)),
    isDownloader: computed(() => isDownloader(user.value.role)),
    isAdmin: computed(() => isAdmin(user.value.role)),
  };
};
