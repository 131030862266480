import { getCreditCardNameByNumber } from 'creditcard.js';

// TOGGLE_API_CREDIT_CARD_ENABLED
// TODO: Remove isToggleEnabled
const isToggleEnabled = (toggle) => {
  try {
    const { account } = JSON.parse(localStorage.getItem('account'));
    return account.toggles?.[toggle];
  } catch (error) {
    return false;
  }
};

// TOGGLE_API_CREDIT_CARD_ENABLED
// TODO: Remove line below
const isApiCreditCardEnabled = isToggleEnabled('toggleApiCreditCardEnabled');

export const getCreditCardBrand = (value = '') => {
  if (isApiCreditCardEnabled) {
    const response = getCreditCardNameByNumber(value);
    const cards = {
      Amex: 'american_express',
      Diners: 'diners_club',
      Elo: 'elo',
      Hipercard: 'hipercard',
      Mastercard: 'mastercard',
      Visa: 'visa',
    };
    return cards[response] ?? '';
  }
  // TOGGLE_API_CREDIT_CARD_ENABLED
  // TODO: Remove code below until the end of the function
  if (value === '') return value;

  const cardsRegex = {
    visa: /^4[0-9]{12}(?:[0-9]{3})?/,
    mastercard: /^5[1-5][0-9]{14}/,
    american_express: /^3[47][0-9]{13}/,
    diners_club: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    hipercard: /^606282|^3841(?:[0|4|6]{1})0/,
    elo: /(4011\d{12}|431274\d{10}|438935\d{10}|451416\d{10}|457393\d{10}|4576\d{12}|457631\d{10}|457632\d{10}|504175\d{10}|50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])\d{10}|627780\d{10}|636297\d{10}|636368\d{10}|636369\d{10}|(6503[1-3])\d{11}|(6500(3[5-9]|4[0-9]|5[0-1]))\d{10}|(6504(0[5-9]|1[0-9]|2[0-9]|3[0-9]))\d{10}|(650(48[5-9]|49[0-9]|50[0-9]|51[1-9]|52[0-9]|53[0-7]))\d{10}|(6505(4[0-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-8]))\d{10}|(6507(0[0-9]|1[0-8]))\d{10}|(6507(2[0-7]))\d{10}|(650(90[1-9]|91[0-9]|920))\d{10}|(6516(5[2-9]|6[0-9]|7[0-9]))\d{10}|(6550(0[0-9]|1[1-9]))\d{10}|(6550(2[1-9]|3[0-9]|4[0-9]|5[0-8]))\d{10}|(506(699|77[0-8]|7[1-6][0-9]))\d{10}|(509([0-9][0-9][0-9]))\d{10})/,
  };

  const cardNumber = value.replace(/\s/g, '');
  const cardBrand = Object.keys(cardsRegex).find((key) => cardsRegex[key].test(cardNumber));
  return cardBrand || '';
};
