import BaseToasterLinkError from '@base/components/BaseToasterLinkError.vue';

export default [
  {
    name: 'info',
    title: 'signer.infoStep.title',
    component: 'EnvelopeSignerInfoForm',
    form: 'signerInfo',
    props: {
      signerAuths: 'signerAuths',
      signerCommunicate: 'signerCommunicate',
      signerSignAs: 'signerSignAs',
      email: 'signerInfo.email',
      phoneNumber: 'signerInfo.phoneNumber',
      name: 'signerInfo.name',
      birthday: 'signerInfo.birthday',
      hasDocumentation: 'signerInfo.hasDocumentation',
      documentation: 'signerInfo.documentation',
      signAs: 'signerInfo.signAs',
      auths: 'signerInfo.auths',
      contact: 'signerInfo.contact',
      communicateBy: 'signerInfo.communicateBy',
      officialDocumentEnabled: 'signerInfo.officialDocumentEnabled',
      selfieEnabled: 'signerInfo.selfieEnabled',
      handwrittenEnabled: 'signerInfo.handwrittenEnabled',
      livenessEnabled: 'signerInfo.livenessEnabled',
      additionalAuths: 'additionalAuthsItems',
      facialBiometricsEnabled: 'signerInfo.facialBiometricsEnabled',
      transferSaveAsContactSwitch: 'transferSaveAsContactSwitch',
    },
    baseState: {
      email: '',
      phoneNumber: null,
      name: '',
      birthday: '',
      hasDocumentation: true,
      documentation: '',
      signAs: '',
      auths: [],
      officialDocumentEnabled: false,
      selfieEnabled: false,
      handwrittenEnabled: false,
      contact: true,
      livenessEnabled: false,
      facialBiometricsEnabled: false,
    },
    action: async (vm, form) => {
      if (form.contact) {
        const url = vm.$store.getters['signer/getLinks']?.contacts;

        await vm.$store.dispatch('signer/createContact', {
          url,
          payload: form,
          context: vm.context,
        });
      }
      const mockFormAuths = {
        ...form,
        auths: form.communicateBy,
        contact: false,
      };
      const url = _.get(vm.$store.getters, 'signer/getLinks.createSigner');
      return vm.$store.dispatch('signer/createSigner', { url, payload: mockFormAuths });
    },
    afterAction: (vm, signer) => {
      const buildedSigner = {
        ...signer,
        auths: vm.signerInfo?.auths,
        context: vm.context,
      };
      Object.assign(vm.signAs, { signers: [buildedSigner] });
    },
  },
  {
    name: 'signAs',
    title: 'signer.infoStep.signAs.label',
    component: 'SignerListSignAsStep',
    form: 'signAs',
    props: {
      signers: 'signAs.signers',
      signerSignAs: 'getSignerSignAsItems',
      signAsList: 'signAs.signAsList',
      flexibleContexts: 'flexibleContexts',
    },
    baseState: {
      signers: [],
      signAsList: {},
      lists: {},
    },
    action: async (vm, payload) => {
      const { signers } = payload;

      await Promise.all(
        signers.map(async (signer) => {
          // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
          // TODO: delete const toggles
          const toggles = _.get(vm.$store.getters, 'info/getToggles');

          // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
          // TODO: delete variable requirements and add into linkSignerPayload
          let requirements;
          if (toggles.toggleNewEnvelopeSetupEnabled) {
            requirements = {
              requirements: { roles: signer.newRoles, provide_evidences: signer.auths },
            };
          }

          const linkSignerPayload = {
            signerKey: signer.key,
            refusable: signer.features?.refusable,
            ...requirements,
          };

          await vm.$store.dispatch('envelope/linkSigner', linkSignerPayload);
          await vm.$store.dispatch('envelope/addRequirement', signer);
        })
      );
      vm.$emit('save');
    },
    errorHandler: (vm, err) => {
      if (!(err && err.response && err.response.status === 422)) return;
      const { data } = err.response;
      if (_.isArray(data)) {
        const errors = data.filter((error) => !_.isEmpty(error.signers.errors));
        const signersErrors = _.reduce(
          errors,
          (map, value) => {
            const { signerId, signAs } = value.signers;
            const signAsList = { [signerId]: map[signerId] || [] };
            const signerSignAsErrors = _.get(signAsList, signerId);
            return Object.assign(map, { [signerId]: [...signerSignAsErrors, signAs] });
          },
          {}
        );
        vm.$set(vm, 'errorMessages', signersErrors);
        vm.$nextTick(() => {
          vm.hasCurrentStepValidationError();
        });
      } else {
        vm.$toast.error({
          component: BaseToasterLinkError,
          props: {
            message: vm.$t('signer.error.generic.action'),
            linkText: vm.$t('signer.error.generic.supportLinkText'),
            link: vm.$t('links.support'),
          },
        });
      }
    },
  },
];
