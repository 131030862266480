import axios from '@/plugins/axios';

export async function registerOperation({ links, name, data }, options) {
  const url = links.operations;
  const jsonRootKey = options?.jsonRootKey ?? 'editingSession';

  const response = await axios.post(url, {
    [jsonRootKey]: {
      operation: {
        name,
        data,
      },
    },
  });

  return response.data;
}
