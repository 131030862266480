const spreadsheetFlow = {
  setup: {
    spreadsheetFile: {
      steps: {
        download: {
          description: 'Baixar',
          substepTitle: 'Baixar planilha',
          substepHeader: 'Baixe a planilha com as variáveis do seu modelo',
          subtitle: 'Esta é uma referência de quais devem ser os nomes das colunas',
          additionalText:
            'Use este modelo de planilha para facilitar o mapeamento das informações na etapa seguinte',
          action: 'Baixar planilha',
        },
        fulfill: {
          description: 'Preencher',
          substepTitle: 'Preencher planilha',
          substepHeader: 'Preencha com as informações necessárias',
          subtitle: 'Cada linha corresponde a um documento que será gerado',
          additionalText: 'Lembre de não deixar células em branco!',
        },
        upload: {
          description: 'Enviar',
          substepTitle: 'Enviar planilha',
          substepHeader: 'Envie a planilha preenchida',
          subtitle: 'É importante que o nome das colunas seja o mesmo da planilha modelo',
          action: 'Selecionar',
        },
      },
    },
    fieldMappings: {
      auths: {
        api: 'Api',
        handwritten: 'Assinatura manuscrita',
        liveness: 'Selfie dinâmica',
        officialDocument: 'Documento oficial',
        selfie: 'Selfie com documento',
        email: 'E-mail',
        sms: 'SMS',
        pix: 'Pix',
        icpBrasil: 'Certificado digital',
        whatsapp: 'Whatsapp',
      },
      title: 'Mapeamento',
      subtitle:
        'Combine os dados variáveis com as respectivas colunas e selecione o tipo de dado, quando houver.',
      table: {
        loading: 'Carregando dados da planilha...',
        variable: { empty: '{{ Variável não mapeada }}' },
        columnType: { empty: 'Tipo não identificado' },
        tooltip: {
          preview: 'Pré-visualizar documento',
          edit: 'Editar linha',
          finishMappings:
            'Finalize o mapeamento e corrija os erros da linha para pré-visualizar o documento',
        },
        actions: 'Ações',
      },
      templateMappings: {
        title: 'Variáveis do modelo - {mappedFields} de {fieldTotal} mapeadas',
        description: 'Agora, selecione a coluna de cada variável',
      },
      signerMappings: {
        form: {
          email: { label: 'E-mail do signatário' },
          name: { label: 'Nome completo do signatário' },
          documentation: { label: 'CPF do signatário' },
          birthday: { label: 'Data de nascimento do signatário' },
          phoneNumber: { label: 'Número de celular' },
        },
        description: '{currentSignerIndex} de {totalSigners} signatários',
      },
      previewModal: {
        header: 'Pré-visualizar documento',
        loader: {
          wait: 'Aguarde uns instantes para pré-visualizar...',
          largeFiles: 'Se o documento for grande, pode ser que leve alguns minutos',
        },
        documentsCounter: 'Documento {selectedDocument} de {totalDocuments}',
        unmapped: '{data} não mapeado',
        signers: 'Signatários',
        name: 'Nome',
        signAs: 'Assinar como',
        data: 'Dado',
        signingMethodLabel: 'Autenticar usando',
        fixedSigner: 'Signatário fixo',
        variableSigner: 'Signatário variável',
      },
      close: 'Fechar',
      columnsTypes: {
        email: {
          label: 'E-mail',
          tooltip: 'Formato do e-mail: email@exemplo.com',
        },
        cep: {
          label: 'CEP',
          tooltip: 'No máximo 8 dígitos. Aceita números e traço',
        },
        documentation: {
          label: 'CPF',
          tooltip: 'No máximo 11 dígitos. Aceita números, ponto e traço',
        },
        date: {
          label: 'DATA',
          tooltip: 'Formato da data: DD/MM/AAAA',
        },
        phone: {
          label: 'Número de celular',
          tooltip: 'No máximo 12 dígitos. Aceita números, traços e parênteses',
        },
        number: {
          label: 'Numérico',
          tooltip: 'Aceita apenas números, ponto e vírgula',
        },
        fullName: {
          label: 'Nome completo',
          tooltip: 'Insira pelo menos um sobrenome',
        },
        string: {
          label: 'Texto e outros',
          tooltip: 'Campo sem validação',
        },
      },
      edit: {
        title: 'Editar dados da linha da planilha',
        columnField: { label: '{label} | Tipo {columnType}' },
        description: 'Exibindo os dados da linha {rowNumber}',
        error: 'Corrija os erros abaixo para gerar o documento',
      },
      errorsDialog: {
        title: 'Erros na planilha',
        description:
          'Há {errorsCount} linha com erro na sua planilha. O documento dessa linha não será enviado caso o erro não seja corrigido. | Há {errorsCount} linhas com erros na sua planilha. Os documentos dessas linhas não serão enviados caso os erros não sejam corrigidos.',
        confirmation: 'Deseja continuar o envio mesmo assim?',
      },
      columnsErrors:
        '{errorsCount} erro encontrado na coluna | {errorsCount} erros encontrados na coluna',
      type: 'Tipo',
    },
    finishStep: {
      title:
        '{countDocumentsToSend} documento pronto para enviar | {countDocumentsToSend} documentos prontos para enviar',
      action: 'Enviar agora!',
    },
  },
  actions: {
    cancel: {
      description: 'Cancelar',
      confirm: {
        title: 'Deseja mesmo cancelar?',
        description: 'Os documentos já enviados serão cancelados.',
        descriptionOld: 'Os documentos já enviados serão cancelados. Esta é uma ação irreversível',
        info: 'Esta é uma ação irreversível',
        action: 'Sim, cancelar',
        cancel: 'Manter',
      },
    },
    delete: {
      description: 'Excluir',
      confirm: {
        title: 'Excluir fluxo dos rascunhos',
        description: 'Tem certeza que deseja excluir este fluxo de seus rascunhos?',
        info: 'Esta ação é irreversível.',
        confirmation: 'Selecione para confirmar a exclusão',
        action: 'Excluir',
        cancel: 'Cancelar',
      },
    },
  },
  steps: {
    flowIdentification: 'Introdução',
    templateSelection: 'Modelo',
    spreadsheetSigner: 'Signatários',
    spreadsheetUpload: 'Planilha',
    spreadsheetFieldMappings: 'Mapeamento',
    spreadsheetFlowDataAnalysis: 'Análise de dados',
    spreadsheetDocName: 'Salvar como',
    flowSettings: 'Configurações',
    spreadsheetFlowFinishStep: 'Envio',
  },
  errors: {
    required: 'Campo obrigatório',
  },
  modalAlert: {
    header: 'Edição temporariamente indisponível',
    title: 'Este modelo foi utilizado nos seguintes fluxos em processo:',
    description: 'Aguarde a finalização do envio destes fluxos para editar o modelo',
  },
  title: 'Fluxo com planilha',
  description: 'Envie documentos em massa através de uma planilha .csv, .xls ou .xlsx',
  new: 'Criar fluxo',
  saveAsDraft: 'Salvar como rascunho',
  list: {
    filter: {
      placeholder: 'Filtrar por',
      all: 'Todos',
      draft: 'Rascunho',
      sent: 'Enviado',
      canceled: 'Cancelado',
    },
    emptyList: {
      title: 'Não encontramos fluxos para o filtro aplicado',
      subtitle: 'Você pode filtrar por outro status ou mudar para TODOS',
    },
    help: 'Ajuda',
  },
  tooltip:
    'Ao selecionar os campos da planilha correspondentes às variáveis do modelo, será exibida a pré-visualização dos dados da primeira linha da planilha.',
  sidebar: {
    flowIdentification: 'Identificação',
    templateSelection: 'Modelo',
    spreadsheetSigner: 'Lista de assinatura',
    spreadsheetUpload: 'Upload de fonte de dados',
    spreadsheetFieldMappings: 'Mapeamento dos campos',
    spreadsheetFlowDataAnalysis: 'Análise de dados',
    spreadsheetDocName: 'Nome dos documentos',
    config: 'Configurações',
    spreadsheetFlowFinishStep: 'Enviar!',
    flowSettings: 'Configurações',
  },
  stepIdentification: {
    title: 'Identifique este fluxo',
    flowName: 'Nome do fluxo',
  },
  stepSigner: {
    title: 'Lista de assinatura',
    subtitle: 'Adicione signatários para que eles assinem o documento que será gerado.',
    spreadsheetSigner: 'Signatário da planilha',
    modals: {
      spreadsheetNew: {
        title: 'Adicionar signatário da planilha',
        description: 'Os dados deste signatário serão informados pela planilha.',
        signerTitle: 'Digite um título para identificar este signatário.',
      },
      actions: {
        add: 'Adicionar',
        cancel: 'Cancelar',
      },
      tooltip: {
        signerTitle: 'Informe a função deste signatário na sua planilha',
        additionalAuths:
          'Informe se deseja adicionar mais uma forma de autenticação além da obrigatória',
      },
    },
  },
  table: {
    name: 'Nome',
    createdAt: 'Criado em',
    status: 'Status',
    statusMessage: {
      sent: 'Enviado',
      canceled: 'Cancelado',
      sending: 'Enviando...',
      draft: 'Rascunho',
      pendingSend: 'Aguardando envio',
      sendingFailed: 'Falha no envio',
    },
    actions: {
      edit: 'Editar fluxo',
      downloadSpreadsheet: 'Baixar planilha utilizada',
      downloadSpreadsheetOld: 'Baixar planilha',
      cancel: 'Cancelar documentos',
      delete: 'Excluir fluxo',
    },
  },
  stepUpload: {
    title: 'Upload de planilha',
    subtitle: 'Selecione um arquivo no formato .csv, .xls ou xlsx.',
    action: 'Selecionar',
  },
  stepFieldMapping: {
    templateMappingsTitle: 'Campos que preencherão as variáveis do modelo',
    signerMappingsTitle: 'Signatário %{index} - %{description}',
    templateMappingsSubtitle:
      'Selecione o campo da planilha correspondente à variável do modelo para definir o conteúdo do documento.',
    signerMappingsSubtitle:
      'Selecione o campo da planilha correspondente ao campo do signatário para definir o conteúdo do documento.',
    modelVariables: 'Variáveis do modelo',
    signerVariables: 'Campos do signatário',
    spreadsheetFields: 'Campos da planilha',
    preVisualization: 'PRÉ-VISUALIZAÇÃO',
    signerFields: {
      email: 'E-mail',
      phoneNumber: 'Celular',
      name: 'Nome completo',
      documentation: 'CPF',
      birthday: 'Data de nascimento',
    },
  },
  stepDataAnalysis: {
    title: 'Análise de dados',
    subtitle: 'Se houverem erros, corrija-os para avançar',
    rowsImported: '{rowsOk} linha será importada. | {rowsOk} linhas serão importadas',
    rowsErrors:
      '{rowsErrors} linha com erro, corrija seu arquivo, volte e refaça o upload da fonte de dados para avançar. | {rowsErrors} linhas com erros, corrija seu arquivo, volte e refaça o upload da fonte de dados para avançar.',
    rowErrorItem: 'Linha {row} (Signatário: {signer_description})',
    rowRequiredFieldErrorItem: 'Linha {row} ({field})',
    loading: 'Analisando dados da planilha',
  },
  stepDocName: {
    title: 'Nome e pasta dos documentos',
    insertVariable: 'Inserir variável',
    document: {
      subtitle: 'Configure um nome para os documentos',
      description: {
        initial: 'Insira ',
        variable: 'variáveis ',
        complement:
          'para que cada documento gerado tenha nome diferente. Elas serão substituídas pelos conteúdos dos campos da planilha (apenas campos mapeados estarão disponíveis).',
      },
      popover: {
        initial:
          'Uma variável receberá um dado de um campo da planilha, as variáveis disponíveis são as mesmas do modelo selecionado neste fluxo, e serão exibidas na cor amarela entre chaves {{}}.',
        complement:
          'Insira variáveis para que cada documento gerado por este fluxo possua um nome diferente, isto ajudará a identificar o documento.',
        variable: 'Veja um exemplo usando variáveis:',
        example: 'Desta forma o nome do documento ficaria:',
      },
    },
    folder: {
      subtitle: 'Configure em qual pasta os documentos serão gerados',
      description: {
        initial: 'Insira ',
        variable: 'variáveis ',
        complement:
          'se quiser que cada documento seja gerado em pastas com nomes diferentes. Para definir ',
        subfolder: 'sub-pastas ',
        final: 'adicione barras “/” entre cada nome (apenas campos mapeados estarão disponíveis).',
      },
      variable: {
        initial:
          'Uma variável receberá um dado de um campo da planilha, as variáveis disponíveis são as mesmas do modelo selecionado neste fluxo e serão exibidas na cor amarela entre chaves {{}}.',
        complement:
          'Insira variáveis para que cada documento gerado por este fluxo seja armazenado em pastas com conteúdos dos campos do formulário.',
        example: 'Exemplo usando variável:',
        name: 'Desta forma um exemplo de como ficaria o nome da pasta:',
      },
      subpath: {
        initial:
          'Para o documento ser gerado dentro de sub-pastas, você pode digitar separando por uma barra /.',
        variable: 'Veja um exemplo:',
        example:
          'Dessa forma o documento será gerado, por exemplo, dentro da pasta 2020 que está dentro da pasta Contratos.',
      },
    },
  },
  stepSettings: {
    title: 'Configurações',
    subtitle: 'Configurações que serão aplicadas aos documentos gerados.',
    deadlineDate: 'Data limite de assinatura',
    deadlineDateInfo:
      'Selecione quantos dias os documentos estarão disponíveis para serem assinados.',
    autoReminders: 'Lembretes automáticos',
    autoRemindersInfo:
      'Envio de até 3 lembretes por e-mail para signatários que ainda não assinaram.',
    sendReminders: 'Enviar lembretes automaticamente a cada',
    documentLanguage: 'Idioma',
    documentLanguageInfo:
      'A página de assinatura e os e-mails serão exibidos no idioma selecionado.',
    closeDocument: 'Finalização dos documentos',
    closeDocumentInfo: 'Escolha de que forma o documento será finalizado',
    autoClose: 'Após a última assinatura o documento será finalizado automaticamente.',
    manualClose:
      'Manualmente. Você terá que finalizar o documento manualmente, caso contrário, ele será finalizado na data limite de assinatura.',
    english: 'Inglês (EUA)',
    portuguese: 'Português',
    tooltip: {
      deadlineDate:
        'Quando a data limite for atingida, o documento será finalizado automaticamente. Caso não conste nenhuma assinatura, ele será cancelado.',
      documentLanguage:
        'Opção para troca do idioma mostrado no momento da assinatura do documento.',
      closeDocument: 'Escolha quando o documento será finalizado.',
    },
  },
  stepFinish: {
    title: 'Documentos prontos para enviar',
    action: 'Enviar agora!',
  },
  browserAlertModal: {
    title: 'Navegador incompativel',
    content: 'O navegador que você está usando é incompativel com nosso sistema.',
    subContent: 'Utilize a última versão do Chrome, Edge, Safari ou Firefox.',
    close: 'Fechar',
  },
};

export default spreadsheetFlow;
