import { axios } from '@/plugins';

const fetchBlockedAccount = async ({ commit }, link) => {
  const { data } = await axios.get(link);
  commit('SET_ACCOUNT', data.account);
  commit('SET_LINKS', data.links);
};

const fetchAccountReactivate = async ({ getters }) => {
  try {
    const { reactiveAccount } = getters.getLinks;
    await axios.post(reactiveAccount);
  } catch (error) {
    throw error;
  }
};

const createAccountPaymentTrustWindow = async ({ rootGetters }) => {
  try {
    const { trustWindowsPath } = rootGetters['info/getLinks'];
    await axios.post(trustWindowsPath);
  } catch (error) {
    throw error;
  }
};

export default {
  fetchBlockedAccount,
  fetchAccountReactivate,
  createAccountPaymentTrustWindow,
};
