export default {
  SET_FLOW_PROCESSES(state, payload) {
    state.flowProcesses = payload;
  },
  SET_PAGINATION(state, payload) {
    state.pagination = payload;
  },
  SET_PROCESS_DATA(state, payload) {
    state.processData = payload;
  },
  SET_SELECTED_PROCESS(state, payload) {
    state.selectedProcess = payload;
  },
  SET_FLOWS_FILTER(state, payload) {
    state.flowsFilter = payload;
  },
  SET_LINKS(state, payload) {
    state.links = payload;
  },
};
