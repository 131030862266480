const icpBrasil = {
  helpLink: 'Como funciona?',
  providersLink: 'Verificar quais são os provedores atendidos',
  alt: 'Provedor do certificado digital',
  certificateTypes: {
    title: 'Assinatura digital',
    subtitle: 'Selecione o tipo de certificado que deseja para assinar',
    local: 'Certificado Local',
    cloud: 'Certificado em Nuvem',
  },
  howItWorksModal: {
    title: 'Certificado digital',
    whatIs: {
      title: 'O que é Assinatura Digital?',
      text: 'É uma forma de uma pessoa ou empresa comprovar sua identidade na internet, por meio do Certificado Digital.',
    },
    why: {
      title: 'Por que assinar com Certificado Digital?',
      authSignerRequired:
        'O Certificado Digital pode ser uma exigência para autenticação do signatário em alguns casos.',
      icpBrasilAsAuth:
        'O remetente deste documento escolheu Certificado Digital como forma de autenticação.',
    },
  },
  noDocumentationFoundModal: {
    title: 'Certificado não encontrado',
    description:
      'Nenhum Certificado Digital na Nuvem vinculado ao CPF {0} foi localizado. Caso necessário, corrija ou solicite a correção do CPF ao remetente do envio.',
    providersLink: 'Confira se o seu provedor é atendido pela Clicksign.',
  },
  cloudUnavailableModal: {
    title: 'Serviço temporariamente indisponível',
    description:
      'Aguarde alguns instantes para tentar novamente ou entre em contato com o provedor do seu certificado para verificar a instabilidade.',
  },
  webPki: {
    installModal: {
      title: 'Assinatura de PDF',
      text: 'Para continuar, é necessário instalar a extensão Web PKI.',
      helpLink: 'O que é Web PKI?',
      action: 'Instalar',
    },
    helpModal: {
      whatIs: {
        title: 'O que é Web PKI?',
        text: 'O Web PKI é uma extensão necessária para autenticar usuários ou assinar usando certificado digital. ',
      },
      howToUse: {
        title: 'Como usar?',
        installExtension: 'Instale a extensão em seu navegador',
        selectCertificate: 'Selecione o certificado que deseja usar',
        authorize: 'Clique em “Autorizar”',
        insertPin: 'Digite o PIN, Token ou QR Code solicitado',
      },
    },
  },
  mobileAlertModal: {
    computerRequired: 'Para assinar com Certificado Digital, se direcione para o computador.',
    onlyCloud: 'Você só conseguirá assinar pelo celular se tiver um Certificado em Nuvem.',
  },
  accessProblemModal: {
    allCertificatesWithExpDate:
      'Não foram localizados certificados válidos para concluir a assinatura.',
    emptyCertificates: 'Não foram localizados Certificados nesse computador.',
    noneCertificateLinkWithCPF:
      'Não encontramos nenhum Certificado Digital vinculado ao CPF registrado.',
    tutorialSign: 'Dificuldades em assinar?',
    title: {
      default: 'Problemas de acesso',
      certExpire: 'Certificado expirado',
    },
  },
  cloudCertificate: {
    title: 'Selecione o provedor do certificado',
    description: 'Provedores atendidos e vinculados ao CPF: {0}',
  },
  certificateLocal: {
    title: 'Selecione um certificado',
    select: {
      placeholder: 'Certificados disponíveis',
      valid: 'Válidos',
      expired: 'Expirados',
      wrongCpf: 'Não relacionados ao CPF cadastrado',
      company: 'Empresa',
    },
    errors: {
      undefined: 'Erro no processo de assinatura com Certificado.',
      general:
        'Erro no processo de assinatura com Certificado. Informe o código do erro ao suporte da ClickSign:',
      userCancelled: 'Operação foi cancelada pelo usuário',
      concurrentSignature:
        'Há outra pessoa assinando ao mesmo tempo. Tente novamente em alguns minutos',
      cspKeysetNotDefined:
        'Certificado não localizado. Verifique se o cartão ou token está conectado ao computador e tente de novo',
      conversionPdfa: 'Não foi possível converter este documento para PDF/A',
    },
    sign: 'Assinar',
  },
  unavailable: {
    errorLabel: 'ERRO: WIDGET EMBEDDED HABILITADO',
    title: 'Não é possível prosseguir com a assinatura',
    subtitle: 'Informe o erro ocorrido ao remetente do documento',
  },
};

export default icpBrasil;
