export default {
  SET_LINKS: (state, payload) => {
    Object.assign(state.links, { ...payload });
  },
  SET_TEMPLATES: (state, payload) => {
    state.templates = payload;
  },
  SET_SPREADSHEET_FLOW: (state, payload) => {
    state.spreadsheetFlow = payload;
  },
  SET_SIGNERS: (state, payload) => {
    state.signers = payload;
  },
  SET_SPREADSHEET_DATA_ANALYSIS: (state, payload) => {
    state.spreadsheetDataAnalysis = payload;
  },
  SET_SPREADSHEET_FLOWS: (state, payload) => {
    state.spreadsheetFlows = payload;
  },
  SET_SPREADSHEET_DATA: (state, payload) => {
    state.spreadsheetData = payload;
  },
  SET_PAGINATION(state, payload) {
    state.pagination = payload;
  },
  UPDATE_SPREADSHEET_ROW: (state, { row, id: rowId }) => {
    const spreadsheetRows = [...state.spreadsheetData];
    const spreadsheetRowIndex = spreadsheetRows.findIndex(({ id }) => id === rowId);
    const spreadsheetRowToUpdate = spreadsheetRows[spreadsheetRowIndex];
    if (!spreadsheetRowToUpdate) return;
    spreadsheetRowToUpdate.data = row;
    spreadsheetRows[spreadsheetRowIndex] = spreadsheetRowToUpdate;
    state.spreadsheetData = [...spreadsheetRows];
  },
  UPDATE_SPREADSHEET_FLOW: (state, payload) => {
    const spreadsheetFlows = [...state.spreadsheetFlows];
    const spreadsheetFlowIndex = spreadsheetFlows.findIndex(({ id }) => id === payload.id);
    if (!spreadsheetFlows[spreadsheetFlowIndex]) return;
    spreadsheetFlows[spreadsheetFlowIndex] = payload;
    state.spreadsheetFlows = [...spreadsheetFlows];
  },
};
