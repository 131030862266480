import axios from 'axios';

export const fetchConsumptionPeriods = async (url) => {
  try {
    const response = await axios({
      method: 'get',
      url,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
