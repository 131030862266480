<template>
  <main>
    <FormFlowListContainer
      v-if="isFormFlowEnabled"
      :loading="loading"
      data-testid="formFlowListContainer"
    />
    <FlowDisabledContainer
      v-else
      type="default"
      data-testid="flowDisabledContainer"
    />
  </main>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from 'vue';

import { useFunctionalities } from '@base/hooks';
import { FEATURES } from '@base/constants';
import { useStore } from '@/store';

import FormFlowListContainer from '@flow/containers/form-flow/FormFlowListContainer/FormFlowListContainer.vue';
import FlowDisabledContainer from '@flow/containers/disabled/FlowDisabledContainer.vue';

export default defineComponent({
  name: 'FormFlowListView',
  components: {
    FormFlowListContainer,
    FlowDisabledContainer,
  },

  setup() {
    const store = useStore();
    const { isEnabled } = useFunctionalities();
    const loading = ref(true);

    const isFormFlowEnabled = computed(() => isEnabled(FEATURES.FORM_FLOW));

    onMounted(async () => {
      store.commit('formFlow/SET_LINKS', { self: window.location.href });
      await store.dispatch('formFlow/fetchFormFlows');
      loading.value = false;
    });

    return {
      isFormFlowEnabled,
      loading,
    };
  },
});
</script>
