import store from '@/store';
import { sentry } from '@/plugins/sentry';
import { axios } from '@/plugins';

export const loadHubspot = () => {
  window.dataLayer.push({ event: 'hubspot-chat-start' });
  window.hsConversationsSettings = { loadImmediately: false };
};

export const openHubspotChat = () => {
  if (window.HubSpotConversations) {
    const status = window.HubSpotConversations.widget.status();

    if (!status.loaded) window.HubSpotConversations.widget.load();
    window.HubSpotConversations.widget.open();
  }
};

function buildFields(data, objectTypeId = '0-1') {
  if (!data) return [];
  return Object.entries(data).map(([key, value]) => ({
    objectTypeId,
    name: key,
    value: value || '',
  }));
}

function buildInfoPayload(fields, formId) {
  const payload = {
    form: {
      form_id: formId,
      fields,
    },
  };

  return payload;
}

/**
 * Utilitary function to send events to hubspot forms API
 * @see {@link https://public.3.basecamp.com/p/WYbxsDA5VKStgJxeNzuVPCfj Lista de eventos hubspot}
 * @param {object} formName
 * @param {object} payload
 * @example
 * await sendHubspotEvent({ contact: { email: user.email } });
 * */
export async function sendHubspotEvent(formName, { contact, company }) {
  try {
    const hubspotConfig = store.getters['info/getConfig']?.hubspot;
    const formId = hubspotConfig?.[formName];
    const link = hubspotConfig?.links?.hubspotFormsPath;
    const contactFields = buildFields(contact);
    const companyFields = buildFields(company, '0-2');
    const payload = buildInfoPayload([...contactFields, ...companyFields], formId);

    await axios.post(link, payload);
  } catch (error) {
    sentry.captureException(error);
  }
}
