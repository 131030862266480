// TOGGLE_FLOW_WITH_FLEXIBLE_AUTH_ENABLED
// TODO: remove this file

import { parseSigner } from '@document/utils/signer-utils';

export default [
  {
    name: 'info',
    title: 'signer.infoStep.formSigner.title',
    component: 'FlowSignerForm',
    form: 'signerInfo',
    props: {
      signerAuths: 'signerAuths',
      auths: 'signerInfo.auths',
      officialDocumentEnabled: 'signerInfo.officialDocumentEnabled',
      selfieEnabled: 'signerInfo.selfieEnabled',
      handwrittenEnabled: 'signerInfo.handwrittenEnabled',
      livenessEnabled: 'signerInfo.livenessEnabled',
      facialBiometricsEnabled: 'signerInfo.facialBiometricsEnabled',
      hasDocumentation: 'signerInfo.hasDocumentation',
      additionalAuths: 'additionalAuthsItems',
      signerCommunicate: 'signerCommunicate',
      communicateBy: 'signerInfo.communicateBy',
      email: 'signerInfo.email',
      phoneNumber: 'signerInfo.phoneNumber',
    },
    baseState: {
      hasDocumentation: true,
      additionalAuths: '',
      communicateBy: '',
      auths: '',
      email: null,
      phoneNumber: null,
      officialDocumentEnabled: false,
      selfieEnabled: false,
      handwrittenEnabled: false,
      livenessEnabled: false,
      facialBiometricsEnabled: false,
    },
    action: (vm, form) => parseSigner(_.clone(form), vm.context),
    afterAction: (vm, signer) => Object.assign(vm, { signAs: { signers: [signer] } }),
  },
  {
    name: 'signAs',
    title: 'signer.infoStep.signAs.label',
    component: 'SignerListSignAsStep',
    form: 'signAs',
    props: {
      signers: 'signAs.signers',
      signerSignAs: 'getSignerSignAsItems',
      signAsList: 'signAs.signAsList',
      dataSources: 'dataSources',
    },
    baseState: {
      signers: [],
      signAsList: {},
    },
    action: async (vm, payload) => {
      const url = _.get(vm.$store.getters, 'signer/getLinks.linkFormSigner');
      const response = await vm.$store.dispatch('signer/linkSigner', {
        url,
        payload,
        context: vm.context,
      });
      vm.$emit('save', response);
      return response;
    },
    errorHandler: (vm, err) => {
      if (!(err && err.response && err.response.status === 422)) return;
      const { data } = err.response;
      const signersErrors = _.chain(data)
        .castArray()
        .filter((error) => !_.isEmpty(_.get(error, 'flowSigners.errors')))
        .reduce((signAsErrors, signAsError) => {
          const signerId = _.get(signAsError, 'flowSigners.formField.id');
          const signAs = _.get(signAsError, 'flowSigners.signAs');
          const signerSignAsErrors = signAsErrors[signerId] || [];
          signerSignAsErrors.push(signAs);
          Object.assign(signAsErrors, { [signerId]: signerSignAsErrors });
          return signAsErrors;
        }, {})
        .value();
      vm.$set(vm, 'errorMessages', signersErrors);
    },
  },
];
