const general = {
  back: 'Back',
  return: 'Return',
  close: 'Close',
  loading: 'Loading...',
  tryAgain: 'Try again',
  download: 'Download',
  next: 'Next',
  help: 'Help',
  secureEnvironment: 'Secure environment Clicksign',
  signatures: 'Signatures',
  finalize: 'Submit',
  sign: 'Sign',
  options: 'Options',
  userActions: {
    signatures: 'Signatures',
    refusal: 'Decline',
    show: 'View',
    download: 'Download',
    dashboard: 'My signature area',
    help: 'Help',
  },
  feature: {
    beta: 'Beta',
  },
  unavailableWidgetEmbedded: {
    error: 'Error: Clicksign inactive on your site',
    title: 'Cannot proceed with signature.',
    description: 'Check with document sender and report the occured error.',
  },
  noLegalStatus: 'Environment {environment} - No legal value',
  slowLoading: 'Loading this page is taking longer than expected',
};

export default general;
