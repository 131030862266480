export default [
  {
    name: 'info',
    title: 'signer.infoStep.title',
    component: 'SignerInfoForm',
    form: 'signerInfo',
    props: {
      signerAuths: 'signerAuths',
      signerCommunicate: 'signerCommunicate',
      signerSignAs: 'signerSignAs',
      email: 'signerInfo.email',
      phoneNumber: 'signerInfo.phoneNumber',
      name: 'signerInfo.name',
      birthday: 'signerInfo.birthday',
      hasDocumentation: 'signerInfo.hasDocumentation',
      documentation: 'signerInfo.documentation',
      signAs: 'signerInfo.signAs',
      auths: 'signerInfo.auths',
      communicateBy: 'signerInfo.communicateBy',
      officialDocumentEnabled: 'signerInfo.officialDocumentEnabled',
      selfieEnabled: 'signerInfo.selfieEnabled',
      handwrittenEnabled: 'signerInfo.handwrittenEnabled',
      livenessEnabled: 'signerInfo.livenessEnabled',
      additionalAuths: 'additionalAuthsItems',
      facialBiometricsEnabled: 'signerInfo.facialBiometricsEnabled',
    },
    baseState: {
      email: '',
      phoneNumber: null,
      name: '',
      birthday: '',
      hasDocumentation: true,
      documentation: '',
      signAs: '',
      auths: '',
      officialDocumentEnabled: false,
      selfieEnabled: false,
      handwrittenEnabled: false,
      contact: true,
      livenessEnabled: false,
      facialBiometricsEnabled: false,
    },
    action: (vm, form) => {
      const url = _.get(vm.$store.getters, 'signer/getLinks.createSigner');
      return vm.$store.dispatch('signer/createSigner', { url, payload: form });
    },
    afterAction: (vm, signer) => {
      Object.assign(vm.signAs, { signers: [signer] });
    },
  },
  {
    name: 'signAs',
    title: 'signer.infoStep.signAs.label',
    component: 'SignerListSignAsStep',
    form: 'signAs',
    props: {
      signers: 'signAs.signers',
      signerSignAs: 'getSignerSignAsItems',
      signAsList: 'signAs.signAsList',
    },
    baseState: {
      signers: [],
      signAsList: {},
      lists: {},
    },
    action: async (vm, payload) => {
      const { signers, signAsList: rolesBySignerIdList } = payload;

      await Promise.all(
        Object.entries(rolesBySignerIdList).map(async ([signerId, roles]) => {
          const signer = signers.find(({ id }) => id === Number(signerId));

          const linkSignerPayload = {
            signerKey: signer.key,
            roles,
            refusable: signer.features?.refusable,
          };

          await vm.$store.dispatch('envelope/linkSigner', linkSignerPayload);
        })
      );
      vm.$emit('save');
    },
  },
];
