import axios from '@/plugins/axios';
import { usePromise } from '@base/hooks/usePromise';
import { wait } from '@base/utils/wait';
import { registerOperation } from '@envelope/services/setup';
import { snakeCase } from 'lodash';

export default {
  async getEnvelope({ commit }, envelopeApiUrl) {
    try {
      const { data: responseData } = await axios.get(envelopeApiUrl);

      commit('SET_ENVELOPE', responseData.link);
    } catch (error) {
      throw error;
    }
  },

  async fetchSettings({ getters, commit }) {
    try {
      const url = getters.getLinks.settings;
      const { data: responseData } = await axios.get(url);

      commit('SET_SETTINGS', responseData?.settings);

      return null;
    } catch (error) {
      return error;
    }
  },

  async fetchSignatures({ getters, commit }) {
    try {
      const url = getters.getLinks.signatures;
      const { data: responseData } = await axios.get(url);

      commit('SET_SIGNATURES', responseData?.signatures);
      commit('SET_CURRENT_GROUP', responseData?.currentGroup);
      commit('SET_BLOCKED', {
        blockAfterRefusal: responseData?.blockAfterRefusal,
        refused: responseData?.refused,
      });

      return null;
    } catch (error) {
      return error;
    }
  },
  async fetchSignature({ commit }, signer) {
    const url = signer.links.self;
    const [error, responseData] = await usePromise(axios.get(url));

    if (error) return error;

    commit('UPDATE_SIGNER', { ...responseData.signer, key: signer.key });

    return null;
  },

  async updateSettings({ getters, commit }, payload) {
    const url = getters.getLinks.self;
    const [error, responseData] = await usePromise(axios.patch(url, payload));
    if (error) return error;

    commit('SET_SETTINGS', responseData?.settings);
    return null;
  },

  async getDocuments({ commit, getters, rootGetters }) {
    // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
    // TODO: Remove toggle declaration and condition
    const isToggleEnabled = rootGetters?.['featureFlags/isToggleEnabled'];
    const toggleNewEnvelopeSetupEnabled = isToggleEnabled?.('toggleNewEnvelopeSetupEnabled');
    try {
      const url = getters.getLinks?.documents;
      const { data: responseData } = await axios.get(url);

      // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
      // TODO: Remove toggle condition
      if (toggleNewEnvelopeSetupEnabled) {
        const oldDocuments = getters.getDocuments;
        if (oldDocuments.length > 0) {
          const newDocuments = responseData.documents.filter((doc) =>
            oldDocuments.some((d) => d.key === doc.key)
          );
          const newDocumentsMapped = newDocuments.map((item) => {
            const documentFound = oldDocuments.find((i) => i.key === item.key);
            return { ...item, stampKey: documentFound.stampKey, orphan: documentFound.orphan };
          });
          if (newDocumentsMapped.length > 0) {
            newDocumentsMapped.map((newDocument) => {
              commit('UPDATE_DOCUMENT', { ...newDocument, posted: true });
              return newDocument;
            });
          }
        } else {
          const newDocuments = responseData.documents.map((document) => ({
            ...document,
            posted: true,
          }));
          commit('SET_DOCUMENTS', newDocuments);
        }
      } else {
        commit('SET_DOCUMENTS', responseData.documents);
      }
    } catch (error) {
      throw error;
    }
  },

  async fetchOverviewDocuments({ commit, getters }) {
    try {
      const url = getters.getLinks?.documents;
      const { data: responseData } = await axios.get(url);

      commit('SET_DOCUMENTS', responseData.documents);
    } catch (error) {
      throw error;
    }
  },

  async getSigners({ commit, getters }) {
    try {
      const url = getters.getLinks?.signers;
      const { data: responseData } = await axios.get(url);

      commit('SET_SIGNERS', responseData.signers);
    } catch (error) {
      throw error;
    }
  },

  async sendEnvelopeRemind({ getters }, message) {
    const url = getters.getLinks.remind;

    const [error] = await usePromise(
      axios.post(url, {
        envelope: { message },
      })
    );

    if (error) return error;

    return null;
  },

  async sendSignerRemind(_, { signer, message }) {
    const url = signer.links.remind;

    const [error] = await usePromise(
      axios.post(url, {
        envelope: { message },
      })
    );

    if (error) return error;

    return null;
  },

  async editEnvelope({ getters, commit }, payload) {
    const url = getters.getLinks.edit;

    const [error, response] = await usePromise(axios.post(url, payload));
    commit('SET_EDIT_SESSION', response.session);

    if (error) return error;

    return response;
  },
  async renameEnvelope({ getters, commit }, { name }) {
    const url = getters.getLinks.rename;

    try {
      await axios.post(url, { name });

      commit('SET_NAME', name);
    } catch (error) {
      throw error;
    }
  },
  async getEditSession({ commit }, url) {
    const [error, response] = await usePromise(axios.get(url));

    if (error) return error;
    commit('SET_LINKS', response.session.links);
    commit('SET_CONFIG', response.session);
    commit('SET_EDIT_SESSION', response.session);
    commit('signer/SET_LINKS', response.session.links.signerLinks, { root: true });

    return null;
  },
  async destroyEditingSession(_, url) {
    const [error] = await usePromise(axios.delete(url));

    if (error) return error;

    return null;
  },
  async getFolder({ commit, getters }) {
    const url = getters.getLinks.folder;
    try {
      const { data } = await axios.get(url);
      commit('SET_FOLDER', data.folder);
      return data.folder;
    } catch (error) {
      return error;
    }
  },
  async registerOperation({ getters }, { name, data }) {
    const links = getters.getLinks;
    const jsonRootKey = 'editingSession';

    return registerOperation({ links, name, data }, { jsonRootKey });
  },
  async updateFolder({ commit, dispatch }, folder) {
    try {
      await dispatch('registerOperation', {
        name: 'update_folder',
        data: { folderId: folder.id },
      });

      commit('SET_FOLDER', folder);
      return null;
    } catch (error) {
      return error;
    }
  },
  async checkDocumentsPreview({ getters, rootGetters, dispatch, commit }) {
    // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
    // TODO: Remove toggle declaration and condition
    const isToggleEnabled = rootGetters?.['featureFlags/isToggleEnabled'];
    const toggleNewEnvelopeSetupEnabled = isToggleEnabled?.('toggleNewEnvelopeSetupEnabled');

    const documents = getters.getDocuments;
    const hasPreviewNotReady = documents.some((document) => !document.readyStamped);

    const condition = toggleNewEnvelopeSetupEnabled
      ? hasPreviewNotReady
      : documents.length > 0 && hasPreviewNotReady;

    if (condition) {
      commit('envelopeSetup/SET_ALREADY_POOLING', true, { root: true });
      await dispatch('getDocuments', document);
      await wait(3000);
      await dispatch('checkDocumentsPreview');
    }
    commit('envelopeSetup/SET_ALREADY_POOLING', false, { root: true });
  },
  async uploadDocument({ getters, commit, rootGetters, dispatch }, { document, formData }) {
    // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
    // TODO: Remove toggle declaration and condition
    const isToggleEnabled = rootGetters?.['featureFlags/isToggleEnabled'];
    const toggleNewEnvelopeSetupEnabled = isToggleEnabled?.('toggleNewEnvelopeSetupEnabled');
    try {
      const url = getters.getLinks.operations;

      const { data } = await axios.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: ({ loaded, total }) => {
          const progress = Math.round((loaded * 100) / total).toString();
          // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
          // TODO: Remove condition
          if (toggleNewEnvelopeSetupEnabled) {
            /* eslint-disable no-param-reassign */
            document.progress = `${progress}`;
            document.posted = true;
            /* eslint-disable no-param-reassign */
          } else {
            commit('UPDATE_UPLOADING_DOCUMENT', {
              ...document,
              progress,
              posted: true,
              readyStamped: false,
            });
          }
        },
      });
      // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
      // TODO: Remove condition
      if (toggleNewEnvelopeSetupEnabled) {
        const newDocKey = data?.operation.data.documentKey;
        commit('UPDATE_DOCUMENT', {
          ...document,
          key: newDocKey,
          stampKey: document.key,
          posted: true,
          orphan: true,
        });
        await dispatch('addNewDocumentRequirements', newDocKey);
      } else {
        commit('REMOVE_UPLOADING_DOCUMENT', document.key);
        commit('SET_NEW_DOCUMENT', document);
      }

      return null;
    } catch (err) {
      // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
      // TODO: Remove condition
      if (toggleNewEnvelopeSetupEnabled) {
        commit('REMOVE_DOCUMENT', document.key);
      } else {
        commit('REMOVE_UPLOADING_DOCUMENT', document.key);
      }
      return err;
    }
  },
  async deleteDocument({ commit, dispatch, rootGetters }, { document, isCancel = false }) {
    try {
      commit('SET_DELETING_DOCUMENT', document);

      await dispatch('registerOperation', {
        name: isCancel ? 'cancel_document' : 'remove_document',
        data: { documentKey: document.key },
      });

      commit('REMOVE_DELETING_DOCUMENT', document.key);
      // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
      // TODO: Remove toggle declaration and condition
      const isToggleEnabled = rootGetters?.['featureFlags/isToggleEnabled'];
      const toggleNewEnvelopeSetupEnabled = isToggleEnabled?.('toggleNewEnvelopeSetupEnabled');
      if (toggleNewEnvelopeSetupEnabled) {
        if (!isCancel) commit('REMOVE_DOCUMENT', document.key);
      } else {
        commit('REMOVE_DOCUMENT', document.key);
      }

      return null;
    } catch (error) {
      commit('REMOVE_DELETING_DOCUMENT', document.key);
      return error;
    }
  },
  async fetchRefusalReason(_, payload) {
    const url = payload.links.refusal;
    const [error, response] = await usePromise(axios.get(url));

    if (error) return [error, null];
    return [null, response.refusal];
  },
  async removeRefusedSigner(_, payload) {
    const url = payload.links.removeSigner;
    const [error] = await usePromise(axios.delete(url));

    if (error) return error;
    return null;
  },

  async fetchEditSigners({ commit, getters }) {
    const url = getters.getLinks?.signers;
    const { data: responseData } = await axios.get(url);

    commit('SET_GROUPS', responseData.groups);
  },

  async createRequirements(_, { requirements, signer }) {
    const url = signer.links.requirements;

    await Promise.all(
      requirements.map(async (payload) => {
        return axios.post(url, { requirements: payload });
      })
    );
  },

  async removeRequirements(_, { requirements, signer }) {
    const url = signer.links.requirements;

    await Promise.all(
      requirements.map(async (payload) => {
        return axios({
          method: 'delete',
          url,
          data: { requirements: payload },
        });
      })
    );
  },

  async updateRequirements({ dispatch }, { name, data }) {
    await dispatch('registerOperation', { name, data });
  },
  async linkSigner({ dispatch }, payload) {
    await dispatch('registerOperation', {
      name: 'add_signer',
      data: payload,
    });
  },
  async updateSigner({ dispatch }, payload) {
    await dispatch('registerOperation', {
      name: 'update_signer',
      data: payload,
    });
  },
  async addRequirement({ dispatch, rootGetters }, signer) {
    const documents = rootGetters['envelope/getDocuments'];
    // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
    // TODO: Remove toggle declaration and condition
    const isToggleEnabled = rootGetters?.['featureFlags/isToggleEnabled'];
    const toggleNewEnvelopeSetupEnabled = isToggleEnabled?.('toggleNewEnvelopeSetupEnabled');
    if (toggleNewEnvelopeSetupEnabled) {
      await Promise.all(
        documents
          .filter((d) => !['closed', 'canceled'].includes(d.status))
          .map(async (document) => {
            const provideEvidences =
              signer.authentications?.length > 0 ? signer.authentications : signer.auths;

            const payload = {
              signerKey: signer.key,
              documentKey: document.key,
              roles: signer.newRoles,
              provideEvidences: provideEvidences.map(snakeCase),
            };

            await dispatch('registerOperation', {
              name: 'add_requirement',
              data: payload,
            });
          })
      );
    } else {
      await Promise.all(
        documents.map(async (document) => {
          const provideEvidences =
            signer.authentications?.length > 0 ? signer.authentications : signer.auths;

          const payload = {
            signerKey: signer.key,
            documentKey: document.key,
            roles: signer.newRoles,
            provideEvidences: provideEvidences.map(snakeCase),
          };

          await dispatch('registerOperation', {
            name: 'add_requirement',
            data: payload,
          });
        })
      );
    }
  },
  async addNewDocumentRequirements({ dispatch, getters }, documentKey) {
    await dispatch('fetchEditSigners');
    const groups = getters.getGroups;
    const signers = Object.values(groups).flat();
    await Promise.all(
      signers.map(async (signer) => {
        const provideEvidences =
          signer.authentications?.length > 0 ? signer.authentications : signer.auths;

        const payload = {
          signerKey: signer.key,
          documentKey,
          roles: signer.roles,
          provideEvidences: provideEvidences.map(snakeCase),
        };

        await dispatch('registerOperation', {
          name: 'add_requirement',
          data: payload,
        });
      })
    );
    await dispatch('fetchEditSigners');
  },
  async updateRequirement({ dispatch }, signer) {
    await Promise.all(
      signer.documents.map(async (document) => {
        const provideEvidences =
          signer.authentications?.length > 0 ? signer.authentications : signer.auths;

        const payload = {
          signerKey: signer.key,
          documentKey: document.key,
          roles: signer.newRoles,
          provideEvidences: provideEvidences.map(snakeCase),
        };

        await dispatch('registerOperation', {
          name: 'update_requirement',
          data: payload,
        });
      })
    );
  },

  async unlinkSigner({ dispatch }, payload) {
    await dispatch('registerOperation', {
      name: 'remove_signer',
      data: payload,
    });
  },
  async fetchMessage({ commit, getters }) {
    const url = getters.getLinks.messages;
    const [error, responseData] = await usePromise(axios.get(url));

    if (error) return error;

    commit('SET_EMAIL_NOTIFIABLE', responseData.messages.emailNotifiable);
    commit('SET_WHATSAPP_NOTIFIABLE', responseData.messages.whatsappNotifiable);
    commit('SET_OPERATIONS', responseData.messages.hasOperations);
    commit('SET_DEFAULT_MESSAGE', responseData.messages.defaultMessage);

    return null;
  },
  async applyOperations({ getters }, payload) {
    const url = getters.getLinks.finish;
    const [error] = await usePromise(axios.post(url, payload));

    if (error) return error;

    return null;
  },
};
