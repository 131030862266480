import { defaultState } from './state';

const findTargetMessageIndex = (messages, target) => {
  const prop = Object.keys(target)[0];

  return messages.findIndex((message) => message[prop] === target[prop]);
};

export default {
  RESET(state) {
    Object.assign(state, defaultState());
  },
  FINISH_CHAT_THREAD(state) {
    state.isFinished = true;
  },
  ADD_CHAT_THREAD(state, payload) {
    const isUnique = state.chatThread.every(({ timestamp }) => timestamp !== payload.timestamp);
    if (!isUnique) return;

    state.chatThread.push(payload);
  },

  UPDATE_CHAT_THREAD(state, { targetMessage, newMessage }) {
    const index = findTargetMessageIndex(state.chatThread, targetMessage);

    if (index === -1) return;

    state.chatThread.splice(index, 1, newMessage);
  },

  REMOVE_CHAT_THREAD(state, targetMessage) {
    const index = findTargetMessageIndex(state.chatThread, targetMessage);

    if (index === -1) return;

    state.chatThread.splice(index, 1);
  },

  SET_SESSION_LINKS(state, links) {
    state.links.session = links;
  },

  SET_CHAT_DATA(state, payload) {
    state.chatData = { ...state.chatData, ...payload };
  },

  SET_FORM_DATA(state, payload) {
    state.formData = payload;
  },

  SET_LAST_MESSAGE_RECEIVED(state, payload) {
    state.lastMessageReceived = payload;
  },
};
