export default {
  SET_GROUPS: (state, payload) => {
    const newGroups = [...payload, ...state.groups];
    state.groups = [...new Map(newGroups.map((group) => [group.id, group])).values()];
  },
  SET_GROUP: (state, payload) => {
    const newGroups = state.groups.map((group) =>
      group.id !== payload.id ? group : { ...group, ...payload }
    );
    state.groups = [...newGroups];
  },
  SET_LINKS: (state, payload) => {
    state.links = { ...state.links, ...payload };
  },
  SET_USERS: (state, payload) => {
    state.users = [...payload];
  },
  UPDATE_GROUPS_MEMBER: (state, payload) => {
    const getModifiedMembershipIndex = (memberships) =>
      memberships?.findIndex((membership) => membership.id === payload.membership.id);

    state.groups = state.groups.map((group) => {
      const modifiedMembershipIndex = getModifiedMembershipIndex(group.memberships);

      if (modifiedMembershipIndex !== -1) {
        const groupCopy = { ...group };
        if (groupCopy.memberships?.length) {
          groupCopy.memberships[modifiedMembershipIndex] = { ...payload.membership };
        }
        return groupCopy;
      }
      return group;
    });
  },
  REMOVE_GROUPS_MEMBER: (state, payload) => {
    state.groups = state.groups.map((group) => {
      const groupCopy = { ...group };
      if (groupCopy.memberships?.length) {
        groupCopy.memberships = groupCopy.memberships?.filter(
          (membership) => membership.id !== payload.membership.id
        );
      }
      return groupCopy;
    });
  },
  REMOVE_GROUP: (state, payload) => {
    state.groups = state.groups.filter((group) => group.id !== payload.id);
  },
  UPDATE_GROUPS_INVITE: (state, payload) => {
    const getModifiedInviteIndex = (invites) =>
      invites?.findIndex((invite) => invite.id === payload.membership.id);

    state.groups = state.groups.map((group) => {
      const modifiedInviteIndex = getModifiedInviteIndex(group.invites);

      if (modifiedInviteIndex !== -1) {
        const groupCopy = { ...group };
        if (groupCopy.invites?.length) {
          groupCopy.invites[modifiedInviteIndex] = { ...payload.membership };
        }
        return groupCopy;
      }
      return group;
    });
  },
  REMOVE_GROUPS_INVITE: (state, payload) => {
    state.groups = state.groups.map((group) => {
      const groupCopy = { ...group };
      if (groupCopy.invites?.length) {
        groupCopy.invites = groupCopy.invites?.filter(
          (invite) => invite.id !== payload.membership.id
        );
      }
      return groupCopy;
    });
  },
};
