<template>
  <BaseModal
    :value="value"
    :header-classes="[$style.header]"
    :modal-classes="[$style.modal, submitedData && $style.submited]"
    :actions-classes="[$style.footer]"
  >
    <template #header>
      <h4 :class="$style.title">
        {{ $t('disabledFlow.purchase.title') }}
      </h4>
    </template>

    <fieldset
      v-if="!submitedData"
      :class="$style.form"
    >
      <p :class="$style.description">
        {{ $t('disabledFlow.purchase.description') }}
      </p>
      <BaseTextInput
        v-model="userData.name"
        :class="$style.field"
        :label="$t('disabledFlow.purchase.form.name')"
        :validations="$v.userData.name"
        required
        autofocus
      />
      <BaseTextInput
        v-model="userData.email"
        :class="$style.field"
        :label="$t('disabledFlow.purchase.form.email')"
        :validations="$v.userData.email"
        required
      />
      <BaseTextInput
        v-model="userData.accountName"
        :label="$t('disabledFlow.purchase.form.accountName')"
        :class="$style.field"
        :validations="$v.userData.accountName"
        required
      />
      <BaseTextInput
        v-model="userData.phoneNumber"
        v-mask="['(##) ####-####', '(##) #####-####']"
        :class="$style.field"
        :label="$t('disabledFlow.purchase.form.phoneNumber')"
        :validations="$v.userData.phoneNumber"
        required
      />
      <BaseSelect
        v-model="userData.documentsAmount"
        :class="$style.field"
        :label="$t('disabledFlow.purchase.form.documentsAmount')"
        :items="documentsAmountOptions"
        required
      />
    </fieldset>
    <div
      v-else
      :class="$style.success"
    >
      <img
        :class="$style.icon"
        src="@flow/assets/icons/send.svg"
        height="64"
        width="64"
      />
      <strong :class="$style.text">{{ $t('disabledFlow.purchase.success') }}</strong>
    </div>

    <template #actions>
      <small
        v-if="!submitedData"
        :class="$style.requiredLegend"
      >
        {{ $t('disabledFlow.purchase.required') }}
      </small>
      <div :class="$style.actions">
        <template v-if="!submitedData">
          <BaseButton
            :class="$style.button"
            aria-label="Close"
            color="secondary"
            @click="$emit('close-modal')"
          >
            {{ $t('general.action.cancel') }}
          </BaseButton>
          <BaseButton
            :class="$style.button"
            :disabled="!canSubmit"
            :loading="loading"
            @click="$emit('send-user', userData)"
          >
            {{ $t('general.action.send') }}
          </BaseButton>
        </template>
        <template v-else>
          <BaseButton
            :class="$style.button"
            color="secondary"
            data-dismiss="modal"
            @click="$emit('close-modal')"
          >
            {{ $t('general.action.close') }}
          </BaseButton>
        </template>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@base/components/BaseModal.vue';
import BaseButton from '@base/components/BaseButton.vue';
import BaseTextInput from '@base/components/BaseTextInput.vue';
import BaseSelect from '@base/components/BaseSelect.vue';
import { DOCUMENTS_CONTANTS } from '@account/constants';

import * as validators from '@base/validators';

export default {
  name: 'PurchaseAutomationPlanModal',
  components: {
    BaseModal,
    BaseButton,
    BaseTextInput,
    BaseSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    submitedData: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userData: {
        name: '',
        email: '',
        accountName: '',
        phoneNumber: '',
        documentsAmount: '',
      },
    };
  },
  validations: {
    userData: {
      name: {
        required: validators.required,
      },
      email: {
        required: validators.required,
        email: validators.email,
      },
      accountName: {
        required: validators.required,
      },
      phoneNumber: {
        required: validators.required,
        phone: validators.phone,
      },
      documentsAmount: {
        required: validators.required,
      },
    },
  },
  computed: {
    canSubmit() {
      return !this.$v.$invalid;
    },
    documentsAmountOptions() {
      return this.buildOptions(DOCUMENTS_CONTANTS.AMOUNT.map((locale) => this.$t(locale)));
    },
  },
  watch: {
    user: {
      handler(user) {
        this.userData = _.clone(user);
      },
      immediate: true,
    },
  },
  methods: {
    buildOptions(array, text, value) {
      return array
        ?.map((entry) => ({
          text: text ? entry[text] : entry,
          value: value ? entry[value] : entry,
        }))
        .slice(2);
    },
  },
};
</script>

<style lang="scss" module>
.modal {
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .button {
      margin-left: var(--space-small-xx);
    }
  }

  &.submited {
    .footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.title {
  margin: 0;
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-bold);
  color: var(--color-brand-primary-600);
}

.form {
  .description {
    margin-bottom: var(--space-regular);
    color: var(--color-neutral-700);
  }

  .field {
    margin-bottom: var(--space-regular);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.success {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .icon {
    margin-bottom: var(--space-regular);
  }

  .text {
    max-width: 360px;
  }
}

.requiredLegend {
  color: var(--color-neutral-700);
}

.actions {
  display: flex;
}
</style>
