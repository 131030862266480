<template>
  <BaseFormField
    :label="label"
    :required="required"
    :uuid="uuid"
    :help-tooltip="helpTooltip"
    :error-message="errorMessage"
  >
    <select
      :id="uuid"
      v-model="internalValue"
      :name="name"
      :disabled="disabled"
      :readonly="readonly"
      :class="[$style.select, ...selectClasses]"
      :autofocus="autofocus"
      :data-testid="dataTestid"
      :data-ga-event="dataGaEvent"
      @click.stop=""
    >
      <option
        v-if="showDefaultOption"
        :value="defaultOptionValue"
        :disabled="isDefaultOptionDisabled"
        selected
      >
        {{ defaultOption }}
      </option>
      <option
        v-for="(item, index) in items"
        :key="index"
        :value="getItemValue(item)"
      >
        {{ getItemText(item) }}
      </option>
    </select>
  </BaseFormField>
</template>

<script>
import { get } from 'lodash';

import { useGA } from '@base/hooks';
import BaseFormField from '@base/components/BaseFormField.vue';
import { validationsMixin } from '../mixins';

export default {
  name: 'BaseSelect',
  components: { BaseFormField },
  mixins: [validationsMixin],
  props: {
    dataGaEvent: {
      type: String,
      default: null,
    },
    dataGaEventCategory: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Object, Number, Boolean],
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    customError: {
      type: String,
      default: '',
    },
    validations: {
      type: Object,
      default: () => ({}),
    },
    helpTooltip: {
      type: String,
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    selectClasses: {
      type: Array,
      default: () => [],
    },
    dataTestid: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    selectClass: {
      type: String,
      default: '',
    },
    isDefaultOptionDisabled: {
      type: Boolean,
      default: true,
    },
    defaultOptionValue: {
      type: String,
      default: null,
    },
    showDefaultOption: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lazyValue: this.value,
      uuid: '',
      selectedItem: {},
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.lazyValue;
      },
      set(value) {
        this.$emit('input', value);
        this.triggerValidation();
      },
    },
    defaultOption() {
      return this.placeholder || this.$t('general.action.selectOption');
    },
    errorMessage() {
      return this.customError || this.validationError;
    },
  },
  watch: {
    value(val) {
      this.lazyValue = val;
      const selectedItem = this.items.find((item) => this.getItemValue(item) === val);
      this.emitGaEvent(selectedItem?.dataGaEventLabel);
    },
  },
  mounted() {
    this.uuid = _.uniqueId('field-');
  },
  methods: {
    get,
    getItemValue(item) {
      if (this.isStringOrNumber(item) || this.returnObject) return item;
      return this.get(item, this.itemValue);
    },
    getItemText(item) {
      return this.isStringOrNumber(item) ? item : get(item, this.itemText);
    },
    isStringOrNumber(item) {
      return ['string', 'number'].includes(typeof item);
    },
    emitGaEvent(gaEventLabel) {
      if (this.dataGaEvent) {
        useGA('event', this.dataGaEvent, this.dataGaEventCategory, gaEventLabel);
      }
    },
  },
};
</script>

<style lang="scss" module>
.select {
  width: 100%;
  padding: var(--space-small-xx) var(--space-small-x);
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  background-color: var(--color-neutral-0);
  border: solid 1px var(--color-neutral-400);
  border-radius: var(--border-radius-small);
  box-shadow: inset 0 1px 1px rgba(24, 24, 24, 0.075);

  &[disabled] {
    background: var(--color-neutral-400);
  }

  &[readonly] {
    padding: 0;
    border: none;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: default;
    }
  }
}
</style>
