const spreadsheetFlow = {
  setup: {
    spreadsheetFile: {
      steps: {
        download: {
          description: 'Descargar',
          substepTitle: 'Descargar planilla',
          substepHeader: 'Descarga la planilla con las variables de tu modelo',
          subtitle: 'Esta es una referencia de cuáles deben ser los nombres de las columnas',
          additionalText:
            'Utiliza este modelo de planilla para facilitar el mapeo de la información en la siguiente etapa',
          action: 'Descargar planilla',
        },
        fulfill: {
          description: 'Completar',
          substepTitle: 'Completar planilla',
          substepHeader: 'Completar con la información necesaria',
          subtitle: 'Cada fila corresponde a un documento que será generado',
          additionalText: '¡Recuerda no dejar celdas en blanco.!',
        },
        upload: {
          description: 'Enviar',
          substepTitle: 'Enviar planilla',
          substepHeader: 'Envíar la planilla completada',
          subtitle:
            'Es importante que los nombres de las columnas sean los mismos que en la planilla modelo',
          action: 'Selecionar',
        },
      },
    },
    fieldMappings: {
      auths: {
        api: 'Api',
        handwritten: 'Firma manuscrita',
        liveness: 'Selfie dinámica',
        officialDocument: 'Documento oficial',
        selfie: 'Selfie con documento',
        email: 'E-mail',
        sms: 'Correo electrónico',
        pix: 'Pix',
        icpBrasil: 'Certificado digital',
        whatsapp: 'Whatsapp',
      },
      title: 'Mapeao',
      subtitle:
        'Combina los datos variables con las columnas correspondientes y selecciona el tipo de dato, cuando aplique.',
      table: {
        loading: 'Cargando los datos de la planilla...',
        variable: { empty: '{{ Variable no mapeada }}' },
        columnType: { empty: 'Tipo não identificado' },
        tooltip: {
          preview: 'Previsualizar documento',
          edit: 'Editar fila',
          finishMappings:
            'Finalize el mapeo y corrige los errores de la fila para previsualizar el documento',
        },
        actions: 'Acciones',
      },
      templateMappings: {
        title: 'Variables del modelo  - {mappedFields} de {fieldTotal} mapeadas',
        description: 'Ahora, selecciona la columna de cada variable',
      },
      signerMappings: {
        form: {
          email: { label: 'Correo electrónico del firmante' },
          name: { label: 'Nombre completo del firmante' },
          documentation: { label: 'CPF firmante' },
          birthday: { label: 'Fecha de nacimiento del firmante' },
          phoneNumber: { label: 'Número de celular' },
        },
        description: '{currentSignerIndex} de {totalSigners} firmantes',
      },
      previewModal: {
        header: 'Previsualizar documento',
        loader: {
          wait: 'Espera un momento para poder previsualizar...',
          largeFiles: 'Si el documento es grande, puede tardar unos minutos en cargarse',
        },
        documentsCounter: 'Documento {selectedDocument} de {totalDocuments}',
        unmapped: '{data} no mapeado',
        signers: 'Firmantes',
        name: 'Nombre',
        signAs: 'Firmar como',
        data: 'Dato',
        signingMethodLabel: 'Autenticar usando',
        fixedSigner: 'Firmante fijo',
        variableSigner: 'Firmante variable',
      },
      close: 'Cerrar',
      columnsTypes: {
        email: {
          label: 'Correo electrónico',
          tooltip: 'Formato del correo electrónico: email@exemplo.com',
        },
        cep: {
          label: 'CEP',
          tooltip: 'Máximo 8 dígitos. Acepta números y guión',
        },
        documentation: {
          label: 'CPF',
          tooltip: 'Máximo 11 dígitos. Acepta números, punto y guión',
        },
        date: {
          label: 'FECHA',
          tooltip: 'Formato de la fecha: DD/MM/AAAA',
        },
        phone: {
          label: 'Número de celular',
          tooltip: 'Máximo 12 dígitos. Acepta números, guiones y paréntesis',
        },
        number: {
          label: 'Numérico',
          tooltip: 'Acepta solo números, punto y coma',
        },
        fullName: {
          label: 'Nombre completo',
          tooltip: 'Ingresa al menos un apellido',
        },
        string: {
          label: 'Texto y otros',
          tooltip: 'El campo no es validado.',
        },
      },
      edit: {
        title: 'Editar datos de la fila de la planilla',
        columnField: { label: '{label} | Tipo {columnType}' },
        description: 'Mostrando los datos de la fila {rowNumber}',
        error: 'Corrige los errores a continuación para generar el documento',
      },
      errorsDialog: {
        title: 'Erros en la planilla',
        description:
          'Existe {errorsCount} filas con error en tu planilla. El documento de esa fila no será enviado si no se corrige el error. | Existen {errorsCount} filas con errores en tu planilla. Los documentos de esas filas no serán enviados si los errores no se corrigen.',
        confirmation: 'Deseas continuar con el envío de todos modos?',
      },
      columnsErrors:
        '{errorsCount} error encontrado en la columna | {errorsCount} errores encontrados en la columna',
      type: 'Tipo',
    },
    finishStep: {
      title:
        '{countDocumentsToSend} documento listo para enviar | {countDocumentsToSend} documentos listos para enviar',
      action: '¡Enviar ahora!',
    },
  },
  actions: {
    cancel: {
      description: 'Cancelar',
      confirm: {
        title: '¿Realmente deseas cancelar?',
        description: 'Los documentos que ya se enviaron serán cancelados.',
        descriptionOld:
          'Los documentos que ya se enviaron serán cancelados. Esta acción no se podrá deshacer.',
        info: 'Esta acción no se podrá deshacer',
        action: 'Sí, cancelar',
        cancel: 'Mantener',
      },
    },
    delete: {
      description: 'Eliminar',
      confirm: {
        title: 'Eliminar flujo de los borradores',
        description: '¿Estás seguro de que deseas eliminar este flujo de tus borradores?',
        info: 'Esta acción no se podrá deshacer.',
        confirmation: 'Selecciona para confirmar la eliminación',
        action: 'Eliminar',
        cancel: 'Cancelar',
      },
    },
  },
  steps: {
    flowIdentification: 'Introducción',
    templateSelection: 'Modelo',
    spreadsheetSigner: 'Firmantes',
    spreadsheetUpload: 'Planilla',
    spreadsheetFieldMappings: 'Mapeo',
    spreadsheetFlowDataAnalysis: 'Análisis de datos',
    spreadsheetDocName: 'Guardar como',
    flowSettings: 'Configuraciones',
    spreadsheetFlowFinishStep: 'Envío',
  },
  errors: {
    required: 'Campo obligatorio',
  },
  modalAlert: {
    header: 'Edición temporalmente no disponible',
    title: 'Este modelo ha sido utilizado en los siguientes flujos en proceso:',
    description: 'Espera a que finalice el envío de estos flujos para editar el modelo',
  },
  title: 'Flujo con planilla',
  description: 'Envía documentos en masa a través de una planilla .csv, .xls o .xlsx',
  new: 'Crear flujo',
  saveAsDraft: 'Guardar como borrador',
  list: {
    filter: {
      placeholder: 'Filtrar por',
      all: 'Todos',
      draft: 'Borrador',
      sent: 'Enviado',
      canceled: 'Cancelado',
    },
    emptyList: {
      title: 'No encontramos flujos para el filtro aplicado',
      subtitle: 'Puedes filtrar por otro estatus o cambiar a TODOS',
    },
    help: 'Ayuda',
  },
  tooltip:
    'Al seleccionar los campos de la planilla correspondientes a las variables de la Plantilla, se mostrará una previsualización de los datos de la primera fila de la planilla.',
  sidebar: {
    flowIdentification: 'Identificación',
    templateSelection: 'Plantilla',
    spreadsheetSigner: 'Lista de firmantes',
    spreadsheetUpload: 'Subir fuente de datos',
    spreadsheetFieldMappings: 'Mapeo de los campos',
    spreadsheetFlowDataAnalysis: 'Análisis de datos',
    spreadsheetDocName: 'Nombre de los documentos',
    config: 'Configurações',
    spreadsheetFlowFinishStep: 'Enviar!',
    flowSettings: 'Configuraciones',
  },
  stepIdentification: {
    title: 'Identifica este flujo',
    flowName: 'Nombre del flujo',
  },
  stepSigner: {
    title: 'Lista de firmantes',
    subtitle: 'Agrega firmantes para que ellos firmen el documento que será generado.',
    spreadsheetSigner: 'Firmante de la planilla',
    modals: {
      spreadsheetNew: {
        title: 'Agregar firmante de la planilla',
        description: 'Los datos de este firmante serán proporcionados por la planilla.',
        signerTitle: 'Ingresa un título para identificar a este firmante.',
      },
      actions: {
        add: 'Agregar',
        cancel: 'Cancelar',
      },
      tooltip: {
        signerTitle: 'Ingresa el rol de este firmante en tu hoja de cálculo',
        additionalAuths:
          'Ingresa si deseas agregar otra forma de autenticación además de la obligatoria.',
      },
    },
  },
  table: {
    name: 'Nombre',
    createdAt: 'Creado en',
    status: 'Estatus',
    statusMessage: {
      sent: 'Enviado',
      canceled: 'Cancelado',
      sending: 'Enviando...',
      draft: 'Borrador',
      pendingSend: 'Esperando envío',
      sendingFailed: 'Error en el envío',
    },
    actions: {
      edit: 'Editar flujo',
      downloadSpreadsheet: 'Descargar planilla utilizada',
      downloadSpreadsheetOld: 'Descargar planilla',
      cancel: 'Cancelar documentos',
      delete: 'Eliminar flujo',
    },
  },
  stepUpload: {
    title: 'Cargar planilla',
    subtitle: 'Selecciona un archivo en formato .csv, .xls ou xlsx.',
    action: 'Seleccionar',
  },
  stepFieldMapping: {
    templateMappingsTitle: 'Campos que llenarán las variables del modelo',
    signerMappingsTitle: 'Firmante %{index} - %{description}',
    templateMappingsSubtitle:
      'Selecciona el campo de la planilla correspondiente a la variable del modelo para definir el contenido del documento.',
    signerMappingsSubtitle:
      'Selecciona el campo de la planilla correspondiente al campo del firmante para definir el contenido del documento.',
    modelVariables: 'Variables del modelo',
    signerVariables: 'Campos del firmante',
    spreadsheetFields: 'Campos de la planilla',
    preVisualization: 'PREVISUALIZACIÓN',
    signerFields: {
      email: 'Correo electrónico',
      phoneNumber: 'Celular',
      name: 'Nombre completo',
      documentation: 'CPF',
      birthday: 'Fecha de nacimiento',
    },
  },
  stepDataAnalysis: {
    title: 'Análisis de datos',
    subtitle: 'Si hay errores, corrígelos para continuar',
    rowsImported: '{rowsOk} fila será importada. | {rowsOk} filaa serán importadas',
    rowsErrors:
      '{rowsErrors} fila con error, corrige el archivo, vuelve a cargar la fuente de datos para continuar. | {rowsErrors} filas con errores, corrige el archivo, vuelve a cargar la fuente de datos para continuar.',
    rowErrorItem: 'Fila {row} (Firmante: {signer_description})',
    rowRequiredFieldErrorItem: 'Fila {row} ({field})',
    loading: 'Analizando los datos de la planilla',
  },
  stepDocName: {
    title: 'Nombre y carpeta de los documentos',
    insertVariable: 'Insertar variable',
    document: {
      subtitle: 'Configura un nombre para los documentos',
      description: {
        initial: 'Inserta ',
        variable: 'variables ',
        complement:
          'para que cada documento generado tenga un nombre diferente, los contenidos de los campos de la planilla serán reemplazados (solo los campos mapeados estarán disponibles).',
      },
      popover: {
        initial:
          'Una variable recibirá un dato de un campo de la planilla, las variables disponibles son las mismas del modelo seleccionado en este flujo, y se mostrarán en color amarillo entre llaves {{}}.',
        complement:
          'Inserta variables para que cada documento generado por este flujo tenga un nombre diferente, lo que ayudará a identificar el documento.',
        variable: 'Ver un ejemplo usando variables:',
        example: 'De esta forma, el nombre del documento sería:',
      },
    },
    folder: {
      subtitle: 'Configura en qué carpeta se generarán los documentos',
      description: {
        initial: 'Inserta ',
        variable: 'variables ',
        complement:
          'si deseas que cada documento sea generado en carpetas con nombres diferentes. Para definir',
        subfolder: 'subcarpetas',
        final:
          'agrega barras diagonales “/” entre cada nombre (solo los campos mapeados estarán disponibles).',
      },
      variable: {
        initial:
          'Una variable recibirá un dato de un campo de la planilla, las variables disponibles son las mismas del modelo seleccionado en este flujo y se mostrarán en color amarillo entre llaves{{}}.',
        complement:
          'Inserta variables para que cada documento generado por este flujo sea almacenado en carpetas con los contenidos de los campos del formulario.',
        example: 'Ejemplo usando variables:',
        name: 'Así se vería el nombre de la carpeta:',
      },
      subpath: {
        initial:
          'Para que el documento se genere dentro de subcarpetas, puedes escribir separando con una barra /.',
        variable: 'Ver un ejemplo:',
        example:
          'De esta forma, el documento se generará, por ejemplo, dentro de la carpeta 2020 que está dentro de la carpeta Contratos.',
      },
    },
  },
  stepSettings: {
    title: 'Configuraciones',
    subtitle: 'Configuraciones que serán aplicadas a los documentos generados.',
    deadlineDate: 'Fecha límite de firma',
    deadlineDateInfo:
      'Selecciona cuántos días estarán disponibles los documentos para ser firmados.',
    autoReminders: 'Recordatorios automáticos',
    autoRemindersInfo:
      'Envío de hasta 3 recordatorios por correo electrónico a los firmantes que aún no hayan firmado.',
    sendReminders: 'Enviar recordatorios automáticamente cada',
    documentLanguage: 'Idioma',
    documentLanguageInfo:
      'La página de firma y los correos electrónicos se mostrarán en el idioma seleccionado.',
    closeDocument: 'Finalización de los documentos',
    closeDocumentInfo: 'Elige cómo se finalizará el documento',
    autoClose: 'Después de la última firma, el documento será finalizado de manera automática.',
    manualClose:
      'Manualmente. Tendrás que finalizar el documento manualmente, de lo contrario, se finalizará en la fecha límite de firma.',
    english: 'Inglês (EE. UU.)',
    portuguese: 'PortuguÉs',
    tooltip: {
      deadlineDate:
        'Cuando se alcance la fecha límite, el documento será finalizado de manera automática. Si no tiene firmas, será cancelado.',
      documentLanguage:
        'Opción para cambiar el idioma mostrado en el momento de la firma del documento.',
      closeDocument: 'Elige cuándo se finalizará el documento.',
    },
  },
  stepFinish: {
    title: 'Documentos listos para enviar',
    action: '¡Enviar ahora!',
  },
  browserAlertModal: {
    title: 'Navegador incompatible',
    content: 'El navegador que estás usando es incompatible con nuestro sistema.',
    subContent: 'Utiliza la última versión de Chrome, Edge, Safari o Firefox.',
    close: 'Cerrar',
  },
};

export default spreadsheetFlow;
