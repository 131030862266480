export default {
  SET_LINKS: (state, payload) => {
    Object.assign(state.links, { ...payload });
  },
  SET_ADVERTISEMENT: (state, payload) => {
    state.advertisement = payload;
  },
  SET_USER: (state, payload) => {
    state.user = payload;
  },
};
