import Vue from 'vue';
import Excalibur from '@clicksign/excalibur-vue2';
import VueToast from '@clicksign/excalibur-vue2/lib/plugins/vue-toastification';
import { registerIcons } from '@clicksign/ds-icons';

Vue.use(Excalibur);
Vue.use(VueToast);

registerIcons();

export default Excalibur;
