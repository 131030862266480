export default {
  SET_MESSAGE_TEMPLATES(state, payload) {
    state.messageTemplates = payload;
  },
  DELETE_MESSAGE_TEMPLATE(state, payload) {
    const messageTemplateIndex = state.messageTemplates.findIndex((item) => item.id === payload);
    state.messageTemplates.splice(messageTemplateIndex, 1);
  },
  SET_LINKS(state, payload) {
    state.links = payload;
  },
};
