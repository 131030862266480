export default {
  AMOUNT: [
    'accountModule.documentsAmount.until5',
    'accountModule.documentsAmount.from6to20',
    'accountModule.documentsAmount.from21to60',
    'accountModule.documentsAmount.from61to100',
    'accountModule.documentsAmount.from101to500',
    'accountModule.documentsAmount.above500',
  ],
};
