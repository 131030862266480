import { camelizeKeys } from '@base/utils';
import { axios } from '@/plugins';
import { get } from 'lodash';
import { buildTrackingSearchRequest } from '../utils/tracking-utils';

const fetchTrackings = async ({ getters, commit }, payload) => {
  try {
    const fetchTrackingsUrl = get(getters, 'getLinks.self');
    const params = buildTrackingSearchRequest(payload);
    const response = await axios.get(fetchTrackingsUrl, { params });
    const responseData = camelizeKeys(get(response, 'data'));
    commit('SET_LINKS', responseData.links);
    commit('SET_NOTIFICATIONS', responseData.notifications);
    commit('SET_PAGINATION', responseData.pagination);
  } catch (err) {
    throw err;
  }
};

const fetchTrackingDetails = async (_, trackingDetailsUrl) => {
  try {
    const response = await axios.get(trackingDetailsUrl);
    const responseData = camelizeKeys(get(response, 'data.notifications'));
    return responseData;
  } catch (err) {
    throw err;
  }
};

export default {
  fetchTrackings,
  fetchTrackingDetails,
};
