<template>
  <div :class="[$style.field, errorMessage && $style.error]">
    <slot name="label">
      <label
        v-if="label"
        :class="$style.label"
        :for="uuid"
      >
        {{ label }}
        <sup
          v-if="required"
          :class="$style.required"
        >
          *
        </sup>
        <a
          v-if="helpLink"
          :class="$style.helpIcon"
          :href="helpLink"
          target="_blank"
        >
          <XIcon icon="question-circle" />
        </a>
        <XTooltip v-if="helpTooltip">
          <template #activator>
            <XIcon
              icon="question-circle"
              :class="$style.helpIcon"
            />
          </template>
          {{ helpTooltip }}
        </XTooltip>
      </label>
    </slot>
    <slot />
    <div
      v-show="errorMessage"
      :class="$style.errorSlot"
    >
      <span :class="$style.errorMessage">{{ errorMessage }}</span>
    </div>
    <div
      v-show="hint"
      :class="$style.hintSlot"
    >
      <span :class="$style.hintMessage">{{ hint }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseFormField',
  props: {
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    helpTooltip: {
      type: String,
      default: '',
    },
    helpLink: {
      type: String,
      default: '',
    },
    uuid: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" module>
.field {
  display: flex;
  flex-direction: column;

  > .label {
    margin-bottom: var(--space-small-xxx);
    font-size: var(--font-size-small-x);

    > .required {
      color: var(--color-feedback-error-400);
    }

    > .helpIcon {
      color: var(--color-brand-primary-400);
    }
  }

  &.error {
    > .label {
      color: var(--color-feedback-error-400);
    }

    > .input {
      border: var(--border-width-small) solid var(--color-feedback-error-400);
      outline-color: var(--color-feedback-error-400);
    }

    > .errorSlot {
      transform-origin: left center;
      animation-duration: var(--time-transition-long);
      animation-name: scalex;

      > .errorMessage {
        font-size: var(--font-size-small-x);
        color: var(--color-feedback-error-400);
      }
    }
  }

  .hintSlot {
    > .hintMessage {
      font-size: var(--font-size-small-x);
    }
  }
}

@keyframes scalex {
  0% {
    transform: scaleX(0.2);
  }

  50% {
    transform: scaleX(1.03);
  }

  100% {
    transform: scaleX(1);
  }
}
</style>
