import { axios } from '@/plugins';

const fetchTemplates = async ({ commit }) => {
  try {
    const url = window.location.href;
    const { data } = await axios.get(url);
    commit('SET_LINKS', data.links);
    commit('SET_TEMPLATES', data.templates);
  } catch (err) {
    throw err;
  }
};

const deleteTemplate = async ({ dispatch }, url) => {
  try {
    await axios.delete(url);
    await dispatch('fetchTemplates');
  } catch (error) {
    throw error;
  }
};

const postTemplate = async ({ getters }, payload) => {
  const url = getters.getLinks.generate;
  const { data } = await axios.post(url, payload);

  return data;
};

const setTemplates = ({ commit }, templates) => {
  commit('SET_TEMPLATES', templates);
};

export default {
  setTemplates,
  fetchTemplates,
  deleteTemplate,
  postTemplate,
};
