const icpBrasil = {
  helpLink: 'How it works?',
  providersLink: 'Check which providers are supported',
  alt: 'Digital certificate provider',
  certificateTypes: {
    title: 'Digital certificate',
    subtitle: 'Select the type of certificate',
    local: 'Local certificate',
    cloud: 'Cloud certificate',
  },
  noDocumentationFoundModal: {
    title: 'Access problem',
    description:
      'No Cloud Certificate linked to CPF {0} was found. If necessary, correct or request correction of the CPF.',
    providersLink: 'Check if your provider is supported by Clicksign.',
  },
  cloudUnavailableModal: {
    title: 'Service temporarily unavailable',
    description:
      'Please wait a few moments to try again, or contact your certificate provider to check for instability.',
  },
  cloudCertificate: {
    title: 'Select certificate provider',
    description: 'Providers linked to the CPF: {0}',
  },
  certificateLocal: {
    title: 'Choose a certificate',
    select: {
      placeholder: 'Avaliable certificates',
      valid: 'Valid',
      expired: 'Expired',
      wrongCpf: 'Not related to registered CPF (Individual Taxpayer Registration Number)',
      company: 'Company',
    },
    sign: 'Sign',
    errors: {
      undefined: 'Error in the certificate signing process.',
      general:
        "Error in the certificate signing process. Report the error code to Clicksign' customer service:",
      userCancelled: 'Operation was cancelled by the user',
      concurrentSignature: 'Another person is signing at the same time. Try again in a few minutes',
      cspKeysetNotDefined:
        'Certificate key not found. Please check if the selected certificate smart-card or USB token is connected to your computer or remove and insert it again.',
    },
  },
  unavailable: {
    errorLabel: 'ERROR: WIDGET EMBEDDED ENABLED',
    title: 'Unable to proceed with signing',
    subtitle: 'Report the error to the document sender.',
  },
  howItWorksModal: {
    title: 'Digital certificate',
    whatIs: {
      title: 'What is a Digital Certificate?',
      text: 'It is the identification of individuals or legal entities in the electronic medium.',
    },
    why: {
      title: 'Why sign with a Digital Certificate?',
      authSignerRequired:
        'The Digital Certificate may be a requirement for signatory authentication in some cases.',
      icpBrasilAsAuth:
        'The sender of this document chooses the certificate as a form of authentication.',
    },
  },
  webPki: {
    installModal: {
      title: 'PDF Signature',
      text: 'To proceed, you need to install the Web PKI extension.',
      helpLink: 'What is Web PKI?',
      action: 'Install',
    },
    helpModal: {
      whatIs: {
        title: 'What is Web PKI?',
        text: 'Web PKI is a necessary extension to authenticate users or signing using Digital Certificate.',
      },
      howToUse: {
        title: 'How to use?',
        installExtension: 'Install the extension in your browser',
        selectCertificate: 'Select the certificate you want to use',
        authorize: 'Click on "Authorize"',
        insertPin: 'Enter the requested PIN, Token or QR Code',
      },
    },
  },
  mobileAlertModal: {
    computerRequired: 'To sign with Digital Certificate, use the computer.',
  },
  accessProblemModal: {
    allCertificatesWithExpDate: 'No valid certificates found to complete the signature.',
    emptyCertificates: "We couldn't find any Certificates on this computer.",
    noneCertificateLinkWithCPF:
      "We didn't find any Digital Certificate linked to the registered CPF.",
    tutorialSign: 'Any problem with signing?',
    title: {
      default: 'Access problem',
      certExpire: 'Expired certificate',
    },
  },
};

export default icpBrasil;
