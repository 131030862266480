import { useEventListener } from '@vueuse/core';

import useGA from '@base/hooks/useGA';

export const useListenForGaEvents = (target, gaContext) => {
  const handleTargetClick = (e) => {
    const path = e?.composedPath() || e.path;

    path
      .filter((elementPath) => elementPath.dataset)
      .forEach((el) => {
        const { gaEvent, gaEventLabel, gaEventContext } = el.dataset;
        const context = gaEventContext || gaContext;

        if (gaEvent && context) useGA('event', gaEvent, context, gaEventLabel);
        else if (gaEvent) useGA('event', gaEvent, gaEventLabel);
      });
  };

  const removeListener = useEventListener(target, 'click', handleTargetClick);

  return { removeListener };
};
