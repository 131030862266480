import { axios } from '@/plugins';

const setLinks = ({ commit }, links) => {
  commit('SET_LINKS', links);
  commit('SET_ROUTES', links);
};

const setUserData = ({ commit }, payload) => {
  localStorage.setItem('user', JSON.stringify(payload));

  commit('SET_USER', payload);
};

const setAccountData = ({ commit, dispatch }, payload) => {
  localStorage.setItem('account', JSON.stringify(payload));

  commit('SET_ACCOUNT_DATA', payload);
  dispatch('featureFlags/setFeatureFlags', { toggles: payload.account.toggles }, { root: true });

  if (payload?.environment)
    dispatch('environment/setVariables', payload.environment, { root: true });
  if (payload?.links) dispatch('setLinks', payload.links);

  return true;
};

const setABTestsData = ({ commit }, payload) => {
  commit('SET_AB_TESTS_DATA', payload);
};

const fetchInfo = async ({ dispatch, getters }, selfUrl) => {
  try {
    const url = selfUrl || getters.getLinks.self;
    const { data } = await axios.get(url);

    dispatch('setAccountData', data);
  } catch (error) {
    throw error;
  }
};

const fetchUserAccounts = async ({ commit, getters }) => {
  try {
    const infoUrl = _.get(getters, 'getLinks.self');
    const { data } = await axios.get(infoUrl);

    commit('SET_USER_ACCOUNTS', data.accounts);
    commit('SET_USER', data.user);
    commit('SET_LINKS', data.user?.links);

    return data;
  } catch (error) {
    throw error;
  }
};

const fetchEmployeesAmount = async ({ getters, commit }) => {
  try {
    const requestUrl = getters.getLinks.employeesAmount;
    const { data } = await axios.get(requestUrl);

    commit('SET_EMPLOYEES_AMOUNT', data.employeesAmount);

    return data.employeesAmount;
  } catch (error) {
    throw error;
  }
};

/**
 * TODO: This function should be deprecrated in favor of updateAccountInfo
 */
const updateInfo = async ({ getters, dispatch, commit }, payload) => {
  try {
    const url = payload.url || _.get(getters, 'getLinks.self');
    const response = await axios.patch(url, payload);
    const userData = response?.data?.data?.user;

    await dispatch('fetchInfo');
    commit('SET_USER', { ...getters?.getUser, ...userData });
    commit('SET_ACCOUNT', {
      ...getters?.getAccount,
      user: { ...getters?.getAccount?.user, ...userData },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * TODO: Replace updateInfo with this function
 */
const updateAccountInfo = async ({ getters, commit }, payload) => {
  try {
    const url = payload.url || _.get(getters, 'getLinks.self');
    const response = await axios.patch(url, payload);

    commit('SET_ACCOUNT', response.data.account);
    return response;
  } catch (error) {
    throw error;
  }
};

const updateEmail = async ({ dispatch }, { url, newEmail, fetchInfoAfterUpdate = true }) => {
  try {
    const response = await axios.patch(url, newEmail);

    if (fetchInfoAfterUpdate) dispatch('fetchInfo');

    return response;
  } catch (error) {
    throw error;
  }
};

const resendEmail = async ({ dispatch }, { url, fetchInfoAfterUpdate = true }) => {
  try {
    const response = await axios.get(url);

    if (fetchInfoAfterUpdate) dispatch('fetchInfo');
    return response;
  } catch (error) {
    throw error;
  }
};

const updatePhoneNumber = async ({ dispatch }, { url, newPhoneNumber }) => {
  try {
    const response = await axios.patch(url, newPhoneNumber);

    dispatch('fetchInfo');
    return response;
  } catch (error) {
    throw error;
  }
};

const resendPhoneNumberConfirmation = async ({ dispatch }, url) => {
  try {
    const response = await axios.get(url);

    dispatch('fetchInfo');
    return response;
  } catch (error) {
    throw error;
  }
};

const confirmPhoneNumber = async ({ getters, commit }, token) => {
  try {
    const url = getters.getAccount?.user.links.confirmToken;
    await axios.get(url, { params: token });

    const newUserData = { ...getters.getAccount?.user, phoneNumberConfirmed: true };
    const newAccountData = { ...getters.getAccount, user: newUserData };
    commit('SET_ACCOUNT', newAccountData);
    return newAccountData;
  } catch (error) {
    throw error;
  }
};

const customMailSolicitation = async ({ getters }) => {
  try {
    const customMailUrl = _.get(getters, 'getLinks.customMailSolicitation');
    const response = await axios.post(customMailUrl);
    return response;
  } catch (error) {
    throw error;
  }
};

const getEmailsBounces = async ({ getters, commit }) => {
  const url = getters.getLinks.notifiableBounces;
  const { data } = await axios.get(url);
  commit('SET_SIDEBAR_BADGES', { emailBounces: !!data.quantity });

  return data;
};

const getIsDownloadProcessing = async ({ getters, commit }) => {
  try {
    const url = getters.getLinks.downloadsNotifiable;
    const { data } = await axios.get(url);
    commit('SET_DOWNLOAD_PROCESSING', data);
    commit('SET_SIDEBAR_BADGES', { downloads: data });
    return data;
  } catch (error) {
    throw error;
  }
};

const changeToggleStatus = async ({ commit }, payload) => {
  commit('SET_TOGGLE_VALUE', payload);
};

const setConfigData = ({ commit }, payload) => {
  commit('SET_CONFIG_DATA', payload);
};
const setRouter = ({ commit }, newRouter) => {
  commit('SET_ROUTER', newRouter);
};

const changeSetupPreference = async ({ getters }, newValue) => {
  const url = getters.getLinks.changeSetupPreference;
  const payload = { account: { envelope_setup_preference_enabled: newValue } };
  try {
    await axios.patch(url, payload);
  } catch (e) {
    return false;
  }
  return true;
};

export default {
  fetchInfo,
  setABTestsData,
  updateInfo,
  updateAccountInfo,
  customMailSolicitation,
  fetchEmployeesAmount,
  setLinks,
  setUserData,
  setAccountData,
  getEmailsBounces,
  getIsDownloadProcessing,
  updateEmail,
  resendEmail,
  changeToggleStatus,
  setConfigData,
  setRouter,
  fetchUserAccounts,
  updatePhoneNumber,
  resendPhoneNumberConfirmation,
  confirmPhoneNumber,
  changeSetupPreference,
};
