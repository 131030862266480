import { camelCase } from 'lodash';

import { useStore } from '@/store';
import { computed } from 'vue';
import { toReactive } from '@vueuse/core';

export const useFunctionalities = () => {
  const store = useStore();
  const functionalities = computed(() => store.getters['subscription/getFunctionalities'] || []);
  const functionalitiesBlockedByAdmin = computed(
    () => store.getters['subscription/getFunctionalitiesBlockedByAdmin'] || []
  );
  const isTrial = computed(() => store.getters['info/getAccount'].trial);
  const isSandbox = computed(() => store.getters['environment/getVariables'].sandbox);

  const isEnabled = (functionality) => functionalities.value.includes(camelCase(functionality));

  const isBlocked = (functionality) => !isEnabled(functionality);

  const isBlockedByAdmin = (functionality) =>
    functionalitiesBlockedByAdmin.value.some(
      (blockedFunctionality) =>
        blockedFunctionality.code === functionality ||
        blockedFunctionality.shortName === functionality
    );

  const isFunctionalitiesEnabled = computed(() =>
    functionalities.value.reduce((acum, value) => ({ ...acum, [value]: isEnabled(value) }), {})
  );

  const functionalitiesStatus = toReactive(isFunctionalitiesEnabled);

  return {
    functionalities: functionalitiesStatus,
    isEnabled,
    isBlocked,
    isBlockedByAdmin,
    isTrial,
    isSandbox,
  };
};
