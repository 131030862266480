const pendingAnswers = {
  actionReminder: '¡Recordatorio reenviado con éxito!',
  actionDisapprove: '¡Respuesta eliminada con éxito!',
  actionError: '¡Ocurrió un error al realizar la acción!',
  emptyText:
    'No encontramos ninguna respuesta pendiente del 2º formulario. {0} ¿te gustaría seleccionar un flujo diferente?',
  header: {
    title: 'Respuestas pendientes del 2º formulario',
    alert:
      'Revisa quién falta por completar y reenvía el recordatorio del formulario, si lo deseas',
    filter: {
      label: 'Flujos',
      placeholder: 'Selecciona el flujo',
      clear: 'Limpiar',
      button: 'Filtrar',
    },
  },
  table: {
    secondRecipient: 'Destinatario',
    name: 'Nombre del flujo',
    createdAt: 'Completado el',
    action: 'Acciones',
    reminder: 'Reenviar recordatorio',
    delete: 'Eliminar respuesta',
  },
  detailsModal: {
    title: 'Detalles de pendência',
    flowDetails: 'Detalles del flujo',
    flowAnswers: 'Respuestas del 1º formulario',
    firstFlowName: 'Nombre del 1º formulario',
    secondFlowName: 'Nombre del 2º formulario',
    identifier: 'Identificador',
  },
  disapproveModal: {
    title: '¿Eliminar respuesta pendiente del flujo?',
    description: 'El enlace del segundo formulario no estará disponible para esta persona.',
  },
  whatsappReminderModal: {
    title: 'Reenviar enlace vía WhatsApp',
    description: 'El firmante recibirá nuevamente el enlace del segundo formulario.',
    alert: 'Para envíos vía WhatsApp, hay un costo de R$ 0,40 por cada recordatorio.',
  },
};

export default pendingAnswers;
