const disabledFlow = {
  banner: {
    message: 'Para empresas com processos padronizados e que',
    highlight: 'desejam automatizar a geração de documentos.',
    pricing: '* Envios a partir de 50 documentos/mês',
    info: 'Em até 1 dia útil, sem compromisso.',
  },
  button: {
    text: 'Receber contato',
  },
  info: {
    title: 'Veja por que você deve contar com o Clicksign Automação:',
    items: {
      whatsapp: {
        title: 'Utilize o WhatsApp para recolher assinaturas e aceites.',
        content:
          'Recolha assinaturas ou aceites rapidamente utilizando o WhatsApp e acelere a formalização e o fechamento dos seus negócios.',
      },
      automation: {
        title: 'Crie documentos a partir de modelos e fluxos de automação.',
        content:
          'Automatize a coleta de dados com até 2 formulários e envie documentos para assinatura, economizando tempo e reduzindo erros.',
      },
      append: {
        title: 'Adicione anexos aos seus formulários para obter validações adicionais.',
        content:
          'Anexos como fotos, RG e comprovantes de endereço poderão ser solicitados e enviados automaticamente para assinatura uma única vez.',
      },
      platform: {
        title: 'Plataforma intuitiva e suporte de time especialista.',
        content:
          'A plataforma da Clicksign simplifica a gestão de documentos e assinaturas eletrônicas, além disso, você tem acesso a um time especializado para dúvidas.',
      },
    },
    subtitle: {
      default: 'Configure, dispare e nunca mais pare.',
      spreadsheet: 'O fluxo com planilha permite:',
    },
    description: 'O seu cliente...',
    list: {
      receiveLink: 'Recebe link',
      formFilled: 'Preenche um formulário',
      signedDocument: 'Assina o documento',
      doneDeal: 'Negócio fechado',
      generateDocuments: 'Gerar múltiplos documentos de forma simples, sem integração de sistemas.',
      spreadsheetFilled:
        'Utilizar a planilha como fonte de dados para preencher o modelo e as informações dos signatário.',
      sendDocuments: 'Gerar e enviar múltiplos documentos para diversos signatários.',
    },
  },
  action: {
    description: 'Se interessou? Então não perca essa oportunidade!',
    purchase: 'Quero conhecer',
  },
  purchase: {
    title: 'Conheça o Plano Automação',
    description: 'Envie seus dados para entrarmos em contato',
    form: {
      name: 'Nome',
      email: 'Email',
      accountName: 'Empresa',
      phoneNumber: 'Número do Telefone',
      documentsAmount: 'Quantidade de documentos/mês',
    },
    required: '* Obrigatório',
    success:
      'Sua mensagem foi enviada, em breve nossa equipe entrará em contato para iniciar o seu atendimento',
    error: 'Não conseguimos completar sua solicitação! Tente novamente',
  },
};

export default disabledFlow;
