const modal = {
  download_log_not_ready: {
    download_doc: 'Baixar documento assinado',
    download_doc_info:
      'O documento assinado está sendo gerado. Atualize essa página em alguns instantes para baixa-lo.',
    ok: 'Ok',
  },
  download: {
    download_doc: 'Baixar documento',
    download_doc_info:
      'O documento assinado só poderá ser baixado quando o mesmo for finalizado. Mas, por enquanto, você poderá baixar o documento original.',
    ok: 'Ok',
  },
  try_close: {
    title: 'Finalizar documento',
    info: 'O documento só poderá ser finalizado quando tiver ao menos uma assinatura. Caso seja necessário, você poderá cancelar o documento a qualquer momento.',
    ok: 'Ok',
  },
  duplicate: {
    title: 'Duplicar documento e enviar para assinatura',
    info: 'Deseja duplicar documento e iniciar um novo processo de assinatura?',
    send: 'Duplicar',
    cancel: 'Cancelar',
  },
  remove_signer: {
    remove_signer: 'Remover signatário',
    wish_remover: 'Deseja mesmo remover esse signatário?',
    after_remove: 'Após removê-lo, se desejar poderá adicioná-lo novamente a lista de signatários.',
    cancel: 'Cancelar',
    remove: 'Remover',
  },
  signerAdd: {
    title: 'Adicionar signatário',
    badge1: '1. DADOS DO SIGNATÁRIO',
    badge2: '2. COMO O SIGNATÁRIO DEVE ASSINAR',
    fillToGo: 'PREENCHA PARA AVANÇAR',
    signAs: 'Assinar como',
    authAs: 'Enviar token de autenticação via',
    authAsNew: 'Autenticação Obrigatória',
    additionalAuths: 'Autenticações adicionais',
    fullName: 'Nome completo',
    fullNameInfo:
      'Com o nome completo preenchido o signatário não poderá digitá-lo quando for assinar',
    fullNamePlaceholder: 'Digite o nome do signatário',
    cpfPresent: 'O signatário possui CPF?',
    cpfPresentInfo: 'Escolha Não caso o signatário não possua CPF.',
    cpfInfo: 'Com o CPF preenchido o signatário não precisará digitá-lo quando for assinar.',
    cpf: 'CPF',
    cpfPlaceholder: 'Digite o CPF do signatário',
    email: 'Email',
    emailPlaceholder: 'Digite o e-mail do contato',
    sms: 'SMS',
    cellphone: 'Celular',
    selfie_required: {
      label: 'Autenticação por selfie',
    },
    birthday: 'Data de nascimento',
    birthdayInfo:
      'Com a data de nascimento preenchida o signatário não precisará digitá-la quando for assinar.',
    footerInfo:
      'O signatário receberá as informações acima já preenchidas quando for assinar e não poderá alterá-las.',
    select: 'SELECIONE',
    yes: 'Sim',
    no: 'Não',
    ok: 'Ok',
    optionalData: 'PREENCHA PELO SIGNATÁRIO (OPCIONAL)',
    optionalDataInfo:
      'O signatário receberá as informações abaixo já preenchidas quando for assinar e não poderá alterá-las.',
    saveContact: 'Marque para salvar como contato na agenda',
    fillToSave: 'PREENCHA PARA ADICIONAR',
    optionalMessage: 'Mensagem (opcional)',
    signerData: 'DADOS DO SIGNATÁRIO',
    selectedSigner: 'Selecionado',
    emptyList: 'Não há itens para selecionar',
    banner: {
      title: 'Conheça a nova autenticação via Pix (Beta)!',
      subtitle: 'Um jeito seguro e fácil de assinar.',
      action: 'Quero conhecer',
    },
    authGroupOptions: {
      token: {
        label: 'Autenticação por Token',
        email: 'Enviar token por E-mail',
        sms: 'Enviar token por SMS',
      },
      pix: {
        label: 'Autenticação por Pix',
        text: 'Autenticação por Pix (Beta)',
      },
    },
  },
  signerContact: {
    title: 'Escolher da agenda',
    badge1: '1. SELECIONAR SIGNATÁRIO',
    badge2: '2. COMO O SIGNATÁRIO DEVE ASSINAR',
    fillToAdd: 'PREENCHA PARA ADICIONAR',
    loadingContacts: 'Carregando contatos da agenda...',
    clickToConfirm: 'Clique para confirmar',
    toSaveContact:
      'Para salvar um contato, vá em Adicionar na lista de signatários e marque para salvar como contato na agenda.',
    fullName: 'Nome completo',
    email: 'Email',
    cpf: 'CPF',
    cellphone: 'Celular',
    authAs: 'Autenticar usando',
    signAs: 'Assinar como',
    birthday: 'Data de nascimento',
    cpfPresent: 'Possui CPF?',
    optionalMessage: 'Mensagem (opcional)',
    info: '+ Info',
    signerData: 'DADOS DO SIGNATÁRIO',
  },
  signerForm: {
    title: 'Adicionar signatário do formulário',
    description: {
      line1: 'Selecione esta opção quando o E-mail e o WhatsApp do signatário forem',
      line2: 'provenientes do formulário.',
    },
    add: 'Adicionar',
    cancel: 'Cancelar',
    plus: 'Saiba mais',
    emailField: {
      label: 'E-mail do signatário',
      tooltip: 'Selecione o título do signatário que deverá receber o documento para assinar.',
      empty: 'Não há e-mails para selecionar',
    },
    signAsField: {
      label: 'Assinar como',
      tooltip: 'Informe como o signatário irá assinar este documento',
    },
    whatsappSection: 'Assinatura via WhatsApp (Opcional)',
    whatsappField: {
      label: 'Celular do signatário',
      tooltip:
        'Selecione o campo correspondente ao telefone celular do signatário para que ele receba as mensagens via WhatsApp para assinar o documento.',
      noPhone: 'Não há telefones para selecionar',
    },
  },
  signature_ordering: {
    title: 'Configurar ordem das assinaturas',
    active_ordenation: 'Ativar ordenação das assinaturas.',
    drag_drop_to_change: 'Arraste e solte para mudar de grupo',
    add_to_ordering: 'Adicione signatários para ordenar as assinaturas.',
    loading_groups: 'Carregando grupos...',
    drag_to_add: 'ARRASTE AQUI PARA ADICIONAR UM NOVO GRUPO',
    active_ordenation_to_ordering:
      'Ative a ordenação das assinaturas para definir quem deverá assinar primeiro, em segundo e assim por diante.',
    tooltip: {
      ordenation_info:
        'Uma vez ativada a ordenação das assinaturas, não é possível desativa-la no documento. Se você deseja todos os signatários assinando simultaneamente, coloque-os no mesmo grupo.',
    },
    notification: {
      save: 'Ordenação de assinaturas atualizada com sucesso!',
    },
  },
  flow_ordering: {
    title: 'Configurar ordem das assinaturas',
    active_ordenation: 'Ativar ordenação das assinaturas.',
    drag_drop_to_change: 'Arraste e solte para mudar de grupo',
    add_to_ordering: 'Adicione signatários para ordenar as assinaturas.',
    loading_groups: 'Carregando grupos...',
    drag_to_add: 'ARRASTE AQUI PARA ADICIONAR UM NOVO GRUPO',
    active_ordenation_to_ordering:
      'Ative a ordenação das assinaturas para definir quem deverá assinar primeiro, em segundo e assim por diante.',
    tooltip: {
      ordenation_info:
        'Uma vez ativada a ordenação das assinaturas, não é possível desativa-la no documento. Se você deseja todos os signatários assinando simultaneamente, coloque-os no mesmo grupo.',
    },
  },
  move_nodes: {
    documents: {
      title: 'Mover itens para ...',
      move: 'Mover',
    },
    trash_bin: {
      title: 'Restaurar itens para ...',
      restore: 'Restaurar',
    },
    cancel: 'Cancelar',
    more: 'Ver mais',
  },
  flow_privacy: {
    title: 'Definir permissões de acesso do fluxo ',
    select_access_type: {
      label: 'Selecione o tipo de acesso do fluxo',
      public: 'Acesso para todos os usuários da conta',
      private: 'Acesso apenas para usuários selecionados',
    },
    select_members: {
      label: 'Selecione os usuários que terão acesso ao fluxo',
      admin: 'Administrador',
      admin_info: 'Administradores da conta sempre terão acesso as pastas por padrão',
      operator: 'Operador do fluxo',
      operator_info:
        'Este usuário não pode ser removido por estar vinculado como operador do Fluxo',
    },
    cancel: 'Cancelar',
    save: 'Salvar',
  },
  signerFormUnutilizedAlert: {
    title: 'Atenção: Há ao menos um signatário do formulário que não está na lista de assinatura.',
    description:
      'Se você deseja que os documentos gerados por este fluxo sejam enviados para estes signatários, adicione-os na lista de assinatura.',
    availableSigners: {
      title: 'Signatários disponíveis:',
      formName: 'Formulário {number}: {name}',
    },
    actions: {
      add: 'Adicionar signatário',
      skip: 'Avançar sem adicionar',
    },
  },
  exitConfirmation: {
    title: 'Deseja sair da edição?',
    description: 'Se você sair, as alterações feitas não serão salvas.',
    cancel: 'Continuar editando',
    confirm: 'Sair',
  },
};

export default modal;
