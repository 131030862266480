const routes = [
  {
    path: '/accounts/:accountId/tracking/notifications',
    name: 'trackingNotifications',
    component: () => import('@tracking/views/TrackingView.vue'),
    meta: {
      layout: 'BaseNavigationLayout',
    },
  },
];

export default routes;
