export const parseAddressResponse = (address) => {
  const addressToParse = {
    address: address.logradouro,
    neighborhood: address.bairro,
    city: address.localidade,
    region: address.uf,
  };

  return addressToParse;
};
