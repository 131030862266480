export default {
  SET_BRAND: (state, payload) => {
    state.brand = { ...payload };
  },
  SET_LOCALE: (state, payload) => {
    state.locale = payload;
  },
  SET_DOCUMENTS: (state, payload) => {
    state.documents = payload.filter((doc) => !doc.blocked);
  },
  SET_VIEWABLE_DOCUMENTS: (state, payload) => {
    state.viewableDocuments = payload;
  },
  SET_SIGNER: (state, payload) => {
    state.signer = Object.assign(state.signer, payload);
  },
  SET_LINKS: (state, payload) => {
    state.links = Object.assign(state.links, payload);
  },
  SET_SUMMARY: (state, payload) => {
    state.hasSummary = payload;
  },
  SET_ENVIRONMENT: (state, payload) => {
    state.environment = payload;
  },
  SET_PHOTO_EVIDENCES: (state, payload) => {
    state.photoEvidences = payload;
  },
  SET_CAF_TOKEN: (state, payload) => {
    state.cafToken = payload;
  },
  SET_CAF_ENVIRONMENT: (state, payload) => {
    state.cafEnvironment = payload;
  },
  SET_TOKEN_INFO: (state, payload) => {
    state.tokenInfo = payload;
  },
  SET_SIGNABLE_DOCUMENTS_COUNT: (state, payload) => {
    state.signableDocumentsCount = {
      batch: payload.batch.signableDocumentsCount,
      pendingDocuments: payload.signer.signableDocumentsCount,
    };
  },
  SET_CLAUSES: (state, payload) => {
    state.clauses = payload;
  },
  SET_HAS_CLAUSES: (state, payload) => {
    state.hasClauses = payload;
  },
  SET_HAS_READ_RECEIPTS: (state, payload) => {
    state.hasReadReceipts = payload;
  },
  SET_READ_RECEIPTS: (state, payload) => {
    state.readReceipts = payload;
  },
  SET_HAS_SEALS: (state, payload) => {
    state.hasSeals = payload;
  },
  SET_SEALS: (state, payload) => {
    state.seals = payload;
  },
  SET_HAS_RUBRICS: (state, payload) => {
    state.hasRubrics = payload;
  },
  SET_RUBRICS: (state, payload) => {
    state.rubrics = payload;
  },
  SET_EMBEDDED: (state, payload) => {
    state.embedded = payload;
  },
  SET_SERVER_CURRENT_TIME: (state, payload) => {
    state.serverCurrentTime = payload;
  },
  SET_FROM_ENVELOPE: (state, payload) => {
    state.fromEnvelope = payload;
  },
  SET_BATCH_KEY: (state, payload) => {
    state.batchKey = payload;
  },
  SET_PAGINATION: (state, payload) => {
    state.pagination = payload;
  },
  SET_DOCUMENTS_SIZE: (state, payload) => {
    state.documentsSize = payload;
  },
  SET_CALL_TO_ACTION_ENABLED: (state, payload) => {
    state.callToActionEnabled = payload;
  },
  UPDATE_LINKS: (state, payload) => {
    Object.assign(state.links, { ...payload });
  },
  SET_VIVO_ENABLED: (state, payload) => {
    state.vivoEnabled = payload;
  },
  SET_DOCUMENTS_COUNT: (state, payload) => {
    state.documentsCount = payload;
  },
  SET_PENDING_DOCUMENTS_COUNT: (state, payload) => {
    state.pendingDocumentsCount = payload;
  },
  SET_SIGNED_DOCUMENTS_COUNT: (state, payload) => {
    state.signedDocumentsCount = payload;
  },
  SET_FROM_SIGNER_AREA: (state, payload) => {
    state.fromSignerArea = payload;
  },
  SET_FACEMATCH_CHECK_STATUS_TIMEOUT: (state, payload) => {
    state.facematchCheckStatusTimeout = payload;
  },
  SET_CAF_EVIDENCES: (state, payload) => {
    state.cafEvidences = payload;
  },
  SET_LIVENESS_KEY: (state, payload) => {
    state.livenessKey = payload;
  },
};
