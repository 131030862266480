const document = {
  info: 'Document {documentIndex} of {documentsCount}',
  download: {
    title: 'Download document',
    description: {
      text: 'For now you can only',
      link: 'download original document.',
      complement: 'The signed document can only be downloaded when finalized.',
    },
    option: {
      original: 'Download original document',
      signed: 'Download signed document',
    },
  },
  order: 'Document | Document | Document {order} of {total}',
  signDue: 'This document may be signed by',
  summary: {
    title: 'Document to sign | Documents to sign',
    header: {
      selected: 'No selected document | {0} selected document | {0} selected documents',
      toSign: 'No document to sign | {0} document to sign | {0} documents to sign',
    },
    actionsTitle: 'Actions',
    selectAll: 'Select all',
    refusable: 'Refusable',
    refused: 'Document paused - waiting for sender action',
  },
  view: 'View',
};

export default document;
