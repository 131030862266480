// TODO: remove phone key when midas is ready

export default {
  dateFormat: 'DD/MM/YYYY',
  errors: {
    shortRequired: 'Campo obligatorio',
    requiredIf: 'El campo es obligatorio',
    required: 'El campo es obligatorio',
    phone: 'Ingresa un número de teléfono válido',
    phoneMidas: 'Ingresa un número de teléfono válido',
    shortPhone: 'Número inválido',
    cellPhone: 'Ingresa un celular válido',
    email: 'Ingresa un correo electrónico válido',
    cnpj: 'Ingresa un CNPJ válido',
    cpf: 'Ingresa un CPF válido',
    shortCpf: 'CPF inválido',
    date: 'Ingresa una fecha válida',
    shortDate: 'Fecha inválida',
    fullName: 'Ingresa nombre y apellido',
    dateInThePast: 'Debe ser anterior a la fecha actual',
    ipCidr: 'Ingresa un IP CIDR válido',
    ipv4Cidr: '@:validation.errors.ipCidr',
    ipv6Cidr: '@:validation.errors.ipCidr',
    url: 'URL inválida',
    signAsRequired: 'Selecciona cómo debe firmar este firmante',
    sameAsPassword: 'La confirmación de la contraseña no coincide.',
    minLength: 'Por favor, ingresa al menos {min} caracteres.',
    maxLength: 'Por favor, ingresa un máximo de {max} caracteres.',
    cep: 'Ingresa un CEP válido',
    fetchCep: 'CEP no encontrado',
    numeric: 'Valor inválido. Ingresa solo números',
    currency: 'Ingresa un valor monetario válido, en Real',
    domain: 'Ingresa un dominio válido',
    hasNoSequentialCharacters: 'No se permiten caracteres secuenciales (123, abc)',
    cannotContainsName: 'No se permite utilizar tu nombre o apellido en la contraseña',
    containsUppercase: 'Ingresa al menos un carácter en mayúscula',
    containsLowercase: 'Ingresa al menos un carácter en minúscula',
    containsNumber: 'Ingresa al menos un número',
    containsSpecial: 'Ingresa al menos un carácter especial',
    creditCardNumber: 'La tarjeta de crédito es inválida',
    expiredCreditCard: 'La fecha de vencimiento es incorrecta',
    creditCardName: 'El nombre del titular de la tarjeta es inválido',
    creditCardExpiration: 'La fecha de vencimiento es incorrecta',
    creditCardCvv: 'El código de seguridad es incorrecto',
    creditCardNetwork: 'Tarjeta no aceptada. Usa otra tarjeta',
    creditCardPastDate: 'Fecha inválida',
    hasEmoji: 'No se permite el uso de emojis',
  },
};
