const routes = [
  {
    path: 'createWhatsappAcceptanceTerm',
    component: () =>
      import(
        '@acceptance-term/views/CreateWhatsappAcceptanceTerm/CreateWhatsappAcceptanceTermView.vue'
      ),
    meta: {
      layout: 'BaseNavigationLayout',
    },
  },
  {
    path: '/accounts/:accountId/acceptance_term/whatsapps',
    name: 'acceptanceTermWhatsappIndex',
    component: () => import('@acceptance-term/views/AcceptTermsWhatsappListView.vue'),
    meta: {
      layout: 'BaseNavigationLayout',
    },
  },
  {
    path: '/accounts/:accountId/acceptance_term/whatsapps/optin',
    name: 'acceptanceTermWhatsappOptIn',
    component: () => import('@acceptance-term/views/AcceptTermsWhatsappOptInView.vue'),
    meta: {
      layout: 'BaseNavigationLayout',
    },
  },
  {
    path: 'acceptanceTermWhatsappShow',
    component: () =>
      import('@acceptance-term/views/AcceptanceTermWhatsappView/AcceptanceTermWhatsappView.vue'),
  },
  {
    path: '/accounts/:accountId/acceptance_term/content_templates',
    name: 'acceptanceTermContentTemplates',
    component: () =>
      import(
        '@acceptance-term/views/AcceptanceTermContentTemplateList/AcceptanceTermContentTemplateListView.vue'
      ),
    meta: {
      layout: 'BaseNavigationLayout',
    },
  },
  {
    path: '/accounts/:accountId/acceptance_term/content_templates/new',
    name: 'acceptanceTermContentTemplateNew',
    props: { type: 'create' },
    component: () =>
      import(
        '@acceptance-term/views/AcceptanceTermTemplateForm/AcceptanceTermTemplateFormView.vue'
      ),
    meta: {
      layout: 'BaseNavigationLayout',
    },
  },
  {
    path: '/accounts/:accountId/acceptance_term/content_templates/:templateId',
    name: 'acceptanceTermContentTemplateShow',
    props: { type: 'edit' },
    component: () =>
      import(
        '@acceptance-term/views/AcceptanceTermTemplateForm/AcceptanceTermTemplateFormView.vue'
      ),
    meta: {
      layout: 'BaseNavigationLayout',
    },
  },
];

export default routes;
