import { onUnmounted, onMounted, ref, computed } from 'vue';

const BREAKPOINTS = {
  extraLarge: ({ width }) => width >= 1200,
  large: ({ width }) => width >= 992,
  medium: ({ width }) => width >= 768,
  small: ({ width }) => width >= 576,
  extraSmall: ({ width }) => width < 576,
};

export const useBreakpoints = () => {
  const windowInfo = ref({});

  const updateWindowInfo = () => {
    windowInfo.value = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };

  const breakpoints = computed(() =>
    Object.entries(BREAKPOINTS).reduce(
      (acc, [breakpoint, getBreakpointValue]) => ({
        ...acc,
        [breakpoint]: getBreakpointValue(windowInfo.value),
      }),
      {}
    )
  );

  const addEventListener = () => {
    updateWindowInfo();
    window.addEventListener('resize', updateWindowInfo);
  };

  const removeEventListener = () => {
    window.removeEventListener('resize', updateWindowInfo);
  };

  onMounted(addEventListener);
  onUnmounted(removeEventListener);

  return { breakpoints };
};
