const document = {
  info: 'Documento {documentIndex} de {documentsCount}',
  download: {
    title: 'Descargar documento',
    description: {
      text: 'Por ahora solo puedes',
      link: 'descargar el documento original.',
      complement:
        'El documento firmado solo se podrá descargar una vez finalizado el proceso de firma.',
    },
    option: {
      original: 'Descargar documento original',
      signed: 'Descargar documento firmado',
    },
  },
  order: 'Documento | Documento | Documento {order} de {total}',
  signDue: 'Firmar hasta',
  summary: {
    title: 'Documento para firmar | Documentos para firmar',
    header: {
      selected:
        'Ningún documento seleccionado | {0} documento seleccionado | {0} documentos seleccionados',
      toSign:
        'Ningún documento para firmar | {0} documento para firmar | {0} documentos para firmar',
    },
    actionsTitle: 'Acciones',
    optionsTitle: 'Opciones',
    selectAll: 'Seleccionar todo',
    refusable: 'Rechazado',
    refused: 'Documento pausado - esperando acción del remitente',
  },
  view: 'Visualizar',
};

export default document;
