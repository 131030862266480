import { kebabCase, uniqueId } from 'lodash';

export const mountFieldPayload = (fields) => {
  return fields.map((payload) => {
    const name = payload.name || uniqueId(`${kebabCase(payload.label)}-`);
    return {
      ...payload,
      name,
      type: payload.kind,
    };
  });
};
