export default {
  GET_CONSUMPTION(state, payload) {
    state.plan = payload.plan;
    state.no_consumption = payload.no_consumption;
    state.period = payload.period;
  },

  SET_CONSUMPTION_PERIODS(state, payload) {
    state.consumptionPeriods = [...payload];
  },
};
