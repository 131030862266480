import BaseToasterLinkError from '@base/components/BaseToasterLinkError.vue';

export default [
  {
    name: 'contacts',
    component: 'ContactsTable',
    form: 'signersFromContacts',
    props: {
      contacts: 'getContacts',
      selectedContacts: 'signersFromContacts.selectedContacts',
      pagination: 'pagination',
    },
    isNextStepButtonDisabled: (vm) => vm.signersFromContacts.selectedContacts.length === 0,
    action: async (vm, form) => {
      const { selectedContacts } = form;
      const createdContacts = [];
      await Promise.all(
        selectedContacts.map(async (contact) => {
          const mockFormAuths = {
            ...contact,
            auths: contact.communicateBy,
            contact: true,
            fromEnvelope: true,
          };
          const url = _.get(vm.$store.getters, 'signer/getLinks.createSigner');
          await vm.$store
            .dispatch('signer/createSigner', {
              url,
              payload: mockFormAuths,
            })
            .then((response) => createdContacts.push(response));
        })
      );
      return createdContacts;
    },
    afterAction: (vm, contacts) => {
      const getAuthenticationsByIndex = (index) =>
        vm.signersFromContacts.selectedContacts[index].authentications;

      const signers = contacts.map((signer, index) => ({
        ...signer,
        context: vm.context,
        authentications: getAuthenticationsByIndex(index),
      }));
      Object.assign(vm.signAs, { signers });
    },
  },
  {
    name: 'signAs',
    title: 'signer.infoStep.signAs.label',
    component: 'SignerListSignAsStep',
    form: 'signAs',
    props: {
      signers: 'signAs.signers',
      signAsList: 'signAs.signAsList',
      signerSignAs: 'getSignerSignAsItems',
      flexibleContexts: 'flexibleContexts',
      hasValidationError: 'hasValidationError',
    },
    isNextStepButtonDisabled: () => false,
    action: async (vm, payload) => {
      const { signers } = payload;

      await Promise.all(
        signers.map(async (signer) => {
          // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
          // TODO: delete const toggles
          const toggles = _.get(vm.$store.getters, 'info/getToggles');

          // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
          // TODO: delete variable requirements and add into linkSignerPayload
          let requirements;
          if (toggles.toggleNewEnvelopeSetupEnabled) {
            requirements = {
              requirements: {
                roles: signer.newRoles,
                provide_evidences: signer.authentications,
              },
            };
          }

          const linkSignerPayload = {
            signerKey: signer.key,
            refusable: signer.features?.refusable,
            ...requirements,
          };

          await vm.$store.dispatch('envelope/linkSigner', linkSignerPayload);
          await vm.$store.dispatch('envelope/addRequirement', signer);
        })
      );
      vm.$emit('save');
    },
    errorHandle: (vm, err) => {
      if (!(err && err.response && err.response.status === 422)) return;
      const { data } = err.response;
      if (_.isArray(data)) {
        const errors = data.filter((error) => !_.isEmpty(error.signers.errors));
        const signersErrors = _.reduce(
          errors,
          (map, value) => {
            const { signerId, signAs } = value.signers;
            const signAsList = { [signerId]: map[signerId] || [] };
            const signerSignAsErrors = _.get(signAsList, signerId);
            return Object.assign(map, { [signerId]: [...signerSignAsErrors, signAs] });
          },
          {}
        );
        vm.$set(vm, 'errorMessages', signersErrors);
        vm.$nextTick(() => {
          vm.hasCurrentStepValidationError();
        });
      } else {
        vm.$toast.error({
          component: BaseToasterLinkError,
          props: {
            message: vm.$t('signer.error.generic.action'),
            linkText: vm.$t('signer.error.generic.supportLinkText'),
            link: vm.$t('links.support'),
          },
        });
      }
    },
  },
];
