import { ref } from 'vue';

export const useModal = () => {
  const modalActive = ref('');

  const isModalActive = (mode) => modalActive.value === mode;

  const openModal = (mode) => {
    modalActive.value = mode;
  };

  const closeModal = (mode) => {
    if (mode && mode === modalActive.value) {
      modalActive.value = '';
    } else if (!mode) {
      modalActive.value = '';
    }
  };

  return { isModalActive, openModal, closeModal, modalActive };
};
