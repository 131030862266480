const refusalSignature = {
  refusalFeedback: {
    title: 'Documento rechazado',
    subtitle: 'Hemos enviado tu respuesta al remitente del documento.',
  },
  refusalModal: {
    title: 'Rechazar firma',
    report: 'Informar un error',
    description: 'Rechaza la firma para informar el problema al remitente',
    select: 'Motivo del Rechazo',
    textareaLabel: 'Comenta aquí con tus palabras',
    textareaPlaceholder: 'No quiero firmar porque...',
    checkbox: 'Entiendo que esta acción es definitiva',
    checkboxVivo: 'Estoy consciente de que esta acción invalida la propuesta enviada',
    button: 'Rechazar',
    selectItems: {
      content: 'Contenido del documento',
      userData: 'Datos personales incorrectos',
      authType: 'Tipo de autenticación',
      signature: 'Uso de la firma electrónica',
      location: 'Permiso de ubicación',
      other: 'Otro motivo',
      incorrectScheduling: 'Programación incorrecta',
      incorrectCartItens: 'Artículos de carrito incorrectos',
      incorrectAddressDetails: 'Detalles de dirección incorrectos',
      notWishPerformCapture: 'No quiero realizar la captura',
    },
    authsPlaceholder: 'Especifica la autenticación',
    auths: {
      api: 'Api',
      email: 'Token vía e-mail',
      pix: 'Pix',
      icpBrasil: 'Certificado digital',
      sms: 'SMS',
      whatsapp: 'Token vía WhatsApp',
      selfie: 'Selfie con documento',
      handwritten: 'Firma manuscrita',
      officialDocument: 'Documento oficial',
      liveness: 'Selfie dinámica',
      facialBiometrics: 'Biometría facial',
      locationRequired: 'Ubicación',
      voiceBiometrics: 'Biometría de voz',
    },
    form: {
      error: 'Ocurrió un error al enviar el formulario',
      success: 'Formulario enviado con éxito',
    },
    refusableSelectedDocuments: 'Puedes rechazar {refusable} de {selected} documentos.',
    seeDocuments: 'Ver cuáles',
  },
  options: {
    download: 'Descargar documento original',
    refusal: 'Rechazar firma | Rechazar firmas',
  },
};

export default refusalSignature;
