import { axios } from '@/plugins';
import { sentry } from '@/plugins/sentry';
import { batchUtils } from '../../utils';

const setBrand = ({ commit }, payload) => {
  commit('SET_BRAND', payload);
};

const createBatch = async ({ getters, commit }, documents) => {
  try {
    const createBatchUrl = _.get(getters, 'getLinks.create');
    const signer = _.get(getters, 'getSigner');
    const batchRequest = batchUtils.buildCreateBatchRequest(documents, signer);
    const batchResponse = await axios.post(createBatchUrl, batchRequest);
    const responseData = _.get(batchResponse, 'data');
    commit('SET_BATCH_KEY', _.get(responseData, 'key'));
    commit('SET_HAS_CLAUSES', _.get(responseData, 'hasClauses'));
    commit('SET_HAS_SEALS', _.get(responseData, 'hasSeals'));
    commit('SET_LINKS', _.get(responseData, 'links'));

    return responseData;
  } catch (err) {
    throw err;
  }
};

const fetchBatch = async ({ commit }, payload) => {
  try {
    const { url, params = {} } = payload;

    const response = await axios.get(url, {
      params,
    });

    const parsedResponse = batchUtils.parseBatchResponse(_.get(response, 'data') || {});

    commit('SET_BATCH_KEY', _.get(parsedResponse, 'key'));
    commit('SET_BRAND', _.get(parsedResponse, 'brand'));
    commit('SET_LOCALE', _.get(parsedResponse, 'locale'));
    commit('SET_DOCUMENTS', _.get(parsedResponse, 'documents', []));
    commit('SET_HAS_CLAUSES', _.get(parsedResponse, 'hasClauses'));
    commit('SET_HAS_RUBRICS', _.get(parsedResponse, 'hasRubrics'));
    commit('SET_HAS_READ_RECEIPTS', _.get(parsedResponse, 'hasReadReceipts'));
    commit('SET_HAS_SEALS', _.get(parsedResponse, 'hasSeals'));
    commit('SET_VIEWABLE_DOCUMENTS', _.get(parsedResponse, 'documents'));
    commit('SET_SIGNER', _.get(parsedResponse, 'signer'));
    commit('SET_TOKEN_INFO', _.get(parsedResponse, 'signer.token', {}));
    commit('SET_FROM_ENVELOPE', _.get(parsedResponse, 'fromEnvelope'));
    commit('SET_LINKS', _.get(parsedResponse, 'links'));
    commit('SET_SUMMARY', _.get(parsedResponse, 'summary', false));
    commit('SET_SERVER_CURRENT_TIME', _.get(parsedResponse, 'currentTime', false));
    commit('SET_CALL_TO_ACTION_ENABLED', _.get(parsedResponse, 'callToActionEnabled', false));
    commit('SET_PAGINATION', _.get(parsedResponse, 'pagination'));
    commit('SET_DOCUMENTS_SIZE', parsedResponse.documentsSize);
    commit('SET_SIGNABLE_DOCUMENTS_COUNT', {
      batch: { signableDocumentsCount: parsedResponse.signableDocumentsSize },
      signer: { signableDocumentsCount: 0 },
    });

    return response.status;
  } catch (err) {
    sentry.captureException(err);
    throw err;
  }
};

// TODO: write spec for this new action
const updateReadReceipt = async ({ getters, commit }, payload) => {
  try {
    const url = payload.links.self;
    await axios.patch(url, { acceptance: { checked: true } });
    const readReceipts = getters.getReadReceipts.acceptances;
    const index = readReceipts.indexOf(payload);
    const newReadReceipts = readReceipts;
    if (index >= 0) newReadReceipts[index] = payload;
    commit('SET_READ_RECEIPTS', { acceptances: newReadReceipts });
  } catch (err) {
    sentry.captureException(err);
    throw err;
  }
};
// TODO: write spec for this new action
const fetchSeals = async ({ getters, commit }) => {
  try {
    const url = getters.getLinks.seals;
    const { data } = await axios.get(url);
    commit('SET_SEALS', data);
  } catch (err) {
    sentry.captureException(err);
    throw err;
  }
};
// TODO: write spec for this new action
const fetchRubrics = async ({ getters, commit }) => {
  try {
    const url = getters.getLinks.rubrics;
    const { data } = await axios.get(url);
    commit('SET_RUBRICS', data.documents);
  } catch (err) {
    sentry.captureException(err);
    throw err;
  }
};
// TODO: write spec for this new action
const fetchReadReceipts = async ({ getters, commit }) => {
  try {
    const url = getters.getLinks.readReceiptAcceptances;
    const { data } = await axios.get(url);
    commit('SET_READ_RECEIPTS', data);
  } catch (err) {
    sentry.captureException(err);
    throw err;
  }
};
// TODO: write spec for this new action
const sendSignerInitials = async ({ getters }, initials) => {
  try {
    const url = getters.getLinks.seals;
    await axios.patch(url, { initials });
  } catch (err) {
    sentry.captureException(err);
    throw err;
  }
};
// TODO: write spec for this new action
const sendRubric = async ({ getters }, initials) => {
  try {
    const url = getters.getLinks.rubrics;
    await axios.post(url, { rubric: { initials } });
  } catch (err) {
    sentry.captureException(err);
    throw err;
  }
};
const fetchClauses = async ({ getters, commit }) => {
  try {
    const url = getters.getLinks.clauses;
    const { data } = await axios.get(url);
    commit('SET_CLAUSES', data);
  } catch (err) {
    sentry.captureException(err);
    throw err;
  }
};

const updateClauses = async ({ getters }) => {
  try {
    const url = getters.getLinks.clauses;
    await axios.patch(url);
  } catch (err) {
    sentry.captureException(err);
    throw err;
  }
};

const signBatch = async ({ getters }, payload) => {
  try {
    const signBatchUrl = getters.getLinks?.sign;

    const locale = _.get(getters, 'getLocale');
    const signRequest = batchUtils.buildBatchSignRequest(payload, locale);
    const signResponse = await axios.post(signBatchUrl, signRequest);

    const responseData = _.get(signResponse, 'data');

    return responseData;
  } catch (err) {
    throw err;
  }
};

const getPixCharge = async (context, pixChargesStatusUrl) => {
  try {
    const pixChargeStatusResponse = await axios.get(pixChargesStatusUrl);
    return pixChargeStatusResponse;
  } catch (err) {
    throw err;
  }
};

const recreatePixCharge = async (context, payload) => {
  const recratePixChargeUrl = payload;

  try {
    const response = await axios.post(recratePixChargeUrl);
    const responseData = _.get(response, 'data');
    return responseData;
  } catch (err) {
    throw err;
  }
};

const sendPixChargeFeedback = async (context, payload) => {
  const { feedbackUrl, feedback } = payload;

  try {
    const responseData = await axios.post(feedbackUrl, { feedback });
    return responseData;
  } catch (err) {
    throw err;
  }
};

const fetchSignatureStatus = async ({ getters, commit }) => {
  try {
    const url = getters.getLinks.waiting;
    const { data, status } = await axios.get(url);

    if (data?.signer) {
      const { signedDocumentsCount, signableDocumentsCount } = data?.signer;

      commit('SET_SIGNED_DOCUMENTS_COUNT', signedDocumentsCount);
      commit('SET_PENDING_DOCUMENTS_COUNT', signableDocumentsCount);
      commit('SET_SIGNER', data?.signer);
      commit('SET_LINKS', data?.links);
    } else commit('SET_SIGNED_DOCUMENTS_COUNT', data?.progress);

    return { data, status };
  } catch (err) {
    throw err;
  }
};

const getTokenStatus = async ({ commit, getters }) => {
  const tokenStatusUrl = _.get(getters, 'getLinks.tokenStatus');

  let resp = {
    data: {
      token: {
        blockedUntil: null,
        remainingAttempts: 2,
      },
    },
  };
  let attempts = 0;
  const MAX_ATTEMPTS = 120;
  try {
    do {
      resp = await axios.get(tokenStatusUrl);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      attempts += 1;
    } while (
      _.get(resp, 'data.token.blockedUntil') === null &&
      _.get(resp, 'data.token.remainingAttempts') > 0 &&
      attempts < MAX_ATTEMPTS
    );
  } catch (err) {
    return {};
  }
  commit('SET_TOKEN_INFO', resp?.data?.token || {});
  return resp?.data?.token || {};
};

const sendToken = async ({ getters }) => {
  try {
    const resendTokenUrl = _.get(getters, 'getLinks.sendToken');
    await axios.post(resendTokenUrl);
  } catch (err) {
    throw err;
  }
};

const checkPresentialToken = async ({ getters }, payload) => {
  try {
    await axios.post(getters?.getLinks.presentialTokensValidate, { presential_token: payload });
  } catch (err) {
    throw err;
  }
};

const fetchPresentialSignatureViewData = async ({ commit, getters }) => {
  try {
    const { data } = await axios.get(getters?.getLinks.self);
    commit('UPDATE_LINKS', data.links);
  } catch (err) {
    throw err;
  }
};

const getBatchUrlByPresentialToken = async ({ commit, getters }, payload) => {
  try {
    const { data } = await axios.get(getters?.getLinks.presentialSignatureUrl, {
      params: {
        presential_token: payload,
      },
    });
    commit('UPDATE_LINKS', { signatureBatchUrl: data.url });
  } catch (err) {
    throw err;
  }
};

const resendToken = async ({ commit, getters }) => {
  try {
    const resendTokenUrl = _.get(getters, 'getLinks.resendToken');
    await axios.get(resendTokenUrl);
    return getTokenStatus({ commit, getters });
  } catch (err) {
    throw err;
  }
};

const setEnvironment = ({ commit }, payload) => {
  commit('SET_ENVIRONMENT', payload);
};

const links = {
  envelope: {
    liveness: 'liveness.self',
    discovery: 'liveness.discovery',
    attempts: 'liveness.attempts',
    attemptsNotify: 'liveness.attemptsNotify',
    livenessJWT: 'liveness.create',
  },
  batch: {
    liveness: 'evidences',
    discovery: 'evidencesDiscovery',
    attempts: 'livenessAttempts',
    attemptsNotify: 'attemptsExceededNotify',
    livenessJWT: 'liveness.create',
  },
};

const getPathLink = (getters, key) => {
  const batchOrEnvelope = _.get(getters, 'fromEnvelope') ? 'envelope' : 'batch';
  return 'getLinks.'.concat(links[batchOrEnvelope][key]);
};

const sendEvidences = async ({ getters, commit }, payload) => {
  const sendEvidencesUrl = _.get(getters, getPathLink(getters, 'discovery'));

  try {
    const responseData = await axios.post(sendEvidencesUrl, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    commit('SET_PHOTO_EVIDENCES', responseData.data.evidences);
    return responseData;
  } catch (err) {
    throw err;
  }
};

const getCafEvidences = async ({ getters, commit }) => {
  const evidencesUrl = _.get(getters, getPathLink(getters, 'liveness'));

  let resp;
  try {
    do {
      resp = await axios.get(evidencesUrl);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } while (resp.status === 202);

    commit('SET_CAF_EVIDENCES', resp.data?.evidences);
    return [null, resp.data];
  } catch (err) {
    return [err, null];
  }
};

const sendEvidencesCaf = async ({ getters, commit }, payload) => {
  const sendEvidencesUrl = _.get(getters, getPathLink(getters, 'discovery'));

  try {
    await axios.post(sendEvidencesUrl, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    await new Promise((resolve) => setTimeout(resolve, 1000));
    return getCafEvidences({ getters, commit });
  } catch (err) {
    return [err, null];
  }
};

const sendJWTCAF = async ({ getters, commit }, payload) => {
  const sendJWTUrl = _.get(getters, getPathLink(getters, 'livenessJWT'));

  try {
    const { data } = await axios.post(sendJWTUrl, {
      liveness: {
        signed_response: payload,
      },
    });

    await getCafEvidences({ getters, commit });

    return [null, data];
  } catch (err) {
    return [err, null];
  }
};

const getCafToken = async ({ getters, commit }) => {
  const cafTokenUrl = _.get(getters, 'getLinks.cafToken');

  try {
    const { data } = await axios.get(cafTokenUrl);
    commit('SET_CAF_TOKEN', data.token);
    commit('SET_CAF_ENVIRONMENT', data.environment);

    return [null, data];
  } catch (err) {
    return [err, null];
  }
};

const checkCanLiveness = async ({ getters }) => {
  const livenessAttempts = _.get(getters, getPathLink(getters, 'attempts'));
  try {
    const { data } = await axios.post(livenessAttempts);
    return [null, data];
  } catch (error) {
    return [error, null];
  }
};

const livenessAttemptsNotify = async ({ getters }) => {
  const livenessAttempts = _.get(getters, getPathLink(getters, 'attemptsNotify'));
  try {
    const { data } = await axios.post(livenessAttempts);
    return [null, data];
  } catch (error) {
    return [error, null];
  }
};

const startIcpBrasilSignature = async ({ getters }, payload) => {
  const startIcpBrasilSignatureUrl = _.get(getters, 'getLinks.startIcpBrasilSignature');

  try {
    const responseData = await axios.post(startIcpBrasilSignatureUrl, payload);
    return responseData;
  } catch (err) {
    throw err;
  }
};

const startIcpBrasilDocuments = async ({ getters }, payload) => {
  const documents = _.get(getters, 'getDocuments');
  const documentsToSign = [];
  for (const document of documents) {
    const response = await axios.post(document.links.startIcp, payload);
    if (response?.status === 200) documentsToSign.push(response.data.icpSignature);
    if (response?.status === 409) throw response.statusText;
  }
  return documentsToSign;
};

const completeIcpBrasilSignature = async ({ getters }, payload) => {
  const completeIcpBrasilSignatureUrl = _.get(getters, 'getLinks.completeIcp');

  try {
    const responseData = await axios.post(completeIcpBrasilSignatureUrl, payload);
    return responseData;
  } catch (err) {
    if (err.response?.status === 422 && err.response?.data?.error === 'already_signed') {
      sentry.captureException(err, {
        tags: {
          code: 'already_signed',
          digitalCertificate: true,
          isCloud: false,
        },
      });

      return null;
    }
    throw err;
  }
};

const refuseSignature = async ({ commit, getters }, { payload, documents = [] }) => {
  try {
    const refuseSignatureUrls = documents
      .filter((document) => document.refusable)
      .map((document) => document.links.refusal);

    const promises = refuseSignatureUrls.map((url) => axios.post(url, payload));
    const responses = await Promise.all(promises);

    const lastResponseData = _.get(responses.pop(), 'data', {});
    const isEnvelope = _.get(getters, 'fromEnvelope');
    if (!isEnvelope) {
      commit('SET_SIGNABLE_DOCUMENTS_COUNT', lastResponseData);
    }
    commit('SET_SIGNER', lastResponseData.signer);
    commit('SET_LINKS', lastResponseData.links);

    return [null, lastResponseData];
  } catch (err) {
    return [err, null];
  }
};

const fetchBatchUnavailable = async ({ commit }, { url }) => {
  const { data } = await axios.get(url);

  commit('SET_BRAND', data.brand);
  commit('SET_VIVO_ENABLED', data.vivoEnabled);
};

const checkBatch = async (_, batch) => {
  const url = batch?.self;
  if (!url) throw new Error(`expected batch.self to be defined, but is "${url}"`);

  try {
    const response = await axios.get(url);

    return [null, response?.data];
  } catch (error) {
    return [error, null];
  }
};

const validateLiveness = async ({ getters }, { name, documentation }) => {
  const { validateLivenessPath } = getters.getCafEvidences[0].links;
  const liveness = {
    signerCpf: documentation,
    signerName: name,
  };

  await axios.post(validateLivenessPath, { liveness });
};

const pullingValidateLiveness = async ({ getters }) => {
  const { validateLivenessPath } = getters.getCafEvidences[0].links;

  const {
    data: {
      photoReview: { status },
    },
  } = await axios.get(validateLivenessPath);

  return status;
};

export default {
  setBrand,
  fetchBatch,
  signBatch,
  getTokenStatus,
  sendToken,
  resendToken,
  fetchSignatureStatus,
  setEnvironment,
  createBatch,
  sendPixChargeFeedback,
  getPixCharge,
  recreatePixCharge,
  sendEvidences,
  sendEvidencesCaf,
  sendJWTCAF,
  checkCanLiveness,
  getCafEvidences,
  livenessAttemptsNotify,
  getCafToken,
  startIcpBrasilSignature,
  startIcpBrasilDocuments,
  completeIcpBrasilSignature,
  refuseSignature,
  fetchReadReceipts,
  updateReadReceipt,
  fetchClauses,
  updateClauses,
  fetchSeals,
  fetchRubrics,
  sendRubric,
  sendSignerInitials,
  checkPresentialToken,
  fetchPresentialSignatureViewData,
  getBatchUrlByPresentialToken,
  fetchBatchUnavailable,
  checkBatch,
  validateLiveness,
  pullingValidateLiveness,
};
