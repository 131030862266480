<script setup>
import { useI18n } from '@/locales';
import { camelCase } from 'lodash';

defineProps({
  items: {
    type: Array,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['item-click']);

const { t, tc } = useI18n();

const HEADERS = [
  {
    label: t('compliance.identifier'),
    value: 'documentName',
  },
  {
    label: t('compliance.flowName'),
    value: 'name',
  },
  {
    label: tc('compliance.form', 2),
    value: 'formBuildersNames',
  },
  {
    label: t('compliance.filledAt'),
    value: 'formFilledAt',
  },
];

const handleItemClick = (item) => {
  emit('item-click', item);
};

const getRowDataTestid = (item, type) => {
  return `${camelCase(item?.name + type)}Link`;
};
</script>

<template>
  <div :class="$style.listProcess">
    <XTable
      :items="items"
      :headers="HEADERS"
      :loading="loading"
      data-testid="formFlowApprovalRequestsTable"
      height="auto"
    >
      <template #items-col-documentName="{ item }">
        <XLink
          no-padding
          plain
          :data-testid="getRowDataTestid(item, 'documentName')"
          @click="handleItemClick(item)"
        >
          <XIcon
            icon="bolt"
            size="lg"
            :style="{ color: item.color }"
          />
          {{ item.documentName }}
        </XLink>
      </template>
      <template #items-col-name="{ item }">
        <XLink
          no-padding
          plain
          :data-testid="getRowDataTestid(item, 'name')"
          @click="handleItemClick(item)"
        >
          {{ item.name }}
        </XLink>
      </template>
      <template #items-col-formBuildersNames="{ item }">
        <XLink
          no-padding
          plain
          :data-testid="getRowDataTestid(item, 'formBuildersNames')"
          @click="handleItemClick(item)"
        >
          <div>
            <div
              v-for="(form, length) in item.formBuilderNames"
              :key="length"
            >
              {{ form.name }}
            </div>
          </div>
        </XLink>
      </template>
      <template #items-col-formFilledAt="{ item }">
        <XLink
          :data-testid="getRowDataTestid(item, 'formFilledAt')"
          no-padding
          plain
          @click="handleItemClick(item)"
        >
          {{ item?.form1FilledAt }} <br />
          {{ item?.form2FilledAt }}
        </XLink>
      </template>
    </XTable>
  </div>
</template>

<style lang="scss" module>
.listProcess {
  display: flex;
  flex-direction: column;
}
</style>
