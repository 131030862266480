import { axios } from '@/plugins';

const fetchForms = async ({ commit }, { url }) => {
  try {
    const {
      data: { formBuilders, links, pagination },
    } = await axios.get(url);

    commit('SET_LINKS', links);
    commit('SET_FORMS', formBuilders);
    commit('SET_PAGINATION', pagination);
  } catch (error) {
    throw error;
  }
};

const setForm = async ({ getters, commit }, payload) => {
  const url = _.get(getters, 'getLinks.self');
  const requestMethod = payload.formId ? 'put' : 'post';
  const { data } = await axios[requestMethod](url, payload);

  commit('SET_LINKS', data.links);

  return data;
};

const deleteForm = async ({ dispatch }, { deleteLink, self }) => {
  try {
    await axios.delete(deleteLink);
    await dispatch('fetchForms', { url: self });
  } catch (error) {
    throw error;
  }
};

const duplicateForm = async ({ dispatch }, { duplicateLink, self }) => {
  try {
    await axios.post(duplicateLink);
    await dispatch('fetchForms', { url: self });
  } catch (error) {
    throw error;
  }
};

export default {
  fetchForms,
  setForm,
  deleteForm,
  duplicateForm,
};
