export default {
  SET_PENDING_ANSWERS: (state, payload) => {
    state.pendingAnswers = payload;
  },
  SET_PAGINATION: (state, payload) => {
    state.pagination = payload;
  },
  SET_LINKS: (state, payload) => {
    state.links = payload;
  },
  SET_FLOWS_FILTER: (state, payload) => {
    state.flowsFilter = payload;
  },
  SET_SELECTED_PROCESS: (state, payload) => {
    state.selectedProcess = payload;
  },
  SET_SELECTED_PROCESS_ANSWERS: (state, payload) => {
    state.selectedProcessAnswers = payload;
  },
};
