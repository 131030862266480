import { useChatMessage } from './useChatMessage';

export const useChatMessageError = (message, options) => {
  const _message = typeof message === 'string' ? useChatMessage(message, options) : message;

  return {
    ..._message,
    hasError: true,
  };
};
