import { axios } from '@/plugins';

const fetchDownloads = async ({ commit, rootGetters }, params) => {
  try {
    const url = rootGetters['info/getRoutes'].downloadsPacks;
    const { data } = await axios.get(url, { params });
    commit('SET_DOWNLOADS', data);
    commit('SET_LINKS', data.links);
  } catch (err) {
    throw err;
  }
};

const downloadNodes = async ({ getters }, { pack }) => {
  try {
    const url = getters.getLinks.backup;
    await axios.post(url, { pack });
  } catch (error) {
    throw error;
  }
};

export default {
  fetchDownloads,
  downloadNodes,
};
