export default {
  SET_TEMPLATE: (state, payload) => {
    state.template = payload;
  },
  SET_WEBVIEWER_INSTANCE: (state, payload) => {
    state.webviewerInstance = payload;
  },
  SET_IS_UPLOADING_TEMPLATE: (state, payload) => {
    state.isUploadingTemplate = payload;
  },
};
