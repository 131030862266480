// TOGGLE_ORGANIZATION_OWNER_ENABLED
// TODO: Remove isToggleOrganizationOwnerEnabled
const isToggleOrganizationOwnerEnabled = () => {
  const { user } = JSON.parse(localStorage.getItem('user'));
  return user.organizationOwnerEnabled;
};

const routes = [
  {
    name: 'workspaces',
    path: '/workspaces',
    view: 'WorkspaceListView',
    meta: { layout: 'BaseCleanLayout' },
    // TOGGLE_ORGANIZATION_OWNER_ENABLED
    // TODO: Remove isToggleGroupPermissionsEnabled and keep WorkspaceListView import
    component: () =>
      isToggleOrganizationOwnerEnabled()
        ? import('@workspaces/views/WorkspaceListView/WorkspaceListView.vue')
        : import('@workspaces/views/WorkspaceListViewOld/WorkspaceListViewOld.vue'),
  },
];

export default routes;
