import moment from 'moment';
import 'moment/dist/locale/pt-br';
import 'moment/dist/locale/es-mx';

moment.updateLocale('pt-br', {
  monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  relativeTime: {
    future: 'Em %s',
    past: '%s atrás',
    s: 'Poucos segundos',
    ss: '%d segundos',
    m: 'Um minuto',
    mm: '%d minutos',
    h: 'Uma hora',
    hh: '%d horas',
    d: 'Um dia',
    dd: '%d dias',
    M: 'Um mês',
    MM: '%d meses',
    y: 'Um ano',
    yy: '%d anos',
  },
});

moment.locale('pt-br');

export default moment;
