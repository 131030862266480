import { sentry } from '@/plugins/sentry';
import { useVerifyLocation } from '@account/composables';
import { getExpressSubscription } from '@account/utils/express-subscription';

export function onboardingNewGuard(to, from, next) {
  try {
    const hasExpressSubscription = getExpressSubscription();
    const { isForeignUser } = useVerifyLocation();
    if (hasExpressSubscription || isForeignUser.value)
      return next({ name: 'onboardingAccountView' });
  } catch (error) {
    sentry.captureException(error);
  }

  return next();
}
