export default {
  SET_LINKS: (state, payload) => {
    Object.assign(state.links, { ...payload });
  },
  SET_OPERATOR: (state, payload) => {
    state.operator = payload;
  },
  SET_BACKUP_CODES: (state, payload) => {
    Object.assign(state.operator, { ...payload });
  },
};
