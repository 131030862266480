import { get, pick, isEmpty, isEqual, pickBy } from 'lodash';

import * as validators from '@base/validators';
import * as customValidators from '../validations/validators';

export default {
  SPREADHSEET_FLOW_STEPS: [
    {
      name: 'flowIdentification',
      component: 'SpreadsheetFlowIdentificationStep',
      icon: 'edit',
      type: 'formStep',
      form: 'identification',
      isNextStepButtonDisabled: (vm) => !get(vm, 'identification.name'),
      props: { name: 'identification.name' },
      defaultValues: (vm) => pick(vm, 'name'),
      action: (vm, form) => vm.$store.dispatch('spreadsheetFlow/updateSpreadSheetFlow', form),
      skipAction: (vm) => get(vm, 'name') === get(vm, 'identification.name'),
      nextStepGaEvent: 'button-flow-step-advance-click',
    },
    {
      name: 'templateSelection',
      component: 'FlowTemplateStep',
      icon: 'file-invoice',
      type: 'formStep',
      form: 'template',
      props: {
        templates: 'templates',
        templateId: 'template.templateId',
        createNewTemplateLink: 'links.templatesNew',
      },
      defaultValues: (vm) => pick(vm, 'templateId'),
      isNextStepButtonDisabled: (vm) => {
        const templateId = get(vm, 'template.templateId');
        return !templateId || templateId === -1;
      },
      action: (vm, form) => vm.$store.dispatch('spreadsheetFlow/updateSpreadSheetFlow', form),
      skipAction: (vm) => {
        const spreadsheetDefaultTemplateId = get(vm, 'templateId');
        const currentFormTemplateId = get(vm, 'template.templateId');
        return !!currentFormTemplateId && spreadsheetDefaultTemplateId === currentFormTemplateId;
      },
      nextStepGaEvent: 'button-template-step-advance-click',
      backStepGaEvent: 'button-template-step-back-click',
    },
    {
      name: 'spreadsheetSigner',
      component: 'SpreadsheetFlowSignerStep',
      icon: 'user-friends',
      props: {
        signers: 'signers',
        accountId: 'accountId',
        spreadsheetFlowId: 'id',
      },
      type: 'formStep',
      isNextStepButtonDisabled: (vm) => isEmpty(vm.signers),
      nextStepGaEvent: 'button-signers-list-step-advance-click',
      backStepGaEvent: 'button-signers-list-step-back-click',
    },
    {
      name: 'spreadsheetUpload',
      component: 'SpreadsheetFlowFileStep',
      type: 'formStep',
      form: 'spreadsheet',
      props: {
        activeSubstep: 'activeSubstep',
        error: 'currentStepActionError',
        spreadsheetName: 'spreadsheet.spreadsheetName',
        handleBackStep: 'handleBackStep',
        handleNextStep: 'handleNextStep',
        isAdvancingStep: 'isAdvancingStep',
        spreadsheetUrl: 'spreadsheet.spreadsheetUrl',
        currentStepIndex: 'currentStepIndex',
        breadCrumbSteps: 'breadCrumbSteps',
        substeps: 'substeps',
      },
      defaultValues: (vm) => pick(vm, ['spreadsheetName', 'spreadsheetHash']),
      isNextStepButtonDisabled: (vm) => {
        const activeSubstep = get(vm, 'activeSubstep');
        const substeps = get(vm, 'substeps');
        return activeSubstep === substeps.length && !get(vm, 'spreadsheet.spreadsheetName');
      },
      action: (vm, form) => vm.$store.dispatch('spreadsheetFlow/updateSpreadSheetFlow', form),
      skipAction: (vm) => !!get(vm, 'spreadsheet.spreadsheetHash'),
      handleActionError: (vm, error) => {
        const errorPath = 'response.data.spreadsheetFlow.errors.spreadsheet[0]';
        const actionError = get(error, errorPath);
        vm.setActionError(actionError);
      },
      afterAction: (vm) => {
        const stepsToReloadDefaultValues = [
          'spreadsheetFieldMappings',
          'spreadsheetDocName',
          'spreadsheetUpload',
        ];
        stepsToReloadDefaultValues.forEach(vm.loadStepDefaultValues);
      },
      substeps: [
        {
          name: 'downloadSpreadsheetFile',
          nextStepGaEvent: 'button-download-spreadsheet-step-advance-click',
          backStepGaEvent: 'button-download-spreadsheet-step-back-click',
          dataGaHelpLinkEvent: 'link-help-spreadsheet-flow-download-click',
        },
        {
          name: 'fulfillSpreadsheetFile',
          nextStepGaEvent: 'button-fill-step-advance-click',
          backStepGaEvent: 'button-fill-step-back-click',
          dataGaHelpLinkEvent: 'link-help-spreadsheet-flow-fill-click',
        },
        {
          name: 'uploadSpreadsheetFile',
          nextStepGaEvent: 'button-upload-step-advance-click',
          backStepGaEvent: 'button-upload-step-back-click',
          dataGaHelpLinkEvent: 'link-help-spreadsheet-flow-upload-click',
        },
      ],
    },
    {
      name: 'spreadsheetFieldMappings',
      component: 'SpreadsheetFlowFieldMappingStep',
      icon: 'user-variable',
      type: 'formStep',
      form: 'fieldMappings',
      props: {
        activeSubstep: 'activeSubstep',
        spreadsheetData: 'spreadsheetData',
        fieldMappings: 'fieldMappings',
        spreadsheetFlowLists: 'spreadsheetFlowLists',
        templateFields: 'templateFields',
        loading: 'isFetchingData',
        spreadsheetFields: 'spreadsheetFields',
        validations: 'validations',
        spreadsheetSigners: 'spreadsheetMappingSigners',
        substeps: 'substeps',
      },
      fetchDataAction: 'spreadsheetFlow/fetchSpreadsheetRows',
      substeps: [
        {
          name: 'signerMappings',
          form: 'fieldMappings.signersMappings',
          nextStepGaEvent: 'button-mapping-step-advance-click',
          backStepGaEvent: 'button-mapping-step-back-click',
          dataGaHelpLinkEvent: 'link-help-spreadsheet-flow-mapping-click',
        },
        {
          name: 'templateMappings',
          form: 'fieldMappings',
          nextStepGaEvent: 'button-mapping-template-step-advance-click',
          backStepGaEvent: 'button-mapping-template-step-back-click',
          dataGaHelpLinkEvent: 'link-help-spreadsheet-flow-mapping-template-click',
        },
      ],
      defaultValues: (vm) => get(vm, 'spreadsheetFieldsMappings'),

      isNextStepButtonDisabled: (vm) => {
        const currentSubStep = vm.substeps[vm.activeSubstep - 1];
        const currentSubStepForm = get(currentSubStep, 'form', '');
        const invalidSubForm = get(vm.validations, `${currentSubStepForm}.hasInvalid`);
        const hasOneValidRow = get(vm.validations, 'spreadsheetData.items', []).some(
          ({ isValid }) => isValid
        );

        return invalidSubForm || (vm.activeSubstep === 2 && !hasOneValidRow);
      },

      action: async (vm, form) => {
        const hasInvalidRows = get(vm.validations, 'spreadsheetData.hasInvalid', false);
        const payload = { ...form, spreadsheetFlowLists: vm.spreadsheetFlowLists };

        if (hasInvalidRows && !vm.confirmedErrors) throw new Error();

        await vm.$store.dispatch('spreadsheetFlow/updateSpreadSheetFlowFieldMappings', payload);
      },

      afterAction: async (vm) => {
        await vm.updateSpreadsheetProcessessBinding();
      },

      skipAction: (vm) => {
        const spreadsheetDefaultFieldMappings = get(vm, 'spreadsheetFieldsMappings');
        const currentFormFieldMappings = get(vm, 'fieldMappings');
        const sameForm = isEqual(currentFormFieldMappings, spreadsheetDefaultFieldMappings);
        return sameForm && vm.confirmedErrors;
      },

      handleActionError: (vm) => {
        vm.$set(vm, 'showSpreadsheetErrorsDialog', true);
      },
    },
    {
      name: 'spreadsheetDocName',
      component: 'SpreadsheetFlowDocNameStep',
      icon: 'edit',
      form: 'spreadsheetFlowDocFolders',
      type: 'formStep',
      props: {
        documentName: 'spreadsheetFlowDocFolders.documentName',
        path: 'spreadsheetFlowDocFolders.path',
        spreadsheetFields: 'spreadsheetFields',
      },
      defaultValues: (vm) => pick(vm, ['path', 'documentName']),
      skipAction: (vm) => {
        const defaultValues = pick(vm, ['path', 'documentName']);
        const form = get(vm, 'spreadsheetFlowDocFolders');
        return isEqual(defaultValues, form);
      },
      isNextStepButtonDisabled: (vm) => {
        const emptyDocumentsName = !get(vm, 'spreadsheetFlowDocFolders.documentName', []).length;
        const emptyFoldersPath = !get(vm, 'spreadsheetFlowDocFolders.path', []).length;
        return emptyDocumentsName || emptyFoldersPath;
      },
      action: (vm, form) => vm.$store.dispatch('spreadsheetFlow/updateSpreadSheetFlow', form),
      nextStepGaEvent: 'button-name-document-step-advance-click',
      backStepGaEvent: 'button-name-document-step-back-click',
    },
    {
      name: 'flowSettings',
      component: 'SpreadsheetFlowSettingsStep',
      icon: 'cog',
      type: 'formStep',
      form: 'settings',
      props: {
        autoClose: 'settings.autoClose',
        deadlineIn: 'settings.deadlineIn',
        defaultDeadline: 'defaultDeadline',
        locale: 'settings.locale',
        maxDeadline: 'maxDeadline',
        remindInterval: 'settings.remindInterval',
        remindIntervals: 'remindIntervals',
        defaultRemindInterval: 'defaultRemindInterval',
        signers: 'signers',
      },
      defaultValues: (vm) =>
        pickBy(pick(vm, ['deadlineIn', 'remindInterval', 'locale', 'autoClose'])),
      skipAction: (vm) => {
        const defaultValues = pick(vm, ['deadlineIn', 'remindInterval', 'locale', 'autoClose']);
        const settings = get(vm, 'settings');
        return isEqual(defaultValues, settings);
      },
      action: (vm, form) => vm.$store.dispatch('spreadsheetFlow/updateSpreadSheetFlow', form),
      nextStepGaEvent: 'button-settings-step-advance-click',
      backStepGaEvent: 'button-settings-step-back-click',
    },
    {
      name: 'spreadsheetFlowFinishStep',
      component: 'SpreadsheetFlowFinishStep',
      icon: 'paper-plane',
      type: 'formStep',
      props: { dataAnalysis: 'dataAnalysis' },
      action: (vm) => vm.redirectToIndexPage(),
      nextStepGaEvent: 'button-save-draft-click',
      backStepGaEvent: 'button-send-back-click',
    },
  ],
  SPREADSHEET_SIGNER_BASE_STATE: {
    description: '',
    signAs: '',
    hasDocumentation: false,
    hasWhatsapp: false,
    additionalAuths: [],
  },
  COLUMN_TYPES: [
    {
      value: 'email',
      text: 'spreadsheetFlow.setup.fieldMappings.columnsTypes.email.label',
      tooltip: 'spreadsheetFlow.setup.fieldMappings.columnsTypes.email.tooltip',
      validation: { email: validators.email },
      dataGAEventLabel: 'email',
    },
    {
      value: 'cep',
      text: 'spreadsheetFlow.setup.fieldMappings.columnsTypes.cep.label',
      tooltip: 'spreadsheetFlow.setup.fieldMappings.columnsTypes.cep.tooltip',
      validation: { cep: validators.cep },
      dataGAEventLabel: 'CEP',
    },
    {
      value: 'documentation',
      text: 'spreadsheetFlow.setup.fieldMappings.columnsTypes.documentation.label',
      tooltip: 'spreadsheetFlow.setup.fieldMappings.columnsTypes.documentation.tooltip',
      validation: { cpf: validators.cpf },
      dataGAEventLabel: 'CPF',
    },
    {
      value: 'date',
      text: 'spreadsheetFlow.setup.fieldMappings.columnsTypes.date.label',
      tooltip: 'spreadsheetFlow.setup.fieldMappings.columnsTypes.date.tooltip',
      validation: { date: validators.date },
      dataGAEventLabel: 'date',
    },
    {
      value: 'phoneNumber',
      text: 'spreadsheetFlow.setup.fieldMappings.columnsTypes.phone.label',
      tooltip: 'spreadsheetFlow.setup.fieldMappings.columnsTypes.phone.tooltip',
      validation: { phone: customValidators.phone },
      dataGAEventLabel: 'cellphone',
    },
    {
      value: 'number',
      text: 'spreadsheetFlow.setup.fieldMappings.columnsTypes.number.label',
      tooltip: 'spreadsheetFlow.setup.fieldMappings.columnsTypes.number.tooltip',
      validation: { numeric: validators.numeric },
      dataGAEventLabel: 'number',
    },
    {
      value: 'fullName',
      text: 'spreadsheetFlow.setup.fieldMappings.columnsTypes.fullName.label',
      tooltip: 'spreadsheetFlow.setup.fieldMappings.columnsTypes.fullName.tooltip',
      validation: { fullName: validators.fullName },
      dataGAEventLabel: 'name',
    },
    {
      value: 'string',
      text: 'spreadsheetFlow.setup.fieldMappings.columnsTypes.string.label',
      tooltip: 'spreadsheetFlow.setup.fieldMappings.columnsTypes.string.tooltip',
      validation: { alphanumeric: validators.alphanumeric },
      dataGAEventLabel: 'text-others',
    },
  ],
  SIGNER_FIELDS_VALIDATIONS: {
    email: 'email',
    phoneNumber: 'phoneNumber',
    name: 'fullName',
    documentation: 'documentation',
    birthday: 'date',
  },
};
