import { ACTIONS } from './chat-actions';

const ROLES = {
  USER: 'user',
  ASSISTANT: 'assistant',
};

const TYPES = {
  MESSAGE: 'message',
  FILE: 'file',
  ACTION: 'action',
  LOADING: 'loading',
  ERROR: 'error',
  SIGNER: 'signer',
};

const CHAT = {
  ROLES,
  TYPES,
  ACTIONS,
};

export { CHAT };
