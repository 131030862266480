<template>
  <div :class="$style.loader">
    <div :class="$style.header">
      <VueSkeletonLoader
        type="rect"
        rounded
        height="48px"
        width="100%"
      />
    </div>
    <div :class="$style.title">
      <VueSkeletonLoader
        type="rect"
        rounded
        height="40px"
        width="340px"
      />
      <VueSkeletonLoader
        type="rect"
        rounded
        height="40px"
        width="100px"
      />
    </div>
    <div
      v-for="item in [1, 2, 3]"
      :key="item"
      :class="$style.content"
    >
      <VueSkeletonLoader
        type="rect"
        rounded
        height="40px"
        width="340px"
      />
      <VueSkeletonLoader
        type="rect"
        rounded
        height="200px"
        width="100%"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import VueSkeletonLoader from 'skeleton-loader-vue';

export default defineComponent({
  name: 'FormFlowShowSkeleton',
  components: {
    VueSkeletonLoader,
  },
});
</script>

<style lang="scss" module>
.loader {
  margin: var(--space-large);

  .header {
    margin-bottom: var(--space-large);
  }

  .title {
    display: flex;
    margin-bottom: var(--space-large);
    gap: var(--space-small-xx);
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--space-large);
    gap: var(--space-regular);
  }
}
</style>
