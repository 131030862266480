<script setup>
import { useStore } from '@/store';
import { lowerCase, upperFirst, camelCase } from 'lodash';
import { computed, ref } from 'vue';

defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const loading = ref(false);

const selectedProcess = computed(() => store.getters['pendingAnswers/getSelectedProcess']);
const selectedProcessAnswers = computed(
  () => store.getters['pendingAnswers/getSelectedProcessAnswers']
);

const buildAnswers = (answers) => {
  if (answers?.length) {
    const items = [];

    answers.forEach((item) => {
      const key = Object.getOwnPropertyNames(item)[0];
      const value = Object.values(item)[0];

      if (item.fileUrl) {
        items.push({
          label: key,
          value,
          fileUrl: item.fileUrl,
        });
      } else {
        items.push({
          label: key,
          value,
        });
      }
    });

    return items;
  }

  return answers;
};

const fetchAnswers = async () => {
  loading.value = true;
  await store.dispatch('pendingAnswers/fetchAnswers');
  loading.value = false;
};
</script>

<template>
  <XModal
    :is-open="showModal"
    :loading="loading"
    scrollable
    data-testid="pendingAnswersDetailsModal"
    @close="$emit('close')"
    @opened="fetchAnswers"
  >
    <template #header>
      {{ $t('pendingAnswers.detailsModal.title') }}
    </template>
    <div>
      <h5>
        {{ $t('pendingAnswers.detailsModal.flowDetails') }}
      </h5>
      <p>
        <span :class="$style.key">
          {{ $t('pendingAnswers.detailsModal.firstFlowName') }}
        </span>
        <br />
        {{ selectedProcess?.formBuilders?.[0]?.name }}
      </p>
      <p>
        <span :class="$style.key">
          {{ $t('pendingAnswers.detailsModal.secondFlowName') }}
        </span>
        <br />
        {{ selectedProcess?.formBuilders?.[1]?.name }}
      </p>
      <p>
        <span :class="$style.key">
          {{ $t('pendingAnswers.detailsModal.identifier') }}
        </span>
        <br />
        {{ selectedProcess?.documentName }}
      </p>
      <h5>
        {{ $t('pendingAnswers.detailsModal.flowAnswers') }}
      </h5>
      <div
        v-for="(answer, index) in buildAnswers(selectedProcessAnswers?.form1)"
        :key="index"
      >
        <p v-if="answer.value">
          <template v-if="answer.label">
            <span :class="$style.key">
              {{ upperFirst(lowerCase(answer.label)) }}
            </span>
            <br />
          </template>
          <XLink
            v-if="answer.fileUrl"
            :to="answer.fileUrl"
            no-padding
            :download="answer.value"
            :data-testid="camelCase(`fileLink${answer.label}`)"
          >
            {{ answer.value }}
          </XLink>
          <span v-else>
            {{ answer.value }}
          </span>
        </p>
        <p
          v-else
          :class="$style.unanswered"
        >
          {{ $t('compliance.unanswered') }}
        </p>
      </div>
    </div>
    <template #actions>
      <XButton
        design="outlined"
        data-testid="modalCloseButton"
        @click="$emit('close')"
      >
        {{ $t('general.action.close') }}
      </XButton>
    </template>
  </XModal>
</template>

<style lang="scss" module>
.key {
  font-size: var(--font-size-small);
  color: var(--color-neutral-800);
}

.unanswered {
  margin: 0;
  font-style: italic;
  color: var(--color-neutral-700);
}
</style>
