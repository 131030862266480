import { sentry } from '@/plugins/sentry';

// Api doc https://docs.appcues.com/article/161-javascript-api
function appcuesTrackNavigation() {
  try {
    const { Appcues } = window;
    if (Appcues && typeof Appcues !== 'undefined') Appcues.page();
  } catch (error) {
    sentry.captureException(error);
  }
}

export { appcuesTrackNavigation };
