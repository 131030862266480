import rubricMutations from './rubric/mutations';

export default {
  SET_DRAFT: (state, payload) => {
    state.draft = payload;
  },
  SET_DRAFTS: (state, payload) => {
    state.drafts = payload;
  },
  SET_PAGINATION: (state, payload) => {
    state.pagination = payload;
  },
  SET_LINKS: (state, payload) => {
    Object.assign(state.links, { ...payload });
  },
  SET_TOGGLES: (state, payload) => {
    state.toggles = payload;
  },
  SET_ARCHIVES: (state, payload) => {
    state.archives = payload;
  },
  SET_UPLOADING_ARCHIVE: (state, payload) => {
    state.uploadingArchives.unshift(payload);
  },
  SET_NEW_ARCHIVE: (state, payload) => {
    state.archives.unshift(payload);
  },
  UPDATE_ARCHIVE(state, archive) {
    const archives = [...state.archives];
    let index;
    if (archive.stampKey) {
      index = state.archives.findIndex(({ stampKey }) => stampKey === archive.stampKey);
    } else {
      index = state.archives.findIndex(({ key }) => key === archive.key);
    }
    archives[index] = archive;
    state.archives = archives;
  },
  PATCH_ARCHIVE: (state, payload) => {
    const archives = [...state.archives];
    const index = archives.findIndex((doc) => doc.key === payload.key);
    archives[index] = { ...payload.data };
    state.archives = archives;
  },
  REMOVE_UPLOADING_ARCHIVE: (state, payload) => {
    state.uploadingArchives = state.uploadingArchives.filter((a) => a.key !== payload);
  },
  SET_DELETING_ARCHIVE: (state, payload) => {
    state.deletingArchives.unshift(payload);
  },
  REMOVE_DELETING_ARCHIVE: (state, payload) => {
    state.deletingArchives = state.deletingArchives.filter((a) => a.key !== payload.key);
  },
  SET_ALREADY_DELETED_ARCHIVE: (state, payload) => {
    state.alreadyDeletedArchives.unshift(payload);
  },
  REMOVE_ALREADY_DELETED_ARCHIVE: (state, payload) => {
    state.alreadyDeletedArchives = state.alreadyDeletedArchives.filter(
      (a) => a?.key !== payload?.key
    );
  },
  REMOVE_ARCHIVE: (state, payload) => {
    const isArchiveDeleting = state.deletingArchives.some((a) => a.key === payload.key);
    if (isArchiveDeleting) return;

    state.archives = state.archives.filter((a) => a.key !== payload.key);
  },
  RESET_STATE: (state) => {
    Object.assign(state, state.initialState());
  },
  SET_SIGNERS: (state, payload) => {
    state.signers = payload;
  },
  // TOGGLE_ENVELOPE_SETUP_SCOPE
  // TODO: Remove mutation below
  UPDATE_SIGNER_FROM_GROUP: (state, payload) => {
    const currentGroup = state.groups[payload.group] || [];

    const indexSignerToUpdate = currentGroup.findIndex((signer) => signer.key === payload.key);
    currentGroup.splice(indexSignerToUpdate, 1, payload);

    state.groups = {
      ...state.groups,
      [payload.group]: currentGroup,
    };
  },
  SET_GROUPS: (state, payload) => {
    state.groups = payload || {};

    if (state.fromEnvelope) {
      state.signers = Object.values(state.groups).flat();
    }
  },
  SET_OPERATOR: (state, payload) => {
    state.operator = payload || {};
  },
  PUSH_SIGNER: (state, payload) => {
    state.signers.push(payload);
  },
  PUSH_SIGNERS: (state, payload) => {
    state.signers = [...state.signers, ...payload];
  },
  REMOVE_SIGNER: (state, payload) => {
    state.signers = state.signers.filter((list) => list.id !== payload.id);
  },
  SET_FROM_ENVELOPE: (state, payload) => {
    state.fromEnvelope = payload;
  },
  SET_SEQUENCE_ENABLED: (state, payload) => {
    state.sequenceEnabled = payload;
  },
  SET_SIGNATURES: (state, payload) => {
    state.positionSign.signatures = payload;
  },
  SET_DRAFT_LOADING: (state, payload) => {
    state.draftLoading = payload;
  },
  SET_AUTO_SIGN: (state, payload) => {
    state.draft.autoSign = payload;
  },
  ...rubricMutations,
};
