import { axios } from '@/plugins';

const fetchIncentives = async ({ rootGetters, commit }) => {
  try {
    // TOGGLE_SMART_UPGRADE_ENABLED
    // TODO: Remove toggle declaration and condition
    const { toggleSmartUpgradeEnabled } = rootGetters['featureFlags/getToggles'];

    // TOGGLE_SMART_UPGRADE_ENABLED
    // TODO: Remove toggle conditional below
    if (toggleSmartUpgradeEnabled) {
      const requestUrl = rootGetters['info/getLinks']?.incentives;
      if (!requestUrl) return {};

      const { data } = await axios.get(requestUrl);

      commit('SET_INCENTIVES', data);
      return data;
    }

    // TOGGLE_SMART_UPGRADE_ENABLED
    // TODO: Remove line below
    return null;
  } catch (err) {
    return null;
  }
};

export default {
  fetchIncentives,
};
