import { useStore } from '@/store';
import { computed } from 'vue';
import { momentFormatDate } from '@/utils/date';

export const useLimitInTrialCheck = () => {
  const store = useStore();

  const account = computed(() => store.getters['info/getAccount'] || {});

  const hasDocumentsLimitInTrial = computed(
    () => account.value.trial && account.value?.remainingDraftPacksInTrial !== null
  );

  const remainingDocumentsInTrial = computed(() => {
    if (hasDocumentsLimitInTrial.value) return account.value?.remainingDraftPacksInTrial;
    return -1;
  });

  const hasExceededDocumentLimit = computed(() => {
    if (!hasDocumentsLimitInTrial.value) return false;
    return remainingDocumentsInTrial.value <= 0 && remainingDocumentsInTrial.value !== null;
  });

  const hasWhatsappAcceptanceLimitInTrial = computed(() => account.value.trial);

  const remainingWhatsappAcceptance = computed(() => {
    if (!hasWhatsappAcceptanceLimitInTrial.value) return -1;
    return account.value?.remainingAcceptanceWhatsappInTrial;
  });

  const hasExceededWhatsappAcceptanceLimit = computed(() => {
    if (!hasWhatsappAcceptanceLimitInTrial.value) return false;
    return remainingWhatsappAcceptance.value <= 0;
  });

  const trialExpiresDate = computed(() => {
    return momentFormatDate(account.value.trialExpiresAt);
  });

  return {
    remainingDocumentsInTrial,
    remainingWhatsappAcceptance,
    hasExceededDocumentLimit,
    hasExceededWhatsappAcceptanceLimit,
    hasDocumentsLimitInTrial,
    trialExpiresDate,
  };
};
