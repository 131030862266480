const documentBase = {
  settings: {
    title: 'Configuraciones',
    deadline: {
      title: 'Fecha límite para las firmas',
      tooltip:
        'Cuando se alcance la fecha límite del documento, se finalizará automáticamente. Si no hay firmas o hay firmas rechazadas, se cancelará. | Cuando se alcance la fecha límite de los documentos, se finalizarán automáticamente. Si no hay firmas o hay firmas rechazadas, se cancelarán.',
      subtitle: 'No será posible realizar firmas una vez alcanzada la fecha límite',
    },
    refusal: {
      title: 'Pausar la firma tras el rechazo',
      checkbox:
        'Si alguien rechaza, el proceso de firma se pausará, impidiendo que otras personas firmen y que el documento se finalice.',
    },
    autoReminder: {
      title: 'Recordatorios automáticos por correo electrónico',
      subtitle: 'Envío de hasta 3 recordatorios por correo electrónico.',
      whatsappWarning: 'Los documentos enviados por WhatsApp no recibirán recordatorios.',
      envelopeWarning: {
        title: 'Frecuencia de envío: cada 3 días',
        subtitle: 'Máximo de 3 recordatorios enviados.',
      },
      label: 'Enviar recordatorios automáticamente cada',
      day: '{day} día | {day} días',
      neverDay: 'No enviar recordatorios',
      eachDay: 'Cada {day} día | Cada {day} días',
      autoReminderLimit: 'Enviar recordatorios automáticamente',
    },
    rubric: {
      title: 'Firma electrónica',
      subtitle: 'Opciones disponibles para confirmar la visualización de un contenido.',
      clauseLabel: 'Confirmación de lectura de cláusulas',
      fullReadConfirmationLabel: 'Visualización completa del documento',
      sealLabel: 'Firma en la esquina de la página',
      nonOptionLabel: 'No habilitar firma electrónica',
      envelope: {
        subtitle: 'El firmante deberá confirmar el contenido mediante su firma',
        checkbox: 'Solicitar firma en la esquina de la página',
        electronicRubric: 'Solicitar firma',
      },
    },
    documentLanguage: {
      title: 'Idioma',
      subtitle:
        'La página de firma y los correos electrónicos se mostrarán en el idioma seleccionado',
      options: {
        english: 'Inglés (EE.UU.)',
        portuguese: 'Portugués',
        spanish: 'Español',
      },
    },
    autoSaveToFuture: 'Sus configuraciones se guardarán automáticamente para el próximo uso',
    deadlineSignature: 'Fecha límite para la firma',
    daysRemainingUntilDeadline:
      'Falta menos de un día para la fecha límite | Falta {day} día para la fecha límite | Faltan {day} días para la fecha límite',
    pauseOnRefusal: 'Pausar proceso de firma tras el rechazo',
    positionRubric: 'Posicionar campos de firma y rubrica',
    documentCloseFinish: 'Finalización',
    documentClose: {
      title: 'Finalización',
      subtitle: '¿Cuándo deben finalizarse los documentos enviados?',
      auto: 'Después de que todos firmen, automáticamente',
      manual: 'Manualmente o hasta la fecha límite. Así es posible revisar antes de finalizar',
    },
  },
  documentsBalanceAlert: {
    message:
      'Aún tienes {0} envío de documentos para usar en tu periodo de prueba. | Aún tienes {0} envíos de documentos para usar en tu periodo de prueba.',
  },
};

export default documentBase;
