import axios from '@/plugins/axios';

export async function fetchMessageEditingSession({ links }) {
  const { data } = await axios.get(links.settings);

  return data.envelope.defaultMessage;
}
// TOGGLE_EXPERIMENT_SUGGESTION_DEFAULT_SUBJECT_ENABLED
// TODO: remove subjectToggle from payload and condition
export async function fetchMessageEnvelopeCompat({ links, subjectToggle }) {
  const { data } = await axios.get(links.settings);

  let response = {
    message: data.pack.defaultMessage,
  };

  if (subjectToggle) {
    response = {
      ...response,
      subject: data.pack.defaultSubject,
    };
  }

  return response;
}
