const wizardEdit = {
  steps: {
    documents: 'Agregar documentos',
    signers: 'Agregar firmantes',
    settings: 'Configuraciones',
    message: 'Mensaje y envío',
  },
  cancelDocumentModal: {
    title: '¿Deseas cancelar el documento?',
    cancelInvalidSignatures: 'La cancelación invalida las firmas ya realizadas.',
    confirmWarning:
      'Para confirmar la cancelación, guarda los cambios en la última etapa de la edición.',
    confirm: 'Cancelar documento',
    defaultError: 'Error al cancelar uno o más archivos',
  },
  header: {
    close: 'Salir de la edición',
  },
  finishedMessage: '¡Cambios realizados con éxito!',
  leaveEdit: {
    title: '¿Deseas salir de la edición?',
    message:
      'Los cambios realizados no se guardarán. Avanza hasta el final del flujo si deseas guardarlos.',
    cancel: 'Continuar editando',
    confirm: 'Salir',
  },
  editError: {
    title: 'Error al editar el sobre',
    message:
      'Se produjeron cambios de estatus en uno o más documentos de este sobre durante su edición y los cambios no pudieron ser guardados. Inténtalo de nuevo.',
  },
  content: {
    title: 'Editar sobre',
    alert:
      'En el modo edición, los cambios solo se aplicarán si se guardan en la etapa de Mensaje y envío',
    signers: {
      blockRemove: 'Ya se firmó un documento. No se puede eliminar',
      deleteSignerError: 'Error al eliminar firmante',
      noDocuments: 'No hay documentos para firmar',
      selectDocuments: 'Elige los documentos que deseas firmar.',
    },
    unlinkSignerModal: {
      title: '¿Eliminar firmante de la lista?',
      description: 'El firmante será eliminado de la lista de firmas de este envío.',
      remove: 'Eliminar firmante',
    },
  },
  message: {
    title: 'Mensaje y envío',
    saveAndSend: 'Guardar y enviar',
    whatsappAlertCost: 'Para envíos vía WhatsApp, hay un costo de R$ 0,40 por recordatorio.',
  },
};

export default wizardEdit;
