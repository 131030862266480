const wizardEdit = [
  {
    path: '/accounts/:accountId/notarial/links/:envelopeKey/editing_sessions/:sessionKey',
    name: 'EnvelopeWizardEdit',
    component: () => import('@envelope/views/EnvelopeWizardEditView/EnvelopeWizardEditView.vue'),
    meta: {
      layout: 'BaseDefaultLayout',
      closeLink: {
        show: true,
        locale: 'envelope.wizardEdit.header.close',
        path: 'envelopeSignatures',
        dataTestid: 'backToEnvelopeOverview',
      },
      refresh: () => true,
    },
  },
];

export default wizardEdit;
