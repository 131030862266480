export default {
  getLinks: (state) => state.links,
  getIsRoot: (state) => state.currentFolder.root,
  getFolderName: (state) => state.currentFolder.name,
  getAncestors: (state) => state.currentFolder.ancestors,
  getTrashBinId: (state) => state.currentFolder.trashBinId,
  getPagination: (state) => state.currentFolder.pagination,
  getCurrentFolder: (state) => state.currentFolder,
  getNodes: (state) => state.currentFolder.nodes,
  getNodesLoading: (state) => state.nodesLoading,
};
