import moment from 'moment-timezone';

const DEFAULT_TIMEZONE = 'America/Bahia';

export const momentFormatDate = (date, format = 'L', timezone = DEFAULT_TIMEZONE) =>
  date ? moment.tz(date, timezone).format(format) : '';

export const momentDate = (date, timezone = DEFAULT_TIMEZONE) =>
  date ? moment.tz(date, timezone) : moment.tz(new Date(), timezone);

export const getDateTimeElapsedSincePageLoaded = (loadPageDateTime) => {
  const millisecondsElapsed = performance.now();

  const timeElapsed = moment(loadPageDateTime).add(millisecondsElapsed, 'millisecond');

  return timeElapsed;
};

export const getDurationBetweenTimes = (startTime, endTime, unit = 'milliseconds') => {
  const diffTime = moment(endTime).diff(moment(startTime));
  const duration = moment.duration(diffTime, unit);
  return duration;
};

export const isTimeElapsedBeforeEndTime = (loadPageDateTime, endTime) => {
  const timeElapsed = getDateTimeElapsedSincePageLoaded(loadPageDateTime);
  const finalTime = moment(endTime);
  const diffTime = finalTime.diff(timeElapsed, 'seconds');

  return diffTime >= 0;
};
