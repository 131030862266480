import { camelCase } from 'lodash';
import { SIGNER_CONSTANTS } from '@document/constants';

export default {
  SET_LINKS: (state, payload) => {
    Object.assign(state.links, { ...payload });
  },
  SET_ADDRESS: (state, payload) => {
    Object.assign(state.subscription?.billingCustomer?.common, { ...payload });
  },
  SET_SUBSCRIPTION: (state, payload) => {
    state.subscription = { ...payload };
  },
  SET_SEGMENTS: (state, payload) => {
    state.segments = payload;
  },
  SET_FUNCTIONALITY(state, payload) {
    state.functionalities = payload.functionalities?.map((functionality) =>
      camelCase(functionality.code)
    );
  },
  SET_FUNCTIONALITIES_BLOCKED_BY_ADMIN(state, payload) {
    const blockedFunctionalities = payload.functionalities.filter(
      (functionality) => functionality.blocked
    );
    const blockedFunctionalitiesWithShortName = blockedFunctionalities.map((functionality) => ({
      ...functionality,
      shortName: SIGNER_CONSTANTS.FUNCTIONALITIES_CODE_SHORT_NAME[camelCase(functionality.code)],
    }));
    state.functionalitiesBlockedByAdmin = [...blockedFunctionalitiesWithShortName];
  },
  SET_IS_LOADING_FUNCTIONALITIES(state, payload) {
    state.isLoadingFunctionalities = payload;
  },
  UPDATE_SEGMENTS: (state, payload) => {
    state.segments.push(payload);
  },
};
