const compliance = {
  title: 'Aprovação das respostas dos fluxos',
  approve: 'Aprovar',
  disapprove: 'Reprovar',
  loading: 'Carregando lista de respostas dos fluxos...',
  header: {
    title: 'Aprovação das respostas dos fluxos',
    help: 'Ajuda',
    alert: 'Confira as respostas pendentes de aprovação abaixo e decida se deseja aprová-las.',
    filter: {
      label: 'Fluxos',
      placeholder: 'Selecione o fluxo',
      clear: 'Limpar',
      button: 'Filtrar',
    },
  },
  comfirmModal: {
    approve: 'Sim, aprovar!',
    disapprove: 'Reprovar',
  },
  empty: {
    title: 'Você ainda não possui resposta pendente de aprovação',
    description:
      'Ative a aprovação de respostas em seu fluxo e confira aqui as informações antes de gerar o documento.',
  },
  form: 'Formulário | Formulários',
  filledAt: 'Preenchido em',
  open: 'Abrir',
  cancel: 'Cancelar',
  notificationSuccess: 'Sucesso! O fluxo foi aprovado e o documento foi enviado para assinatura.',
  notificationDisapprove: 'Aviso! O fluxo foi Reprovado e o documento não será mais gerado.',
  modal: {
    title: 'Aprovar fluxo',
    actionsInfo: 'Após clicar em uma das opções, ainda será exibido uma tela de confirmação.',
  },
  confirmAction: {
    approve: 'Tem certeza que deseja aprovar?',
    approveInfo:
      'Ao aprovar, o documento será enviado para a assinatura. Esta ação é irreversível.',
    disapprove: 'Tem certeza que deseja reprovar?',
    disapproveInfo:
      'Ao reprovar, o documento não poderá mais ser gerado e enviado para a assinatura. Esta ação é irreversível.',
  },
  unanswered: 'Não informado',
  identifier: 'Identificador',
  flowName: 'Nome do fluxo',
};

export default compliance;
