const commonMeta = {
  layout: 'BaseNavigationLayout',
  parent: 'envelopeShow',
};

const mapRouteCommonMeta = (route) => ({
  ...route,
  meta: {
    ...(route.meta || {}),
    ...commonMeta,
  },
});

const envelope = [
  {
    name: 'envelopeShow',
    path: '/accounts/:accountId/notarial/links/:envelopeKey',
    component: () => import('@document/views/EnvelopeShowView/EnvelopeShowView.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'envelopeSignatures' },
      },
      {
        path: 'document_list',
        name: 'envelopeDocuments',
        component: () =>
          import(
            '@document/containers/envelope/EnvelopeOverviewContainer/EnvelopeOverviewContainer.vue'
          ),
        meta: { activeTab: 'documents' },
      },
      {
        path: 'settings',
        name: 'envelopeSettings',
        component: () =>
          import(
            '@document/containers/envelope/EnvelopeSettingsContainer/EnvelopeSettingsContainer.vue'
          ),
        meta: { activeTab: 'settings' },
      },
      {
        path: 'signatures',
        name: 'envelopeSignatures',
        component: () =>
          import(
            '@document/containers/envelope/EnvelopeSignaturesContainer/EnvelopeSignaturesContainer.vue'
          ),
        meta: { activeTab: 'signatures' },
      },
    ].map(mapRouteCommonMeta),
  },
];

export default envelope;
