const rubric = {
  error: 'Ocorreu um erro ao realizar a rubrica!',
  documentView: 'Visualize o documento até o final',
  readTooltip: 'Documento com visualização pendente',
  readConfirmation: 'Visualizado',
  readWidgetConfirmation: 'Visualizado',
  readError: 'Visualização obrigatória',
  tooltip: 'Documento com visualização pendente',
  modal: {
    clauses: {
      title: 'Confirmação de leitura de cláusulas',
      desc: 'Para concluir a assinatura, confirme a ciência do conteúdo a seguir:',
      itens: 'Cláusulas:',
      accept: 'Li e aceito as cláusulas/itens',
    },
    seals: {
      title: 'Rubrica no canto da página',
      desc: 'As páginas abaixo requerem uma rubrica.',
      pages: 'Páginas:',
      all: 'Todas as páginas',
      placeholder: 'bc',
      label: 'Insira suas iniciais para definir a rubrica (ex: BC).',
    },
    agree: 'Confirmar',

    title: 'Rubrica no canto da página',
    electronicRubric: 'Rubrica eletrônica',
    multipleDocuments:
      'Para prosseguir, confirme sua rubrica nas páginas do seguinte documento:|Para prosseguir, confirme sua rubrica nas páginas dos seguintes documentos:',
    singleDocumentAllPages:
      'Para prosseguir, confirme sua rubrica em todas as páginas do documento.',
    singleDocumentSomePages:
      'Para prosseguir, confirme sua rubrica na seguinte página:|Para prosseguir, confirme sua rubrica nas seguintes páginas:',
    pages: 'Página|Páginas',
    allPages: 'Todas as páginas',
    insertInitials: 'Insira suas iniciais para definir a rubrica (ex: BC).',
    initialsPlaceholder: 'Bc',
    confirm: 'Rubricar',
  },
};

export default rubric;
