<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from '@/store';
import { upperFirst, lowerCase } from 'lodash';

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['close', 'set-process-action']);

const store = useStore();

const loading = ref(false);

const processData = computed(() => store.getters['flowProcess/getProcessData']);
const forms = computed(() => {
  const newArray = [];
  if (processData.value?.form1?.length) {
    newArray.push(processData.value?.form1);
  }
  if (processData.value?.form2?.length) {
    newArray.push(processData.value?.form2);
  }
  return newArray;
});

const handleProcess = async () => {
  loading.value = true;
  await store.dispatch('flowProcess/getAnswers');
  loading.value = false;
};

const buildAnswers = (answers) => {
  if (answers?.length) {
    const items = [];

    answers.forEach((item) => {
      const key = Object.getOwnPropertyNames(item)[0];
      const value = Object.values(item)[0];

      if (item.fileUrl) {
        items.push({
          label: key,
          value,
          fileUrl: item.fileUrl,
        });
      } else {
        items.push({
          label: key,
          value,
        });
      }
    });

    return items;
  }

  return answers;
};

watch(
  () => props.show,
  (value) => {
    if (value) {
      handleProcess();
    }
  }
);
</script>

<template>
  <XModal
    :is-open="show"
    :title="`${$t('compliance.modal.title')}: ${processData?.flowName}`"
    width="900"
    close-icon
    :loading="loading"
    data-testid="complianceModal"
    @close="$emit('close')"
  >
    <div :class="$style.modalProcessContainer">
      <div
        v-for="(form, formIndex) in forms"
        :key="formIndex"
      >
        <h4 :class="$style.formTitle">
          {{ $tc('compliance.form') }} {{ formIndex + 1 }}:
          {{ processData?.form1Name }}
        </h4>
        <div
          v-for="(answer, index) in buildAnswers(form)"
          :key="index"
          :class="$style.content"
        >
          <p
            v-if="answer.label"
            :class="$style.key"
          >
            <span>
              {{ upperFirst(lowerCase(answer.label)) }}
            </span>
          </p>
          <p
            v-if="answer.value"
            :class="$style.value"
          >
            <XLink
              v-if="answer.fileUrl"
              :to="answer.fileUrl"
              no-padding
              :download="answer.value"
            >
              {{ answer.value }}
            </XLink>
            <span v-else>
              {{ answer.value }}
            </span>
          </p>
          <p
            v-else
            :class="$style.unanswered"
          >
            {{ $t('compliance.unanswered') }}
          </p>
        </div>
      </div>
    </div>

    <template #actions>
      <div :class="$style.modalFooter">
        <span :class="$style.confirmInfo">
          {{ $t('compliance.modal.actionsInfo') }}
        </span>
        <XButton
          design="outlined"
          data-testid="disapproveButton"
          data-ga-event="button-disapprove-click"
          @click="$emit('set-process-action', 'disapprove')"
        >
          {{ $t('compliance.disapprove') }}
        </XButton>

        <XButton
          data-testid="approveButton"
          data-ga-event="button-approve-click"
          @click="$emit('set-process-action', 'approve')"
        >
          {{ $t('compliance.approve') }}
        </XButton>
      </div>
    </template>
  </XModal>
</template>

<style lang="scss" module>
.modalProcessContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 500px;
  gap: var(--space-medium);

  .content {
    padding-bottom: var(--space-medium);

    .key {
      margin: 0;
      color: var(--color-neutral-700);
    }

    .value {
      margin: 0;
      color: var(--color-neutral-900);
    }

    .unanswered {
      margin: 0;
      font-style: italic;
      color: var(--color-neutral-700);
    }

    &:last-child {
      padding: 0;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: var(--space-small-xx);
    }
  }
}

.modalFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .confirmInfo {
    color: var(--color-neutral-700);
  }

  button {
    margin-left: var(--space-small-xx);
  }
}

.formTitle {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
}
</style>
