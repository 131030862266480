export default {
  links: {},
  accessTokens: [],
  memberships: [],
  apiUserId: null,
  hooks: [],
  plan: '',
  linkUpgradePlan: '',
  events: [],
  widgetEmbedded: false,
};
