import { computed } from 'vue';
import { useStore } from '@/store';

// TODO: replace the computed belows in favor of info/isForeignUser getter
// Move isNationalUser to a getter as well
export const useVerifyLocation = () => {
  const SUPPORTED_FOREIGN_COUNTRIES = ['mx'];

  const store = useStore();
  const user = computed(
    () => store.getters['info/getUsers']?.user || store.getters['info/getUser']
  );

  // TOGGLE_INTERNATIONALIZATION_ENABLED
  // TODO: Remove toggle below
  const isToggleInternationalizationEnabled =
    store.getters['info/getToggles'].toggleInternationalizationEnabled;

  // TOGGLE_INTERNATIONALIZATION_ENABLED
  // TODO: Remove toggle from conditional below
  const isForeignUser = computed(
    () =>
      isToggleInternationalizationEnabled &&
      SUPPORTED_FOREIGN_COUNTRIES.includes(user.value?.location)
  );

  const isNationalUser = computed(() => !isForeignUser.value);

  return {
    isForeignUser,
    isNationalUser,
  };
};
