import { axios } from '@/plugins';

const fetchConsumption = async ({ commit, rootGetters }) => {
  try {
    const link = rootGetters['info/getRoutes'].consumption;
    const { data } = await axios.get(link);

    commit('SET_CONSUMPTION', data);
    commit('SET_LINKS', data.links);
  } catch (error) {
    throw error;
  }
};

const fetchAccountCancellation = async ({ rootGetters }, payload) => {
  try {
    const { accountCancellation } = rootGetters['info/getRoutes'];
    const { status } = await axios.post(accountCancellation, payload);
    return status;
  } catch (error) {
    throw error;
  }
};

const fetchPlans = async ({ commit }, payload) => {
  try {
    commit('SET_PLANS', payload);
  } catch (error) {
    throw error;
  }
};

const fetchPaymentProfileItems = async ({ rootGetters, commit }, params = {}) => {
  const url = rootGetters['info/getLinks'].billingIntegration?.paymentProfileItems;
  const { data } = await axios.get(url, { params });
  commit('SET_CARDS', data.paymentProfileItems);
  commit('SET_CREDIT_CARD_PAGINATION', data.pagination);
};

const deleteCreditCard = async ({ dispatch }, url) => {
  await axios.delete(url);
  await dispatch('fetchPaymentProfile');
};

const fetchPaymentProfile = async ({ rootGetters, dispatch, commit }, params = {}) => {
  const url = `${rootGetters['info/getLinks'].billingIntegration?.paymentProfile}`;
  const { data } = await axios.get(url);
  await dispatch('fetchPaymentProfileItems', params);
  commit('SET_PAYMENT_PROFILE', data.paymentProfile);
};

const setBankSlipAsDefault = async ({ rootGetters, commit }) => {
  const url = `${rootGetters['info/getLinks'].billingIntegration?.paymentProfile}`;
  const { data } = await axios.patch(url, { paymentProfile: { paymentMethod: 'bank_slip' } });
  commit('SET_PAYMENT_PROFILE', data.paymentProfile);
};

const updateCard = async ({ rootGetters, dispatch }, id) => {
  const url = `${rootGetters['info/getLinks'].billingIntegration?.paymentProfileItems}/${id}`;
  await axios.patch(url, { default: true });
  await dispatch('fetchPaymentProfile');
};

const addCard = async ({ rootGetters }, payload) => {
  const url = rootGetters['info/getLinks'].billingIntegration?.paymentProfileItems;
  const { data } = await axios.post(url, payload);
  return data;
};

const sendPaymentProfileToVindi = async ({ rootGetters }, payload) => {
  const { vindiPaymentEndpoint, vindiPaymentKey } = rootGetters['environment/getVariables'];
  const { data } = await axios.post(vindiPaymentEndpoint, payload, {
    headers: {
      Authorization: `Basic ${btoa(vindiPaymentKey)}`,
    },
  });

  return data;
};

const setVindiErrors = ({ commit }, payload) => {
  commit('SET_VINDI_ERRORS', payload);
};

const fetchInvoices = async ({ rootGetters, commit }, { params }) => {
  const url = rootGetters['info/getLinks'].billingIntegration?.invoices;
  const { data } = await axios.get(url, { params });
  commit('SET_INVOICES', data.invoices);
  commit('SET_INVOICES_PAGINATION', data.pagination);
};

// TOGGLE_MIDAS_SCOPE
// TODO: Remove this action when scope >= 3
const fetchConsumptionInfo = async ({ rootGetters, commit }) => {
  const url = rootGetters['info/getLinks'].billingIntegration?.consumptionInfo;
  const { data } = await axios.get(url);
  commit('SET_CONSUMPTION_INFO', data);
};

const fetchProductUsages = async ({ rootGetters, commit }) => {
  const url = rootGetters['info/getLinks'].billingIntegration?.consumptionInfo;
  const { data } = await axios.get(url);
  commit('SET_PERIOD_USAGES', data.periodUsages);
  commit('SET_PRODUCT_USAGES', data.productUsages);
  commit('SET_NEGOTIATION', data?.negotiation);
};

// TODO: use this action when XPagination eventBased is fixed
const changePage = async ({ commit }, url) => {
  const { data } = await axios.get(url);
  commit('SET_CARDS', data.paymentProfileItems);
  commit('SET_CREDIT_CARD_PAGINATION', data.pagination);
};

const fetchBillingInfo = async ({ rootGetters, commit }) => {
  const url = rootGetters['info/getLinks'].billingIntegration?.billingInfo;
  const { data: billingInfo } = await axios.get(url);
  commit('SET_BILLING_INFO', billingInfo);
};

const changeDueDay = async ({ rootGetters, dispatch }, dueDay) => {
  const url = rootGetters['info/getLinks'].billingIntegration?.updateSubscription;
  await axios.patch(url, {
    subscription: { dueDay },
  });
  await dispatch('fetchConsumption');
};

export default {
  fetchInvoices,
  fetchConsumption,
  fetchAccountCancellation,
  fetchPlans,
  fetchPaymentProfile,
  addCard,
  sendPaymentProfileToVindi,
  setVindiErrors,
  fetchConsumptionInfo,
  fetchProductUsages,
  fetchPaymentProfileItems,
  deleteCreditCard,
  updateCard,
  setBankSlipAsDefault,
  changePage,
  fetchBillingInfo,
  changeDueDay,
};
