const modal = {
  download_log_not_ready: {
    download_doc: 'Descargar documento firmado',
    download_doc_info:
      'El documento firmado se está generando. Actualiza esta página en un momento más para descargarlo.',
    ok: 'Ok',
  },
  download: {
    download_doc: 'Descargar documento',
    download_doc_info:
      'El documento firmado solo podrá descargarse cuando esté finalizado. Mientras tanto, puedes descargar el documento original.',
    ok: 'Ok',
  },
  try_close: {
    title: 'Finalizar documento',
    info: 'El documento solo podrá finalizarse cuando tenga al menos una firma. Si es necesario, puedes cancelar el documento en cualquier momento.',
    ok: 'Ok',
  },
  duplicate: {
    title: 'Duplicar documento y enviar para firma',
    info: '¿Deseas duplicar el documento e iniciar un nuevo proceso de firma?',
    send: 'Duplicar',
    cancel: 'Cancelar',
  },
  remove_signer: {
    remove_signer: 'Eliminar firmante',
    wish_remover: '¿Realmente deseas eliminar este firmante?',
    after_remove:
      'Después de eliminarlo, si lo deseas, podrás agregarlo nuevamente a la lista de firmantes.',
    cancel: 'Cancelar',
    remove: 'Eliminar',
  },
  signerAdd: {
    title: 'Agregar firmante',
    badge1: '1. DATOS DEL FIRMANTE',
    badge2: '2. CÓMO DEBE FIRMAR EL FIRMANTE',
    fillToGo: 'COMPLETA PARA AVANZAR',
    signAs: 'Firmar como',
    authAs: 'Enviar token de autenticación vía',
    authAsNew: 'Autenticación Obligatoria',
    additionalAuths: 'Autenticaciones adicionales',
    fullName: 'Nombre completo',
    fullNameInfo: 'Con el nombre completo ingresado, el firmante no podrá modificarlo cuando firme',
    fullNamePlaceholder: 'Introduce el nombre del firmante',
    cpfPresent: '¿El firmante tiene CPF?',
    cpfPresentInfo: 'Elige No si el firmante no tiene CPF.',
    cpfInfo: 'Con el CPF ingresado, el firmante no tendrá que ingresarlo al firmar.',
    cpf: 'CPF',
    cpfPlaceholder: 'Introduce el CPF del firmante',
    email: 'Correo electrónico',
    emailPlaceholder: 'Introduce el correo del contacto',
    sms: 'SMS',
    cellphone: 'Número de celular',
    selfie_required: {
      label: 'Autenticación por selfie',
    },
    birthday: 'Fecha de nacimiento',
    birthdayInfo:
      'Con la fecha de nacimiento ingresada, el firmante no tendrá que ingresarla al firmar.',
    footerInfo:
      'El firmante recibirá la información anterior ya completada cuando firme y no podrá modificarla.',
    select: 'SELECCIONAR',
    yes: 'Sí',
    no: 'No',
    ok: 'Ok',
    optionalData: 'LLENAR POR EL FIRMANTE (OPCIONAL)',
    optionalDataInfo:
      'El firmante recibirá la información a continuación ya completada cuando firme y no podrá modificarla.',
    saveContact: 'Marcar para guardar como contacto en la agenda',
    fillToSave: 'LLENA PARA AGREGAR',
    optionalMessage: 'Mensaje (opcional)',
    signerData: 'DATOS DEL FIRMANTE',
    selectedSigner: 'Seleccionado',
    emptyList: 'No hay elementos para seleccionar',
    banner: {
      title: '¡Conoce la nueva autenticación vía Pix (Beta)!',
      subtitle: 'Una forma segura y fácil de firmar.',
      action: 'Quiero conocer',
    },
    authGroupOptions: {
      token: {
        label: 'Autenticación por Token',
        email: 'Enviar token por Correo Electrónico',
        sms: 'Enviar token por SMS',
      },
      pix: {
        label: 'Autenticación por Pix',
        text: 'Autenticación por Pix (Beta)',
      },
    },
  },
  signerContact: {
    title: 'Elegir de la agenda',
    badge1: '1. SELECCIONAR FIRMANTE',
    badge2: '2. CÓMO DEBE FIRMAR EL FIRMANTE',
    fillToAdd: 'COMPLETA PARA AGREGAR',
    loadingContacts: 'Cargando contactos de la agenda...',
    clickToConfirm: 'Haz click para confirmar',
    toSaveContact:
      'Para guardar un contacto, ve a Agregar en la lista de firmantes y marca para guardar como contacto en la agenda.',
    fullName: 'Nombre completo',
    email: 'Correo electrónico',
    cpf: 'CPF',
    cellphone: 'Número de Celular',
    authAs: 'Autenticar usando',
    signAs: 'Firmar como',
    birthday: 'Fecha de nacimiento',
    cpfPresent: '¿Tiene CPF?',
    optionalMessage: 'Mensaje (opcional)',
    info: '+ Info',
    signerData: 'DATOS DEL FIRMANTE',
  },
  signerForm: {
    title: 'Agregar firmante del formulario',
    description: {
      line1:
        'Selecciona esta opción cuando el Correo Electrónico y el WhatsApp del firmante provengan del formulario.',
      line2: 'provenientes del formulario.',
    },
    add: 'Agregar',
    cancel: 'Cancelar',
    plus: 'Saber más',
    emailField: {
      label: 'Correo electrónico del firmante',
      tooltip: 'Selecciona el título del firmante que deberá recibir el documento para firmar.',
      empty: 'No hay correos electrónicos para seleccionar',
    },
    signAsField: {
      label: 'Firmar como',
      tooltip: 'Indica cómo el firmante firmará este documento',
    },
    whatsappSection: 'Firma vía WhatsApp (Opcional)',
    whatsappField: {
      label: 'Celular del firmante',
      tooltip:
        'Selecciona el campo correspondiente al teléfono celular del firmante para que reciba los mensajes vía WhatsApp para firmar el documento.',
      noPhone: 'No hay teléfonos para seleccionar',
    },
  },
  signature_ordering: {
    title: 'Configurar orden de las firmas',
    active_ordenation: 'Activar orden de las firmas.',
    drag_drop_to_change: 'Arrastra y suelta para cambiar de grupo',
    add_to_ordering: 'Agregar firmantes para ordenar las firmas.',
    loading_groups: 'Cargando grupos...',
    drag_to_add: 'ARRASTRA AQUÍ PARA AGREGAR UN NUEVO GRUPO',
    active_ordenation_to_ordering:
      'Activa la orden de las firmas para definir quién deberá firmar primero, segundo, y así sucesivamente.',
    tooltip: {
      ordenation_info:
        'Una vez activada la orden de las firmas, no se podrá desactivar en el documento. Si deseas que todos los firmantes firmen simultáneamente, colócalos en el mismo grupo.',
    },
    notification: {
      save: 'Orden de firmas actualizada con éxito!',
    },
  },
  flow_ordering: {
    title: 'Configurar orden de las firmas',
    active_ordenation: 'Activar orden de las firmas.',
    drag_drop_to_change: 'Arrastra y suelta para cambiar de grupo',
    add_to_ordering: 'Agregar firmantes para ordenar las firmas.',
    loading_groups: 'Cargando grupos...',
    drag_to_add: 'ARRASTRA AQUÍ PARA AGREGAR UN NUEVO GRUPO',
    active_ordenation_to_ordering:
      'Activa la orden de las firmas para definir quién deberá firmar primero, segundo, y así sucesivamente.',
    tooltip: {
      ordenation_info:
        'Una vez activada la orden de las firmas, no se podrá desactivar en el documento. Si deseas que todos los firmantes firmen simultáneamente, colócalos en el mismo grupo.',
    },
  },
  move_nodes: {
    documents: {
      title: 'Mover elementos a ...',
      move: 'Mover',
    },
    trash_bin: {
      title: 'Restaurar elementos a ...',
      restore: 'Restaurar',
    },
    cancel: 'Cancelar',
    more: 'Ver más',
  },
  flow_privacy: {
    title: 'Definir permisos de acceso del flujo ',
    select_access_type: {
      label: 'Selecciona el tipo de acceso del flujo',
      public: 'Acceso para todos los usuarios de la cuenta',
      private: 'Acceso solo para usuarios seleccionados',
    },
    select_members: {
      label: 'Selecciona los usuarios que tendrán acceso al flujo',
      admin: 'Administrador',
      admin_info:
        'Los administradores de la cuenta siempre tendrán acceso a las carpetas por defecto',
      operator: 'Operador del flujo',
      operator_info:
        'Este usuario no puede ser eliminado por estar vinculado como operador del flujo',
    },
    cancel: 'Cancelar',
    save: 'Guardar',
  },
  signerFormUnutilizedAlert: {
    title: 'Atención: Hay al menos un firmante del formulario que no está en la lista de firmas.',
    description:
      'Si deseas que los documentos generados por este flujo se envíen a estos firmantes, añádelos a la lista de firmas.',
    availableSigners: {
      title: 'Firmantes disponibles:',
      formName: 'Formulario {number}: {name}',
    },
    actions: {
      add: 'Agregar firmante',
      skip: 'Avanzar sin agregar',
    },
  },
};

export default modal;
