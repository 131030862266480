import { CHAT } from '@ai/constants';

import { useChatMessage } from './useChatMessage';

export const useChatMessageAction = (message, options) => {
  const type = CHAT.TYPES.ACTION;
  const actions = options?.actions ?? [];

  return {
    ...useChatMessage(message, { ...options, type }),
    actions,
  };
};
