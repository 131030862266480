import moment from 'moment';
import locales from '../locales/widget-locales';

const ACCEPTED_DATE_FORMAT = 'DD/MM/YYYY';

export const parseBatchResponse = (documentBatch) => {
  if (!documentBatch || !Object.keys(documentBatch).length) {
    return documentBatch;
  }

  const documentBatchToParse = { ...documentBatch };

  _.set(documentBatchToParse, 'documents', documentBatchToParse.documents);
  return documentBatchToParse;
};

export const buildBatchSignRequest = (payload, locale) => {
  const localeDateFormat = _.get(locales, `messages.${locale}.validation.dateFormat`);
  const isAcceptedDateFormat = localeDateFormat === ACCEPTED_DATE_FORMAT;
  const isPresentialSignMethod = payload.auths.includes('presential');
  const shouldChangeBirthdayDateFormat = payload.birthday && !isAcceptedDateFormat;
  const defaultParameters = [
    'name',
    'birthday',
    'documentation',
    'latitude',
    'longitude',
    'locationEnabled',
  ];
  const signatureParameters = _.pick(payload, defaultParameters);
  Object.assign(
    signatureParameters,
    shouldChangeBirthdayDateFormat && {
      birthday: moment(payload.birthday, localeDateFormat).format(ACCEPTED_DATE_FORMAT),
    }
  );
  Object.assign(signatureParameters, {
    selfie_base64: payload.base64Image,
    handwritten_base64: payload.handwrittenBase64,
    // TODO: REMOVE TOKEN REDUNDANCE WHEN THE API IT'S READY TO RECEIVE ONLY A TOKEN PARAM
    token_email: payload.token,
    token_sms: payload.token,
    token_whatsapp: payload.token,
    token: payload.token,
    official_document_front_base64: payload.officialDocumentFrontBase64,
    official_document_back_base64: payload.officialDocumentBackBase64,
    icp_signatures: payload.icpSignatures,
    massive_signature: payload.massiveSignature,
    liveness_key: payload.livenessKey,
  });
  if (payload.selfieKey) {
    Object.assign(signatureParameters, {
      photo_reviews: { selfie: payload.selfieKey },
    });
  }
  const validParams = _.pickBy(signatureParameters, (notEmptyValue) => notEmptyValue);
  const signatureData = { signature: validParams };
  if (isPresentialSignMethod) Object.assign(signatureData, { signer: { email: payload.email } });
  return signatureData;
};

export const buildCreateBatchRequest = (documentsKeys, signer) => {
  const data = {
    batch: {
      document_keys: documentsKeys,
      signer_key: signer.key,
    },
  };

  return data;
};
