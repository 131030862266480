const signatures = {
  refusal: {
    seeReason: 'Ver motivo da recusa',
    removeFromEnvelope: 'Excluir do envelope',
    removeFromDocument: 'Excluir do documento',
    canRemoveSignerFromEnvelope:
      'Você pode excluir o signatário deste envelope e adicioná-lo novamente para que ele receba uma nova solicitação de assinatura.',
    canRemoveSignerFromDocument:
      'Você pode excluir o signatário deste documento e adicioná-lo novamente para que ele receba uma nova solicitação de assinatura.',
  },
  remind: {
    signerWillReceiveLinkAgain:
      'O signatário receberá novamente o link para assinatura dos documentos pendentes.',
  },
  empty: {
    title: 'Nenhum signatário adicionado',
    description: 'Edite o envelope para adicionar quem irá assinar',
    action: 'Editar envelope',
  },
  actions: {
    remindAll: 'Enviar lembretes',
    editSigners: 'Editar signatários',
  },
};

export default signatures;
