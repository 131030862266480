const consumption = {
  financialProfile: {
    title: 'Você sabia que um usuário pode ter acesso às cobranças sem ser Administrador?',
    description: {
      text: 'Vá em {0} e configure agora mesmo!',
      link: 'Configurações dos Usuários',
    },
  },
  plan: {
    periodConsumption: 'Dados do período de {0} à {1}. {2}',
    moreInfo: 'Entenda o consumo.',
    currentConsumption: 'Consumo Atual',
    yourPlan: 'Seu plano',
    plansInfo: 'Veja cobertura e valores dos planos',
    noConsumption: 'Sem consumo registrado',
    infoConsumption: 'O consumo é contabilizado até 3 dias antes do fim do período.',
    infoConsumptionHelp: 'Saiba mais.',
  },
  historic: {
    noHistoric: 'Sem histórico de faturas',
    billHistoric: 'Histórico de faturas',
    tooltipReport: 'Ver Relatório',
    tooltipInvoice: 'Ver Fatura',
    tooltipNFE: 'Ver Nota Fiscal',
    emission: 'Emissão',
    period: 'Período',
    monthReference: 'Mês de referência',
    monthReferenceMobile: 'Mês',
    due: 'Vencimento',
    amount: 'Valor',
    status: 'Status',
    links: 'Links',
    actions: 'Ações',
    visualize: 'Visualizar',
    report: 'Ver consumo',
    nfe: 'Ver nota fiscal',
    payInvoice: 'Pagar fatura',
    payInvoiceMobile: 'Pagar',
    statusNames: {
      review: 'Revisar',
      pending: 'Pendente',
      paid: 'Paga',
      cancelled: 'Cancelada',
      canceled: 'Cancelada',
      scheduled: 'Agendada',
      overdue: 'Vencida',
      refunded: 'Estornada',
    },
  },
  request: {
    changePlan: 'Alterar plano',
    requestChangePlan: 'Solicitar alteração de plano',
    cancelAccount: 'Cancelar conta',
    adminFunctionality: 'Apenas administradores da conta podem solicitar o cancelamento.',
    requestCancelAccount: 'Solicitar cancelamento de conta',
    editBillingData: 'Atualizar dados de cobrança',
    billingData: 'Dados de cobrança',
  },
  trial: {
    account: 'Conta em período de teste',
    plan: 'Contrate um plano',
    documents: '{numberDocuments} envio de documento | {numberDocuments} envios de documento',
    acceptances: '{numberAcceptances} aceite | {numberAcceptances} aceites',
    description: 'Você possui {documents} e {acceptances} para aproveitar até {endDate}.',
    blockingNotice:
      'Ao usar todo seu saldo de envios, as funcionalidades serão bloqueadas. Não se preocupe, {0}',
    paymentWarn: 'nenhuma cobrança automática será gerada.',
    cancelAccount: 'Cancelar conta',
    button: 'Solicitar cancelamento da conta',
  },
  accountCancellation: {
    pending: {
      title: 'Cancelamento da conta',
      find: 'Encontramos {0}. {1} Regularize as cobranças em aberto para que possamos prosseguir com o cancelamento da conta.',
      findText: '{number} fatura com pagamento pendente | {number} faturas com pagamento pendente',
      suport:
        'Em caso de dúvida, selecione a opção de falar com o suporte no canto superior direito da tela ou entre em contato através do {0}',
      button: 'Voltar',
    },
    steps: {
      pendingBills: {
        title: 'Solicitação não concluída. Verifique as pendências abaixo.',
        subtitle:
          'Encontramos {pendingBills} fatura com pagamento pendente | Encontramos {pendingBills} faturas com pagamento pendente',
        description:
          'Para seguir com o cancelamento, regularize as cobranças da conta. Dúvidas? {0}',
        supportHelp: 'Fale com o nosso suporte.',
        action: 'Visualizar fatura pendente',
      },
      pendingDocuments: {
        title: 'Deseja cancelar? Você perderá documentos em processo',
        subtitle:
          'Identificamos {pendingDocuments} documento em processo na sua conta | Identificamos {pendingDocuments} documentos em processo na sua conta',
        invalidSignaturesWarning: 'Todas as assinaturas já feitas serão invalidadas',
        noNewSignaturesWarning: 'Nenhuma assinatura nova poderá ser feita',
        seeDocuments: 'Ver documentos',
      },
      reason: {
        title: 'Por que decidiu cancelar a conta, {userName}?',
        selectPlaceholder: 'Selecione o motivo...',
        choice: {
          title: 'Compartilhe o motivo da sua decisão',
          text: 'Indicando o motivo do cancelamento da conta, você ajuda a Clicksign a melhorar os serviços.',
        },
        keepPlan: {
          now: 'atual',
          nowTrial: 'de testes',
          renew: 'Seu plano será renovado em {0}',
          trialEnd: 'Seu teste acaba em {0}',
          cancelText:
            'Para que você aproveite todo o período {0}, sugerimos que o cancelamento seja feito em {1}.',
          button: 'Manter a conta',
        },
        options: {
          avoidBilling: 'Quero evitar cobrança no final do teste',
          closeEnterprise: 'Encerrei as atividades da minha empresa',
          badCustomerService: 'Não gostei do atendimento',
          difficultToUse: 'Tive dificuldade para utilizar',
          noLike: 'Não encontrei as funcionalidades que preciso',
          findOther: 'Encontrei oferta melhor de outra plataforma',
          eventual: 'Tive uma demanda eventual e não preciso mais usar',
          pricing: 'Os planos são caros para o meu orçamento',
          other: 'Outro',
        },
        placeholder: {
          avoidBilling: 'Conte-nos mais sobre o motivo do cancelamento',
          closeEnterprise: 'Conte-nos mais sobre o motivo do cancelamento.',
          noLike: 'Quais funcionalidades você não encontrou na Clicksign?',
          findOther: 'Qual plataforma oferece uma oferta melhor pra você?',
          eventual: 'Conte-nos mais sobre o motivo do cancelamento.',
          pricing: 'Conte-nos mais sobre o motivo do cancelamento.',
          badCustomerService: 'Conte-nos mais sobre o motivo do cancelamento',
          difficultToUse: 'Conte-nos mais sobre o motivo do cancelamento',
          other: 'Conte-nos: em que podemos melhorar?',
        },
      },
      help: {
        titleFeature: 'Não encontrou a funcionalidade que precisava?',
        titlePlan: 'Não encontrou o plano ideal para você?',
        advance: 'Continuar com cancelamento',
        keepPlan: 'Manter a conta',
        support: {
          title: 'Contato com o suporte',
          text: 'Nosso time está aqui para te ajudar a encontrar o que precisa',
        },
        faq: {
          title: 'Perguntas frequentes',
          text: 'Tire suas dúvidas sobre as funcionalidades',
        },
        sales: {
          title: 'Contato com o comercial',
          text: 'Nosso time pode te ajudar a encontrar o plano ideal',
        },
      },
      finish: {
        title: 'Antes de ir, considere o plano Eventual',
        keepPlanTitle: 'Antes de ir, considere manter o plano Eventual',
        error: 'Ocorreu um erro ao cancelar sua conta! Tente novamente',
        eventual: {
          title: 'No plano Eventual, você só paga quando usa',
          recurrence: 'Sem cobrança recorrente',
          documentPrice: 'R$ 8,50 por documento finalizado',
          users: '1 usuário na conta',
          access: 'Você não perde acesso aos arquivos desta conta',
          button: 'Mudar para o plano Eventual',
          buttonKeepPlan: 'Manter o plano Eventual',
          buttonSubscribeEventual: 'Contratar plano Eventual',
        },
        cancel: {
          title: 'Ao cancelar, você perde seu acesso e todos os benefícios',
          backup: 'Fazer Backup',
          clear: 'Os documentos guardados na plataforma serão excluídos ({0})',
          docsCancel: 'Todos os documentos em processo serão cancelados',
          block: 'Seu acesso à plataforma será bloqueado imediatamente',
          check: 'Selecione para confirmar o cancelamento',
          button: 'Cancelar a conta agora',
        },
      },
    },
    loading: 'Iniciando o processo de cancelamento...',
    actions: {
      keepPlan: 'Manter a conta',
      continue: 'Continuar com cancelamento',
    },
  },
  changePlan: {
    pending: {
      title: 'Alterar plano',
      find: 'Encontramos {0}. {1} Regularize as cobranças em aberto para que possamos prosseguir com a alteração de plano.',
      disclaimer:
        'Após o reconhecimento do pagamento será possível realizar a alteração de plano e, em caso de saldo referente aos dias não utilizados, o desconto proporcional será aplicado.',
    },
  },
};

export default consumption;
