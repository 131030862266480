import { useI18n } from '@/locales';

const { t } = useI18n();

const HOVER_INFO = {
  token: {
    whatsapp: {
      text: t('signer.infoStep.communicateBy.warning'),
      link: t('links.help.billing.whatsAppSignature'),
    },
    sms: {
      text: t('signer.infoStep.envelope.authsModal.smsWarning'),
      link: t('links.help.billing.tokenSms'),
    },
    whatsappBlocked: {
      text: t('signer.infoStep.envelope.authsModal.whatsappBlocked'),
    },
  },
  selfie: {
    text: t('signer.infoStep.envelope.authsModal.selfieBlocked'),
  },
  facialBiometrics: {
    text: t('signer.infoStep.envelope.authsModal.facialBiometricsBlocked'),
  },
  liveness: {
    text: t('signer.infoStep.envelope.authsModal.livenessBlocked'),
  },
};

export default { HOVER_INFO };
