const consumption = {
  financialProfile: {
    title: '¿Sabías que un usuario puede acceder a los cobros sin ser Administrador?',
    description: {
      text: 'Ve a {0} y configúralo ahora mismo.',
      link: 'Configuración de Usuarios',
    },
  },
  plan: {
    periodConsumption: 'Datos del período de {0} a {1}. {2}',
    moreInfo: 'Entender el consumo.',
    currentConsumption: 'Consumo Actual',
    yourPlan: 'Tu plan',
    plansInfo: 'Ver cobertura y valores de los planes',
    noConsumption: 'Sin consumo registrado',
    infoConsumption: 'El consumo se contabiliza hasta 3 días antes del fin del período.',
    infoConsumptionHelp: 'Conoce más.',
  },
  historic: {
    noHistoric: 'Sin historial de facturas',
    billHistoric: 'Historial de facturas',
    tooltipReport: 'Ver Informe',
    tooltipInvoice: 'Ver Factura',
    tooltipNFE: 'Ver Nota Fiscal',
    emission: 'Emisión',
    period: 'Período',
    monthReference: 'Mes de referencia',
    monthReferenceMobile: 'Mes',
    due: 'Vencimiento',
    amount: 'Valor',
    status: 'Estatus',
    links: 'Enlaces',
    actions: 'Acciones',
    visualize: 'Visualizar',
    report: 'Ver consumo',
    nfe: 'Ver nota fiscal',
    payInvoice: 'Pagar factura',
    payInvoiceMobile: 'Pagar',
    statusNames: {
      review: 'Revisar',
      pending: 'Pendiente',
      paid: 'Pagada',
      cancelled: 'Cancelada',
      canceled: 'Cancelada',
      scheduled: 'Programada',
      overdue: 'Vencida',
      refunded: 'Reembolsada',
    },
  },
  request: {
    changePlan: 'Cambiar el plan',
    requestChangePlan: 'Solicitar cambio de plan',
    cancelAccount: 'Cancelar cuenta',
    adminFunctionality: 'Solo los administradores de la cuenta pueden solicitar la cancelación.',
    requestCancelAccount: 'Solicitar cancelación de cuenta',
    editBillingData: 'Actualizar datos de facturación',
    billingData: 'Datos de facturación',
  },
  trial: {
    account: 'Cuenta en período de prueba',
    plan: 'Contrata un plan',
    documents: '{numberDocuments} envío de documento | {numberDocuments} envíos de documento',
    acceptances: '{numberAcceptances} aceptación | {numberAcceptances} aceptaciones',
    description: 'Tienes {documents} y {acceptances} para disfrutar hasta {endDate}.',
    blockingNotice:
      'Al usar todo tu saldo de envíos, las funcionalidades serán bloqueadas. No te preocupes, {0}',
    paymentWarn: 'no se generará ningún cobro automático.',
    cancelAccount: 'Cancelar cuenta',
    button: 'Solicitar cancelación de la cuenta',
  },
  accountCancellation: {
    pending: {
      title: 'Cancelación de la cuenta',
      find: 'Encontramos {0}. {1} Regulariza los cobros pendientes para que podamos proceder con la cancelación de la cuenta.',
      findText: '{number} factura pendiente de pago | {number} facturas pendientes de pago',
      suport:
        'En caso de duda, selecciona la opción de hablar con soporte en la esquina superior derecha de la pantalla o contacta a través de {0}',
      button: 'Regresar',
    },
    steps: {
      pendingBills: {
        title: 'La solicitud no fue completada. Revisa los pendientes a continuación.',
        subtitle:
          'Encontramos {pendingBills} factura pendiente de pago | Encontramos {pendingBills} facturas pendientes de pago',
        description:
          'Para proceder con la cancelación, regulariza los cobros de la cuenta. ¿Dudas? {0}',
        supportHelp: 'Habla con nuestro soporte.',
        action: 'Visualizar factura pendiente',
      },
      pendingDocuments: {
        title: '¿Deseas cancelar? Perderás documentos en proceso',
        subtitle:
          'Identificamos {pendingDocuments} documento en proceso en tu cuenta | Identificamos {pendingDocuments} documentos en proceso en tu cuenta',
        invalidSignaturesWarning: 'Todas las firmas ya hechas serán invalidadas',
        noNewSignaturesWarning: 'No se podrán realizar nuevas firmas',
        seeDocuments: 'Ver documentos',
      },
      reason: {
        title: '¿Por qué decidiste cancelar la cuenta, {userName}?',
        selectPlaceholder: 'Selecciona el motivo...',
        choice: {
          title: 'Comparte el motivo de tu decisión',
          text: 'Indicando el motivo de la cancelación de la cuenta, ayudas a Clicksign a mejorar sus servicios.',
        },
        keepPlan: {
          now: 'actual',
          nowTrial: 'de prueba',
          renew: 'Tu plan se renovará el {0}',
          trialEnd: 'Tu prueba termina el {0}',
          cancelText:
            'Para que aproveches todo el período {0}, te sugerimos que la cancelación se haga el {1}.',
          button: 'Mantener la cuenta',
        },
        options: {
          avoidBilling: 'Quiero evitar el cobro al final de la prueba',
          closeEnterprise: 'Cerré las actividades de mi empresa',
          badCustomerService: 'No me gustó la atención',
          difficultToUse: 'Tuve dificultades para usarlo',
          noLike: 'No encontré las funcionalidades que necesito',
          findOther: 'Encontré una mejor oferta en otra plataforma',
          eventual: 'Tuve una demanda eventual y ya no necesito usarla',
          pricing: 'Los planes son caros para mi presupuesto',
          other: 'Otro',
        },
        placeholder: {
          avoidBilling: 'Cuéntanos más sobre el motivo de la cancelación',
          closeEnterprise: 'Cuéntanos más sobre el motivo de la cancelación.',
          noLike: '¿Qué funcionalidades no encontraste en Clicksign?',
          findOther: '¿Qué plataforma te ofrece una mejor oferta?',
          eventual: 'Cuéntanos más sobre el motivo de la cancelación.',
          pricing: 'Cuéntanos más sobre el motivo de la cancelación.',
          badCustomerService: 'Cuéntanos más sobre el motivo de la cancelación',
          difficultToUse: 'Cuéntanos más sobre el motivo de la cancelación',
          other: 'Cuéntanos: ¿en qué podemos mejorar?',
        },
      },
      help: {
        titleFeature: '¿No encontraste la funcionalidad que necesitabas?',
        titlePlan: '¿No encontraste el plan ideal para ti?',
        advance: 'Continuar con la cancelación',
        keepPlan: 'Mantener la cuenta',
        support: {
          title: 'Contacto con soporte',
          text: 'Nuestro equipo está aquí para ayudarte a encontrar lo que necesitas',
        },
        faq: {
          title: 'Preguntas frecuentes',
          text: 'Resuelve tus dudas sobre las funcionalidades',
        },
        sales: {
          title: 'Contacto con el área comercial',
          text: 'Nuestro equipo puede ayudarte a encontrar el plan ideal',
        },
      },
      finish: {
        title: 'Antes de irte, te sugerimos el plan Eventual',
        keepPlanTitle: 'Antes de irte, te sugerimos mantener el plan Eventual',
        error: '¡Ocurrió un error al cancelar tu cuenta! Inténtalo nuevamente',
        eventual: {
          title: 'En el plan Eventual, solo pagas cuando lo uses',
          recurrence: 'Sin cobro recurrente',
          documentPrice: 'R$ 8,50 por documento finalizado',
          users: '1 usuario en la cuenta',
          access: 'No pierdes acceso a los archivos de esta cuenta',
          button: 'Cambiar al plan Eventual',
          buttonKeepPlan: 'Mantener el plan Eventual',
          buttonSubscribeEventual: 'Contratar plan Eventual',
        },
        cancel: {
          title: 'Al cancelar, perderás tu acceso y todos los beneficios',
          backup: 'Hacer copia de seguridad',
          clear: 'Los documentos guardados en la plataforma serán eliminados ({0})',
          docsCancel: 'Todos los documentos en proceso serán cancelados',
          block: 'Tu acceso a la plataforma será bloqueado inmediatamente',
          check: 'Selecciona para confirmar la cancelación',
          button: 'Cancelar la cuenta ahora',
        },
      },
    },
    loading: 'Iniciando el proceso de cancelación...',
    actions: {
      keepPlan: 'Mantener la cuenta',
      continue: 'Continuar con la cancelación',
    },
  },
  changePlan: {
    pending: {
      title: 'Cambiar el plan',
      find: 'Encontramos {0}. {1} Regulariza los cobros pendientes para que podamos proceder con el cambio de plan.',
      disclaimer:
        'Tras la confirmación del pago será posible realizar el cambio de plan y, en caso de saldo por los días no utilizados, se aplicará el descuento proporcional.',
    },
  },
};

export default consumption;
