import { axios } from '@/plugins';

const verifySelfieIsValidy = async ({ commit, getters }, payload) => {
  try {
    const {
      data: { photoReview },
    } = await axios.post(getters.getLinks.reviewSelfies, payload);
    commit('SET_SELFIE_APPROVED', photoReview.approved);
    commit('SET_SELFIE_KEY', photoReview.key);
    return photoReview.approved;
  } catch (err) {
    commit('SET_SELFIE_APPROVED', false);
    return false;
  }
};

export default {
  verifySelfieIsValidy,
};
