const liveness = {
  title: 'Selfie dinámica',
  carousel: {
    step1: {
      title: 'Selfie dinámica',
      text: 'Necesitamos tomar una foto tuya para probar que eres una persona real',
    },
    step2: {
      title: 'Tu foto se adjuntará al documento',
      text: {
        first: 'Estar vestido',
        second: 'No uses lentes ni accesorios',
        third: 'Saca tu documento del plástico',
        fourth: 'Busca un ambiente iluminado y adecuado',
      },
    },
    step3: {
      title: 'Activa tu cámara',
      text: 'Cuando se te solicite, toca en permitir para iniciar la captura de la imagen',
    },
  },
  cafModal: {
    title: '¡Casi listo! Toma una foto de tu rostro para finalizar la validación.',
    error: 'Hemos encontrado un problema',
    content: {
      loading: 'Enviamos tu foto para ser analizada {0} Espera un momento...',
      keepPosition: 'Mantén esta posición',
      success: '¡Foto tomada con éxito!',
      error: 'Aún no hemos identificado un rostro',
      sdkError: 'Ocurrió un problema durante la captura de la imagen, inténtalo de nuevo.',
    },
    button: {
      success: 'Avanzar',
      error: 'Inténtalo nuevamente',
      sendAndNext: 'Enviar y avanzar',
    },
    flipCamera: 'Voltear la cámara',
  },
  attemptsError: {
    title: 'Límite de intentos alcanzado',
    subtitle: 'Informa al remitente del documento',
  },
  result: {
    remaining: 'Informa al remitente del documento | Intentos restantes: {count}',
    takeAnother: 'Tomar otra',
    isOkTitle: '¿Todo bien con la foto?',
    isOkSub:
      'Tu foto será protegida por una marca de agua. Al enviarla, autorizas el registro de la imagen en el documento final',
  },
  cameraError: 'Cámara no encontrada.',
  actions: {
    next: 'Avanzar',
    tryAgain: 'Inténtalo nuevamente',
    goBack: 'Regresar',
    allowCamera: 'Activar cámara',
    takePicture: 'Tomar foto',
  },
  knowMore: 'Conoce más',
  consentLabel:
    'Al hacer click en "Enviar", autorizas el registro de tu imagen en el registro del documento.',
  helpModal: {
    whatIsLivenessTitle: '¿Qué es Selfie dinámica?',
    whatIsLivenessDescription:
      'EEs un método de autenticación que permite validar que la persona que firma es real, mediante la captura de su rostro.',
    whyLivenessTitle: '¿Por qué firmar con Selfie dinámica?',
    whyLivenessDescription:
      'Esta autenticación reduce el riesgo de fraudes, haciendo que la firma electrónica sea aún más segura.',
    authMethod: '*Este método de autenticación fue elegido por el remitente de este documento.',
    title: 'Selfie dinámica',
    knowMore: {
      text1: '¿Quieres saber más? Consulta nuestra',
      text2: 'y',
      link1: 'Política de Privacidad',
      link2: 'Centro de Ayuda.',
    },
  },
  analyzingModal: 'Analizando la foto de tu rostro',
  verifyError: {
    reproved: {
      title: 'Foto no reconocida',
      description:
        'El documento no será firmado. {0} Ponte en contacto con el remitente del documento',
    },
    refused: {
      title: '@:steps.liveness.verifyError.reproved.title',
      description: '@:steps.liveness.verifyError.reproved.description',
    },
  },
};

export default liveness;
