export default {
  links: {},
  templates: [],
  spreadsheetFlow: {
    data: [],
  },
  signers: [],
  spreadsheetDataAnalysis: {},
  spreadsheetFlows: [],
  spreadsheetData: [],
  pagination: {},
};
