export default {
  dateFormat: 'DD/MM/YYYY',
  errors: {
    requiredIf: 'El campo es obligatorio',
    required: 'El campo es obligatorio',
    phone: 'Ingresa un número de teléfono válido',
    email: 'Ingresa un correo electrónico válido',
    cpf: 'Ingresa un CPF válido',
    date: 'Ingresa una fecha válida',
    fullName: 'Ingresa nombre y apellido',
    dateInThePast: 'Debe ser anterior a la fecha actual',
    minLength: 'Por favor, ingresa al menos {min} caracteres.',
  },
};
