const setupPreference = {
  changeError: 'Error al cambiar el tipo de envío. Verifica la conexión e inténtalo de nuevo.',
  enabled: {
    switch: 'Sobre habilitado',
    text: 'Estás usando el sobre, una nueva versión de envío.',
    link: '¿Qué cambia?',
  },
  disabled: {
    switch: 'Sobre deshabilitado',
    text: 'Estás en el modo estándar de envío.',
    link: 'Más información sobre el sobre',
  },
};

export default setupPreference;
