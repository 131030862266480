const routes = [
  {
    view: 'SignerAreaDashboardView',
    component: () => import('@signer-area/views/SignerAreaDashboardView.vue'),
  },
  {
    view: 'SignerAreaNotificationsView',
    component: () => import('@signer-area/views/SignerAreaNotificationsView.vue'),
  },
];

export default routes;
