import documentsBase from '@document/locales/es_MX/base';
import documentSigner from '@document/locales/es_MX/documentShow/signer';

const document = {
  base: documentsBase,
  signer: documentSigner,
  signatures: {
    signatures: 'Firmas',
    signAs: 'Firmar como',
    auth_as: 'Autenticar usando',
    runningAt: '%{days_left} para firmar. En proceso hasta %{deadline_at} %{time_limit}',
    closedAt: 'Finalizado el %{finished_at}',
    canceledAt: 'Cancelado el %{canceled_at}',
    addedAt: 'Agregado el %{added_at}',
    document_status: 'Documento %{status}',
    duplicate: 'Duplicar documento',
    finish: 'Finalizar',
    cancel: 'Cancelar',
    download: 'Descargar',
    downloadOriginal: 'Descargar documento original',
    downloadOriginalDocx: 'Descargar  el documento original en docx',
    downloadSigned: 'Descargar documento firmado',
    downloadOriginalSigned: 'Descargar documento original y firmado',
    signers: 'Firmantes',
    noSignersAdded: 'Ningún firmante agregado.',
    signStatus: 'Estado de las firmas en el documento',
    addSigners: 'Agregar nuevos firmantes',
    signerEmail: 'Correo del firmante',
    email: 'Correo electrónico',
    sendToEnvelope: 'Para cambiar firmantes, documentos, configuraciones o enviar recordatorios,',
    accessTheEnvelope: 'ingresa al sobre.',
    sms: 'SMS',
    cellphone: 'Celular',
    selectLanguage: 'Selecciona el idioma del documento',
    signerOptions: 'Configuraciones del firmante',
    informedCpf: 'Número de identificación fiscal registrado',
    waitingSignature: 'Esperando firma',
    didNotSign: 'No firmó',
    sendReminder: 'Enviar recordatorio',
    save: 'Guardar',
    fileName: 'Nombre del archivo:',
    showTimestamps: 'Ver sello de tiempo',
    list: {
      title: 'Lista de firmantes',
      signaturesDone: '{totalSignatures}/{totalSigners} Firmas completadas',
    },
    selfie: {
      title: 'Selfie con documento',
    },
    addressProof: {
      title: 'Comprobante de residencia',
    },
    handwritten: {
      title: 'Firma manuscrita',
    },
    officialDocument: {
      title: 'Documento oficial',
      titleFront: 'Frente del documento oficial',
      titleBack: 'Reverso del documento oficial',
    },
    liveness: {
      title: 'Selfie dinámica',
    },
    facialBiometrics: {
      title: 'Biometría facial',
    },
    cancelModal: {
      title: '¿Cancelar el documento?',
      text: 'Al cancelar, todas las firmas ya realizadas serán invalidadas.',
      checkbox: 'Selecciona para confirmar la cancelación',
      confirmButton: 'Confirmar cancelación',
    },
    finishModal: {
      title: '¿Finalizar el documento?',
      text: 'El documento finalizado no estará disponible para nuevas firmas.',
      view: 'Solo podrás visualizarlo.',
      confirmButton: 'Finalizar',
    },
    timestampModal: {
      title: 'Información del sello de tiempo',
      description:
        'El sello de tiempo certifica la fecha y hora en que cada firma con certificado digital ocurrió. Mira los detalles de las firmas:',
      certifierName: 'Emitido por:',
      localDate: 'Fecha Local:',
      utcDate: 'Fecha UTC:',
    },
    blockOrderingModal: {
      title: 'Clasificación de firmas no disponible',
      description: {
        reason:
          'No es posible cambiar la clasificación de las firmas en un documento que ya fue enviado.',
        solution: 'Para usar la clasificación, actívala al agregar firmantes en la etapa de envío.',
        link: 'Aprende más sobre la clasificación de firmas.',
      },
    },
    signersGroups: {
      name: 'Grupo {id}',
    },
  },
  historic: {
    events: {
      title: 'EVENTOS',
      info: 'Historial de los eventos ocurridos en el documento.',
    },
    reminders: {
      title: 'RECORDATORIOS ENVIADOS',
      info: 'Recordatorios enviados automáticamente o por un operador.',
      noReminders: 'Ningún recordatorio enviado',
      sent: 'Enviado a:',
    },
  },
  edit: {
    signatures: 'Firmas',
    historic: 'Historial',
    document: 'Documento',
    details: 'Detalles',
    flow: 'Flujo de Automatización',
    settings: 'Configuraciones',
    running: 'En proceso',
    closed: 'Completados',
    canceled: 'Cancelados',
    notification: {
      addSigner: 'Firmante agregado con éxito.',
      removeSigner: 'Firmante eliminado con éxito.',
    },
  },
  cancel: {
    success: 'Documento {0} cancelado con éxito',
  },
  finish: {
    success: 'Documento {0} finalizado con éxito',
  },
  settings: {
    deadlineDate: 'FECHA LÍMITE PARA FIRMAS',
    deadlineDateInfo: 'No se podrán realizar firmas después de alcanzar la fecha límite.',
    autoReminders: 'RECORDATORIOS AUTOMÁTICOS',
    autoRemindersInfo:
      'Envío de hasta 3 recordatorios por correo para los firmantes que aún no han firmado el documento.',
    sendReminders: 'Enviar recordatorios automáticamente cada',
    documentLanguage: 'IDIOMA DEL DOCUMENTO',
    documentLanguageInfo:
      'La página de firma y los correos se mostrarán en el idioma seleccionado.',
    closeDocument: 'FINALIZACIÓN DEL DOCUMENTO',
    closeDocumentInfo: 'Elige de qué forma se finalizará el documento',
    autoClose: 'Después de la última firma, el documento se finalizará automáticamente.',
    manualClose:
      'Manualmente. Tendrás que finalizar el documento manualmente, de lo contrario, se finalizará en la fecha límite de firma.',
    english: 'Inglés (EE.UU.)',
    portuguese: 'Portugués',
    spanish: 'Español',
    notifications: {
      save: '¡Configuraciones guardadas con éxito!',
    },
  },
  message: {
    title: 'Mensaje',
    info: 'Este mensaje se enviará a todos los firmantes de este documento. Si responden al mensaje, será enviado directamente a tu correo.',
    message_placeholder: 'Escribe tu mensaje aquí.',
    alert: {
      whatsappDisable: 'Mensaje deshabilitado para firmas vía WhatsApp',
      whatsappWarning: 'El mensaje a continuación solo será enviado a quienes firmen por correo',
    },
    labels: {
      message: 'Mensaje personalizado (opcional)',
      subject: 'Asunto del correo electrónico (opcional)',
    },
    actions: {
      ia: 'Generar textos con IA | Generar texto con IA',
    },
  },
  signaturesKind: {
    sign: 'Firmar',
    witness: 'Firmar como testigo',
    party: 'Firmar como parte',
    intervening: 'Firmar como interviniente',
    receipt: 'Firmar para acusar recibo',
    approve: 'Firmar para aprobar',
    endorser: 'Firmar como endosante',
    endorsee: 'Firmar como endosatario',
    administrator: 'Firmar como administrador',
    guarantor: 'Firmar como avalista',
    transferor: 'Firmar como cedente',
    transferee: 'Firmar como cesionario',
    contractee: 'Firmar como contratada',
    contractor: 'Firmar como contratante',
    jointDebtor: 'Firmar como deudor solidario',
    issuer: 'Firmar como emisor',
    manager: 'Firmar como administrador',
    buyer: 'Firmar como comprador',
    seller: 'Firmar como vendedor',
    attorney: 'Firmar como apoderado',
    legalRepresentative: 'Firmar como representante legal',
    coResponsible: 'Firmar como co-responsable',
    validator: 'Firmar como validador',
    surety: 'Firmar como fiador',
    lessor: 'Firmar como arrendador',
    lessee: 'Firmar como arrendatario',
    ratify: 'Firmar para homologar',
    employer: 'Firmar como empleador',
    employee: 'Firmar como empleado',
    consenting: 'Firmar como consentidor',
    accountant: 'Firmar como contador',
    debtor: 'Firmar como deudor',
    franchisee: 'Firmar como franquiciado',
    creditor: 'Firmar como acreedor',
    franchisor: 'Firmar como franquiciador',
    insured: 'Firmar como asegurado',
    grantor: 'Firmar como otorgante',
    president: 'Firmar como presidente',
    partner: 'Firmar como socio',
    comforter: 'Firmar como comodante',
    accountHolder: 'Firmar como titular de cuenta',
    distracted: 'Firmar como distraído',
    distracting: 'Firmar como distratante',
    lender: 'Firmar como prestamista',
    associate: 'Firmar como asociado',
    broker: 'Firmar como corredor',
    bailee: 'Firmar como depositario fiel',
    collateralProvider: 'Firmar como garante',
    pledged: 'Firmar como consignado',
    consignee: 'Firmar como consignatario',
    lawyer: 'Firmar como abogado',
    grantee: 'Firmar como beneficiario',
    borrower: 'Firmar como prestatario',
    guarantorSpouse: 'Firmar como cónyuge del fiador',
    legalGuardian: 'Firmar como responsable legal',
    director: 'Firmar como director(a)',
    realEstateBroker: 'Firmar como corredor de bienes raíces',
    insuranceBroker: 'Firmar como corredor de seguros',
    licensor: 'Firmar como licenciante',
    licensed: 'Firmar como licenciatario',
    serviceProvider: 'Firmar como proveedor de servicios',
    secured: 'Firmar como garantizado',
    consented: 'Firmar como consentido',
    interveningGuarantor: 'Firmar como garante interviniente',
    consentingIntervenor: 'Firmar como interviniente anuente',
    surveyor: 'Firmar como inspector',
    buildingManager: 'Firmar como síndico',
    intermediary: 'Firmar como intermediario',
    condominiumMember: 'Firmar como condómino',
    owner: 'Firmar como propietario',
    resident: 'Firmar como residente',
    treasurer: 'Firmar como tesorero',
    secretary: 'Firmar como secretario',
    signedAsSign: 'Firmado',
    signedAsApprove: 'Firmado para aprobar',
    signedAsParty: 'Firmado como parte',
    signedAsWitness: 'Firmado como testigo',
    signedAsIntervening: 'Firmado como interviniente',
    signedAsReceipt: 'Firmado para acusar recibo',
    signedAsEndorser: 'Firmado como endosante',
    signedAsEndorsee: 'Firmado como endosatario',
    signedAsAdministrator: 'Firmado como administrador',
    signedAsGuarantor: 'Firmado como avalista',
    signedAsTransferor: 'Firmado como cedente',
    signedAsTransferee: 'Firmado como cesionario',
    signedAsContractee: 'Firmado como contratada',
    signedAsContractor: 'Firmado como contratante',
    signedAsJointDebtor: 'Firmado como deudor solidario',
    signedAsIssuer: 'Firmado como emisor',
    signedAsManager: 'Firmado como administrador',
    signedAsBuyer: 'Firmado como comprador',
    signedAsSeller: 'Firmado como vendedor',
    signedAsAttorney: 'Firmado como apoderado',
    signedAsLegalRepresentative: 'Firmado como representante legal',
    signedAsCoResponsible: 'Firmado como co-responsable',
    signedAsValidator: 'Firmado como validador',
    signedAsSurety: 'Firmado como fiador',
    signedAsLessor: 'Firmado como arrendador',
    signedAsLessee: 'Firmado como arrendatario',
    signedAsRatify: 'Firmado para homologar',
    signedAsEmployer: 'Firmado como empleador',
    signedAsEmployee: 'Firmado como empleado',
    signedAsConsenting: 'Firmado como consentidor',
    signedAsAccountant: 'Firmado como contador',
    signedAsDebtor: 'Firmado como deudor',
    signedAsFranchisee: 'Firmado como franquiciado',
    signedAsCreditor: 'Firmado como acreedor',
    signedAsFranchisor: 'Firmado como franquiciador',
    signedAsInsured: 'Firmado como asegurado',
    signedAsGrantor: 'Firmado como otorgante',
    signedAsPresident: 'Firmado como presidente',
    signedAsPartner: 'Firmado como socio',
    signedAsComforter: 'Firmado como comodante',
    signedAsAccountHolder: 'Firmado como titular de cuenta',
    signedAsDistracted: 'Firmado como distraído',
    signedAsDistracting: 'Firmado como distratante',
    signedAsLender: 'Firmado como prestamista',
    signedAsAssociate: 'Firmado como asociado',
    signedAsBroker: 'Firmado como corredor',
    signedAsBailee: 'Firmado como depositario fiel',
    signedAsCollateralProvider: 'Firmado como garante',
    signedAsPledged: 'Firmado como consignado',
    signedAsConsignee: 'Firmado como consignatario',
    signedAsLawyer: 'Firmado como abogado',
    signedAsGrantee: 'Firmado como beneficiario',
    signedAsLoanee: 'Firmado como prestatario',
    signedAsBorrower: 'Firmado como prestatario',
    signedAsGuarantorSpouse: 'Firmado como cónyuge del fiador',
    signedAsLegalGuardian: 'Firmado como responsable legal',
    signedAsDirector: 'Firmado como director(a)',
    signedAsRealEstateBroker: 'Firmado como corredor de bienes raíces',
    signedAsInsuranceBroker: 'Firmado como corredor de seguros',
    signedAsLicensor: 'Firmado como licenciante',
    signedAsLicensed: 'Firmado como licenciatario',
    signedAsServiceProvider: 'Firmado como proveedor de servicios',
    signedAsSecured: 'Firmado como garantizado',
    signedAsConsented: 'Firmado como consentido',
    signedAsInterveningGuarantor: 'Firmado como garante interviniente',
    signedAsConsentingIntervenor: 'Firmado como interviniente anuente',
    signedAsSurveyor: 'Firmado como inspector',
    signedAsBuildingManager: 'Firmado como síndico',
    signedAsIntermediary: 'Firmado como intermediario',
    signedAsCondominiumMember: 'Firmado como condómino',
    signedAsOwner: 'Firmado como propietario',
    signedAsResident: 'Firmado como residente',
    signedAsTreasurer: 'Firmado como tesorero',
    signedAsSecretary: 'Firmado como secretario',
  },
  setup: {
    loadingDocuments: 'Cargando documento...',
    seeDocument: 'Ver documento',
    title: 'Lista de firmantes',
    info: 'Agrega contactos para firmar los documentos',
    add: 'Agregar',
    agenda: 'Agenda',
    ordenation: 'Clasificación',
    noSigners: 'Ningún signatario agregado.',
    sent: '¡Enviado!',
    documentSentToSigners: 'Documentos enviados a los firmantes.',
    seeDocumentList: 'Ver lista de documentos',
    seeThisDocument: 'Ver este documento',
    steps: {
      document: 'Documento',
      settings: 'Configuraciones',
      message: 'Mensaje',
    },
  },
  preview: {
    download: 'Descargar',
    page: 'Página',
  },
  list: {
    signature: {
      signAs: 'Firmar como',
      authAs: 'Autenticar usando',
      cellphone: 'Celular',
      cpfValidate: {
        pending: 'Verificación en proceso.',
        conferred: 'El nombre coincide con la base de datos de la Receita Federal.',
        cpfEmpty: 'Verificación no realizada.',
        cpfNotFound: 'El CPF informado no existe en la base de datos de la Receita Federal.',
        cpfInvalid: 'El CPF informado no es válido.',
        divergentBirthday:
          'La fecha de nacimiento ingresada no coincide con la registrada en la base de datos de la Receita Federal. (%{federal_tax_birthday})',
        divergentName:
          'El nombre ingresado no coincide con el registrado en la base de datos de la Receita Federal. (%{federal_tax_name})',
        cpfRegularizationPending:
          'El CPF ingresado tiene una regularización pendiente en la base de datos de la Receita Federal.',
        cpfOwnerDeceased:
          'El propietario del CPF ha fallecido según la base de datos de la Receita Federal.',
        cpfSuspended:
          'El CPF ingresado está suspendido según la base de datos de la Receita Federal.',
        cpfCanceled:
          'El CPF ingresado está cancelado según la base de datos de la Receita Federal.',
        cpfNull:
          'El CPF fue anulado tras identificarse un fraude en el registro, según la base de datos de la Receita Federal.',
        signerValidatorFail: 'Se produjo un error al consultar los datos en la Receita Federal.',
        receitaProxyFail: 'Se produjo un error al consultar los datos en la Receita Federal.',
      },
      nameVerification: 'Verificación del nombre',
      signedAt: 'Fecha de la firma',
      authSelfie: 'Autenticación por selfie',
      authHandwritten: 'Firma manuscrita',
      authOfficialDocumentFront: 'Documento oficial (frente)',
      authOfficialDocumentBack: 'Documento oficial (reverso)',
      authOfficialDocumentFrontBack: 'Documento oficial (frente y reverso)',
      authLiveness: 'Selfie dinámica',
      facialBiometrics: 'Biometría facial',
      authBiometric: 'Biometría facial - CAF y SERPRO',
      presential: 'Firma presencial',
      addressProof: 'Comprobante de residencia',
    },
    tooltip: {
      refusalReason: 'Motivo del rechazo',
      refusalInfo:
        'Elimina y vuelve a agregar al signatario para que pueda intentar firmar nuevamente',
      blockWhatsappReminder: 'Deshabilitado para envío a través de WhatsApp',
      orderingBlocked: 'Desactive la clasificación para editar',
    },
    signer: {
      signAs: 'Firmar como',
      authAs: 'Autenticar usando',
      selfSigner: '(Usted)',
      signAllDocuments: 'Firma todos los documentos',
      communicateBy: 'Envío vía',
      signatureRefusal: 'Rechazo de firma',
      permitted: 'habilitado',
      notPermitted: 'deshabilitado',
      fullName: 'Nombre completo',
      email: 'Correo electrónico',
      cellphone: 'Número de celular',
      cpf: 'CPF',
      birthday: 'Fecha de nacimiento',
      selfie: 'Selfie con documento',
      handwritten: 'Firma manuscrita',
      officialDocument: 'Documento oficial',
      facialBiometricsEnabled: 'Biometría facial habilitada',
      liveness: 'Selfie dinámica',
      locationRequired: 'Ubicación requerida',
      voiceBiometrics: 'Biometría de voz',
      auths: {
        api: 'API',
        sms: 'Token vía SMS',
        whatsapp: 'Token vía WhatsApp',
        pix: 'Pix',
        email: 'Token vía correo electrónico',
        icpBrasil: 'Certificado digital',
        officialDocument: 'Documento oficial',
        selfie: 'Selfie con documento',
        liveness: 'Selfie dinámica',
        handwritten: 'Firma manuscrita',
        facialBiometrics: 'Biometría facial',
        voiceBiometrics: 'Biometría de voz',
        biometric: 'Biometría facial - CAF y SERPRO',
        addressProof: 'Comprobante de domicilio',
        provideEvidence: 'Sin token',
      },
      communicateByOptions: {
        email: 'Correo electrónico',
        whatsapp: 'WhatsApp',
        sms: 'SMS',
      },
      info: {
        icpBrasil: {
          signedWith:
            '{subjectName} {certificateType} firmó con certificado digital ICP-Brasil válido hasta {validityEnd}.',
          details: 'CPF: {cpf}, Número de serie del certificado ICPBR: {serialNumber}',
        },
        more: 'Ver más',
        less: 'Ver menos',
      },
      notify: {
        send: '¡Recordatorio enviado!',
      },
      notifyAll: {
        send: 'Enviar recordatorio',
        title: 'Enviar recordatorio a los firmantes',
        description:
          'Acaba de eliminar a un signatario que rechazó la firma. Ahora el documento puede ser firmado por los otros firmantes. ¿Qué tal enviarles un recordatorio para informarles que ya es posible firmar el documento?',
      },
      delete: {
        confirm: 'Haga clic para confirmar',
      },
      pix: 'Autenticación vía Pix',
      rubric: 'Firma electrónica',
      hasRubric: 'habilitada',
    },
    actions: {
      move: 'Mover',
      new: 'Nueva carpeta',
      edit: 'Renombrar',
      delete: 'Eliminar',
      title: 'Documentos',
      permissions: 'Permisos',
      select_all: 'Seleccionar todo',
    },
    status: {
      canceled: 'Cancelado el %{at}',
      closed: 'Finalizado el %{at}',
      running: 'En proceso hasta %{at}',
    },
    empty: {
      title: 'Agrega y envíe documentos para firma.',
      description: 'Descarga un {link} y agréguelo para probar.',
      document_example: 'documento de ejemplo',
      document_name: 'Contrato de Prestación de Servicios - Ejemplo.pdf',
      new_document: 'Agregar Documento',
      newDocuments: 'Agregar Documentos',
      empty_folder: 'Carpeta vacía.',
      move_document:
        'Mueve un documento a esta carpeta seleccionando un documento y haciendo click en el botón de mover.',
    },
    search: {
      title_search: 'Resultado de la búsqueda',
      search_not_found: 'No se encontraron resultados para tu búsqueda.',
    },
    signatures: {
      showMore: 'Ver +%{count}',
      showLess: 'Ver menos',
    },
    document: {
      path: 'en Documentos',
    },
  },
  running: 'en proceso',
  closed: 'finalizado',
  canceled: 'cancelado',
  flow: {
    empty:
      'Este documento no fue creado a partir de un flujo, por lo tanto, no hay información para mostrar.',
    deleted: 'Flujo eliminado',
    details: {
      title: 'Detalles del flujo',
      short_title: 'Detalles',
      form: 'Formulario',
      unanswered: 'No informado',
    },
    forms: {
      form1_filled_at: 'Formulario 1 completado el %{deadline_at}',
      form2_filled_at: 'Formulario 2 completado el %{deadline_at}',
    },
    attachments: {
      title: 'Archivos adjuntos',
      description: 'Archivos enviados a través del formulario.',
      empty:
        'Los detalles y archivos del flujo generado a partir de la automatización se mostrarán aquí.',
    },
  },
  declinedSignature: 'Firma Rechazada',
  infoModal: {
    title: 'Motivo del rechazo',
    comments: 'Comentario del firmante:',
    description:
      'rechazó la firma. | rechazó la firma por el siguiente motivo: | rechazó la firma por los siguientes motivos:',
    reasons: {
      content: 'Contenido del documento',
      incorrectPersonalInfo: 'Datos personales incorrectos',
      selectedAuthentication: 'Forma de autenticación',
      electronicSignatureUse: 'Uso de la firma electrónica',
      location: 'Uso de la recolección de ubicación',
      other: 'Otro motivo',
    },
  },
  details: {
    saveButton: 'Guardar cambios',
    help: {
      text: 'Utiliza los detalles para optimizar la administración de sus documentos, definiendo plazos e incluyendo observaciones. Esta información será visible solo para los operadores y no formará parte del documento. {0}',
      link: 'Vea ejemplos de uso',
    },
    validityDate: {
      placeholder: 'Seleccione la fecha',
      label: 'Fecha de vigencia final',
    },
    detailTextArea: {
      label: 'Observación',
      placeholder: 'Escribe una observación',
    },
    message: {
      success: 'Guardado con éxito',
      error: 'Ocurrió un error',
    },
    event: {
      button: 'Agregar detalle',
      form: {
        name: {
          placeholder: 'Escribe el título del detalle',
          label: 'Título',
        },
        date: {
          label: 'Fecha',
          placeholder: 'DD/MM/AAAA',
        },
        kind: {
          label: 'Tipo de campo',
          placeholder: 'Fecha',
          items: {
            date: 'Fecha',
            text: 'Texto',
          },
        },
        description: {
          label: 'Descripción',
        },
      },
    },
  },
};

export default document;
