const signer = {
  group: 'Grupo {0}',
  list: {
    title: 'Lista de firmantes',
    signaturesDone: '{totalSigned}/{totalSignatures} Firmas realizadas',
    tracking: 'Correos electrónicos enviados',
    signatureRefused: 'Firma rechazada',
    communicateBy: 'Envío vía',
    seeMore: 'Ver menos|Ver más',
    loading: 'Cargando información...',
    email: 'Correo electrónico',
    whatsapp: 'WhatsApp',
    sms: 'SMS',
    phoneNumber: 'Número de celular',
    cpf: 'CPF',
    birthday: 'Fecha de nacimiento',
    nameVerification: {
      title: 'Verificación de nombre',
      conferred: 'El nombre coincide con la base de datos de la autoridad fiscal.',
      cpfNotFound: 'El CPF no existe en la base de datos.',
      cpfInvalid: 'El CPF  no es válido.',
      divergentName:
        'El nombre ingresado es diferente del registro en la base de datos de la autoridad fiscal. ({name})',
      divergentBirthday:
        'La fecha de nacimiento ingresada es diferente del registro en la base de datos de la autoridad fiscal. ({birthday})',
      receitaProxyFail: 'Hubo un error al consultar la base de datos de la autoridad fiscal.',
      pending: 'Verificación en curso.',
      cpfEmpty: 'Verificación no realizada.',
      cpfRegularizationPending: 'El CPF tiene una regularización pendiente en la base de datos.',
      cpfOwnerDeceased: 'El propietario del CPF está fallecido según la base de datos.',
      cpfSuspended: 'El CPF está suspendido según la base de datos.',
      cpfCanceled: 'El CPF está cancelado según la base de datos.',
      cpfNull:
        'El CPF fue anulado tras identificar un fraude en el registro según la base de datos.',
      signerValidatorFail: 'Hubo un error al consultar la base de datos de la autoridad fiscal.',
    },
    signedAt: 'Fecha de firma',
    authAsPix: 'Autenticación vía pix',
    authAsList: 'Autenticar usando',
    pixVerification: '{0} (E2EId / Banco Central)',
    icpBrasil: {
      signedWith:
        '{subjectName} {certificateType} firmó con un certificado digital válido hasta {validityEnd}.',
      details: 'CPF: {cpf}, Número de serie del certificado digital: {serialNumber}',
    },
    refusal: {
      enabledLabel: 'Rechazo de firma',
      isEnabled: 'habilitado',
      signAs: 'Firma rechazada',
      reasonTooltip: 'Razón del rechazo',
      infoTooltip: 'Elimina y vuelve a agregar al firmante para que intente firmar nuevamente',
    },
    remind: {
      refused: 'Este firmante rechazó la firma',
      blocked: 'El documento está pausado',
      outsideActiveGroup: 'Esperando la firma de otro firmante',
    },
    actions: {
      deleteConfirm: 'Haz click para confirmar',
    },
  },
  toolbar: {
    newSigner: 'Nuevo firmante',
    newContact: 'Firmante de la lista de contactos',
    sequence: 'Ordenar firmas',
  },
  evidence: {
    modal: {
      filename: 'Nombre del archivo',
      selfie: 'Selfie con documento',
      handwritten: 'Firma manuscrita',
      officialDocumentFront: 'Frente del documento oficial',
      officialDocumentBack: 'Reverso del documento oficial',
      liveness: 'Selfie dinámica',
      facialBiometrics: 'Biometría facial',
    },
  },
  sequence: {
    title: 'Configurar orden de las firmas',
    checkbox: {
      label: 'Activar orden de las firmas.',
      tooltip:
        'Una vez activada el orden de las firmas, no se puede desactivar en el documento. Si deseas que todos los firmantes firmen al mismo tiempo, colócalos en el mismo grupo.',
    },
    dragAndDropInfo: 'Arrastra y suelta para cambiar de grupo',
    banner: {
      activeSequence:
        'Activa el orden de firmas para definir quién debe firmar primero, en segundo lugar y así sucesivamente.',
      emptyGroups: 'Agrega firmantes para ordenar las firmas.',
    },
    dragAndDropPlaceholder: 'ARRASTRAR AQUÍ PARA AGREGAR UN NUEVO GRUPO',
    successSubmit: '¡El Orden de firmas actualizada con éxito!',
  },
  auths: {
    api: 'Api',
    sms: 'Token vía SMS',
    whatsapp: 'Token vía WhatsApp',
    pix: 'Pix',
    email: 'Token vía correo electrónico',
    icpBrasil: 'Certificado digital',
    officialDocument: 'Documento oficial',
    selfie: 'Selfie con documento',
    liveness: 'Selfie dinámica',
    handwritten: 'Firma manuscrita',
    facialBiometrics: 'Biometría facial',
    addressProof: 'Comprobante de domicilio',
    biometric: 'Biometría facial - CAF y SERPRO',
  },
  roles: {
    preffix: {
      as: 'Como',
      to: 'Para',
      sign: 'Firmar',
      signed: 'Firmó',
    },
    signed: 'Firmó',
    sign: 'Firmar',
    witness: 'Testigo',
    party: 'Parte',
    intervening: 'Interviniente',
    receipt: 'Acusar recibo',
    approve: 'Aprobar',
    endorser: 'Endosante',
    endorsee: 'Endosatario',
    administrator: 'Administrador',
    guarantor: 'Avalista',
    transferor: 'Cedente',
    transferee: 'Cesionario',
    contractee: 'Contratada',
    contractor: 'Contratante',
    jointDebtor: 'Deudor solidario',
    issuer: 'Emisor',
    manager: 'Gestor',
    buyer: 'Parte compradora',
    seller: 'Parte vendedora',
    attorney: 'Apoderado',
    legalRepresentative: 'Representante legal',
    coResponsible: 'Responsable solidario',
    validator: 'Validador',
    surety: 'Fiador',
    lessor: 'Arrendador',
    lessee: 'Arrendatario',
    ratify: 'Homologar',
    employer: 'Empleador',
    employee: 'Empleado',
    consenting: 'Consintiente',
    accountant: 'Contador',
    debtor: 'Deudor',
    franchisee: 'Franquiciado',
    creditor: 'Acreedor',
    franchisor: 'Franquiciador',
    insured: 'Asegurado',
    grantor: 'Otorgante',
    president: 'Presidente',
    partner: 'Socio',
    comforter: 'Comodante',
    accountHolder: 'Titular de cuenta',
    distracted: 'Distratada',
    distracting: 'Distratante',
    lender: 'Prestamista',
    associate: 'Asociado',
    broker: 'Corredor',
    bailee: 'Depositario fiel',
    collateralProvider: 'Caucionante',
    pledged: 'Consignado',
    consignee: 'Consignatario',
    lawyer: 'Abogado',
    grantee: 'Otorgado',
    borrower: 'Comodatario',
    guarantorSpouse: 'Cónyuge del fiador',
    legalGuardian: 'Responsable legal',
    director: 'Director(a)',
    realEstateBroker: 'Corredor de bienes raíces',
    insuranceBroker: 'Corredor de seguros',
    licensor: 'Licenciante',
    licensed: 'Licenciada',
    serviceProvider: 'Prestador(a) de servicios',
    secured: 'Afianzado',
    consented: 'Consentido',
    interveningGuarantor: 'Interviniente Garantidor',
    consentingIntervenor: 'Interviniente Consintiente',
    surveyor: 'Inspector',
    buildingManager: 'Administrador de edificio',
    intermediary: 'Intermediario(a)',
    condominiumMember: 'Condómino',
    owner: 'Propietario(a)',
    resident: 'Residente',
    treasurer: 'Tesorero(a)',
    secretary: 'Secretario(a)',
  },
  signedAs: {
    sign: 'Firmó',
    approve: 'Firmó para aprobar',
    party: 'Firmó como parte',
    witness: 'Firmó como testigo',
    intervening: 'Firmó como interviniente',
    receipt: 'Firmó para acusar recibo',
    endorser: 'Firmó como endosante',
    endorsee: 'Firmó como endosatario',
    administrator: 'Firmó como administrador',
    guarantor: 'Firmó como avalista',
    transferor: 'Firmó como cedente',
    transferee: 'Firmó como cesionario',
    contractee: 'Firmó como contratada',
    contractor: 'Firmó como contratante',
    jointDebtor: 'Firmó como deudor solidario',
    issuer: 'Firmó como emisor',
    manager: 'Firmó como gestor',
    buyer: 'Firmó como parte compradora',
    seller: 'Firmó como parte vendedora',
    attorney: 'Firmó como apoderado',
    legalRepresentative: 'Firmó como representante legal',
    coResponsible: 'Firmó como responsable solidario',
    validator: 'Firmó como validador',
    surety: 'Firmó como fiador',
    lessor: 'Firmó como arrendador',
    lessee: 'Firmó como arrendatario',
    ratify: 'Firmó para homologar',
    employer: 'Firmó como empleador',
    employee: 'Firmó como empleado',
    consenting: 'Firmó como consintiente',
    accountant: 'Firmó como contador',
    debtor: 'Firmó como deudor',
    franchisee: 'Firmó como franquiciado',
    creditor: 'Firmó como acreedor',
    franchisor: 'Firmó como franquiciador',
    insured: 'Firmó como asegurado',
    grantor: 'Firmó como otorgante',
    president: 'Firmó como presidente',
    partner: 'Firmó como socio',
    comforter: 'Firmó como comodante',
    accountHolder: 'Firmó como titular de cuenta',
    distracted: 'Firmó como distratada',
    distracting: 'Firmó como distratante',
    lender: 'Firmó como prestamista',
    associate: 'Firmó como asociado',
    broker: 'Firmó como corredor',
    bailee: 'Firmó como depositario fiel',
    collateralProvider: 'Firmó como caucionante',
    pledged: 'Firmó como consignado',
    consignee: 'Firmó como consignatario',
    lawyer: 'Firmó como abogado',
    grantee: 'Firmó como otorgado',
    borrower: 'Firmó como comodatario',
    guarantorSpouse: 'Firmó como cónyuge del fiador',
    legalGuardian: 'Firmó como responsable legal',
    director: 'Firmó como director(a)',
    realEstateBroker: 'Firmó como corredor de bienes raíces',
    insuranceBroker: 'Firmó como corredor de seguros',
    licensor: 'Firmó como licenciante',
    licensed: 'Firmó como licenciada',
    serviceProvider: 'Firmó como prestador(a) de servicios',
    secured: 'Firmó como afianzado',
    consented: 'Firmó como consentido',
    interveningGuarantor: 'Firmó como interviniente garantidor',
    consentingIntervenor: 'Firmó como interviniente consintiente',
    surveyor: 'Firmó como inspector',
    buildingManager: 'Firmó como administrador de edificio',
    intermediary: 'Firmó como intermediario(a)',
    condominiumMember: 'Firmó como condómino',
    owner: 'Firmó como propietario(a)',
    resident: 'Firmó como residente',
    treasurer: 'Firmó como tesorero(a)',
    secretary: 'Firmó como secretario(a)',
  },
  signAs: {
    sign: 'Firmar',
    witness: 'Firmar como testigo',
    party: 'Firmar como parte',
    intervening: 'Firmar como interviniente',
    receipt: 'Firmar para acusar recibo',
    approve: 'Firmar para aprobar',
    endorser: 'Firmar como endosante',
    endorsee: 'Firmar como endosatario',
    administrator: 'Firmar como administrador',
    guarantor: 'Firmar como avalista',
    transferor: 'Firmar como cedente',
    transferee: 'Firmar como cesionario',
    contractee: 'Firmar como contratada',
    contractor: 'Firmar como contratante',
    jointDebtor: 'Firmar como deudor solidario',
    issuer: 'Firmar como emisor',
    manager: 'Firmar como gestor',
    buyer: 'Firmar como parte compradora',
    seller: 'Firmar como parte vendedora',
    attorney: 'Firmar como apoderado',
    legalRepresentative: 'Firmar como representante legal',
    coResponsible: 'Firmar como responsable solidario',
    validator: 'Firmar como validador',
    surety: 'Firmar como fiador',
    lessor: 'Firmar como arrendador',
    lessee: 'Firmar como arrendatario',
    ratify: 'Firmar para homologar',
    employer: 'Firmar como empleador',
    employee: 'Firmar como empleado',
    consenting: 'Firmar como consintiente',
    accountant: 'Firmar como contador',
    debtor: 'Firmar como deudor',
    franchisee: 'Firmar como franquiciado',
    creditor: 'Firmar como acreedor',
    franchisor: 'Firmar como franquiciador',
    insured: 'Firmar como asegurado',
    grantor: 'Firmar como otorgante',
    president: 'Firmar como presidente',
    partner: 'Firmar como socio',
    comforter: 'Firmar como comodante',
    accountHolder: 'Firmar como titular de cuenta',
    distracted: 'Firmar como distratada',
    distracting: 'Firmar como distratante',
    lender: 'Firmar como prestamista',
    associate: 'Firmar como asociado',
    broker: 'Firmar como corredor',
    bailee: 'Firmar como depositario fiel',
    collateralProvider: 'Firmar como caucionante',
    pledged: 'Firmar como consignado',
    consignee: 'Firmar como consignatario',
    lawyer: 'Firmar como abogado',
    grantee: 'Firmar como otorgado',
    borrower: 'Firmar como comodatario',
    guarantorSpouse: 'Firmar como cónyuge del fiador',
    legalGuardian: 'Firmar como responsable legal',
    director: 'Firmar como director(a)',
    realEstateBroker: 'Firmar como corredor de bienes raíces',
    insuranceBroker: 'Firmar como corredor de seguros',
    licensor: 'Firmar como licenciante',
    licensed: 'Firmar como licenciada',
    serviceProvider: 'Firmar como prestador(a) de servicios',
    secured: 'Firmar como afianzado',
    consented: 'Firmar como consentido',
    interveningGuarantor: 'Firmar como interviniente garantidor',
    consentingIntervenor: 'Firmar como interviniente consintiente',
    surveyor: 'Firmar como inspector',
    buildingManager: 'Firmar como administrador de edificio',
    intermediary: 'Firmar como intermediario(a)',
    condominiumMember: 'Firmar como condómino',
    owner: 'Firmar como propietario(a)',
    resident: 'Firmar como residente',
    treasurer: 'Firmar como tesorero(a)',
    secretary: 'Firmar como secretario(a)',
  },
};

export default signer;
