export const dateTimeFormats = {
  numeric: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
  numericTimeNoSeconds: {
    hour: 'numeric',
    minute: 'numeric',
  },
  dateTime2Digit: {
    day: '2-digit',
    year: '2-digit',
    month: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  },
};
