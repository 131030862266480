<template>
  <div :class="$style.container">
    <h4>
      {{ formFlowName }}
    </h4>
    <XCard
      elevation="low"
      :class="$style.general"
    >
      <p
        v-if="firstFormName"
        data-testid="firstFormName"
      >
        {{ $t('flowModule.show.generalInfo.firstForm') }} <br />
        <span :class="$style.info">
          {{ firstFormName }}
        </span>
      </p>
      <p data-testid="complianceProcess">
        {{ $t('flowModule.show.generalInfo.approvation') }} <br />
        <span :class="$style.info">
          {{ getComplianceProcessText }}
        </span>
      </p>
      <p v-if="complianceProcess">
        {{ $t('flowModule.show.generalInfo.flowMemberships') }} <br />
        <span :class="$style.info">
          {{ getFlowMembershipsName }}
        </span>
      </p>
      <p
        v-if="secondFormName"
        data-testid="secondFormName"
      >
        {{ $t('flowModule.show.generalInfo.secondForm') }} <br />
        <span :class="$style.info">
          {{ secondFormName }}
        </span>
      </p>
      <p
        v-if="secondFormEmail"
        data-testid="secondFormEmail"
      >
        {{ $t('flowModule.show.generalInfo.secondFormEmail') }} <br />
        <span :class="$style.info">
          {{ secondFormEmail }}
        </span>
      </p>
      <p
        v-if="templateName"
        data-testid="templateName"
      >
        {{ $t('flowModule.show.generalInfo.template') }} <br />
        <span :class="$style.info">
          {{ templateName }}
        </span>
      </p>
      <p
        v-if="membershipName"
        data-testid="membershipName"
      >
        {{ $t('flowModule.show.generalInfo.membershipName') }} <br />
        <span :class="$style.info">
          {{ membershipName }}
        </span>
      </p>
    </XCard>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useI18n } from '@/locales';

export default defineComponent({
  props: {
    formFlowName: {
      type: String,
      default: '',
    },
    firstFormName: {
      type: String,
      default: '',
    },
    secondFormName: {
      type: String,
      default: '',
    },
    secondFormEmail: {
      type: String,
      default: '',
    },
    templateName: {
      type: String,
      default: '',
    },
    complianceProcess: {
      type: Boolean,
      default: false,
    },
    membershipName: {
      type: String,
      default: '',
    },
    flowMemberships: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { t } = useI18n();

    const getComplianceProcessText = computed(() =>
      props.complianceProcess ? t('flowModule.show.enabled') : t('flowModule.show.disabled')
    );

    const getFlowMembershipsName = computed(() =>
      props.flowMemberships.map((membership) => membership.name).join(', ')
    );

    return {
      getComplianceProcessText,
      getFlowMembershipsName,
    };
  },
});
</script>

<style lang="scss" module>
.container {
  .general {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--space-small-xx);

    p {
      margin-bottom: 0px;
    }

    .info {
      color: var(--color-neutral-800);
    }
  }
}
</style>
