const validator = {
  document: {
    info: {
      title: 'Valida tu documento',
      description: 'Agrega un PDF para validar que es un documento firmado a través de Clicksign',
    },
    form: {
      invalid: {
        file_type: 'Formato no permitido, por favor utiliza solo archivos .PDF',
        file_size: 'El tamaño del archivo excede el límite permitido de %{file_max_size}mb',
      },
    },
    result: {
      title: 'Resultado',
      validate_at: 'Validación realizada',
      date_time: 'Fechas y horario en GMT',
      file_hash: 'Hash del archivo validado',
      signed: 'Documento firmado electrónicamente a través de Clicksign.',
      invalid: 'No fue posible validar este archivo.',
      icp_valid: 'Certificado ICP-Brasil válido: Clicksign Administración de Documentos S.A.',
      more_info: 'Conocer más',
    },
    loading: 'Validando documento',
    resend: 'Validar otro documento',
    print: 'Imprimir',
    lgpd: {
      link: 'Aviso de privacidad conforme a la nueva ley de LGPD',
    },
  },
};

export default validator;
