export default {
  getLinks: (state) => state.links,
  getTemplates: (state) => state.templates,
  getSpreadsheetFlow: (state) => state.spreadsheetFlow,
  getSigners: (state) => state.signers,
  getSpreadsheetDataAnalysis: (state) => state.spreadsheetDataAnalysis,
  getSpreadsheetFlows: (state) => state.spreadsheetFlows,
  getSpreadsheetData: (state) => state.spreadsheetData,
  getPagination: (state) => state.pagination,
};
