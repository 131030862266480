const membership = {
  title: 'Usuarios',
  titleOld: 'Usuarios de la cuenta',
  list: {
    title: 'Todos los usuarios',
    member: {
      edit: 'Editar',
      remove: 'Eliminar de la cuenta',
      consumptionAccess: 'Acceso a la Facturación',
      roles: {
        admin: 'Administrador',
        global: 'Miembro',
        member: 'Miembro',
        guest: 'Solo lectura',
        downloader: 'Observador con descarga',
        observer: 'Observador sin descarga',
      },
      invite: {
        pending: 'Aún no ha ingresado.',
      },
      apiUserWarning: 'No se puede eliminar. Miembro configurado con API.',
      hasConsumptionAccess: 'Acceso a la Facturación',
      hasTrackingAccess: 'Acceso a correos enviados',
    },
    pagination: {
      previous: 'Anterior',
      next: 'Siguiente',
    },
    pendingInvites: {
      title: 'Invitaciones pendientes',
    },
  },
  invite: {
    title: 'Agregar usuario',
    form: {
      name: 'Nombre (opcional)',
      email: 'Correo electrónico',
      role: {
        label: 'Tipo de usuario',
        options: {
          admin: 'Administrador',
          global: 'Miembro',
          observer: 'Observador (No puede descargar documentos)',
          downloader: 'Observador (Puede descargar documentos)',
        },
      },
      enableAccess: 'Habilitar acceso a {0}',
      consumption: 'Plan y Facturación',
      tracking: 'Correos enviados',
      additionalAccessTitle: 'Accesos adicionales (opcional)',
      moreInfo: 'Más información.',
      group: 'Grupo (opcional)',
      groupHelpTooltip: 'Concede permisos de acceso a las carpetas para los grupos',
    },
    action: 'Agregar',
    hasSamlInfo: 'Para agregar usuarios a la cuenta, contacta al soporte.',
    notification: '{name} ha sido invitado con éxito.',
  },
  edit: {
    title: 'Editar usuario',
    form: {
      consumptionAccess: 'Habilitar acceso a la página de facturación para este usuario. {0}',
      trackingAccess: 'Habilitar acceso a la página de correos enviados para este usuario. {0}',
      moreInfo: 'Más información.',
    },
    memberHasFlows: {
      title:
        'El usuario {name} tiene su correo configurado en el siguiente Flujo: | El usuario {name} tiene su correo configurado en los siguientes Flujos:',
      description: 'Para cambiar el tipo, selecciona un nuevo usuario para estos Flujos.',
      info: 'Los usuarios de tipo Observador no aparecerán en la lista.',
    },
    roles: {
      title: 'Tipo de usuario',
      options: {
        admin: 'Administrador',
        global: 'Miembro',
        observer: 'Observador (No puede descargar documentos)',
        downloader: 'Observador (Puede descargar documentos)',
      },
    },
    notification: '{name} actualizado con éxito',
  },
  delete: {
    title: 'Eliminar usuario de la cuenta',
    description: '¿Deseas realmente eliminar a {name} de esta cuenta?',
    memberHasFlows: {
      title:
        'El usuario {name} tiene su correo configurado en el siguiente Flujo: | El usuario {name} tiene su correo configurado en los siguientes Flujos:',
      description: 'Para eliminarlo, selecciona un nuevo usuario para estos Flujos.',
      info: 'Los usuarios de tipo Observador no aparecerán en la lista.',
      select: 'Seleccionar',
      ownedFormFlows: 'Flujos con Formulario',
      ownedSpreadsheetFlows: 'Flujos con Hoja de cálculo',
    },
    notification: '{name} fue eliminado con éxito',
  },
  user: 'Usuario',
  memberActionsDropdown: {
    edit: 'Editar usuario',
    removeGroupUser: 'Eliminar de este grupo',
    removeAccountMember: 'Eliminar de la cuenta',
  },
};

export default membership;
