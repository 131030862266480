import { parseContactsToSigners } from '@document/utils/signer-utils';

export default [
  {
    name: 'contacts',
    component: 'ContactsTable',
    form: 'signersFromContacts',
    props: {
      contacts: 'getContacts',
      selectedContacts: 'signersFromContacts.selectedContacts',
      pagination: 'pagination',
    },
    isNextStepButtonDisabled: (vm) => vm.signersFromContacts.selectedContacts.length === 0,
  },
  {
    name: 'signAs',
    component: 'SignerListSignAsStep',
    form: 'signAs',
    props: {
      signers: 'signAs.signers',
      signAsList: 'signAs.signAsList',
      signerSignAs: 'getSignerSignAsItems',
      hasValidationError: 'hasValidationError',
    },
    isNextStepButtonDisabled: () => false,
    setupAction: (vm) => {
      const selectedContacts = _.get(vm, 'signersFromContacts.selectedContacts');
      const signers = parseContactsToSigners(selectedContacts);
      vm.$set(vm.signAs, 'signers', signers);
    },
    action: async (vm, payload) => {
      const { signers, signAsList: rolesBySignerIdList } = payload;

      await Promise.all(
        Object.entries(rolesBySignerIdList).map(async ([signerId, roles]) => {
          const signer = signers.find(({ id }) => id === Number(signerId));

          const linkSignerPayload = {
            signerKey: signer.key,
            roles,
            refusable: signer.features?.refusable,
          };

          await vm.$store.dispatch('envelope/linkSigner', linkSignerPayload);
        })
      );
      vm.$emit('save');
    },
  },
];
