import { isValid as isValidCpf } from '@fnando/cpf';
import { isValid as isValidCnpj } from '@fnando/cnpj';
import * as defaultValidators from 'vuelidate/lib/validators';
import moment from 'moment';
import { get } from 'lodash';
import { helpers } from '@vuelidate/validators';
import { getCreditCardBrand } from '@base/utils/credit-card-utils';
import { MIDAS_VALIDATIONS_CONSTANTS } from '@account/constants';
import emojiRegex from 'emoji-regex';
import axios from 'axios';

export function email(value = '') {
  return !value || defaultValidators.email(value.toLowerCase());
}

export function required(value) {
  return defaultValidators.required(value);
}

export function requiredIf(prop, path, valueToMatch) {
  return defaultValidators.helpers.withParams(
    { type: 'requiredIf', prop },
    function validate(value, parentVm) {
      const object = path ? get(this, path) : parentVm;
      const hasValue = valueToMatch ? get(object, prop) === valueToMatch : get(object, prop);
      return hasValue ? defaultValidators.helpers.req(value) : true;
    }
  );
}

export function requiredIfFnCallback(prop, callback) {
  return defaultValidators.helpers.withParams(
    { type: 'requiredIf', prop },
    function validate(value, formValidate) {
      const isCallbackValid = callback(formValidate, this, value);
      return isCallbackValid ? defaultValidators.helpers.req(value) : true;
    }
  );
}

/**
 * Regex used is based on:
 *
 * @link https://pt.stackoverflow.com/questions/46672/como-fazer-uma-express%c3%a3o-regular-para-telefone-celular/46676#46676
 */
// TODO: remove phone function when midas is ready
export function phone(value) {
  const phoneOrCellRegex = /^\([1-9]{2}\) (?!90)(?:[2-9]|9[1-9])[0-9]{3}-[0-9]{4}$/;

  return !value || phoneOrCellRegex.test(value);
}

function getNumbersInParentheses(inputString) {
  const regex = /\((\d+)\D*(\d+)\)/;
  const matches = inputString.match(regex);

  if (matches?.length === 3) {
    const [, number1, number2] = matches;
    return `${number1}${number2}`;
  }

  return null;
}

export function phoneMidas(value) {
  const numbers = getNumbersInParentheses(value);
  const hasDDD = MIDAS_VALIDATIONS_CONSTANTS.PHONE_AREAS_CODES.includes(numbers);

  if (hasDDD) {
    const regex = /^\([1-9]{2}\) (?!90)(?:[2-9]|9[1-9])[0-9]{3}-[0-9]{4}$/;
    return !value || regex.test(value);
  }
  return false;
}

export function ipv6Cidr(value) {
  const regex =
    /^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*(\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?$/;
  return !value || regex.test(value);
}

export function ipv4Cidr(value) {
  const regex =
    /^(((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([1-9]|1[0-9]|2[0-8]|3[0-2])){0,1}){0,1}((\s*,\s*)(?=[^/])){0,1})+$/;
  return !value || regex.test(value);
}

export function ipCidr(value) {
  return !value || ipv4Cidr(value) || ipv6Cidr(value);
}

export function cnpj(value) {
  return !value || isValidCnpj(value);
}

export function cpf(value) {
  return !value || isValidCpf(value);
}

export function date(value) {
  const format = this.$t('validation.dateFormat');
  const MAX_YEARS = 120;
  return (
    !value ||
    (moment(value, format, true).isValid() &&
      moment().diff(moment(value, format), 'years') <= MAX_YEARS)
  );
}

export function dateInThePast(value) {
  const format = this.$t('validation.dateFormat');
  return !value || moment(value, format).diff(moment(), 'days') < 0;
}

export function fullName(value) {
  const hasNumbers = new RegExp(/\d/g).test(value);
  const hasMoreThanOneWord = value && value.trim().split(' ').length > 1;

  return !value || (!hasNumbers && hasMoreThanOneWord);
}

export function url(value) {
  const isLocalhost =
    value.search('http://localhost') !== -1 || value.search('http://127.0.0.1') !== -1;
  return defaultValidators.url(value) || isLocalhost;
}

export function numeric(value) {
  const numericRegex = /^[0-9,.]*$/g;
  return !value || numericRegex.test(value);
}

export function alpha(value) {
  return defaultValidators.alpha(value);
}

export function cep(value = '') {
  const formattedRegex = /^[0-9]{5}-[0-9]{3}$/gm;
  const unformattedRegex = /^\d{8}$/gm;
  return !value || formattedRegex.test(value.trim()) || unformattedRegex.test(value.trim());
}

export async function fetchCep(value) {
  const zipCode = value.replace('-', '');

  if (zipCode.length !== 8) return false;
  const brasilApiURL = `https://brasilapi.com.br/api/cep/v1/${zipCode}`;
  try {
    await axios.get(brasilApiURL);
    return true;
  } catch {
    return false;
  }
}

export function alphanumeric(value) {
  const regex = /\w/;
  return !value || regex.test(value);
}

export function sameAs(value) {
  return defaultValidators.sameAs(value);
}

export function minLength(length) {
  return defaultValidators.minLength(length);
}

export function maxLength(length) {
  return defaultValidators.maxLength(length);
}

export function containsUppercase(value) {
  const regexUppercase = /[A-Z]/;
  return !value || regexUppercase.test(value);
}

export function containsLowercase(value) {
  const regexLowercase = /[a-z]/;
  return !value || regexLowercase.test(value);
}

export function containsNumber(value) {
  const regexNumbers = /[0-9]/;
  return !value || regexNumbers.test(value);
}

export function containsSpecial(value) {
  const regexSpecialCaracters = /[#?!@$%^&*-]/;
  return !value || regexSpecialCaracters.test(value);
}

export function hasNoSequentialCharacters(value) {
  const sequentialLength = 3;
  const loopSize = value.length + 1;
  let sequentialSlice = value.slice(0, sequentialLength);
  let hasSequential = false;

  const letters = 'abcdefghijklmnopqrstuvwxyz';
  const invertedLetters = 'zyxwvutsrqponmlkjihgfedcba';
  const lettersUppercase = letters.toUpperCase();
  const invertedLettersUppercase = invertedLetters.toUpperCase();
  const numbers = '0123456789';
  const invertedNumbers = '9876543210';

  for (let index = sequentialLength; index < loopSize; index += 1) {
    if (
      letters.indexOf(sequentialSlice) > -1 ||
      invertedLetters.indexOf(sequentialSlice) > -1 ||
      lettersUppercase.indexOf(sequentialSlice) > -1 ||
      invertedLettersUppercase.indexOf(sequentialSlice) > -1 ||
      numbers.indexOf(sequentialSlice) > -1 ||
      invertedNumbers.indexOf(sequentialSlice) > -1
    ) {
      hasSequential = true;
      break;
    }

    sequentialSlice = sequentialSlice.slice(1) + value.charAt(index);
  }

  return hasSequential ? !defaultValidators.helpers.req(true) : true;
}

export function domain(value) {
  const regex =
    /([a-z0-9A-Z]\.)*[a-z0-9-]+\.([a-z0-9]{2,24})+(\.co\.([a-z0-9]{2,24})|\.([a-z0-9]{2,24}))*/g;

  return !value || regex.test(value);
}

export function creditCardNumber(errors) {
  return helpers.withParams({ errors }, (value) => {
    if (errors?.length > 0) {
      const errorsVindi = ['card_number', 'payment_company_id', 'payment_company_code'];
      const errorsFound = errorsVindi.filter((error) => errors?.includes(error));
      return errorsFound.length === 0;
    }
    return !value || value.replace(/\s/g, '').length >= 14;
  });
}

export function creditCardExpiration(errors) {
  return helpers.withParams({ errors }, () => {
    if (errors?.length > 0) {
      const errorsVindi = ['card_expiration'];
      const errorsFound = errorsVindi.filter((error) => errors?.includes(error));
      return errorsFound.length === 0;
    }
    return true;
  });
}

export function creditCardNetwork(value) {
  const creditCardWithoutMask = value.replace(/\s/g, '');
  return !value || getCreditCardBrand(creditCardWithoutMask).length > 0;
}

export function creditCardPastDate(value) {
  const [month, year] = value.split('/');
  const formattedDate = new Date(`${month}/01/${year}`);
  const currentDate = new Date();
  return !value || formattedDate > currentDate;
}

export function creditCardCVV(errors) {
  return helpers.withParams({ errors }, () => {
    if (errors?.length > 0) {
      const errorsVindi = ['card_cvv'];
      const errorsFound = errorsVindi.filter((error) => errors?.includes(error));
      return errorsFound.length === 0;
    }
    return true;
  });
}

export function containsUpperAndLowercase(value) {
  return !value || (containsLowercase(value) && containsUppercase(value));
}

export function hasEmoji(value) {
  return !value || !emojiRegex().test(value);
}
