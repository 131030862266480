import { axios } from '@/plugins';

const updateLinks = ({ commit }, payload) => {
  commit('SET_LINKS', payload);
};

const fetchOperator = async ({ getters, commit, dispatch }) => {
  try {
    const url = getters.getLinks.self;
    const {
      data: { data, links },
    } = await axios.get(url);
    commit('SET_OPERATOR', data.user);
    dispatch('updateLinks', links);
  } catch (err) {
    throw err;
  }
};

const sendOptinEmail = async ({ getters }) => {
  try {
    const url = getters?.getLinks?.mfaOptin;
    await axios.get(url);
  } catch (err) {
    throw err;
  }
};

const createMultiFactorSettings = async ({ getters, commit }, payload) => {
  try {
    const url = getters?.getLinks?.mfaOperator;
    const { data } = await axios.post(url, payload);
    commit('SET_BACKUP_CODES', { backupCodes: data?.mfa?.backupCodes });
  } catch (err) {
    throw err;
  }
};

const deleteMultiFactorSettings = async ({ getters }) => {
  const url = _.get(getters, 'getLinks.mfaOperator');
  await axios.delete(url);
};

const resetMultiFactorSettings = async ({ getters, commit }) => {
  const url = _.get(getters, 'getLinks.mfaOperatorReset');
  const { data } = await axios.patch(url);
  commit('SET_LINKS', { setupUrl: _.get(data, 'mfa.setupUrl') });
};

const updateOperator = async ({ getters, commit }, payload) => {
  const { user, type } = payload;
  try {
    const url = type === 'password' ? getters.getLinks.password : getters.getLinks.self;
    const {
      data: { data },
    } = await axios.patch(url, { user });
    commit('SET_OPERATOR', data.user);
  } catch (err) {
    throw err;
  }
};

const forgotPassword = async ({ getters }, payload) => {
  try {
    const requestUrl = _.get(getters, 'getLinks.forgotPassword');
    await axios.post(requestUrl, payload);
  } catch (err) {
    throw err;
  }
};

export default {
  updateLinks,
  fetchOperator,
  createMultiFactorSettings,
  deleteMultiFactorSettings,
  resetMultiFactorSettings,
  updateOperator,
  forgotPassword,
  sendOptinEmail,
};
