import * as PDFJSLib from 'pdfjs-dist/legacy/build/pdf';
import * as PDFJSViewer from 'pdfjs-dist/legacy/web/pdf_viewer';
import { TextLayerMode } from 'pdfjs-dist/lib/web/ui_utils';
import 'pdfjs-dist/legacy/web/pdf_viewer.css';

import { onMounted, unref, computed, watch } from 'vue';
import { resolveUnref, useResizeObserver } from '@vueuse/core';
import { sentry } from '@/plugins/sentry';
import { PDF_SCALES } from '@base/constants';

// eslint-disable-next-line import/no-unresolved
import PDFJSWorker from '@base/workers/pdfjs-wrapper.worker?worker';

const { AnnotationMode } = PDFJSLib;
let pdfWorkerInstance = null;

const PDF_CONFIG = {
  scale: PDF_SCALES.AUTO,
  minZoomThreshold: 10,
};

export const usePDFJS = (documentUrl, container, options) => {
  const {
    initialScale = PDF_CONFIG.scale,
    resizeByWindow = false,
    removeBorders = false,
    throwOnError = true,
    onPageChange,
    onPageInit,
    onDocumentLoad,
    onScaleChange,
    onPagesLoad,
  } = options;

  const eventBus = new PDFJSViewer.EventBus();
  let pdfViewer = null;

  const containerToResize = computed(() =>
    resizeByWindow ? document.body : resolveUnref(container)
  );

  pdfWorkerInstance = pdfWorkerInstance || new PDFJSWorker();
  PDFJSLib.GlobalWorkerOptions.workerPort = pdfWorkerInstance;

  function initPdfViewer() {
    const pdfLinkService = new PDFJSViewer.PDFLinkService({ eventBus });

    /**
     * references: @link https://github.com/mozilla/pdf.js/blob/v2.14.305/web/base_viewer.js#L83
     */
    pdfViewer = new PDFJSViewer.PDFViewer({
      container: unref(container),
      textLayerMode: TextLayerMode.ENABLE,
      annotationMode: AnnotationMode.ENABLE,
      linkService: pdfLinkService,
      eventBus,
      removePageBorders: unref(removeBorders),
    });

    pdfLinkService.setViewer(pdfViewer);
  }

  function resetZoom() {
    pdfViewer.currentScaleValue = initialScale;
  }

  function increaseZoom() {
    pdfViewer.increaseScale();
  }

  function decreaseZoom() {
    pdfViewer.decreaseScale();
  }

  function handlePagesLoaded(data) {
    resetZoom();
    onPagesLoad?.(data?.pagesCount);
  }

  async function loadPdf() {
    const pdfUrl = unref(documentUrl);
    const loadingTask = PDFJSLib.getDocument({ url: pdfUrl, isEvalSupported: false });

    try {
      const pdfDocument = await loadingTask.promise;

      pdfViewer.setDocument(pdfDocument);
    } catch (error) {
      sentry.captureException(error, (scope) => {
        scope.setTag('pdfjs', true);
        scope.setContext('pdfjs', { pdfUrl });

        return scope;
      });

      if (throwOnError) throw error;
    }

    onDocumentLoad?.();
  }

  // Defined in https://github.com/mozilla/pdf.js/blob/v2.14.305/web/base_viewer.js#L798
  eventBus.on('pagesloaded', handlePagesLoaded);

  // Defined in https://github.com/mozilla/pdf.js/blob/v2.14.305/web/base_viewer.js#L411
  if (onPageInit) eventBus.on('pagesinit', onPageInit);
  if (onPageChange) eventBus.on('pagechanging', onPageChange);
  if (onScaleChange) eventBus.on('scalechanging', onScaleChange);

  onMounted(async () => {
    initPdfViewer();
    await loadPdf();
  });

  watch(documentUrl, () => loadPdf());
  useResizeObserver(containerToResize, resetZoom);

  return {
    resetZoom,
    increaseZoom,
    decreaseZoom,
  };
};
