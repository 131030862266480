export default {
  getBrand: (state) => state.brand,
  getDocuments: (state) => state.documents,
  getViewableDocuments: (state) => state.viewableDocuments,
  hasRefusableDocuments: (state) => state.documents.some((doc) => doc.refusable),
  isBatch: (state) => state.viewableDocuments.length > 1 || state.hasSummary,
  getDocumentsSize: (state) => state.documentsSize,
  fromEnvelope: (state) => state.fromEnvelope,
  getSigner: (state) => state.signer,
  getLocale: (state) => state.locale,
  getLinks: (state) => state.links,
  isEmbedded: (state) => state.embedded,
  getSignerDashboardUrl: (state) => {
    const link = state.links.signerDashboard;
    const isWidgetEmbedded = state.embedded;
    const isFromSignerArea =
      new URL(window.location.href).searchParams.has('from_signer_area') || state.fromSignerArea;
    return isFromSignerArea && !isWidgetEmbedded ? link : '';
  },
  getTokenInfo: (state) => state.tokenInfo,
  getSummary: (state) => state.hasSummary,
  getEnvironment: (state) => state.environment,
  getPhotoEvidences: (state) => state.photoEvidences,
  getSignableDocumentsCount: (state) => state.signableDocumentsCount,
  getClauses: (state) => state.clauses,
  hasClauses: (state) => state.hasClauses,
  hasReadReceipts: (state) => state.hasReadReceipts,
  getReadReceipts: (state) => state.readReceipts,
  hasSeals: (state) => state.hasSeals,
  hasRubrics: (state) => state.hasRubrics,
  getRubrics: (state) => state.rubrics,
  getSeals: (state) => state.seals,
  getServerCurrentTime: (state) => state.serverCurrentTime,
  getBatchKey: (state) => state.batchKey,
  getCallToActionEnabled: (state) => state.callToActionEnabled,
  getPagination: (state) => state.pagination,
  getVivoEnabled: (state) => state.vivoEnabled,
  getDocumentsCount: (state) => state.documentsCount,
  getSignedDocumentsCount: (state) => state.signedDocumentsCount,
  getPendingDocumentsCount: (state) => state.pendingDocumentsCount,
  fromSignerArea: (state) =>
    new URL(window.location.href).searchParams.has('from_signer_area') || state.fromSignerArea,
  getFacematchCheckStatusTimeout: (state) => state.facematchCheckStatusTimeout,
  getCafEvidences: (state) => state.cafEvidences,
  getLivenessKey: (state) => state.livenessKey,
};
