const account = {
  emailVerificationModal: {
    title: 'Para avançar, confirme seu e-mail',
    description: 'Clique no link que enviamos para {email}',
    notReceived: 'Não recebi o e-mail',
    success: 'E-mail reenviado',
    failed: 'Erro ao reenviar o e-mail. Tente novamente.',
  },
  message_template: {
    message_template_saved: 'Mensagens salvas',
    create_new_message_template: 'Criar nova mensagem',
    info: 'Crie uma mensagem para reutilizá-la, ela ficará salva para usar ao enviar documentos.',
    name: 'Nome',
    enter_signer_name: 'Digite o nome do signatário',
    message: 'Mensagem',
    cancel: 'Cancelar',
    create: 'Criar',
    remove: 'Excluir',
    delete_message_template: 'Excluir mensagem salva',
    delete_message_info: 'Tem certeza que deseja excluir a mensagem abaixo?',
  },
  blocked: {
    loginLink: 'Entrar com outra conta',
    generic: {
      info: {
        title: 'Erro ao acessar {0}',
        description:
          'Esta conta pode estar com faturas pendentes de pagamento ou pode ter sido cancelada a pedido do administrador.',
        unblock: 'Entre em outra conta ou fale com o suporte para recuperar seu acesso.',
      },
      help: {
        description: 'Dúvidas? Visite nossa {0}.',
      },
    },
    overduePayment: {
      common: {
        unpaidTitle: 'A conta {accountName} foi bloqueada',
        title: 'Para recuperar agora o acesso à conta, regularize os débitos',
        subtitle:
          'Não foi possível confirmar o pagamento da fatura e, por esse motivo, a conta foi temporariamente bloqueada.',
        alert: '{email} e demais responsáveis receberam um e-mail com instruções para desbloqueio',
      },
      admin: {
        unpaidTitle: 'Pagamento não identificado',
        unpaidSubtitle:
          'Não foi possível confirmar o pagamento tentado em {0}. Para recuperar o acesso à conta {1}, regularize o débito abaixo.',
        action: 'regularize o débito abaixo',
      },
      paymentVoucher: {
        admin: {
          description: '{0}. Se a pendência já foi regularizada {1}.',
          attemptInfo: 'Seu acesso será liberado enquanto analisamos a tentativa de pagamento',
          action: 'clique aqui',
        },
        unpaid: {
          description:
            'Se o débito já foi regularizado, {0} e recupere o acesso enquanto analisamos o pagamento.',
          action: 'envie o comprovante',
        },
      },
      creditCardAlert: {
        text: 'Não se preocupe mais com atrasos e bloqueios. Na fatura, escolha pagar com cartão de crédito!',
        button: 'Pagar com cartão',
      },
      paymentAttemptModal: {
        header: 'Estamos analisando a tentativa de pagamento',
        text: 'Nossa verificação pode levar até 72 horas. O acesso à conta ficará indisponível até que o pagamento seja identificado.',
        button: 'Entendi',
      },
      needHelp: 'Dúvidas? Visite nossa {0}.',
      error: 'Ocorreu um erro ao enviar os dados, tente novamente',
      fetchInvoiceError: 'Ocorreu um erro ao buscar as faturas, tente novamente',
    },
    forcedChurn: {
      title: 'A conta {accountName} foi desativada',
      subtitle:
        'A conta foi desativada porque ainda não identificamos o pagamento dos débitos abaixo:',
      reactivation: {
        description:
          'Clicando em “Reativar agora”, as faturas serão emitidas e o acesso à conta será {0} enquanto aguardamos a confirmação do pagamento. {1}.',
        bold: 'liberado imediatamente',
        help: 'Entenda a reativação',
      },
      actions: {
        return: 'Voltar pro painel de contas',
        reactivate: 'Reativar agora',
        sendPaymentVoucher: 'Enviar comprovante',
      },
      common: {
        subtitle:
          'Não foi possível confirmar o pagamento da fatura e, por esse motivo, a conta foi desativada.',
        paymentVoucherDescription:
          'Se o débito já foi regularizado, {0} para agilizarmos o desbloqueio.',
        paymentVoucherLink: 'envie o comprovante',
      },
    },
    cancellation: {
      title: 'Conta cancelada em {date}',
      info: 'Se você mudar de ideia, tem até {date} para reativá-la. Ao final deste prazo, a conta será excluída definitivamente.',
      helpText: 'Precisa de ajuda? Visite nossa Central de Ajuda ou',
      support: 'entre em contato com o suporte',
      reactivateButton: 'Quero reativar a conta',
      reactivateError: 'Algo deu errado. Atualize a página e tente novamente.',
      notAdmin: {
        text: 'Para recuperá-la, o administrador da conta deve solicitar reativação até {date}.',
        description: 'Após esta data, a conta será excluída definitivamente.',
      },
      modal: {
        title: 'Deseja reativar sua conta?',
        text: 'Ao confirmar, a conta será reativada imediatamente.',
        invoiceWarning:
          'O plano contratado anteriormente será retomado e uma fatura referente à mensalidade será emitida.',
        resumedPlan: 'O plano contratado anteriormente será retomado.',
        confirmButton: 'Confirmar reativação da conta',
      },
    },
    ipRange: {
      title: 'Seu IP não está autorizado a acessar a conta {0}',
      subtitle: 'Entre em contato com o administrador desta conta para obter acesso',
      actions: {
        returnToAccounts: 'Voltar para escolha de contas',
      },
      help: 'Precisa de ajuda? Visite nossa {0} ou {1}.',
    },
  },
  denied: {
    title: 'Sua conta foi temporariamente suspensa',
    description:
      'Detectamos atividades nesta conta que violam nossos {0}. Por isso, a suspendemos e pausamos os documentos em processo de assinatura, como forma de precaução.',
    complement:
      'Contate nosso time de suporte se desejar reativá-la. Caso o contato não ocorra, a conta poderá ser bloqueada definitivamente.',
    action: 'Falar com o suporte',
    logout: 'Sair da conta',
  },
  chooseAccount: {
    title: 'O que deseja acessar?',
    accountType: 'Conta',
  },
  emailValidation: {
    step: 'Passo 2 de 2',
    title: 'Agora é só confirmar seu e-mail',
    description:
      'Por segurança, enviamos um pedido de confirmação para o seu e-mail. Verifique sua caixa de entrada e confirme para ver e assinar seus documentos.',
    inputLabel: 'E-mail atual',
    inputPlaceholder: 'email.informado@empresa.com',
    help: 'Saiba mais sobre sua área',
    success: 'E-mail reenviado',
    failed: 'Erro ao reenviar o e-mail. Tente novamente.',
    actions: {
      resend: 'Reenviar',
    },
    timer: {
      message: 'Você poderá reenviar o e-mail em {0}',
    },
  },
  getStarted: {
    title: 'Tudo pronto para enviar seu primeiro documento. Vamos começar?',
    goToNewEnvelope: 'Sim, vamos lá',
    skip: 'Pular essa etapa',
  },
};

export default account;
