export default {
  links: {},
  consumption: {},
  plans: [],
  paymentProfile: {},
  vindiErrors: [],
  invoices: [],
  invoicesPagination: {},
  consumptionFromMidas: {},
  cards: [],
  creditCardPagination: {
    page: 1,
    perPage: 10,
    pageCount: 1,
  },
  billingInfo: {},
  productUsages: [],
  periodUsages: {},
  // TOGGLE_MIDAS_SCOPE
  // Remove consumptionInfo state when scope >= 3
  consumptionInfo: {},
  negotiation: {},
};
