const location = {
  title: 'Ver ubicación compartida',
  desc: 'Proporciona las coordenadas en el dispositivo electrónico para ver la posición en el mapa',
  latitude: 'Latitud',
  longitude: 'Longitud',
  button: 'Ver ubicación',
  helpLink: '¿Dónde encontrar esta información?',
  secureEnvironment: 'Ambiente seguro de Clicksign',
};

export default location;
