const error500 = {
  name: 'Erro 500',
  title: 'Oops! Erro inesperado.',
  subtitle: 'Por favor, atualize sua página ou tente novamente mais tarde.',
  firstParagraph: {
    content:
      'Se essa mensagem continuar, pedimos que volte à {0} e tente entrar de novo. Caso prefira, você também pode conferir nossa {1} para saber se estamos com alguma indisponibilidade em nossos sistemas.',
    homePage: 'página inicial',
    statusPage: 'página de status',
  },
};

export default error500;
