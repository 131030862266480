import { axios } from '@/plugins';

const getMessageTemplates = async ({ commit }, url) => {
  const {
    data: { messageTemplates, links },
  } = await axios.get(url);

  commit('SET_MESSAGE_TEMPLATES', messageTemplates);
  commit('SET_LINKS', links);
};

const setMessageTemplate = async ({ getters }, { messageTemplate }) => {
  await axios.post(getters.getLinks.self, { messageTemplate });
};

const deleteMessageTemplate = async ({ commit }, { id, url }) => {
  await axios.delete(url);

  commit('DELETE_MESSAGE_TEMPLATE', id);
};

export default {
  getMessageTemplates,
  setMessageTemplate,
  deleteMessageTemplate,
};
