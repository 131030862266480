const location = {
  title: 'Visualizar localização compartilhada',
  desc: 'Informe as coordenadas no dispositivo eletrônico para ver a posição no mapa',
  latitude: 'Latitude',
  longitude: 'Longitude',
  button: 'Visualizar localização',
  helpLink: 'Onde encontrar essas informações?',
  secureEnvironment: 'Ambiente seguro Clicksign',
};

export default location;
