import { getSentryEnviroment } from '@base/utils/get-sentry-enviroment';

export default {
  appVersion: document.documentElement.getAttribute('app-version'),

  isLegacy: import.meta.env.LEGACY,
  isDev: import.meta.env.DEV,

  sentry: {
    dsn: process.env.SENTRY_FRONT_DSN,
    enviroment: getSentryEnviroment(window.location),
  },
};
