export default {
  SET_LINKS: (state, payload) => {
    state.links = { ...state.links, ...payload };
  },
  SET_BATCHES: (state, payload) => {
    state.batches = payload || [];
  },
  ADD_BATCHES: (state, payload) => {
    state.batches = _.uniqBy([...state.batches, ...payload], 'key');
  },
  SET_NOTIFICATIONS: (state, payload) => {
    state.notifications = payload ?? {};
  },
};
