const errorGeneral = {
  firstParagraph: {
    content: 'Fique calmo! Você pode explorar a nossa {0} ou fazer {1}.',
    homePage: 'página inicial',
    login: 'login',
  },
  secondParagraph: {
    content: 'Precisa de ajuda? Visite nossa {0}  ou envie um e-mail para {1}.',
    helpCenter: 'central de ajuda',
  },
};

export default errorGeneral;
