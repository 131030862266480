const refusalSignature = {
  refusalFeedback: {
    title: 'Document refused',
    subtitle: 'We send your response to the sender of the document.',
  },
  refusalModal: {
    title: 'Refuse to sign',
    report: 'Report an issue',
    description: 'Refuse the signature to report the issue to the sender',
    select: 'Reason for refusal',
    textareaLabel: 'Comment here with your words',
    textareaPlaceholder: "I don't want to sign because...",
    checkbox: 'I understand that this action is irreversible',
    button: 'Refuse',
    selectItems: {
      content: 'Document content',
      userData: 'Incorrect personal data',
      authType: 'Authentication form',
      signature: 'Use of eletronic signature',
      location: 'Location permission',
      other: 'Another reason',
      incorrectScheduling: 'Incorrect scheduling',
      incorrectCartItens: 'Incorrect cart itens',
      incorrectAddressDetails: 'Incorrect address details',
      notWishPerformCapture: 'I do not wish to perform the capture',
    },
    authsPlaceholder: 'Specify authentication',
    auths: {
      api: 'Api',
      email: 'e-mail',
      pix: 'Pix',
      icpBrasil: 'Digital certificate',
      sms: 'SMS',
      selfie: 'Picture of face with official document',
      handwritten: 'Handwritten signature',
      officialDocument: 'Official document photo',
      liveness: 'Dynamic selfie',
      facialBiometrics: 'Facial biometrics',
      whatsapp: 'WhatsApp',
      locationRequired: 'Location',
      voiceBiometrics: 'Voice biometrics',
    },
    form: {
      error: 'An error has occurred when submit the form',
      success: 'Form has been submitted successfully',
    },
    refusableSelectedDocuments: 'You can refuse {refusable} of {selected} documents.',
    seeDocuments: 'See which ones',
  },
  options: {
    download: 'Download original document',
    refusal: 'Refuse to sign',
  },
};

export default refusalSignature;
