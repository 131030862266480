import { timestamp } from '@vueuse/core';
import { camelCase, uniqueId } from 'lodash';

import { CHAT } from '@ai/constants';

const getMessageTypeProperty = (type) => ({ [camelCase(`is-${type}-type`)]: true });

export const useChatMessage = (message, options) => {
  const uniqueTimestamp = options?.timestamp ?? uniqueId(timestamp().toString());

  const role = options?.role ?? CHAT.ROLES.USER;
  const type = options?.type ?? CHAT.TYPES.MESSAGE;

  const getMessages = () => message.trim().split('\n');

  return {
    ...getMessageTypeProperty(type),
    timestamp: uniqueTimestamp,
    role,
    type,
    message,
    getMessages,
    formData: options?.formData,
  };
};
