import { maskText } from '@base/utils/mask';
import Vue from 'vue';

const maskPlugin = {
  install(_Vue) {
    /* eslint-disable */
    _Vue.$mask = maskText;
    _Vue.prototype.$mask = maskText;
    /* eslint-enable */
  },
};

Vue.use(maskPlugin);

export default maskPlugin;
