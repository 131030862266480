<template>
  <div :class="$style.container">
    <h4>{{ $t('flowModule.show.configInfo.title') }}</h4>
    <XCard
      elevation="low"
      :class="$style.config"
    >
      <p>
        {{ $t('flowModule.show.configInfo.deadlineIn') }} <br />
        <span :class="$style.info">
          {{ $tc('general.date.days', deadlineIn, { days: deadlineIn }) }}
        </span>
      </p>
      <p>
        {{ $t('flowModule.show.configInfo.locale') }} <br />
        <span :class="$style.info">{{ locale }}</span>
      </p>
      <p v-if="remindInterval">
        {{ $t('flowModule.show.configInfo.remindInterval') }} <br />
        <span :class="$style.info">
          {{ $tc('general.date.days', remindInterval, { days: remindInterval }) }}
        </span>
      </p>
      <p>
        {{ $t('flowModule.show.configInfo.autoClose') }} <br />
        <span :class="$style.info">
          {{ autoClose ? $t('flowModule.show.enabled') : $t('flowModule.show.disabled') }}
        </span>
      </p>
    </XCard>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FormFlowShowConfigInfo',
  props: {
    deadlineIn: {
      type: Number,
      default: 30,
    },
    remindInterval: {
      type: Number,
      default: 3,
    },
    locale: {
      type: String,
      default: 'pt-BR',
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  setup() {},
});
</script>

<style lang="scss" module>
.container {
  .config {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: var(--space-large-x);

    .info {
      color: var(--color-neutral-800);
    }
  }
}
</style>
