const validator = {
  document: {
    info: {
      title: 'Valide seu documento',
      description: 'Adicione um PDF para validar que é um documento assinado através da Clicksign',
    },
    form: {
      invalid: {
        file_type: 'Formato não permitido, por favor utilize apenas arquivos .PDF',
        file_size: 'O tamanho do arquivo ultrapassa o permitido de %{file_max_size}mb',
      },
    },
    result: {
      title: 'Resultado',
      validate_at: 'Validação efetuada',
      date_time: 'Datas e horário em GMT',
      file_hash: 'Hash do arquivo validado',
      signed: 'Documento assinado eletronicamente através da Clicksign.',
      invalid: 'Não foi possível validar esse arquivo.',
      icp_valid: 'Certificado ICP-Brasil válido: Clicksign Gestão de Documentos S.A.',
      more_info: 'Saiba mais',
    },
    loading: 'Validando documento',
    resend: 'Validar outro documento',
    print: 'Imprimir',
    lgpd: {
      link: 'Aviso de privacidade conforme a nova lei de LGPD',
    },
  },
};

export default validator;
