<template>
  <div :class="$style.container">
    <h5>
      {{ $t('flowModule.show.signerInfo.signer') }} {{ order }} ({{
        flowSigner
          ? $t('flowModule.show.signerInfo.flowSigner')
          : $t('flowModule.show.signerInfo.fixed')
      }})
    </h5>
    <div :class="$style.signerInfo">
      <p v-if="signer.email || signer.label">
        {{ $t('flowModule.show.signerInfo.email') }} <br />
        <span :class="$style.info">{{ flowSigner ? signer.label : signer.email }}</span>
      </p>
      <p v-if="signer.name && !flowSigner">
        {{ $t('flowModule.show.signerInfo.name') }} <br />
        <span :class="$style.info">{{ signer.name }}</span>
      </p>
      <p>
        {{ $t('flowModule.show.signerInfo.foreignerSigner') }} <br />
        <span :class="$style.info">
          {{ $t(`general.action.${signer.hasDocumentation ? 'no' : 'yes'}`) }}
        </span>
      </p>
      <p v-if="signer.phoneNumber">
        {{ $t('flowModule.show.signerInfo.phoneNumber') }} <br />
        <span :class="$style.info">{{ signer.phoneNumber }}</span>
      </p>
      <p v-if="signer.birthday">
        {{ $t('flowModule.show.signerInfo.birthday') }} <br />
        <span :class="$style.info">{{ momentFormatDate(signer.birthday) }}</span>
      </p>
      <p>
        {{ $t('flowModule.show.signerInfo.send') }} <br />
        <span :class="$style.info">{{ $t(`signer.communicateBy.${signer.communicateBy}`) }}</span>
      </p>
      <p v-if="signer.auths.length > 0">
        {{ $t('flowModule.show.signerInfo.auth') }} <br />
        <span :class="$style.info">{{ getAuths }}</span>
      </p>
      <p>
        {{ $t('flowModule.show.signerInfo.refusable') }} <br />
        <span :class="$style.info">
          {{ $t(`general.action.${signer.refusable ? 'yes' : 'no'}`) }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { momentFormatDate } from '@/utils/date';
import { useI18n } from '@/locales';
// TOGGLE_FLOW_WITH_FLEXIBLE_AUTH_ENABLED
// TODO: delete useToggle import
import { useToggle } from '@base/hooks';

export default defineComponent({
  name: 'FormFlowShowSignerInfo',
  props: {
    signer: {
      type: Object,
      default: () => ({}),
    },
    order: {
      type: Number,
      default: 1,
    },
    flowSigner: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();

    // TOGGLE_FLOW_WITH_FLEXIBLE_AUTH_ENABLED
    // TODO: delete toggle
    const { isEnabled: toggleFlowWithFlexibleAuthEnabled } = useToggle(
      'toggleFlowWithFlexibleAuthEnabled'
    );

    const getAuths = computed(() => {
      // TOGGLE_FLOW_WITH_FLEXIBLE_AUTH_ENABLED
      // TODO: delete from condition
      if (toggleFlowWithFlexibleAuthEnabled.value && _.isArray(props.signer.auths)) {
        return props.signer.auths
          .map((auth) => t(`document.list.signer.auths.${_.camelCase(auth)}`))
          .join(', ');
      }
      let authsText = t(`signer.auths.${_.camelCase(props.signer.auths[0])}`);
      const auths = ['handwritten', 'liveness', 'officialDocument', 'selfie', 'facialBiometrics'];
      auths.forEach((auth) => {
        if (props.signer[`${auth}Enabled`]) authsText += `, ${t(`signer.auths.${auth}`)}`;
      });
      return authsText;
    });

    return {
      getAuths,
      momentFormatDate,
    };
  },
});
</script>

<style lang="scss" module>
.container {
  .signerInfo {
    display: grid;
    grid-template-columns: 1fr;

    .info {
      color: var(--color-neutral-800);
    }

    @include breakpoint('small') {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint('medium') {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
</style>
