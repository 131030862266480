import messageTemplate from './message-template';

const accountModule = {
  messageTemplate,
  chooseAccount: {
    title: '¡Hola, {name}!',
    subtitle: '¿Qué deseas hacer hoy?',
    accountType: 'Cuenta',
    signerAreaType: 'Firmar documentos',
    signerArea: 'Área de firma',
    signersCard: {
      title: 'Firmar documentos',
      info: 'No tienes firmas pendientes por ahora',
      pendingSignatures: 'firma pendiente | firmas pendientes',
      button: 'Ingresa al área de firma',
    },
    operatorCard: {
      title: 'Enviar documentos',
      info: 'Prueba gratis durante 14 días todo lo que Clicksign tiene para ofrecer.',
      link: '¿Cómo crear una nueva cuenta?',
      hasAccount: {
        info: 'Ingresa a tu cuenta para enviar y rastrear tus documentos y aceptaciones',
      },
      contentList: [
        'Firmas digitales y electrónicas',
        'Envíos vía WhatsApp, SMS o correo electrónico',
        'Diversos tipos de Autenticación',
        'Gestión de documentos y aceptaciones',
      ],
      button: 'Crear cuenta',
    },
  },
  onboarding: {
    firstStep: {
      title: '¿Qué deseas hacer?',
      signerDescription: 'Firmar un documento que recibí',
      operatorDescription: 'Probar el envío de documentos',
    },
    accountStep: {
      step: 'Paso 1 de 2',
      title: 'Información de tu cuenta',
      personal: 'Persona Física',
      business: 'Persona Moral',
      company: {
        label: 'Nombre de la empresa',
        placeholder: 'Introduce el nombre de la empresa',
      },
      cpf: {
        label: 'CPF',
      },
      birthday: {
        label: 'Fecha de nacimiento',
      },
      phoneNumber: {
        label: 'Teléfono',
      },
      segment: '¿En qué sector trabajas?',
      documentsAmount: '¿Cuántos documentos deseas enviar por mes?',
      reason: '¿Por qué decidiste crear una cuenta?',
      selectPlaceholder: 'Selecciona...',
      button: 'Continuar',
      loadError: 'Ocurrió un error al cargar la página, intenta nuevamente',
      saveError: 'Error al guardar la información. Intenta nuevamente.',
    },
    additionalDataStep: {
      title: 'Ayuda a mejorar tu experiencia, {userFirstName}',
      segment: '¿En qué sector trabajas?',
      documentsAmount: '¿Cuántos documentos deseas enviar por mes?',
      reason: '¿Por qué decidiste crear una cuenta?',
      selectPlaceholder: 'Selecciona...',
      button: 'Continuar',
      loadError: 'Ocurrió un error al cargar la página, intenta nuevamente',
      saveError: 'Error al guardar la información. Intenta nuevamente.',
      step: 'Paso 2 de 2',
    },
    creatingAccount: {
      text: 'Creando cuenta en 3, 2, 1...',
    },
    signOut: 'Cerrar sesión',
  },
  settings: {
    businessTitle: 'Configuraciones de la empresa: {0}',
    personTitle: 'Configuraciones: {0}',
  },
  documentsAmount: {
    until5: 'Hasta 5 documentos',
    from6to20: 'Entre 6 y 20 documentos',
    from21to60: 'Entre 21 y 60 documentos',
    from61to100: 'Entre 61 y 100 documentos',
    from101to500: 'Entre 101 y 500 documentos',
    above500: 'Más de 500 documentos',
  },
  profile: {
    title: 'Información',
    action: 'Guardar cambios',
    success: 'Información actualizada con éxito',
    error: 'No fue posible guardar los cambios de la cuenta.',
    fetchError: 'Hubo un problema al obtener los datos, intenta nuevamente.',
    blockMessage: 'Solo el administrador de la cuenta puede editar la información de registro.',
    avatar: {
      action: 'Editar perfil',
    },
    account: {
      nameInfo: 'Nombre que se muestra en la plataforma y en los correos electrónicos enviados',
      segment: 'Sector de actividad económica',
      contact: 'Forma de contacto preferida',
      phone: 'Teléfono',
      whatsapp: 'WhatsApp',
      placeholderPhone: '(__) ____-____',
      email: 'Correo electrónico',
      business: {
        type: 'Persona Moral',
        name: 'Nombre de la empresa',
        cnpj: 'CNPJ',
        placeholderCnpj: '__.___.___/____-__',
        tradingName: 'Razón social',
        employeesAmount: 'Número de empleados',
      },
      personal: {
        birthday: 'Fecha de nacimiento',
        type: 'Persona Física',
        name: 'Nombre completo',
        cpf: 'CPF',
        placeholderCpf: '___.___.___-__',
        placeholderBirthday: 'dd/mm/aaaa',
      },
    },
    sender: {
      title: 'Remitente personalizado',
      text: 'Permite personalizar el remitente de los correos electrónicos enviados por Clicksign.',
      curSender: 'Remitente actual',
      helpEmail: 'firma@clicksign.com',
      changeSender: 'Solicitar cambio',
      more: 'Más información',
    },
    warn: {
      title: 'Cambiar tipo de cuenta',
      text: '¿Deseas cambiar el tipo de tu cuenta a {type}?',
      action: 'Cambiar',
      close: 'Cerrar',
    },
    billing: {
      form: {
        title: 'Actualizar datos de facturación',
        success: 'Datos actualizados con éxito',
      },
    },
  },
  branding: {
    title: 'Marca y colores',
    subtitle:
      'Se mostrarán en el encabezado de tu cuenta, en los correos electrónicos y en las páginas de firma.',
    helpLink: 'Recomendaciones para personalización',
    sendLogoText: 'Comienza seleccionando tu logotipo',
    headerText: 'Encabezado',
    buttonText: 'Botón',
    backgroundColor: 'Color de fondo',
    textColor: 'Color del texto',
    reset: 'Restablecer',
    finish: 'Guardar cambios',
    loadError: 'Ocurrió un error al cargar la página, inténtalo nuevamente',
    saveError: 'Ocurrió un error al guardar los datos, inténtalo nuevamente.',
    saveSuccess: 'Tus configuraciones de personalización se han guardado.',
    contrastAlert: {
      title: 'El botón es ilegible. Usa colores que contrasten.',
      text: 'Ajusta los colores del botón para garantizar una mejor lectura.',
      link: 'Ver ejemplos',
    },
    brandingPreview: {
      title: 'Vista previa',
      emailSender: 'Correo enviado por: {0}',
      altImage: 'Logotipo de la empresa',
      altClicksignLogo: 'Logotipo de Clicksign',
      signRequest: 'Solicitud de firma de {accountName}',
      button: 'Botón de ejemplo',
    },
    brandingUploader: {
      brandingImageAlt: 'Logotipo cargado',
      logoUploadButton: 'Seleccionar tu logotipo',
      logoHintSize: 'Tamaño ideal: 240x120 píxeles',
      logoHintFileType: 'Formatos aceptados: jpeg o png',
      tooltip: 'Eliminar',
      sizeError: 'Error al cargar la imagen. Elige un archivo de menos de 2 MB.',
      typeError: 'Error al cargar la imagen. Elige un archivo JPEG o PNG.',
    },
  },
  customSenderModal: {
    request: {
      header: 'Remitente personalizado',
      description:
        'Con esta funcionalidad, los correos electrónicos enviados por Clicksign tendrán como remitente un correo de tu empresa, por ejemplo, contacto@tuempresa.com.',
      infoText: 'Antes de solicitar, es importante saber:',
      bullets: {
        item1: 'Debes tener un dominio corporativo. Ejemplo: email@tuempresa.com.',
        item2:
          'No se pueden usar correos con dominios genéricos. Ejemplo: @gmail.com; o @outlook.com.',
        item3: 'Esta personalización no incluye el correo de respuesta.',
      },
      checkBox: 'Confirmo que he leído y entendido la información anterior',
      moreInfo: 'Más información',
      button: 'Solicitar ahora',
    },
    error: 'Hubo un error al solicitar el remitente personalizado. Inténtalo nuevamente.',
    success: {
      header: 'Solicitud recibida',
      description:
        'Nos pondremos en contacto a través del correo electrónico {0} con los próximos pasos para activar el remitente personalizado.',
      button: 'Entendido',
    },
  },
  limitExceddedInTrialModal: {
    header: {
      reachLimit: 'Has alcanzado el límite de documentos en el período de prueba',
      formToRequest: 'Habla con un especialista',
      success: 'Solicitud realizada',
    },
    title: {
      createDocumentReachLimit: 'Límite alcanzado',
      whatappAcceptanceReachLimit: 'Límite de aceptaciones alcanzado',
      formToRequest: 'Habla con un especialista',
    },
    subtitle: {
      formToRequest: 'Envía tus datos para que nos pongamos en contacto',
    },
    message: {
      reachLimit: {
        firstParagraph:
          'Ya tienes {limit} envíos en curso. Completa los procesos no finalizados accediendo al área de borradores o contacta con nuestro equipo comercial para solicitar envíos adicionales hasta el final del período de prueba.',
        secondParagraph: '¿Ya elegiste el plan ideal para ti? Contrata y sigue usando Clicksign.',
      },
    },
    formLabels: {
      name: 'Nombre',
      email: 'Correo electrónico',
      accountName: 'Empresa',
      phoneNumber: 'Teléfono',
    },
    actions: {
      openPlansLink: 'Contratar un plan',
      requestMoreDocuments: 'Solicitar envíos adicionales',
      talkToSpecialist: 'Habla con un especialista',
    },
  },
  brand: {
    title: 'Personalizar',
  },
  memberships: {
    title: 'Usuarios',
  },
  groups: {
    title: 'Grupos',
  },
  info: {
    title: 'Información',
  },
  api: {
    title: 'API',
    error: 'Ocurrió un error al cargar la página, intenta nuevamente',
    documentsBalanceAlert: {
      message:
        'Durante el período de prueba, es posible crear hasta 50 documentos vía API. Si necesitas un volumen mayor, ',
      cta: 'prueba en el entorno Sandbox',
    },
    buttons: {
      goToSandbox: 'Ir a Sandbox',
      goToApiDocumentation: 'Ver la documentación',
      saveEmail: 'Guardar correo electrónico',
      createAccessToken: 'Generar Access Token',
      createWebhook: 'Agregar Webhook',
      generate: 'Generar',
      remove: 'Eliminar',
      activate: 'Activar',
      widgetEmbeddedPurchase: 'Contratar',
    },
    headers: {
      cardInfo: {
        apiDocumentation: 'Consulta la documentación y conoce nuestra API',
        sandbox: '¿Necesitas probar la integración? Ingresa al entorno Sandbox',
      },
      email: {
        title: 'Correo del usuario de la API',
        subtitle: 'El correo aparecerá en los eventos de los documentos creados vía API.',
        selectPlaceholder: 'Selecciona un usuario',
      },
      accessToken: {
        title: 'Token de acceso',
        subtitle: 'Es Necesario para realizar la autenticación en la API de Clicksign.',
        link: 'Más información sobre el Token de acceso',
      },
      widget: {
        title: 'Clicksign en tu sitio web',
        subtitle:
          'Widget incrustado para incorporar el proceso de firma en tu sitio web o aplicación. Costo adicional de R$ 200 por mes.',
        link: 'Más información sobre Clicksign en tu sitio web',
        status: {
          enabled: 'Habilitado',
          waiting: 'Esperando',
        },
      },
      webhooks: {
        title: 'Webhooks',
        subtitle: 'Comunican eventos ocurridos en los documentos a una aplicación externa.',
        link: 'Más información sobre webhooks',
      },
      presentialSignature: {
        title: 'Firma presencial',
        subtitle:
          'Función de firma presencial. Ideal para uso en terminales de autoservicio, tablets y similares.',
        link: 'Más información sobre firma presencial',
      },
    },
    notification: {
      success: {
        saveEmail: 'Correo guardado con éxito',
        createAccessToken: 'Token de acceso generado con éxito',
        removeAccessToken: 'Token de acceso eliminado con éxito',
        createHook: 'Webhook añadido con éxito. Consulta el HMAC SHA256 Secret abajo',
        editHook: 'Webhook editado con éxito',
        removeHook: 'Webhook eliminado con éxito',
        activateHook: 'Webhook activado con éxito',
      },
      error: {
        saveEmail: 'Error al guardar el nuevo correo',
        createAccessToken: 'Error al crear el token de acceso',
        removeAccessToken: 'Error al eliminar el token de acceso',
        createHook: 'Error al agregar el webhook',
        removeHook: 'Error al eliminar el webhook',
        editHook: 'Error al editar el webhook',
        activateHook: 'Error al activar el webhook',
      },
    },
    tokenInfo: {
      title: 'Token {count}',
      description: 'Descripción',
      accessToken: 'Token de acceso',
      lastOperation: 'Última uso',
      noLastOperation: 'Nunca ha sido utilizado',
    },
    hookInfo: {
      url: 'URL del Webhook',
      secret: 'HMAC SHA256 Secret',
      events: 'Eventos',
      status: 'Estatus',
      active: 'Habilitado',
      disabled: 'Deshabilitado',
    },
    modal: {
      createAccessToken: {
        title: 'Generar token de acceso',
        label: 'Descripción del token de acceso',
        placeholder: 'Ejemplo: token_misistema',
        description: 'Úsalo para indicar dónde será utilizado',
      },
      removeAccessToken: {
        title: '¿Deseas eliminar este token de acceso?',
        text: 'Esta acción no se puede deshacer.',
      },
      webhook: {
        selectAllEvents: 'Seleccionar todos los eventos',
        title: 'Webhooks',
        label: 'Dirección',
        placeholder: 'https://example.com/postreceive',
      },
      removeWebhook: {
        title: '¿Deseas eliminar este Webhook?',
        text: 'Esta acción no se puede deshacer.',
      },
      widgetEmbedded: {
        header: 'Contratar Widget incrustado',
        title: 'Mejora tu plan con el Widget incrustado',
        subtitle: 'Ingresa tus datos y nos pondremos en contacto',
        name: 'Nombre',
        namePlaceholder: 'Nombre del cliente',
        email: 'Correo electrónico',
        emailPlaceholder: 'emailcliente@ejemplo.com',
        company: 'Empresa',
        companyPlaceholder: 'Empresa del cliente',
        phone: 'Número de teléfono',
        phonePlaceholder: '(  ) _____-____',
        waitTitle: 'Información enviada',
        waitText: 'Nuestro equipo se pondrá en contacto en las próximas 24 horas.',
      },
      webhookTable: {
        active: 'Activar',
        event: 'Evento',
        description: 'Descripción',
        events: {
          upload: 'Ocurre cuando se realiza la carga de un documento.',
          addSigner: 'Ocurre cuando se añaden firmantes a un documento.',
          removeSigner: 'Ocurre cuando se eliminan firmantes de un documento.',
          sign: 'Ocurre cuando un firmante firma un documento.',
          close: 'Ocurre cuando un documento se finaliza manualmente.',
          autoClose:
            'Ocurre cuando un documento se finaliza automáticamente justo después de la última firma.',
          deadline:
            'Ocurre cuando se alcanza la fecha límite para la firma de un documento. Si el documento contiene al menos una firma, se finaliza. De lo contrario, el documento se cancela.',
          documentClosed: 'Ocurre cuando el documento firmado está listo para descargar.',
          cancel: 'Ocurre cuando un documento se cancela manualmente.',
          updateDeadline: 'Ocurre cuando se cambia la fecha límite para la firma de un documento.',
          updateAutoClose:
            'Ocurre cuando se cambia la opción de finalización automática de un documento.',
          addImage: 'Ocurre cuando se realiza un evento personalizado de imagen para un documento.',
          updateLocale: 'Ocurre cuando se cambia el idioma de un documento.',
          refusal: 'Ocurre cuando se rechaza un documento.',
          custom:
            'Ocurre cuando se realiza un evento personalizado de token (correo electrónico, sms) para un documento.',
          acceptanceTermEnqueued:
            'Ocurre cuando un "Aceptación vía WhatsApp" está en la fila para su envío (el destinatario ya tiene un Aceptación en proceso).',
          acceptanceTermSent: 'Ocurre cuando se envía una "Aceptación vía WhatsApp".',
          acceptanceTermCompleted:
            'Ocurre cuando una "Aceptación vía WhatsApp" se finaliza con éxito.',
          acceptanceTermRefused:
            'Ocurre cuando una "Aceptación vía WhatsApp" es rechazada (cerrada por el destinatario).',
          acceptanceTermCanceled:
            'Ocurre cuando una "Aceptación vía WhatsApp" es cancelada por el remitente.',
          acceptanceTermExpired:
            'Ocurre cuando una "Aceptación vía Whatsapp" expira (se alcanza el tiempo límite para la aceptación).',
          acceptanceTermError:
            'Ocurre cuando hay un error interno en la funcionalidad con Clicksign o con WhatsApp.',
          attemptsByWhatsappExceeded:
            'Ocurre cuando se exceden los intentos de reenviar el Token vía WhatsApp.',
          attemptsByLivenessOrFacematchExceeded:
            'Ocurre cuando se exceden los intentos de autenticación vía Selfie dinámica o Biometría facial.',
          livenessRefused: 'Ocurre cuando el análisis de la Selfie dinámica es rechazado.',
          facematchRefused: 'Ocurre cuando el análisis de la Biometría facial es rechazado.',
          documentscopyRefused: 'Ocurre cuando el análisis de la Documentoscopia es rechazado.',
          biometricRefused: 'Ocurre cuando el análisis de la Biometría facial SERPRO es rechazado.',
        },
      },
    },
  },
  consumption: {
    title: 'Plan y Facturación',
  },
  betaProgram: {
    title: 'Programa Beta',
  },
  saml: {
    title: 'Seguridad',
    helpLink: 'Más información sobre SSO',
    status: {
      title: 'Estatus SSO',
      enabled: 'Habilitado',
      disabled: 'Deshabilitado',
      syncing: 'Importando metadatos',
    },
    sso: {
      subtitle: 'Single Sign On (SSO)',
      samlIdentity: 'Dominio de la empresa',
      samlFile: 'Sube un archivo XML para importar metadatos',
      samlFileChoice: 'Subir archivo',
      identityProvider:
        'La identificación se genera automáticamente después de importar los metadatos',
      upload: 'Carga realizada con éxito.',
      fileTypeError: 'Sube un archivo en formato XML',
      warning: 'Ejemplo: tudominio.com',
      verifySamlIdentity: 'Verificar dominio',
      deleteSamlIdentity: 'Eliminar dominio',
      verificationInProgress: 'Verificación en progreso',
      verificationError: 'Error al iniciar la verificación. Inténtalo nuevamente.',
      verificationSuccess: 'Verificación realizada con éxito',
      verifiedDomain: 'Dominio verificado',
    },
    validateIdentityProviderModal: {
      verify: {
        title: 'Verificar dominio mediante registro DNS',
        firstStep:
          'Ingresa a tu cuenta del proveedor de nombres de dominio (goddady, Google Domains o similar)',
        secondStep: 'Agrega el registro TXT a continuación en la configuración del DNS de {domain}',
        thirdStep: 'Una vez completado el segundo paso, haz clic en “Verificar” a continuación.',
        disclaimer:
          'Asegúrate de que el registro TXT se ha agregado correctamente antes de continuar. Diferencias en el registro pueden afectar la verificación.',
        confirmButton: 'Verificar',
        cancelButton: 'Cerrar',
      },
      fail: {
        title: 'Error en la verificación de propiedad',
        firstParagraph:
          'Asegúrate de que el registro TXT se ha agregado correctamente a la configuración del DNS. Si todo está correcto, intenta verificar el dominio nuevamente después de 24 horas.',
        confirmButton: 'Volver',
        cancelButton: 'Cerrar',
      },
    },
    domainExclusionConfirmationModal: {
      title: '¿Eliminar el dominio de la empresa?',
      content:
        'Al confirmar, el Single Sign On será deshabilitado en tu cuenta. Para habilitarlo nuevamente, será necesario repetir el proceso de configuración.',
      checkbox: 'Confirmo que deseo eliminar el dominio',
      actionConfirm: 'Eliminar',
      actionCancel: 'Cancelar',
    },
    domainExcludeToast: {
      success: 'Dominio eliminado con éxito',
      error: 'Error al eliminar el dominio. Intenta nuevamente.',
    },
    provider: {
      subtitle: 'Proveedor de identidad',
      urn: 'Identificador de la Parte Confianza (URN)',
      assertionEndpoint: 'SAML Assertion Consumer Endpoint',
      assertionEndpointInfo: 'Esta es una solicitud POST',
      copy: 'Copiar',
      copied: '¡Datos copiados con éxito!',
    },
    fieldMap: {
      attribute: 'Atributo SAML | Atributo SAML {index}',
      userGroup: 'Atributo del grupo de usuarios',
      addAttribute: 'Agregar nuevo atributo',
    },
  },
  sessionSettings: {
    title: 'Tiempo de inactividad de la sesión',
    saveAction: 'Guardar cambios',
    knowMore: 'Más información sobre tiempo de inactividad de la sesión',
    timeSelect: {
      onlyMinutes: '{minutes} minutos',
      onlyHour: '{hours} hora | {hours} horas',
      hourAndMinutes: '{hours} hora y {minutes} minutos | {hours} horas y {minutes} minutos',
      default: '{hours} horas (predeterminado)',
    },
    successMessage: 'Tiempo de inactividad de la sesión configurado con éxito',
    errorMessage: 'Error al configurar el tiempo de inactividad de la sesión. Inténtalo de nuevo.',
  },
  enabledIpRange: {
    title: 'Limitación de acceso por IP',
    helpLink: 'Más información sobre la limitación de acceso',
    addNewRange: 'Agregar nuevo rango',
    emailNotify: 'Notificar por correo',
    fields: {
      name: 'Identificación de los IPs autorizados',
      address: 'IP CIDR',
    },
    placeholder: {
      name: 'Ejemplo: Sucursal Juan Pessoa',
      email: 'correo@ejemplo.com',
    },
    tooltip: {
      remove: 'Eliminar',
      emailTitle: '¿Cómo funciona?',
      emailText: 'La notificación será enviada si un IP fuera del rango intenta ingresa',
    },
    modal: {
      remove: {
        title: '¿Deseas eliminar este rango?',
        text: 'Esta acción no se puede deshacer.',
      },
    },
    notification: {
      email: {
        enabled: 'Notificación por correo habilitada',
        disabled: 'Notificación por correo deshabilitada',
      },
      error: 'Error al guardar la limitación de acceso. Inténtalo de nuevo.',
    },
  },
  modalTrialExpired: {
    title: 'Has alcanzado el límite de tiempo para la prueba',
    content: {
      subtitle: 'Límite de tiempo alcanzado',
      timeSpent:
        'Ya has aprovechado la prueba gratuita de 14 días, ¡pero no tienes que detenerte ahí!',
      choosePlan: 'Elige el plan ideal para ti y continúa usando Clicksign.',
    },
    imageAlt: 'imagen-persona-prueba-expirada',
    purchase: 'Contratar un plan',
  },
  signerArea: {
    title: 'Área del firmante',
    content: {
      text: '¡En construcción!',
      info: 'Pronto podrás visualizar y firmar aquí tus documentos pendientes',
    },
  },
};

export default accountModule;
