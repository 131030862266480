const audit = {
  title: 'Auditoria da conta',
  help: {
    beta: 'Esta funcionalidade está em fase de teste.',
    feature: 'Comece a utilizá-la para analisar atividades da sua conta.',
    link: 'Ajuda',
  },
  empty: {
    title: 'A atividade da sua conta será registrada aqui',
    text: 'Apenas usuários administradores têm acesso a essa área',
  },
  emptySearch: {
    title: 'Nenhum resultado encontrado',
    description: 'Experimente alterar o filtro para encontrar o que está procurando',
  },
  table: {
    name: 'Lote',
    size: 'Tamanho',
    action: 'Ação',
  },
  dateFilter: {
    placeholder: 'Selecione uma data...',
    button: 'Filtrar',
  },
};

export default audit;
