import Vue from 'vue';
import Vuex from 'vuex';

import featureFlags from '@/store/modules/featureFlags/main';
import widget from './widget/main';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: { widget, featureFlags },
});

export default store;
export const useStore = () => store;
