import axios from '@/plugins/axios';
import { pick } from 'lodash';
import { registerOperation } from '@envelope/services/setup/operations';

export const SETTINGS_PARAMS = [
  'remindIntervals',
  'maxDeadline',
  'deadlineAt',
  'remindInterval',
  'autoClose',
  'locale',
  'blockAfterRefusal',
];

export const UPDATE_SETTINGS_PARAMS = [
  'defaultMessage',
  'defaultSubject',
  'deadlineAt',
  'remindInterval',
  'autoClose',
  'locale',
  'blockAfterRefusal',
];

export function parseEditingSessionToSettings({ envelope }) {
  return pick(envelope, SETTINGS_PARAMS);
}

export function parseEnvelopeCompatToSettings({ pack }) {
  return pick(pack, SETTINGS_PARAMS);
}

export async function updateSettingsEditingSession({ links, settings }) {
  const response = await registerOperation({
    links: { operations: links.operations },
    name: 'update_envelope',
    data: pick(settings, UPDATE_SETTINGS_PARAMS),
  });

  return pick(response?.operation?.data, UPDATE_SETTINGS_PARAMS);
}

export async function updateSettingsEnvelopeCompat({ links, settings }) {
  const payload = {
    pack: pick(settings, UPDATE_SETTINGS_PARAMS),
  };

  const { data } = await axios.patch(links.settings, payload);
  return parseEnvelopeCompatToSettings(data);
}
