const routes = [
  {
    path: '/accounts/:accountId/dashboard',
    name: 'dashboard',
    component: () => import('@dashboard/views/DashboardView.vue'),
    meta: {
      layout: 'BaseNavigationLayout',
      additionalDataSurveyTrackingId: 'dashboard',
    },
  },
  {
    path: '/accounts/:accountId/dashboard/document_details',
    name: 'dashboardDocumentDetails',
    component: () => import('@dashboard/views/DashboardDocumentsDetailsView.vue'),
    meta: {
      layout: 'BaseNavigationLayout',
    },
  },
];

export default routes;
