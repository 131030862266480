import { ref, watchEffect } from 'vue';

export const useTimer = (secondsTime, callback, { interval = 1000, autoStart = false } = {}) => {
  const timer = ref('');
  const intervalId = ref(null);
  const minutes = ref(null);
  const seconds = ref(null);
  const timePast = ref(secondsTime);
  const isFinished = ref(false);
  const isPaused = ref(false);
  const addLeadingZero = (value) => String(value).padStart(2, 0);

  const handleTimerEnd = () => {
    callback();
    isFinished.value = true;
    clearInterval(intervalId.value);
  };

  watchEffect(() => {
    minutes.value = addLeadingZero(Math.floor(timePast.value / 60));
    seconds.value = addLeadingZero(timePast.value % 60);
    timer.value = `${minutes.value}:${seconds.value}`;
  });

  const start = () => {
    clearInterval(intervalId.value);
    isPaused.value = false;
    intervalId.value = setInterval(() => {
      if (isFinished.value) return;
      timePast.value -= 1;
      if (timePast.value <= 0) handleTimerEnd();
    }, interval);
  };

  const pause = () => {
    isPaused.value = true;
    clearInterval(intervalId.value);
  };

  const reset = (time) => {
    timePast.value = time || secondsTime;
    isFinished.value = false;
  };

  if (autoStart) start();

  return {
    timer,
    minutes,
    seconds,
    start,
    reset,
    isFinished,
    pause,
  };
};
