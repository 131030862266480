const download = {
  title: 'Document available',
  subtitle: 'Your document can now be downloaded!',
  description: 'Find out {0} and how it can help you in your daily life!',
  whatIs: 'what an electronic signature is',
  availableUntil: 'Document available until {0}',
  action: 'Download',
  help: 'Do you need any help? Visit our {0} or e-mail {1}.',
  helpCenter: 'Help Center',
  helpEmail: 'ajuda@clicksign.com',
  expired: {
    title: 'Download period expired',
    subtitle: 'This document is no longer available here.',
    description: 'Contact the sender to request the signed document',
  },
};

export default download;
