const location = {
  title: 'View shared location',
  desc: 'Enter the coordinates of the electronic device to see the position on the map',
  latitude: 'Latitude',
  longitude: 'Longitude',
  button: 'View location',
  helpLink: 'Where do I find this information?',
  secureEnvironment: 'Secure environment Clicksign',
};

export default location;
