const wizardEdit = {
  steps: {
    documents: 'Adicionar documentos',
    signers: 'Adicionar signatários',
    settings: 'Configurações',
    message: 'Mensagem e envio',
  },
  cancelDocumentModal: {
    title: 'Deseja cancelar o documento?',
    cancelInvalidSignatures: 'O cancelamento invalida as assinaturas já realizadas.',
    confirmWarning: 'Para confirmar o cancelamento, salve as alterações na última etapa da edição.',
    confirm: 'Cancelar documento',
    defaultError: 'Erro ao cancelar um ou mais arquivos',
  },
  header: {
    close: 'Sair da edição',
  },
  finishedMessage: 'Alterações realizadas com sucesso!',
  leaveEdit: {
    title: 'Deseja sair da edição?',
    message:
      'As alterações feitas não serão salvas. Avance até o final do fluxo caso deseje salvar.',
    cancel: 'Continuar editando',
    confirm: 'Sair',
  },
  editError: {
    title: 'Erro ao editar o envelope',
    message:
      'Ocorreram alterações de status em um ou mais documentos deste envelope durante sua edição e as mudanças não puderam ser salvas. Tente novamente.',
  },
  content: {
    title: 'Editar envelope',
    alert:
      'No modo edição, as alterações só serão aplicadas se forem salvas na etapa de Mensagem e envio',
    signers: {
      blockRemove: 'Já assinou um documento. Não pode ser excluído',
      deleteSignerError: 'Erro ao remover signatário',
      noDocuments: 'Não há documentos para assinar',
      selectDocuments: 'Selecione os documentos a serem assinados',
    },
    unlinkSignerModal: {
      title: 'Excluir signatário da lista?',
      description: 'O signatário será excluído da lista de assinaturas deste envio.',
      remove: 'Excluir signatário',
    },
  },
  message: {
    title: 'Mensagem e envio',
    saveAndSend: 'Salvar e enviar',
    whatsappAlertCost: 'Para envios via WhatsApp, há um custo de R$ 0,40 por lembrete.',
  },
};

export default wizardEdit;
