const templateModule = {
  title: 'Modelos',
  new: 'Criar Modelo',
  newAi: 'Modelo Inteligente',
  key: 'Chave',
  words: {
    here: 'aqui',
  },
  alert: {
    text: 'Gere documentos automaticamente a partir de modelos pré-cadastrados.',
    action: 'Ver documentação',
  },
  status: {
    created: 'Carregando...',
    published: '@:templateModule.status.created',
    failed: {
      unknown: 'error',
      formatInvalid: 'Formato inválido',

      reason: {
        incorrectImageTag:
          'A tag: "{tag}" no seu modelo está incorreta. Certifique-se de que a tag de "imagem" esteja em letras minúsculas. Exemplo: "[imagem] variavel".',
        unopenedTag: 'Variável aberta incorretamente, faltando chaves no início. Ex.: {tag}}}',
        unclosedTag: 'Variável fechada incorretamente, faltando chaves no final. Ex.: {tag}',
        undefined:
          'O formato do arquivo pode não estar em .docx ou podem haver erros nas variáveis',
      },
    },
  },
  list: {
    invalidWarning: 'Há modelos inválidos na lista. Verifique como corrigir {0}.',
    pendingTemplatetesWarning:
      'Processando últimos modelos. Aguarde para visualizá-los nesta lista',
    loading: 'Carregando lista de modelos...',
    empty: 'Crie seu modelo para que a Clicksign gere seus documentos automaticamente',
    keyHelp: 'Chave para geração de documentos via API.',
    actions: {
      generate: 'Gerar documento',
      options: 'Opções',
    },
    emptyFlow: 'Selecione este modelo em um {0} ou {1} para criar um processo automatizado.',
    formFlow: 'fluxo com formulário',
    spreadsheetFlow: 'fluxo com planilha',
  },
  notification: {
    success: {
      destroy: 'Modelo {name} excluído com sucesso.',
    },
    error: {
      destroy: 'Falha ao excluir modelo.',
    },
  },
  merge: {
    title: 'Gerar documento',
    info: 'Crie um documento e envie para assinatura.',
    fileNameLabel: 'Nome do documento que será gerado',
    fileNameTip:
      "Para que o documento seja criado dentro de uma pasta, basta inserir o nome da pasta neste campo. Por exemplo, '/pasta 1/pasta 2/contrato 123.docx'.",
    templateText: 'Adicione as variáveis do documento no formato json',
    action: 'Gerar',
    errors: {
      generic: 'Ocorreu um erro ao gerar o documento. Por favor, tente novamente mais tarde.',
    },
  },
};

export default templateModule;
