const errorGeneral = {
  firstParagraph: {
    content:
      '¡Mantén la calma! Aunque no entraste a donde querías, aún puedes usar nuestro sitio web. Explora nuestra página principal o inicia sesión.',
    homePage: 'página inicial',
    login: 'login',
  },
  secondParagraph: {
    content: '¿Necesitas ayuda? Visita nuestro {0} o envía un correo electrónico a {1}.',
    helpCenter: 'Centro de Ayuda',
  },
};

export default errorGeneral;
