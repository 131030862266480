import { axios } from '@/plugins';

const fetchDataApiSettings = async ({ getters, commit }) => {
  try {
    const apiUrl = _.get(getters, 'getLinks.self');
    const { data } = await axios.get(apiUrl);
    if (_.has(data, 'account')) {
      const { accessTokens, plan, memberships, apiUserId, hooks, eligibleEvents, widgetEmbedded } =
        data.account;
      commit('SET_LINKS', data.links);
      commit('SET_ACCESS_TOKENS', accessTokens);
      commit('SET_MEMBERSHIPS', memberships);
      commit('SET_API_USER_ID', apiUserId);
      commit('SET_HOOKS', hooks);
      commit('SET_PLAN', plan);
      commit('SET_EVENTS', eligibleEvents);
      commit('SET_WIDGET_EMBEDDED', widgetEmbedded);
    } else {
      const { upgradePlan } = data.links;
      commit('SET_LINK_UPGRADE_PLAN', upgradePlan);
    }
  } catch (error) {
    throw error;
  }
};

const editApiUser = async ({ getters, commit }, apiUserId) => {
  try {
    const url = _.get(getters, 'getLinks.self');
    const { data } = await axios.patch(url, { apiUserId });
    commit('SET_API_USER_ID', _.get(data, 'account.apiUserId'));
  } catch (error) {
    throw error;
  }
};

const fetchAccessTokens = async ({ getters, commit }) => {
  try {
    const apiUrl = _.get(getters, 'getLinks.self');
    const { data } = await axios.get(apiUrl);
    const accessTokens = _.get(data, 'account.accessTokens');
    commit('SET_ACCESS_TOKENS', accessTokens);
  } catch (error) {
    throw error;
  }
};

const createAccessToken = async ({ getters, dispatch }, description) => {
  try {
    const url = _.get(getters, 'getLinks.createAccessToken');
    await axios.post(url, { description });
    await dispatch('fetchAccessTokens');
  } catch (error) {
    throw error;
  }
};

const removeAccessToken = async ({ dispatch }, url) => {
  try {
    await axios.delete(url);
    await dispatch('fetchAccessTokens');
  } catch (error) {
    throw error;
  }
};

const fetchHooks = async ({ getters, commit }) => {
  try {
    const apiUrl = _.get(getters, 'getLinks.self');
    const { data } = await axios.get(apiUrl);
    const { hooks } = data.account;
    commit('SET_HOOKS', hooks);
  } catch (error) {
    throw error;
  }
};

const createHook = async ({ getters, dispatch }, payload) => {
  try {
    const url = _.get(getters, 'getLinks.createHook');
    const hook = { endpoint: payload.endpoint, eligibleEvents: payload.events };
    const response = await axios.post(url, { hook });
    await dispatch('fetchHooks');
    const events = _.get(response, 'data.hook.eligibleEvents');
    return events;
  } catch (error) {
    throw error;
  }
};

const editHook = async ({ dispatch }, payload) => {
  try {
    const url = payload.self;
    const hook = { endpoint: payload.endpoint, eligibleEvents: payload.events };
    const response = await axios.patch(url, { hook });
    await dispatch('fetchHooks');
    const events = _.get(response, 'data.hook.eligibleEvents');
    return events;
  } catch (error) {
    throw error;
  }
};

const removeHook = async ({ dispatch }, payload) => {
  try {
    await axios.delete(payload.self);
    await dispatch('fetchHooks');
  } catch (error) {
    throw error;
  }
};

const activateHook = async ({ dispatch }, payload) => {
  try {
    await axios.post(payload.activate);
    await dispatch('fetchHooks');
  } catch (error) {
    throw error;
  }
};

export default {
  fetchDataApiSettings,
  editApiUser,
  removeAccessToken,
  createAccessToken,
  fetchAccessTokens,
  createHook,
  fetchHooks,
  removeHook,
  editHook,
  activateHook,
};
