const acceptanceTerm = {
  sentAt: 'Enviado en',
  createNew: 'Crear nuevo',
  characteresCounter: '{charactersCount} caracteres disponibles',
  sentDate: 'Fecha de envío',
  acceptanceModels: 'Plantilla de Aceptación',
  noAcceptanceModels: 'Ninguna plantilla registrada',
  date: 'Fecha',
  title: 'Título',
  ordenation: 'Clasificación',

  sentDatePlaceholder: 'Selecciona...',

  status: {
    sent: 'En proceso de aceptación',
    completed: 'Finalizado con éxito',
    canceled: 'Cancelado por el Remitente',
    refused: 'Cerrado por el destinatario',
    expired: 'El plazo de aceptación ha expirado',
    error: 'El envío falló',
    enqueued: 'En espera de envío',
  },

  list: {
    pageTitle: 'Aceptar vía WhatsApp',
    remittee: 'Destinatario',
    contact: 'Contacto',
    acceptanceTitle: 'Título de Aceptación',
    status: 'Estatus',
    createdAt: 'Enviado',
    noDate: 'Sin fecha',
    searchPlaceholder: 'Destinatario, contacto o título',
    chargingTooltip: 'La Aceptación vía WhatsApp tiene un costo adicional',
    alert: 'La Aceptación ahora es un recurso premium. Mejora tu plan para seguir utilizándola.',

    empty: {
      search: {
        title: 'No se encontraron resultados en la búsqueda',
        subtitle: 'Intenta modificar tu búsqueda o quitar los filtros',
      },

      registers: {
        title: 'Envía un nuevo contenido para aceptación vía WhatsApp',
        subtitle: 'Los estatus de tus envíos se mostrarán aquí',
      },

      actions: {
        create: 'Crear nuevo',
        receiveTest: 'Probar Aceptación',
        upgrade: 'Mejorar plan',
      },
    },
  },

  create: {
    title: 'Crear Aceptación vía WhatsApp',
    chargingWarning: 'La Aceptación vía WhatsApp',
    chargingLink: 'tiene un costo adicional.',
    help: 'Ayuda',
    addSigner: 'Nuevo destinatario',
    totalOf: '{x} de {total}',
    rejectedAcceptancesTotalOf:
      '@:acceptanceTerm.create.totalOf las aceptaciones no fueron creadas',
    creatingAcceptanceTerm: 'Creando aceptación',
    creatingAcceptanceTermFailedModalMessage:
      '"No pudimos generar las aceptaciones de los destinatarios indicados. Puedes intentarlo de nuevo ahora.',
    tryLater: 'Intentar después',
    errorProccesingAcceptance: 'Error al procesar la Aceptación',
    verifyConnection: 'Verifica tu conexión antes de continuar.',
    contactUpdateError: 'No se pudo actualizar alguno de los contactos de la lista',
    limitReached: 'Se alcanzó el límite de aceptaciones gratuitas. ¿Necesitas más?',
    limitReachedLinks: '{0} o {1}.',
    chooseAPlan: 'Contrata un plan',
    contactSupport: 'Habla con nuestros especialistas',
    wai: {
      removeSigner: 'Eliminar destinatario',
    },

    acceptanceTermInfo: {
      title: 'Aceptar',
      label: {
        acceptanceTermTitle: 'Título',
        content: 'Contenido',
      },
      placeholder: {
        acceptanceTermTitle: 'Ej: Acuerdo de prestación de servicios',
        content:
          'Ej: Estoy de acuerdo con la prestación de servicios realizada, por el valor de $x, prestada en la fecha y, a las x horas.',
      },
      selectTemplate: 'Seleccionar plantilla',
      selectTemplateDefaultOption: 'Plantillas guardadas',
      saveContentTemplate: 'Guardar como plantilla',
      saveContentTemplateHelp: 'Consulta cómo utilizar plantillas',
    },
    senderInfo: {
      title: 'Tus datos',
      label: {
        senderName: 'Nombre del Remitente',
        senderPhone: 'Teléfono (opcional)',
      },
      placeholder: {
        senderPhone: '(__)_____-____',
      },
      senderPhoneHint: 'Será mostrado al destinatario',
    },
    receiverInfo: {
      title: 'Destinatarios',
      label: {
        signerName: 'Nombre completo',
        signerPhone: 'WhatsApp',
      },
      placeholder: {
        signerName: 'Nombre',
        signerPhone: '(__)_____-____',
      },
      contactPhoneHint: 'Se guardará en la lista',
    },
    actions: {
      send: 'Enviar Aceptación',
    },
    warning: '@:acceptanceTerm.create.totalOf las aceptaciones no pudieron ser creadas',
    success: '¡Aceptación creada con éxito!',
    error: 'Error al crear la aceptación',
  },
  optIn: {
    title: 'Aceptación vía WhatsApp',
    subtitle: 'Formaliza tus acuerdos vía WhatsApp con Clicksign',
    freeTest: 'Crear una Aceptación',
    receiveAcceptance: 'Probar Aceptación',
    firstDescriptionParagraph:
      '¿Has tenido conversaciones de negocio por WhatsApp para cerrar un acuerdos? Con la Aceptación, puedes darle más seguridad a ese acuerdo sin necesidad de generar un documento.',
    secondDescriptionParagraph: '¡Haz una prueba gratuita para conocer esta nueva función!',
    howToUse: '¿Cómo funciona?',
    cards: {
      tasks:
        'Agrega el nombre y número de celular de tu cliente y el contenido que quieres enviar.',
      receiving:
        'El cliente recibirá un mensaje de Clicksign vía WhatsApp, donde podrá confirmar los datos, revisar y aceptar el contenido.',
      finished: '¡Aceptación completada! Puedes consultar el historial cuando lo necesites.',
    },
    modal: {
      confirm: {
        title: 'Información importante',
        confirm: 'Estoy de acuerdo',
        error: 'Ocurrió un error al confirmar la aceptación',
        description:
          'La Aceptación vía WhatsApp es una funcionalidad nueva que está en fase de pruebas. Por ello, es importante informarte que:',
        terms: [
          'Es posible que haya actualizaciones y mejoras durante el período de uso',
          'Algunas funciones como el envío de aceptaciones múltiples y el envío automatizado aún no están disponibles.',
        ],
      },
    },
    sendTestModal: {
      header: 'Prueba como funciona la Aceptación vía WhatsApp en la práctica',
      title: 'Ejemplo de Aceptación',
      subtitle:
        'Recibe un ejemplo de Aceptación en tu WhatsApp y comprueba lo fácil y rápido que es aceptar un contenido enviado.',
      form: {
        receiverInfo: 'Tus datos',
        label: {
          name: 'Nombre completo',
          whatsapp: 'WhatsApp',
        },
        placeholder: {
          whatsapp: '(__)_____-____',
        },
      },
      actions: {
        receiveExample: 'Recibir ejemplo',
      },
      sendOptinRequestError: 'Error al crear ejemplo de aceptación',
      imageAlt:
        'Mano sosteniendo un smartphone con el envío de aceptación vía WhatsApp en la pantalla',
    },
  },
  show: {
    details: {
      createdAt: 'Creado en {createdAt}',
      cancel: 'Cancelar',
      openPreview: 'Abrir registro de aceptación',
      previewTooltip: 'Disponible después de ser finalizado',
    },
    history: {
      title: 'Historial de eventos',
      emptyStateTitle: 'Sigue tu Aceptación aquí',
      emptyStateSubtitle: 'Cuando el envío se complete, el primer evento será registrado',
    },
    document: {
      description: 'Documento creado tras la aceptación del destinatario',
    },
    cancelAcceptanceTerm: {
      title: '¿Cancelar esta Aceptación?',
      description: 'Esta acción no se podrá deshacer y el destinatario no podrá aceptar.',
      checkboxLabel: 'Selecciona para confirmar la cancelación',
      cancel: 'Cerrar y mantener',
      confirm: 'Cancelar',
      error: 'Error al cancelar la aceptación',
      success: 'Aceptación cancelada',
    },
  },
  documentsBalanceAlert: {
    message:
      'Aún tienes {0} Aceptación vía WhatsApp para usar en tu periodo de prueba. | Aún tienes {0} Aceptaciones vía WhatsApp para usar en tu periodo de prueba.',
  },
  requestTrialExtension: {
    header: 'Has alcanzado el límite de Aceptaciones en el periodo de prueba',
    message: {
      reachLimit: {
        firstParagraph:
          'Ya has utilizado las 30 Aceptaciones gratuitas del periodo de prueba. Para seguir disfrutando de los beneficios de esta funcionalidad, consulta nuestros planes y contrata la mejor opción para ti.',
        secondParagraph:
          '¿Necesitas más tiempo para probar? Habla con uno de nuestros especialistas.',
      },
    },
  },
  contentTemplate: {
    create: {
      pageTitle: 'Plantilla de Aceptación',
      back: 'Regresar',
      label: {
        title: 'Título',
        content: 'Contenido',
      },
      titleError: 'Este título ya ha sido utilizado.',
      save: 'Guardar plantilla',
      delete: 'Eliminar plantilla',
      success: '¡Plantilla guardado con éxito!',
      error: 'Error al guardar la plantilla',
    },
    edit: {
      error: 'Error al cargar la plantilla',
    },
    error: 'Error al crear Mensaje recurrente',
    success: '¡Mensaje recurrente creado con éxito!',
    list: {
      pageTitle: '@:acceptanceTerm.acceptanceModels',
      create: 'Crear nuevo',
      table: {
        headers: {
          contentTitle: 'Título del mensaje',
          content: 'Contenido',
        },
      },
      empty: {
        title: 'Guarda plantillas de aceptación para facilitar tus envíos',
        subtitle: 'Tus plantillas de aceptación guardados se mostrarán aquí',
      },
    },
    delete: {
      title: 'Eliminar esta plantilla de aceptación',
      description:
        'Las plantillas de aceptación eliminadas no se pueden recuperar. Esta acción es inmediata.',
      success: '¡Plantilla eliminado con éxito!',
      error: 'Error al eliminar el plantilla',
    },
    modal: {
      title: 'Seleccionar plantilla',
    },
    validations: {
      maxLengthMessage: 'El límite máximo de caracteres es de {0} incluyendo título y contenido',
    },
  },
  confirmAcceptanceIsSentModal: {
    title: 'Aceptación enviada',
    description:
      'Espera unos momentos y verifica si la Aceptación ha llegado a tu WhatsApp. Sigue las instrucciones para completar el proceso.',
  },
};

export default acceptanceTerm;
