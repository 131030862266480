export function isReadonly(role) {
  const roles = ['observer', 'downloader'];
  return roles.includes(role);
}

export function isObserver(role) {
  return role === 'observer';
}

export function isDownloader(role) {
  return role === 'downloader';
}

export function isAdmin(role) {
  return role === 'admin';
}
