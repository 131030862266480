const templateModule = {
  title: 'Modelos',
  new: 'Crear Modelo',
  newAi: 'Modelo Inteligente',
  key: 'Clave',
  words: {
    here: 'aquí',
  },
  alert: {
    text: 'Genera documentos automáticamente a partir de modelos pre-registrados..',
    action: 'Ver documentación',
  },
  status: {
    created: 'Cargando...',
    published: '@:templateModule.status.created',
    failed: {
      unknown: 'error',
      formatInvalid: 'Formato inválido',

      reason: {
        incorrectImageTag:
          'La etiqueta: "{tag}" en el modelo es incorreta. Asegúrate de que la etiqueta de "imagen" esté en minúsculas. Ejemplo: "[imagen] variable".',
        unopenedTag: 'Variable abierta incorrectamente, faltan llaves al inicio. Ej.: {tag}}}',
        unclosedTag: 'Variable cerrada incorrectamente, faltan llaves al final. Ej.:  {tag}',
        undefined:
          'El formato del archivo puede no estar en .docx o puede haber errores en las variables',
      },
    },
  },
  list: {
    invalidWarning: 'Existen modelos que no son válidos en la lista. Verifica cómo corregir  {0}.',
    pendingTemplatetesWarning:
      'Procesando los últimos modelos. Espera para visualizarlos en esta lista',
    loading: 'Cargando la lista de modelos...',
    empty: 'Crea tu modelo para que Clicksign genere tus documentos de manera automática',
    keyHelp: 'Clave para generación de documentos vía API.',
    actions: {
      generate: 'Generar documento',
      options: 'Opciones',
    },
    emptyFlow: 'Selecciona este modelo en un {0} ó {1} para crear un proceso automatizado.',
    formFlow: 'flujo com formulario',
    spreadsheetFlow: 'flujo con planilla',
  },
  notification: {
    success: {
      destroy: 'Modelo {name} eliminado con éxito.',
    },
    error: {
      destroy: 'Error al eliminar el  modelo.',
    },
  },
  merge: {
    title: 'Generar documento',
    info: 'Crea un documento y envíalo para firma.',
    fileNameLabel: 'Nombre del documento que será generado',
    fileNameTip:
      "Para que el documento sea creado dentro de una carpeta, simplemente ingresa el nombre de la carpeta en este campo. Por ejemplo, '/carpeta 1/carpeta 2/contrato 123.docx'.",
    templateText: 'Agrega las variables del documento en formato json',
    action: 'Generar',
    errors: {
      generic: 'Ocurrió un error al generar el documento. Por favor, inténtelo de nuevo más tarde.',
    },
  },
};

export default templateModule;
