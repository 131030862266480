import { get, reduce, orderBy, isEmpty, camelCase, map, chain } from 'lodash';

import { SPREADSHEET_FLOW_CONSTANTS } from '@flow/constants';

export const buildUpdateSpreadsheetFlowRequest = (spreadsheetFlowData) => {
  const data = { spreadsheetFlow: spreadsheetFlowData };

  return data;
};

export const parseSpreadsheetFlowResponse = (response) => {
  const spreadsheetFlowData = get(response, 'spreadsheetFlow');
  const templateMappings = get(response, 'spreadsheetFlow.spreadsheetFieldsMappings', []);
  const signers = get(response, 'spreadsheetFlow.spreadsheetFlowLists', []).map((signer) => {
    const signerFields = get(signer, 'signerFields', []).map((field) => ({
      ...field,
      name: camelCase(field.name),
    }));
    return { ...signer, signerFields };
  });
  const columns = orderBy(get(spreadsheetFlowData, 'spreadsheetFields'), 'column');

  const parsedTemplateMappings = templateMappings.reduce((mappings, templateMapping) => {
    const mapping = {
      [get(templateMapping, 'templateFieldId')]: get(templateMapping, 'spreadsheetFieldId'),
    };
    Object.assign(mappings, mapping);
    return mappings;
  }, {});

  const parsedSignersMappings = chain(signers)
    .groupBy('description')
    .map((group) => group.pop())
    .reduce((signersMappings, signer) => {
      const signerMappings = get(signer, 'spreadsheetSignerMappings', []);
      signerMappings.forEach((signerMapping) => {
        const signerDescription = get(signer, 'description');
        const signerField = camelCase(get(signerMapping, 'signerField'));
        const mappings = signersMappings[signerDescription] || {};
        const mapping = { [signerField]: get(signerMapping, 'spreadsheetFieldId') };
        Object.assign(signersMappings, { [signerDescription]: { ...mappings, ...mapping } });
      });
      return signersMappings;
    }, {})
    .value();

  const parsedColumnsTypes = columns.reduce((columnsTypes, column) => {
    const templateMappingType = get(
      templateMappings.find(({ spreadsheetFieldId }) => spreadsheetFieldId === column.id),
      'dataType'
    );
    const signerMappingField = Object.values(parsedSignersMappings)
      .map((signerMapping) =>
        Object.keys(signerMapping).find((field) => signerMapping[field] === column.id)
      )
      .filter((hasValue) => hasValue)
      .pop();
    const fieldsValidations = SPREADSHEET_FLOW_CONSTANTS.SIGNER_FIELDS_VALIDATIONS;
    const signerMappingType = fieldsValidations[signerMappingField];
    const columnType = signerMappingType || templateMappingType;
    if (columnType) Object.assign(columnsTypes, { [column.id]: _.camelCase(columnType) });
    return columnsTypes;
  }, {});

  const spreadsheetFieldsMappings = {
    templateMappings: parsedTemplateMappings,
    signersMappings: parsedSignersMappings,
    columnsTypes: parsedColumnsTypes,
  };

  Object.assign(
    spreadsheetFlowData,
    { spreadsheetFieldsMappings },
    { spreadsheetFlowLists: signers },
    { spreadsheetFields: columns }
  );

  return spreadsheetFlowData;
};

export const buildSignerCreationRequest = (signer) => {
  const signerData = signer;

  if (!isEmpty(signerData.additionalAuths)) {
    signerData.additionalAuths.forEach((auth) => Object.assign(signerData, { [auth]: true }));
    delete signerData.additionalAuths;
  }

  return signerData;
};

export const parseSignersResponse = (signers, spreadsheetSigners) => {
  const allSigners = signers
    .map((signer) => ({ ...signer.signer, ...signer }))
    .concat(spreadsheetSigners);

  return orderBy(allSigners, 'createdAt');
};

export const buildFieldMappingsRequest = (payload) => {
  const templateMappings = get(payload, 'templateMappings', {});
  const signersMappings = get(payload, 'signersMappings', {});
  const columnsTypes = get(payload, 'columnsTypes', {});
  const spreadsheetSigners = get(payload, 'spreadsheetFlowLists', []);

  const parsedTemplateMappings = map(templateMappings, (spreadsheetFieldId, templateField) => ({
    spreadsheetFieldId,
    templateFieldId: Number(templateField),
    data_type: _.snakeCase(columnsTypes[spreadsheetFieldId]),
  }));

  const parsedSignerMappings = reduce(
    spreadsheetSigners,
    (mappings, signer) => {
      const signerMapping = get(signersMappings, signer.description);
      const signerMappings = map(signerMapping, (spreadsheetFieldId, signerField) => ({
        spreadsheetFieldId,
        signerField: _.snakeCase(signerField),
        spreadsheetFlowListId: signer.id,
      })).filter(({ spreadsheetFieldId }) => !!spreadsheetFieldId);
      return mappings.concat(signerMappings);
    },
    []
  );

  return { field_mappings: parsedTemplateMappings, signer_mappings: parsedSignerMappings };
};

export const signerColor = (signerProperty) => {
  /* eslint-disable no-bitwise */
  /* eslint-disable no-param-reassign */
  const descriptionHash = Array.from(Array(signerProperty.length), (_, index) => index).reduce(
    (hash, index) => {
      hash = signerProperty.charCodeAt(index) + ((hash << 5) - hash);
      return hash;
    },
    ''
  );
  const randomColor = Array.from(Array(3), (_, index) => index).reduce((color, index) => {
    const value = (descriptionHash >> (index * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
    return color;
  }, '');
  return `#${randomColor}`;
};

export const parseSpreadsheetRowsResponse = (response, columns) => {
  const rows = response?.data?.spreadsheetFlowProcesses || [];

  const sanitizeRowData = (row, columnIndex) => {
    const columnValueRaw = row[columnIndex];
    const columnValue =
      columnValueRaw === undefined || columnValueRaw === null ? '' : columnValueRaw;

    return columnValue.toString();
  };

  return rows.map(({ id, row, links, key }, index) => ({
    id,
    links,
    index,
    key,
    data: columns.map((_, columnIndex) => sanitizeRowData(row, columnIndex)),
  }));
};

// TODO: use Yup for validations for cleaner solution
export const buildRowValidations = (
  fieldMappings = {},
  columns = [],
  spreadsheetSigners = [],
  templateFields = [],
  validators = {}
) =>
  reduce(
    fieldMappings.columnsTypes,
    (schema, columnType, column) => {
      const columnId = parseInt(column, 10);
      const mappedColumn = columns.find(({ id }) => id === columnId);
      const validation = {
        ...get(
          SPREADSHEET_FLOW_CONSTANTS.COLUMN_TYPES.find(({ value }) => value === columnType),
          'validation',
          {}
        ),
      };

      if (!mappedColumn || !validation) return {};

      const templateMappings = get(fieldMappings, 'templateMappings', {});
      const signersMappings = get(fieldMappings, 'signersMappings', {});
      const signer = Object.keys(signersMappings).find((name) =>
        Object.values(signersMappings[name]).includes(columnId)
      );
      const signerMappings = signer ? signersMappings[signer] : {};
      const signerField = Object.keys(signerMappings).find(
        (field) => signerMappings[field] === columnId
      );
      const isRequiredSignerField =
        signer &&
        signerField &&
        get(
          spreadsheetSigners.find(({ description }) => description === signer),
          'signerFields',
          []
        ).some(({ name, required }) => name === signerField && required);
      const templateFieldId = Object.keys(templateMappings).find(
        (fieldId) => templateMappings[fieldId] === columnId
      );
      const isRequiredTemplateField = templateFields.some(
        ({ id, required }) => id.toString() === templateFieldId && required
      );
      const isFieldRequired = isRequiredSignerField || isRequiredTemplateField;
      const columnIndex = mappedColumn.column;

      Object.assign(validation, isFieldRequired && { required: validators.required });
      Object.assign(schema, { [columnIndex]: validation });

      return schema;
    },
    {}
  );

// TODO: use Yup for validations for cleaner solution
export const buildFieldMappingValidationScheme = (
  spreadsheetFlowLists = [],
  templateFields = [],
  fieldMappings = {},
  validators = {}
) => {
  const signersMappings = spreadsheetFlowLists
    .filter((signer) => !!signer.signerFields)
    .reduce((signersMappingsSchema, signer) => {
      const requiredSignerFields = signer.signerFields.filter(({ required }) => required);
      const signerSchema = requiredSignerFields.reduce(
        (fieldsSchema, field) => ({
          ...fieldsSchema,
          [field.name]: { required: validators.required },
        }),
        {}
      );

      return {
        ...signersMappingsSchema,
        [signer.description]: _.clone(signerSchema),
      };
    }, {});

  const templateMappings = templateFields
    .filter(({ required }) => required)
    .reduce(
      (templateFieldsSchema, field) => ({
        ...templateFieldsSchema,
        [field.id]: { required: validators.required },
      }),
      {}
    );

  const columnsTypes = Object.values(fieldMappings.templateMappings).reduce(
    (columnsTypesSchema, columnId) => ({
      ...columnsTypesSchema,
      [columnId]: { required: validators.required },
    }),
    {}
  );

  return {
    signersMappings,
    templateMappings,
    columnsTypes,
  };
};

export const validate = (scheme, data, contextThis) => {
  const validationResults = Object.entries(scheme).map(([key, currentScheme]) => {
    const value = data[key];

    return [
      key,
      Object.values(currentScheme).every((validator) => validator.call(contextThis, value)),
    ];
  });

  return {
    items: Object.fromEntries(validationResults),
    isValid: validationResults.every(([, isValid]) => isValid),
  };
};

export const validateSignerMapping = (scheme, data, contextThis) => {
  const validationResults = Object.entries(scheme).map(([key, currentScheme]) => {
    const value = data[key];

    const results = Object.entries(currentScheme).map(([field, fieldScheme]) => {
      const fieldValue = get(value, `[${field}]`);

      return [
        field,
        Object.values(fieldScheme).every((validator) => validator.call(contextThis, fieldValue)),
      ];
    });

    return [
      key,
      {
        items: Object.fromEntries(results),
        isValid: results.every(([, isValid]) => isValid),
      },
    ];
  });

  return {
    items: Object.fromEntries(validationResults),
    isValid: validationResults.every(([, { isValid }]) => isValid),
  };
};
