export default {
  SET_CURRENT_FOLDER: (state, payload) => {
    state.currentFolder = payload;
    Object.assign(state.links, { ...payload.links });
  },
  SET_NODES_LOADING: (state, payload) => {
    state.nodesLoading = payload;
  },
  SET_LINKS: (state, payload) => {
    Object.assign(state.links, { ...payload });
  },
};
