export default {
  data() {
    return {
      modalActive: '',
    };
  },
  computed: {
    hasSomeModalActive() {
      return !!this.modalActive;
    },
  },
  methods: {
    isModalActive(mode) {
      return this.modalActive === mode;
    },
    openModal(mode) {
      this.modalActive = mode;
    },
    closeModal() {
      this.modalActive = '';
    },
  },
};
