import { ref } from 'vue';
import { useI18n } from '@/locales';

const hasUpdates = ref(false);

export const useHeadway = () => {
  const { t } = useI18n();

  const initHeadway = ({ openChangelog }) => {
    const handleWidgetCount = (widget) => {
      hasUpdates.value = !!widget.getUnseenCount();
    };

    Headway.init({
      selector: '.changelog',
      account: 'xYr2n7',
      callbacks: {
        onWidgetReady: handleWidgetCount,
        onShowWidget: openChangelog,
      },
      translations: {
        title: t('layout.header.headway.title'),
        readMore: t('layout.header.headway.readMore'),
        footer: t('layout.header.headway.footer'),
      },
    });
  };

  const addHeadwayScript = () => {
    const script = document.createElement('script');
    script.src = '//cdn.headwayapp.co/widget.js';
    script.id = 'headway-script';
    script.setAttribute('async', true);
    document.head.appendChild(script);
    return script;
  };

  const removeHeadwayScript = () => {
    const script = document.getElementById('headway-script');
    if (script) script.remove();
  };

  const loadHeadwayScript = (options) => {
    const headway = addHeadwayScript();
    headway.addEventListener('load', () => {
      initHeadway(options);
    });
  };

  return {
    hasUpdates,
    initHeadway,
    removeHeadwayScript,
    loadHeadwayScript,
  };
};
