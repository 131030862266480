const general = {
  back: 'Regresar',
  return: 'Regresar',
  close: 'Cerrar',
  loading: 'Cargando...',
  tryAgain: 'Intentar nuevamente',
  download: 'Descargar',
  next: 'Avanzar',
  help: 'Ayuda',
  secureEnvironment: 'Ambiente seguro Clicksign',
  signatures: 'Firmas',
  finalize: 'Finalizar',
  sign: 'Firmar',
  options: 'Opciones',
  userActions: {
    signatures: 'Firmas',
    refusal: 'Rechazar',
    show: 'Visualizar',
    download: 'Descargar',
    help: 'Ayuda',
    dashboard: 'Mi área de firma',
    nextPage: 'Siguiente',
    prevPage: 'Anterior',
  },
  action: {
    conclude: 'Concluir',
  },
  feature: {
    beta: 'Beta',
  },
  unavailableWidgetEmbedded: {
    error: 'Error: Clicksign no está activo en tu sitio',
    title: 'No es posible continuar {0} con la firma.',
    description: 'Verifica con el remitente del documento, {0} e informa el error ocurrido.',
  },
  noLegalStatus: 'Ambiente {environment} - Sin valor legal',
  slowLoading: 'La carga de esta página está tardando más de lo esperado',
};

export default general;
