const disabledFlow = {
  banner: {
    message: 'Para empresas con procesos estandarizados y que',
    highlight: 'desean automatizar la generación de documentos.',
    pricing: '* Envíos a partir de 50 documentos/mes',
    info: 'En un plazo de hasta 1 día hábil, sin compromiso.',
  },
  button: {
    text: 'Recibir contacto',
  },
  info: {
    title: 'Conoce por qué deberías tener la Automatización de Clicksign:',
    items: {
      whatsapp: {
        title: 'Utiliza WhatsApp para recopilar firmas y aceptaciones.',
        content:
          'Recopila firmas o aceptaciones rápidamente a través de WhatsApp y agiliza la formalización y cierre de tus negocios.',
      },
      automation: {
        title: 'Crea documentos a partir de modelos y flujos de automatización.',
        content:
          'Automatiza la recopilación de datos con hasta 2 formularios y envía documentos para firma, optimizando el tiempo y minimizando errores.',
      },
      append: {
        title: 'Agrega archivos adjuntos a tus formularios para obtener validaciones adicionales.',
        content:
          'Archivos adjuntos como fotos, identificación y comprobantes de domicilio podrán ser solicitados y enviados automáticamente para firma una sola vez.',
      },
      platform: {
        title: 'Plataforma intuitiva y soporte especializado.',
        content:
          'La plataforma de Clicksign simplifica la gestión de documentos y firmas electrónicas, además tendrás acceso a un equipo especializado para consultas.',
      },
    },
    subtitle: {
      default: '"Configura, activa y no te detengas.',
      spreadsheet: 'El flujo con planilla permite:',
    },
    description: 'Tu cliente...',
    list: {
      receiveLink: 'Recibe un enlace',
      formFilled: 'Completa el formulario',
      signedDocument: 'Firma el documento',
      doneDeal: 'Negocio cerrado',
      generateDocuments:
        'Genera múltiples documentos de forma sencilla, sin necesidad de integrar sistemas.',
      spreadsheetFilled:
        'Utiliza la hoja de cálculo como fuente de datos para completar el modelo y la información de los firmantes.',
      sendDocuments: 'Genera y envía múltiples documentos a varios firmantes.',
    },
  },
  action: {
    description: '¿Te interesa? ¡No pierdas esta oportunidad!',
    purchase: 'Quiero saber más',
  },
  purchase: {
    title: 'Conoce el Plan de Automatización',
    description: 'Envía tus datos para que nos pongamos en contacto',
    form: {
      name: 'Nombre',
      email: 'Correo electrónico',
      accountName: 'Empresa',
      phoneNumber: 'Número de teléfono',
      documentsAmount: 'Cantidad de documentos/mes',
    },
    required: '* Obligatorio',
    success:
      'Hemos recibido tu mensaje. Nuestro equipo se comunicará contigo en breve para iniciar tu servicio',
    error: '¡No pudimos completar tu solicitud! Inténtalo de nuevo',
  },
};

export default disabledFlow;
