import axios from 'axios';
import { buildURLParams } from '@base/utils/build-url-params';

const getDocument = async ({ commit }) => {
  try {
    const url = window.location.pathname;
    const response = await axios({
      method: 'get',
      url,
    });

    const payload = response.data;
    const links = _.get(response, 'data.document.links');
    commit('GET_DOCUMENT', payload.document);
    if (payload.ancestors) commit('SET_ANCESTORS', payload.ancestors);
    commit('SET_LINKS', links);
    return payload;
  } catch (error) {
    throw error;
  }
};

const getDocumentWithUrl = async ({ commit, getters }) => {
  try {
    const url = _.get(getters, 'getLinks.self');
    const response = await axios({
      method: 'get',
      url,
    });

    const payload = response.data.document;
    const links = _.get(response, 'data.document.links');
    commit('GET_DOCUMENT', payload);
    commit('SET_LINKS', links);
    return payload;
  } catch (error) {
    throw error;
  }
};

const getDocumentLists = async ({ commit, getters }, payload) => {
  try {
    const url = `${getters.getLinks.lists}${buildURLParams(payload)}`;
    const update = payload.update || false;
    const { data } = await axios.get(url);

    const getSignersByStatus = (status) => (signer) => signer.status === status;
    const lists = {
      pendingSigners: data.signers.filter(getSignersByStatus('pending')),
      refusedSigners: data.signers.filter(getSignersByStatus('refused')),
      signatures: data.signers.filter(getSignersByStatus('signed')),
    };
    if (update) commit('UPDATE_DOCUMENT_LISTS', lists);
    else commit('SET_DOCUMENT_LISTS', lists);
    commit('SET_TOTAL_SIGNERS', data.total_signers);
    commit('SET_TOTAL_SIGNATURES', data.total_signatures);
    commit('SET_TOTAL_PENDING_SIGNERS', lists.pendingSigners.length);
  } catch (error) {
    throw error;
  }
};

const getEnvelopeDocumentSigners = async ({ getters, commit }, params) => {
  const url = _.get(getters, 'getLinks.signers');
  const { data } = await axios.get(url, { params });

  if (params.page === 1) commit('SET_DOCUMENT_SIGNERS', data.groups);
  else commit('UPDATE_DOCUMENT_SIGNERS', data.groups);

  commit('SET_TOTAL_SIGNERS', data.total_signers);
  commit('SET_TOTAL_SIGNATURES', data.total_signatures);
  commit('SET_CURRENT_GROUP', data.current_group);

  return data.signers;
};

const emptyDocumentLists = ({ commit }) => {
  commit('EMPTY_DOCUMENT_LISTS');
};

const getDocumentGroups = async ({ commit, getters }) => {
  try {
    const url = _.get(getters, 'getLinks.sequencing_list');
    const response = await axios({
      method: 'get',
      url,
    });

    const { data } = response;
    commit('SET_DOCUMENT_GROUPS', data.groups);
    commit('SET_TOTAL_SIGNERS', data.total_signers);
    commit('SET_TOTAL_SIGNATURES', data.total_signatures);
    commit('SET_TOTAL_PENDING_SIGNERS', data.total_pending_signers);
    return data;
  } catch (error) {
    return error;
  }
};

const patchDocumentSettings = async ({ commit, getters }, payload) => {
  try {
    const url = getters.getLinks.self;
    const { data } = await axios.patch(url, payload);
    commit('UPDATE_DOCUMENT_SETTINGS', payload);
    return [null, data];
  } catch (err) {
    return [err?.response?.data || err, null];
  }
};

const deleteDocumentSigner = async ({ commit, getters }, payload) => {
  const { url, notify } = payload;

  try {
    const { data } = await axios({
      method: 'delete',
      url,
      data: { notify },
    });

    commit('DELETE_DOCUMENT_SIGNER', data);
    commit('SET_TOTAL_SIGNERS', getters.getTotalSigners - 1);

    return data;
  } catch (error) {
    throw error;
  }
};
const fetchDocumentSigner = async ({ commit }, url) => {
  try {
    const { data } = await axios.get(url);

    commit('UPDATE_DOCUMENT_SIGNER', data.signer);

    return data;
  } catch (error) {
    throw error;
  }
};

// TODO: Mover actions sem commits para services ou outra solução
/* eslint-disable */
const sendDocumentMessage = async ({ commit }, obj) => {
  try {
    const response = await axios({
      method: 'post',
      url: 'request_signature',
      data: { message: obj },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
/* eslint-enable */

// TODO: Mover actions sem commits para services ou outra solução
/* eslint-disable */
const getDocumentOrdering = async ({ commit }, obj) => {
  try {
    const response = await axios({
      method: 'post',
      url: `/accounts/${obj.accountId}/folders/${obj.folderId}/documents/${obj.documentKey}/sequencing`,
    });

    const payload = {
      sequence_enabled: response.data.sequence_enabled,
      groups: response.data.groups,
    };
    return payload;
  } catch (error) {
    throw error;
  }
};
/* eslint-enable */

const setDocumentOrdering = async (_, obj) => {
  try {
    const response = await axios({
      method: 'post',
      url: `/accounts/${obj.accountId}/folders/${obj.folderId}/documents/${obj.documentKey}/configure_sequencing`,
      data: obj.config,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateLogReadyStatus = async ({ commit, getters }, documentUrl) => {
  try {
    const url = getters.getLinks.signed;
    const {
      data: { signed },
      status,
    } = await axios.get(url);
    const islogReady = status === 200 && !!signed.content_name;
    if (islogReady) {
      const { data: response } = await axios.get(documentUrl);
      commit('GET_DOCUMENT', response.document);
    }
    return islogReady;
  } catch (error) {
    throw error;
  }
};

const notifySigner = async (_context, payload) => {
  const { accountId, documentKey, folderId, listId, email, message } = payload;

  try {
    const response = await axios({
      method: 'post',
      url: `/accounts/${accountId}/folders/${folderId}/documents/${documentKey}/lists/${listId}/remind_signature`,
      data: { email, message },
    });

    return response;
  } catch (error) {
    return error;
  }
};

const notifyAllSigners = async ({ state }) => {
  try {
    const url = state.links.request_signature;
    const response = await axios.post(url, {});
    return [null, response];
  } catch (error) {
    return [error, null];
  }
};

const listDocumentDetails = async ({ commit, getters }) => {
  try {
    const url = getters.getLinks.details;
    const { data } = await axios.get(url);
    commit('SET_DOCUMENT_DETAILS', data.document_details);
    return data;
  } catch (err) {
    throw err;
  }
};

const addDocumentDetails = async ({ dispatch, getters }, payload) => {
  try {
    const url = getters.getLinks.details_batch;
    await axios.post(url, payload);
    dispatch('listDocumentDetails');
  } catch (err) {
    throw err;
  }
};

const removeDocumentDetail = async ({ dispatch }, url) => {
  try {
    await axios.delete(url);
    dispatch('listDocumentDetails');
  } catch (err) {
    throw err;
  }
};

const editDocumentDetails = async (_, { url, payload }) => {
  try {
    await axios.put(url, payload);
  } catch (err) {
    throw err;
  }
};

const patchCancelDocument = async ({ getters }) => {
  const link = getters.getLinks.cancel_document;
  await axios.patch(link);
};

const patchCloseDocument = async ({ getters }) => {
  const link = getters.getLinks.close_document;
  await axios.patch(link);
};

const getEvents = async ({ getters, commit }) => {
  try {
    const url = getters?.getLinks.events;
    const { data } = await axios.get(url);
    commit('SET_DOCUMENT_EVENTS', data?.events);
  } catch (err) {
    throw err;
  }
};

const getFlow = async ({ getters, commit }) => {
  try {
    const url = getters?.getLinks.flow;
    const {
      data: { flow },
    } = await axios.get(url);
    commit('SET_DOCUMENT_FLOW', flow);
    return flow;
  } catch (err) {
    throw err;
  }
};

const fetchTimestamps = async ({ getters, commit }) => {
  const { data } = await axios.get(getters.getLinks.document_timestamp_signature);
  commit('SET_DOCUMENT_TIMESTAMPS', data.signature_info);
};

const fetchDocxBase64 = async (_, endpoint) => {
  const { data } = await axios.get(endpoint);

  return data.docx_base64;
};

export default {
  notifyAllSigners,
  deleteDocumentSigner,
  fetchDocumentSigner,
  emptyDocumentLists,
  getDocument,
  getDocumentLists,
  getDocumentOrdering,
  getDocumentGroups,
  notifySigner,
  setDocumentOrdering,
  sendDocumentMessage,
  patchDocumentSettings,
  getEnvelopeDocumentSigners,
  updateLogReadyStatus,
  listDocumentDetails,
  addDocumentDetails,
  removeDocumentDetail,
  editDocumentDetails,
  getDocumentWithUrl,
  patchCancelDocument,
  patchCloseDocument,
  getEvents,
  getFlow,
  fetchTimestamps,
  fetchDocxBase64,
};
