import { axios } from '@/plugins';
import { sentry } from '@/plugins/sentry';
import moment from 'moment';
import locales from '@widget/locales/widget-locales';

const createIcpCloudDocumentsVersion = async ({ getters }) => {
  try {
    const url = getters.getLinks.startIcpCloudSignature;
    const { data } = await axios.post(url);
    return data;
  } catch (err) {
    sentry.captureException(err);
    throw err;
  }
};

const fetchIcpCloudProviders = async ({ getters, commit, dispatch }, signerInfo) => {
  const { icpSignatures } = await dispatch('createIcpCloudDocumentsVersion');

  const { name, documentation, birthday, latitude, longitude, locationEnabled } = signerInfo;

  const locale = getters.getLocale;
  const localeDateFormat = locales?.messages[locale]?.validation?.dateFormat;

  const formattedDate = moment(birthday, localeDateFormat).format('DD/MM/YYYY');

  const isFromEnvelope = getters.fromEnvelope;
  const keyName = isFromEnvelope ? 'requestKey' : 'batchKey';
  const keyValue = getters.getBatchKey;

  try {
    const payload = {
      name,
      documentation,
      birthday: formattedDate,
      latitude,
      longitude,
      icpSignatures,
      locationEnabled,
      [keyName]: keyValue,
      fromSignerArea: new URL(window.location.href).searchParams.has('from_signer_area'),
    };

    const url = getters.getLinks.icpCloudProviders;
    const { data } = await axios.post(url, payload);
    commit('SET_ICP_CLOUD_PROVIDERS', data.providers);
    return data.providers;
  } catch (err) {
    sentry.captureException(err);
    throw err;
  }
};

const fetchIcpCloudInfo = async ({ getters, commit, dispatch }) => {
  try {
    const url = getters.getLinks.self;
    const { data } = await axios.get(url);

    const keyName = data?.fromEnvelope ? 'requestKey' : 'batchKey';

    commit('SET_BATCH_KEY', data[keyName]);
    commit('SET_BRAND', data?.brand);
    commit('SET_CALL_TO_ACTION_ENABLED', data?.callToActionEnabled);
    commit('SET_FROM_ENVELOPE', !!data?.fromEnvelope);
    commit('SET_DOCUMENTS_COUNT', data?.documentsCount);
    commit('SET_ICP_CLOUD_SIGNATURE_JWT', data?.jwt);
    commit('SET_ICP_CLOUD_SIGNATURE_INFO', data?.signature);
    commit('SET_LOCALE', data?.locale);
    commit('SET_LINKS', data?.links);
    commit('SET_SIGNER', data?.signer);
    commit('widget/SET_FROM_SIGNER_AREA', !!data?.fromSignerArea, { root: true });

    dispatch('featureFlags/setFeatureFlags', { toggles: data?.toggles }, { root: true });

    return data;
  } catch (error) {
    sentry.captureException(error);
    throw error;
  }
};

const completeIcpCloud = async ({ getters }, payload = {}) => {
  try {
    const url = getters.getLinks.complete;
    const jwt = getters.getIcpCloudSignatureJwt;
    const { data } = await axios.post(url, { jwt, ...payload });
    return data;
  } catch (error) {
    if (error.response?.status === 422 && error.response?.data?.error === 'already_signed') {
      sentry.captureException(error, {
        tags: {
          code: 'already_signed',
          digitalCertificate: true,
          isCloud: true,
        },
      });
      return null;
    }

    sentry.captureException(error);
    throw error;
  }
};

const signIcpCloud = async ({ getters }) => {
  try {
    const signatureInfo = getters.getIcpCloudSignatureInfo;
    const url = getters.getLinks.sign;
    const { data } = await axios.post(url, { signature: signatureInfo });
    return data;
  } catch (error) {
    sentry.captureException(error);
    throw error;
  }
};

export default {
  createIcpCloudDocumentsVersion,
  fetchIcpCloudProviders,
  fetchIcpCloudInfo,
  completeIcpCloud,
  signIcpCloud,
};
