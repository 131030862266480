import { kebabCase, snakeCase } from 'lodash';
import { sentry } from '@/plugins/sentry';
import { canUseLocalStorage } from '@base/utils/can-use-storage';

export default function hotjar(type, name) {
  if (!canUseLocalStorage()) return false;

  try {
    if (window.hj) {
      const windowHotjar = window.hj;
      if (windowHotjar && typeof windowHotjar === 'function') {
        const action = type === 'event' ? kebabCase(name) : snakeCase(name);
        windowHotjar(type, action);
      }
    }
  } catch (error) {
    sentry.captureException(error, { tags: { hotjar: true } });
  }

  return null;
}
