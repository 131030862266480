import { axios } from '@/plugins';

const PER_PAGE = 10;

const fetchInvites = async (
  { commit, rootGetters, dispatch },
  payload = { page: 1, perPage: PER_PAGE }
) => {
  const { page, perPage } = payload;

  try {
    const endpoint = rootGetters['info/getLinks']?.invites;
    const searchParams = new URLSearchParams();

    searchParams.append('per_page', perPage || PER_PAGE);
    searchParams.append('page', page);

    const requestUrl = `${endpoint}?${searchParams}`;

    const { data } = await axios.get(requestUrl);

    if (data?.pagination?.pageCount < page) {
      const newPayload = {
        ...payload,
        page: page - 1,
      };
      await dispatch('fetchInvites', newPayload);

      return;
    }

    commit('SET_INVITES', data?.invites);
    commit('SET_PAGINATION', data?.pagination);
  } catch (err) {
    throw err;
  }
};

export default { fetchInvites };
