const workspaces = {
  title: '¡Hola, {name}!',
  subtitle: '¿Qué deseas hacer hoy?',
  list: {
    header: 'Cuentas',
    // TOGGLE_ORGANIZATION_OWNER_ENABLED
    // TODO: Remove headerOld
    headerOld: 'Cuenta',
    options: {
      title: 'Opciones',
      editName: 'Editar nombre',
      moveAccount: 'Mover cuenta',
      linkAccount: 'Vincular cuenta',
      deleteAccount: 'Eliminar cuenta',
    },
    emptyWorkspace: {
      title: 'No hay cuentas vinculadas a este workspace',
      action: 'Mover una cuenta',
    },
  },
  signerAreaType: 'Firmar documentos',
  signerArea: 'Área de firmas',
  signersCard: {
    title: 'Documentos para que firmes',
    emptySignatures: 'Sin firmas pendientes',
    titleOld: 'Firmar documentos',
    info: 'No hay firmas pendientes para ti, por ahora',
    pendingSignatures: 'firma pendiente | firmas pendientes',
    button: 'Acceder al área de firmas',
  },
  operatorCard: {
    title: 'Workspaces',
    // TOGGLE_ORGANIZATION_OWNER_ENABLED
    // TODO: Remove titleOld
    titleOld: 'Enviar documentos',
    hasAccount: {
      info: 'Accede a la cuenta para enviar y seguir tus documentos y aceptaciones',
    },
    tooltip: 'Eres administrador de este ambiente',
    customize: 'Personalizar',
    createWorkspace: 'Crear workspace',
  },
  createWorkspaceModal: {
    title: 'Crear workspace',
    form: {
      name: {
        label: 'Elige un nombre para el workspace',
        placeholder: 'Ejemplo: proyecto beta',
      },
    },
    success: 'Workspace creado con éxito',
    error: 'Error al crear workspace',
  },
  editWorkspaceModal: {
    title: 'Editar nombre del Workspace',
    form: {
      name: {
        label: 'Elige un nombre',
        placeholder: 'Ejemplo: proyecto beta',
      },
    },
    success: 'Workspace editado con éxito',
    error: 'Error al editar workspace',
  },
  deleteWorkspaceModal: {
    title: 'Eliminar el workspace {name}',
    subtitle:
      'El workspace será eliminado, pero las cuentas seguirán existiendo. Elige el workspace al que se deben mover las cuentas.',
    form: {
      workspace: {
        label: 'Mover cuentas al workspace',
      },
    },
    error: 'Error al eliminar workspace',
    success: 'El workspace {name} fue eliminado con éxito',
  },
  deleteEmptyWorkspaceModal: {
    title: '¿Eliminar el workspace {name}?',
    subtitle: 'Esta acción no se podrá deshacer.',
    error: 'Error al eliminar workspace',
    success: 'El workspace {name} fue eliminado con éxito',
  },
  moveAccountModal: {
    title: 'Mover una cuenta a {name}',
    form: {
      accounts: {
        label: 'Cuentas',
      },
    },
    move: {
      success: 'Cuentas movidas al workspace {name} con éxito',
      error: 'Error al mover cuentas',
    },
    search: {
      error: 'Error al buscar cuentas',
    },
  },
  workspaceCollapse: {
    editWorkspaceName: 'Editar nombre',
    moveAccount: 'Mover cuenta',
    linkNewAccount: 'Vincular cuenta nueva',
    deleteWorkspace: 'Eliminar workspace',
  },
  editOrganizationNameModal: {
    title: 'Personalizar ambiente',
    changeLogo: 'Cambiar logo',
    form: {
      organizationName: {
        label: 'Nombre del ambiente',
        placeholder: 'Ejemplo: Acme Inc.',
      },
    },
    disclaimer:
      'El registro será aplicado en el ambiente, en los correos electrónicos y en las páginas de firma de todas las cuentas.',
    success: 'Ambiente personalizado con éxito',
    errors: {
      default: 'Error al personalizar ambiente',
      brand: {
        logo: 'Logo {message}',
        color_1: 'Color de fondo del encabezado {message}',
        color_2: 'Color de fondo del botón {message}',
        color_3: 'Color del texto del botón {message}',
      },
      name: 'Nombre {message}',
    },
  },
  sideBarOrganizationAccountsMenu: {
    backToWorkspaceList: 'Volver al panel de workspaces',
  },
};

export default workspaces;
