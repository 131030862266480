const download = {
  title: 'Documento disponible',
  subtitle: '¡Tu documento ya puede ser descargado aquí!',
  description: 'Descubre {0} y cómo puede ayudarte en tu día a día.',
  whatIs: 'qué es una firma electrónica',
  availableUntil: 'Documento disponible hasta {0}',
  action: 'Descargar',
  help: '¿Necesitas ayuda? Visita nuestra {0} o envía un correo a {1}.',
  helpCenter: 'Centro de ayuda',
  helpEmail: 'ayuda@clicksign.com',
  expired: {
    title: 'Plazo para descargar expirado',
    subtitle: 'Lo sentimos, pero este documento ya no está disponible aquí.',
    description:
      'Contacta a la persona que envió el documento para firma y solicita el documento firmado.',
  },
};

export default download;
