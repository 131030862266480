export default {
  SET_DOCUMENT_TYPE(state, payload = '') {
    state.documentType = payload;
  },
  SET_DOCUMENT_FRONT(state, payload = '') {
    state.documentFront = payload;
  },
  SET_DOCUMENT_BACK(state, payload = '') {
    state.documentBack = payload;
  },
  SET_FACEMATCH_STATUS(state, payload) {
    state.facematchStatus = payload;
  },
};
