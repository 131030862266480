const getOnlyNumbers = (str) => parseFloat(String(str).replace(/^scope_/, ''));

class ScopedToggle {
  constructor(value) {
    this.value = value;
  }

  toString() {
    return this.value;
  }

  isEnabled(scope = 1) {
    if (typeof this.value === 'boolean' || scope === undefined) return !!this.value;
    return getOnlyNumbers(scope) <= getOnlyNumbers(this.value);
  }

  isDisabled(scope) {
    return !this.isEnabled(scope);
  }
}

export const useScopedToggles = () => {
  const create = (strOrBol) => new ScopedToggle(strOrBol);

  return { create };
};
