const tracking = {
  title: 'E-mails enviados',
  description: 'Acompanhe o envio dos e-mails para assinatura aos signatários',
  delivered: 'Entregue',
  spam: 'Spam',
  bounce: 'Bounce',
  loading: 'Buscando...',
  date: 'Data e hora do envio',
  email: 'E-mail do signatário',
  kind: 'Tipo de email',
  status: 'Status do email',
  subject: 'Assunto',
  modal: {
    details: {
      title: 'Andamento do envio',
      sendedEmails: 'E-mails enviados',
      signerEmail: 'E-mail do signatário: {email}',
      changeEmail: 'Alterar o e-mail aqui.',
    },
  },
  titles: {
    signatureRequest: 'Solicitação de assinatura',
    signatureReminder: 'Lembrete de assinatura',
    token: 'Token',
    documentCanceled: 'Aviso de documento cancelado',
    secondForm: 'Formulário para preenchimento',
    signedDocument: 'Confirmação de assinatura',
    expiring: 'Lembrete para baixar documento assinado',
    receipt: 'Comprovante de assinatura',
    refusal: 'Aviso de documento pausado',
    facematchRejected: 'Aviso de assinatura não processada',
  },
  statuses: {
    processing: 'Processando envio',
    delivery: 'Enviado',
    spam: 'Spam',
    bounce: 'Não recebido',

    v2: {
      processing: 'Enviado',
      delivery: 'Entregue',
      spam: 'Recebido como SPAM',
      bounce: 'Não entregue',
    },

    descriptions: {
      processing: 'O envio do e-mail está sendo processado.',
      delivery: 'O e-mail foi enviado com sucesso.',
      spam: 'O e-mail foi identificado como spam.',
      bounce: 'O e-mail não chegou ao signatário.',
    },
  },
  providerErrors: {
    hardBounce: 'Verifique se o e-mail está correto ou se ainda existe.',
    softBounce:
      'Um problema temporário impediu o recebimento. {0} Verifique com o signatário se a caixa de entrada está cheia',
    spamComplaint: 'Verifique com o signatário se o e-mail está na caixa de spam.',
    spamNotification: 'Verifique com o signatário se o e-mail está na caixa de spam.',
  },
  kinds: {
    signatureRequest: 'Assinar documento',
    signatureReminder: 'Lembrete de assinatura',
    token: 'Token',
    documentCanceled: 'Documento cancelado',
    secondForm: 'Preencher formulário',
    signedDocument: 'Documento assinado',
    expiring: 'Prazo para expirar',
    receipt: 'Comprovante de assinatura',
    refusal: 'Documento pausado',
    facematchRejected: 'Assinatura não processada',
    signatureWatcherEnvelopeClosed: 'Documento assinado',
    signatureWatcherDocumentSigned: 'Comprovante de assinatura',
    signatureWatcherDocumentSent: 'Acompanhar assinatura',
  },
  filters: {
    inputPlaceholder: 'Buscar por e-mail do signatário',
    apply: 'Aplicar',
    label: 'Filtro',
    clean: 'Limpar filtros',
    select: 'Selecione',
    appliedFilters: 'FILTROS APLICADOS',
    date: 'Data',
    dateRange: 'Data do envio',
    dateShortcuts: {
      thisWeek: 'Essa semana',
      lastWeek: 'Semana passada',
      thisMonth: 'Esse mês',
      lastMonth: 'Mês passado',
      last7Days: 'Últimos 7 dias',
      last30Days: 'Últimos 30 dias',
      last120Days: 'Últimos 120 dias',
    },
    dateHint: 'Histórico de 120 dias',
  },
  list: {
    empty: {
      title: 'Ainda não há envios de documentos para assinatura.',
      details: 'E-mails de documentos enviados serão exibidos aqui.',
      description: 'Após fazer um envio, você poderá acompanhar o recebimento por aqui.',
      searchTitle: 'Não foram localizados envios nesta busca.',
      searchDescription: 'Experimente pesquisar com outros filtros.',
    },
  },
  details: {
    loading: 'Carregando detalhes...',
    title: 'Detalhes',
    email: 'E-mail do signatário',
  },
  bounceDetails: 'Detalhes técnicos:',
};

export default tracking;
