const TEMPLATE_ACTIONS = {
  GENERATE_TEMPLATE: 'generate_template',
  UPLOAD_DOCUMENT: 'upload_document',
  GO_TO_MODEL_PAGE: 'go_to_model_page',
  TEMPLATE_PREVIEW: 'template_preview',
  SHOW_TEMPLATE_PREVIEW: 'show_template_preview',
  GENERATE_FLOW_FORM: 'generate_flow_form',
};

const SIGNER_ACTIONS = {
  NEW_SIGNER_CONTACT: 'new_signer_contact',
  NEW_FORM_SIGNER: 'new_form_signer',
  NEW_SIGNER: 'new_signer',
  MANAGE_SIGNERS: 'manage_signers',
  PREVIEW_FORM: 'preview_form',
  COPY_FLOW_FORM: 'copy_flow_form',
  SHOW_FLOW_SETTINGS: 'show_flow_settings',
  ACTIVATE_FLOW_FORM: 'activate_flow_form',
};

export const ACTIONS = {
  ...TEMPLATE_ACTIONS,
  ...SIGNER_ACTIONS,
};
