import { effectScope, getCurrentInstance, shallowReactive } from 'vue';

export const useRouter = () => getCurrentInstance().proxy.$router;

export const useRoute = () => {
  const root = getCurrentInstance().proxy.$root;

  if (!root._$route) {
    const route = effectScope(true).run(() => shallowReactive({ ...root.$router.currentRoute }));
    root._$route = route;

    root.$router.afterEach((to) => {
      Object.assign(route, to);
    });
  }

  return root._$route;
};
