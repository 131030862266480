export default {
  ancestors: [],
  key: '',
  filename: '',
  autoclose: '',
  locale: '',
  remind_interval: '',
  current_group: '',
  default_remind_interval: '',
  deadline_at: '',
  created_at: '',
  finished_at: '',
  original_file_url: '',
  original_file_name: '',
  signed_file_url: '',
  signed_file_name: '',
  ziped_file_url: '',
  ziped_file_name: '',
  folder_name: '',
  folder_path: '',
  running_path: '',
  finished_path: '',
  canceled_path: '',
  log_ready: '',
  sequence_enabled: false,
  lists: {
    signatures: [],
    pendingSigners: [],
    refusedSigners: [],
  },
  signatures: [],
  signers: [],
  pending_signers: [],
  events: [],
  remind_intervals: [],
  reminders: [],
  groups: {},
  flow: {},
  answers: {},
  links: {},
  toggles: {},
  totalSignatures: 0,
  totalSigners: 0,
  totalPendingSigners: 0,
  refused_signers: [],
  documentDetails: [],
  deletedEvents: [],
  from_envelope: false,
  envelopeSigners: [],
  currentGroup: 0,
  status: '',
  documentTimestamps: [],
};
