const betaProgram = {
  optIn: {
    title: 'Programa Clicksign Beta',
    subTitle: 'Seja um dos primeiros a testar novos recursos',
    description: {
      firstParagraph: 'Você foi selecionado para fazer parte do Programa Clicksign Beta!',
      secondParagraph:
        'O programa é 100% gratuito e os participantes serão os primeiros a experimentar os lançamentos da plataforma.',
      thirdParagraph: 'Inscreva-se e comece a experimentar funcionalidades em primeira mão.',
    },
    cta: 'Inscrever-se',
    link: 'O que é uma funcionalidade beta?',
    howToUse: 'Como funciona?',
    cards: {
      subscription:
        'Inscreva-se para participar e receber um aviso quando uma  funcionalidade entrar e sair da fase de teste',
      experiment: 'Comece a experimentar  funcionalidades disponíveis para teste',
      share:
        'Compartilhe suas percepções conosco e ajude a construir uma Clicksign cada vez melhor',
    },
    imageAlt: 'Pessoa feliz segurando um tablet',
    modal: {
      title: 'Falta pouco para fazer parte do Programa Clicksign Beta',
      description: 'Antes de finalizar sua inscrição, é importante saber: ',
      confirm: 'Estou ciente',
      terms: [
        'O Programa Clicksign Beta é 100% gratuito.',
        'Você pode cancelar sua inscrição a qualquer momento. As atividades da sua conta não serão prejudicadas.',
        'As funcionalidades disponibilizadas no programa estão em período de teste. Por isso, elas ficarão disponíveis por tempo limitado e poderão ser melhoradas durante o uso.',
      ],
      lastTerm:
        'Para reportar instabilidade na funcionalidade testada ou compartilhar suas impressões sobre ela, mande um e-mail para',
      checkbox: 'Confirmo que li e estou ciente dos termos acima',
      error: 'Não foi possível realizar sua inscrição. Tente novamente',
    },
  },
  optOut: {
    title: 'Programa Clicksign Beta',
    subscribed: 'Inscrito',
    content: [
      'Você já faz parte do nosso Programa Clicksign Beta.',
      'Quer compartilhar suas impressões sobre uma funcionalidade beta com o nosso time?',
    ],
    sendEmail: 'Envie um e-mail para {email}',
    cancelButton: 'Cancelar inscrição',
    modal: {
      title: 'Cancelar inscrição no Programa Clicksign Beta',
      description:
        'É uma pena que você queira cancelar sua inscrição. Antes de confirmar, compartilhe com a gente o motivo da sua decisão.',
      textAreaPlaceHolder: 'Conte-nos: por que decidiu cancelar?',
      checkbox: 'Quero cancelar a minha inscrição no Programa Clicksign Beta',
      confirm: 'Cancelar inscrição',
    },
    toast: 'Sua inscrição foi cancelada com sucesso',
    error: 'Não foi possível cancelar sua inscrição. Tente novamente',
  },
};

export default betaProgram;
