export default {
  actions: {
    forward: 'Avanzar',
    back: 'Regresar',
  },
  fields: {
    lists: 'Firmantes:',
    selectPlaceholder: 'Ningún firmante',
  },
  seal: {
    title: 'Rúbrica en la esquina de la página',
    subtitle:
      'Indica quién debe insertar una rúbrica en la esquina inferior de cada página del documento.',
    alert: 'La rúbrica se aplicará en todas las páginas del documento.',
    fields: {
      pagesLabel: 'Páginas:',
      pagesPlaceholder: 'Ej: 1, 2, 3',
      allPagesLabel: 'Todas las páginas',
      selectedPage: 'Página',
    },
  },
  fullReadConfirmation: {
    title: 'Visualización completa del documento',
    subtitle: 'Indica quién debe visualizar todas las páginas del documento para poder firmarlo.',
  },
  clause: {
    title: 'Confirmación de lectura de cláusulas',
    subtitle:
      'Indica qué firmantes deben confirmar la lectura y qué partes del documento deben ser confirmadas.',
    fields: {
      clauses: 'Leí y acepto las cláusulas/elementos:',
      placeholder: 'Ej: 1-4, 10, 20',
    },
  },
};
