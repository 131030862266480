import axios from 'axios';
import { camelizeKeys, snakeCaseKeys } from '@base/utils';

const axiosInstance = axios.create({
  headers: { 'content-type': 'application/json' },
  responseType: 'json',
});

export default axiosInstance;

const parseKeys = (data, keyParser) => {
  try {
    const dataObject = typeof data === 'string' ? JSON.parse(data) : data;
    return keyParser(dataObject);
  } catch (error) {
    return data;
  }
};

axiosInstance.interceptors.request.use(
  (request) => {
    request.headers.setAccept('application/json');

    Object.assign(
      request,
      request.data && { data: parseKeys(request.data, snakeCaseKeys) },
      request.params && { params: parseKeys(request.params, snakeCaseKeys) }
    );
    return request;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    const data = parseKeys(response.data, camelizeKeys);
    return { ...response, data };
  },
  (error) => {
    const parsedError = error;
    const location = error?.response?.data?.location;
    if (error?.response?.status === 401 && location) {
      window.location = `${location}?session_expired=true`;
    }
    if (parsedError.response && typeof parsedError.response.data !== 'string') {
      parsedError.response.data = parseKeys(error.response.data, camelizeKeys);
    }
    return Promise.reject(parsedError);
  }
);
