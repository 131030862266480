import signature from './signature';
import steps from './steps';

const messages = {
  signature,
  steps,
};

export default {
  messages,
};
