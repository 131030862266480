const flow = {
  setup: {
    name: 'Flujo',
    title: 'Comencemos a crear tu flujo',
    subtitle: 'Define un nombre y un color para identificar este flujo.',
    selectColor: 'Elige un color para identificar este flujo:',
    labelName: 'Nombre del flujo',
    next: 'Avanzar',
    back: 'Regresar',
    form: 'Formulario',
    forms: 'Formularios',
    template: 'Plantilla',
    active: 'Activar',
    activated: 'Activado',
    copy: 'Copiar',
    copied: 'Copiado',
    close: 'Cerrar',
    formSearch: 'Buscar un formulario',
    emptySearch:
      'No encontramos resultados para tu búsqueda. {0} ¿Qué tal intentar un término diferente?',
    formAlert: {
      description: 'Necesitas al menos un formulario para continuar este flujo.',
      complement: 'Crea un formulario y luego vuelve a esta etapa para continuar.',
      new: 'Crear formulario',
    },
    formAlertTwo: {
      description: 'Necesitas dos formularios para continuar este flujo.',
      complement: 'Crea un formulario y luego vuelve a esta etapa para continuar.',
      new: 'Crear formulario',
    },
    templateAlert: {
      description: 'Necesitas una plantilla para continuar este flujo.',
      complement: 'Crea una plantilla y luego vuelve a esta etapa para continuar.',
      new: 'Crear Plantilla',
    },
    stepForm: {
      name: 'Formulario',
      title: 'Selecciona un formulario',
      empty: 'No existe ningún formulario en tu cuenta',
      new: 'Haz click aquí para crear un formulario',
      modal: {
        title: '¿Qué deseas hacer a continuación?',
        option1: {
          title: 'Seleccionar un segundo formulario',
          description:
            'Selecciona esta opción cuando tu documento necesite información de diferentes personas. Ej.: El Comprador llena los datos personales y el Vendedor completa la información del pedido.',
        },
        option2: {
          title: 'Avanzar a la elección del Plantilla',
          description: 'Selecciona esta opción cuando tu flujo solo tenga un formulario.',
        },
      },
      reviewFormFieldsTooltip:
        'Este formulario no tiene campos de correo electrónico o teléfono obligatorios',
    },
    stepFormTwo: {
      name: 'Formulario',
      title: 'Selecciona el segundo formulario',
      empty: 'No hay otro formulario disponible en tu cuenta',
      new: 'Haz click aquí para crear un formulario',
      settings: {
        newTitle: 'Completa el segundo formulario',
        // TOGGLE_FLOW_SIMPLIFIED_JOURNEY_ENABLED
        // TODO: Remove title locale
        title: 'Completa del segundo formulario',
        afterFirstForm: 'Después de completar el primer formulario',
        willBeSent: 'Se enviará al correo electrónico o WhatsApp que definas',
        theSecondForm: 'El segundo formulario',
        fillSecondForm: 'Define quién debe completar el segundo formulario',
        // TOGGLE_FLOW_SIMPLIFIED_JOURNEY_ENABLED
        // TODO: Remove typeAnEmail locale
        typeAnEmail: 'Ingresa un correo electrónico',
        useAnEmail: 'Usa un correo electrónico',
        selectFromFirstForm: 'Selecciona del primer formulario',
        email: 'Correo electrónico',
        phone: 'Teléfono',
        emailOrWhatsapp:
          'Correo electrónico o WhatsApp del formulario (solo los campos obligatorios estarán disponibles)',
        noEmail: 'No hay correos electrónicos para seleccionar',
        noPhone: 'No hay teléfonos para seleccionar',
        select: 'Seleccionar',
        messageInfo:
          'Mensaje que se enviará dentro del correo electrónico para completar el segundo formulario. (Opcional)',
        addAnswersToEmail:
          'Incluir en este correo electrónico las respuestas del primer formulario.',
        tooltip: {
          typeAnEmail: 'Define un correo electrónico para el envío del segundo formulario.',
          selectFromFirstForm:
            'Utiliza un correo electrónico o teléfono ingresado en un campo del primer formulario.',
        },
      },
    },
    templateAndFieldsEditor: 'Editor de plantillas y variables',
    stepTemplate: {
      name: 'plantilla',
      title: 'Selecciona una plantilla',
      new: 'Haz click aquí para crear una plantilla',
      search: 'Buscar una plantilla',
      clickToEdit: 'Haz click para editar la plantilla del documento',
      loadingTemplate: 'Creando tu plantilla de automatización... Espera mientras terminamos.',
      loadingFields:
        'Generando tus formularios. Esto puede tardar un momento. ¡Pronto podrás continuar!',
    },
    stepFields: {
      name: 'Contenido de los documentos',
      title: 'Contenido de los documentos',
      subtitle:
        'Selecciona el campo del formulario correspondiente al campo de la plantilla para definir el contenido del documento.',
      templateField: 'Campo de la plantilla',
      formField: 'Campo del formulario',
    },
    stepFolder: {
      name: 'Nombre de los documentos',
      title: 'Nombre de los documentos',
      insertVariable: 'Insertar variable',
      warningLimitMsg:
        'Límite máximo de 255 caracteres. Evita usar variables con textos muy largos.',
      document: {
        subtitle: 'Configura un nombre para los documentos',
        description: {
          initial: 'Inserta ',
          variable: 'variables ',
          complement:
            'para que cada documento generado tenga un nombre diferente. Serán reemplazados por los contenidos de los campos del formulario (solo los campos obligatorios estarán disponibles).',
        },
        popover: {
          initial:
            'Una variable recibirá un dato de un campo del formulario, las variables disponibles son las mismas de la plantilla seleccionado en este flujo y se mostrarán en color amarillo entre llaves {{}}.',
          complement:
            'Inserta variables para que cada documento generado por este flujo tenga un nombre diferente, esto ayudará a identificar el documento.',
          variable: 'Mira un ejemplo usando variables:',
          example: 'Así quedaría el nombre del documento:',
        },
      },
      folder: {
        subtitle: 'Configura en qué carpeta se generarán los documentos',
        description: {
          initial: 'Inserta ',
          variable: 'variables ',
          complement:
            'si deseas que cada documento se genere en carpetas con nombres diferentes. Para definir ',
          subfolder: 'subcarpetas ',
          final:
            'agrega barras “/” entre cada nombre (solo los campos obligatorios estarán disponibles).',
        },
        variable: {
          initial:
            'Una variable recibirá un dato de un campo del formulario, las variables disponibles son las mismas de la plantilla seleccionado en este flujo y se mostrarán en color amarillo entre llaves {{}}.',
          complement:
            'Inserta variables para que cada documento generado por este flujo se almacene en carpetas con contenidos de los campos del formulario.',
          example: 'Ejemplo usando variable:',
          name: 'Así quedaría el nombre de la carpeta:',
        },
        subpath: {
          initial:
            'Para que el documento se genere dentro de subcarpetas, puedes escribir separando por una barra /.',
          variable: 'Mira un ejemplo:',
          example:
            'De esta forma, el documento se generará, por ejemplo, dentro de la carpeta 2020 que está dentro de la carpeta Contratos.',
        },
        chooseFolder: 'Elegir carpeta',
      },
    },
    stepSigners: {
      name: 'Lista de firmas',
      title: 'Lista de firmas',
      subtitle: 'Agrega firmantes para que firmen el documento que se generará.',
      empty: 'Ningún signatario agregado.',
      newSigner: 'Nuevo signatario',
      newSignerForm: 'Signatario del formulario',
      newSignerContact: 'Signatario de la agenda',
      myselfAsSigner: 'Yo voy a firmar',
      selfSignerTooltip: 'Súmate fácilmente al proceso de firma.',
      selfSignerTitle: '¿Necesitas firmar también?',
      iGot: 'Entendido',
      orderSigners: 'Ordenar firmas',
      orderingInfo: 'Opción disponible para el envío de 1 documento',
      envelope: {
        unavailable: 'No disponible para este documento',
      },
      blockModal: {
        contacts: {
          title: 'No se pueden agregar firmantes',
          description:
            'Primero elimina a los firmantes con firmas rechazadas del documento para luego agregar firmantes de la agenda.',
        },
        addSigner: {
          title: 'No se pueden agregar firmantes',
          description:
            'Primero elimina a los firmantes con firmas rechazadas del documento para luego agregar nuevos firmantes.',
        },
        order: {
          title: 'No se pueden ordenar las firmas',
          description:
            'Primero elimina a los firmantes con firmas rechazadas del documento para luego ordenar las firmas.',
        },
      },
      reviewFormFieldsModal: {
        title: 'Error al incluir signatario del formulario',
        text: 'Esto puede ocurrir por dos motivos',
        missingFields: 'No localizamos los campos {0} y {1} (para firma vía WhatsApp).',
        requiredFields: 'Los campos están en el formulario, pero no son obligatorios.',
        email: 'Correo electrónico del signatario',
        phoneNumber: 'Teléfono/WhatsApp',
        helpLinkText: 'Aprende más sobre cómo agregar firmantes variables',
        formInUseTooltip: 'Ya está en uso por otro flujo.',
        close: 'Cerrar',
        instructions: 'Haz click en el enlace del formulario abajo para ajustarlo.',
      },
      contactTooltip: {
        title: '¿Quieres firmar este sobre?',
        // TOGGLE_ENVELOPE_SETUP_SCOPE
        // TODO: Remove titleBatch
        titleBatch: '¿Quieres firmar este documento?',
        content:
          '¡Tus datos ya están en la agenda de contactos! Solo haz click en {0} para agregarte.',
        signerFromContacts: 'Signatario de la agenda',
        action: 'Entendido',
      },
    },
    stepSettings: {
      name: 'Configuraciones',
      title: 'Configuraciones',
      subtitle: 'Configuraciones que se aplicarán a los documentos generados.',
      complianceProcess: 'Aprobación de las respuestas del flujo',
      complianceProcessInfo:
        'Si está activado, el flujo estará esperando la aprobación de un operador antes de que se genere y envíe el documento para firma.',
      complianceYes:
        'Activado. Esperar la aprobación de un operador para generar el documento y enviarlo para firma.',
      complianceNo: 'Desactivado. El documento se enviará automáticamente para firma.',
      notificationMembers:
        'Define quién recibirá notificaciones por correo electrónico para aprobar los flujos',
      deadlineDate: 'Fecha límite para firma',
      dateValue: '{day} día | {day} días',
      deadlineDateInfo:
        'Selecciona cuántos días los documentos estarán disponibles para ser firmados.',
      autoReminders: 'Recordatorios automáticos',
      autoRemindersInfo:
        'Envío de hasta 3 recordatorios por correo electrónico a los firmantes que aún no han firmado.',
      sendReminders: 'Enviar recordatorios automáticamente cada',
      documentLanguage: 'Idioma',
      documentLanguageInfo:
        'La página de firma y los correos electrónicos se mostrarán en el idioma seleccionado.',
      closeDocument: 'Finalización de los documentos',
      closeDocumentInfo: 'Elige cómo se finalizará el documento',
      autoClose: 'Después de la última firma, el documento se finalizará automáticamente.',
      manualClose:
        'Manualmente. Tendrás que finalizar el documento manualmente, de lo contrario, se finalizará en la fecha límite de firma.',
      english: 'Inglés (EE.UU.)',
      portuguese: 'Portugués',
      tooltip: {
        complianceProcess:
          'Revisa las respuestas de los formularios en la página de aprobación y envía para firma solo después de ser aprobado.',
        deadlineDate:
          'Cuando se alcance la fecha límite, el documento se finalizará automáticamente. Si no contiene ninguna firma, se cancelará.',
        documentLanguage:
          'Opción para cambiar el idioma mostrado en el momento de la firma del documento.',
        closeDocument: 'Elige cuándo se finalizará el documento.',
      },
      flowOperator: {
        title: 'Operador del flujo',
        description:
          'El correo electrónico de este operador se mostrará en el Registro de todos los documentos firmados generados por este flujo. Los usuarios tipo Observador no serán listados.',
      },
    },
    stepMessage: {
      name: 'Mensaje',
      title: 'Mensaje',
      subtitle:
        'Mensaje que se enviará a todos los firmantes cuando reciban el documento para firmar.',
      label: 'Mensaje (Opcional)',
    },
    stepActive: {
      allReady: 'Todo listo',
      templateGeneratedWithSuccess:
        '¡Tu plantilla ha sido creada exitosamente! Ahora puedes enviar el enlace a continuación a la persona que debe completar los campos del documento.',
      afterFillANewDocument:
        'Después de completarlo, se generará un nuevo documento y se enviará para firma automáticamente.',
      name: 'Activación',
      title: 'Activación',
      subtitle:
        'Al activar el flujo, se generará un nuevo documento después completar el formulario. Mientras esté activo, no podrás realizar cambios en el formulario ni en la plantilla que se estén utilizando en este flujo.',
      activatedInfo:
        'Envía este enlace a la persona que debe completar el formulario. Después de completarlo, se generará un nuevo documento y se enviará automáticamente para firma.',
      activeError:
        '¡Uuups! Hay una inconsistencia en este Autoflow. Revisa todos los pasos e intenta activar nuevamente.',
      createAnotherFlowError: '¡Ups! No se puede crear un nuevo flujo.',
    },
    upload: {
      template: 'Documento plantilla',
      notice: 'Para la creación de tu documento plantilla, solo se acepta un documento a la vez.',
      dragAndDrop: 'Arrastra y suelta aquí o',
      error: {
        format: 'Formato de archivo inválido. Selecciona un archivo .docx o .pdf.',
        general: 'Hubo un error con tu documento. Carga otro documento.',
        multipleDocs: 'Solo puedes enviar un documento a la vez.',
        requiredDoc: 'Selecciona al menos un archivo.',
      },
    },
    selectTemplate: {
      title: 'Selecciona una plantilla',
      subtitle: 'Carga un documento plantilla o selecciona uno existente.',
      uploadTemplate: 'Cargar un documento plantilla',
      or: 'o',
    },
    watcher: {
      title: 'Observadores',
      button: 'Agregar',
      email: {
        placeholder: 'Ingrese el correo electrónico',
        label: 'Quién seguirá la firma',
      },
      kind: {
        label: 'Cuándo será notificado',
        options: {
          allSteps: 'En cada firma del documento',
          onFinished: 'Solo al finalizar el documento',
        },
      },
      item: {
        allSteps: 'Será notificado en cada firma del documento',
        onFinished: 'Será notificado solo al finalizar el documento',
        checkbox: 'Recibir el documento finalizado',
      },
    },
  },
};

export default flow;
