import { axios } from '@/plugins';
import { buildURLParams } from '@base/utils/build-url-params';
import { spreadsheetFlowUtils } from '../../utils';

const createSpreadsheetFlow = async ({ getters, commit }) => {
  try {
    const spreadsheetFlowUrl = _.get(getters, 'getLinks.self');
    const response = await axios.post(spreadsheetFlowUrl);
    const responseData = _.get(response, 'data');
    commit('SET_LINKS', _.get(responseData, 'links'));

    return responseData;
  } catch (err) {
    throw err;
  }
};

const fetchTemplates = async ({ getters, commit }) => {
  try {
    const fetchFlowTemplatesUrl = _.get(getters, 'getLinks.templates');
    const response = await axios.get(fetchFlowTemplatesUrl);
    const responseData = _.get(response, 'data');
    commit('SET_TEMPLATES', _.get(responseData, 'templates'));

    return responseData;
  } catch (err) {
    throw err;
  }
};

const updateSpreadSheetFlow = async ({ getters, commit }, payload) => {
  try {
    const spreadsheetFlowUrl = _.get(getters, 'getLinks.self');
    const requestData = spreadsheetFlowUtils.buildUpdateSpreadsheetFlowRequest(payload);
    const response = await axios.patch(spreadsheetFlowUrl, requestData);
    const responseData = _.get(response, 'data');
    const parsedResponseData = spreadsheetFlowUtils.parseSpreadsheetFlowResponse(responseData);
    commit('SET_SPREADSHEET_FLOW', parsedResponseData);

    return responseData;
  } catch (err) {
    throw err;
  }
};

const updateSpreadSheetFlowFieldMappings = async ({ getters }, payload) => {
  try {
    const fieldMappingsUrl = _.get(getters, 'getLinks.mappings');
    const requestData = spreadsheetFlowUtils.buildFieldMappingsRequest(payload);
    await axios.post(fieldMappingsUrl, requestData);
  } catch (err) {
    throw err;
  }
};

const setupFinish = async ({ getters }) => {
  try {
    const setupFinishUrl = _.get(getters, 'getLinks.finish');
    const response = await axios.post(setupFinishUrl);
    const responseData = _.get(response, 'data');

    return responseData;
  } catch (err) {
    throw err;
  }
};

const fetchSpreadsheetFlowData = async ({ commit }) => {
  try {
    // TODO: refactor href implementation
    const url = window.location.href.replace('/edit', '');
    const response = await axios.get(url);
    const responseData = _.get(response, 'data');
    const spreadsheetSigners = _.get(response, 'data.spreadsheetFlow.spreadsheetFlowLists');
    const signers = _.get(response, 'data.spreadsheetFlow.spreadsheetFlowSignerLists');
    const parsedSigners = spreadsheetFlowUtils.parseSignersResponse(signers, spreadsheetSigners);
    const parsedResponseData = spreadsheetFlowUtils.parseSpreadsheetFlowResponse(responseData);

    commit('SET_SIGNERS', parsedSigners);
    commit('SET_LINKS', _.get(responseData, 'links'));
    commit('signer/SET_LINKS', responseData?.links.signerLinks, { root: true });
    commit('SET_SPREADSHEET_FLOW', parsedResponseData);

    return responseData;
  } catch (err) {
    throw err;
  }
};

const createSigner = async ({ getters }, payload) => {
  try {
    const requestData = spreadsheetFlowUtils.buildSignerCreationRequest(payload);
    const createSignerUrl = _.get(getters, 'getLinks.spreadsheetFlowLists');
    await axios.post(createSignerUrl, requestData);
  } catch (err) {
    throw err;
  }
};

const linkSigner = async ({ getters }, payload) => {
  try {
    const createSignerUrl = _.get(getters, 'getLinks.spreadsheetFlowSignerLists');
    await axios.post(createSignerUrl, payload);
  } catch (err) {
    throw err;
  }
};

const deleteSigner = async (_, deleteSignerUrl) => {
  try {
    await axios.delete(deleteSignerUrl);
  } catch (err) {
    throw err;
  }
};

const getSpreadsheetFlows = async ({ commit }, payload) => {
  try {
    const url = window.location.href;
    const params = payload;
    if (params.status === '') delete params.status;
    const { data } = await axios.get(`${url}${buildURLParams(params)}`);
    commit('SET_LINKS', data.links);
    commit('SET_SPREADSHEET_FLOWS', data.spreadsheetFlows);
    commit('SET_PAGINATION', data.pagination);
    return data;
  } catch (err) {
    throw err;
  }
};

const setPagination = ({ getters, commit }, payload) => {
  commit('SET_PAGINATION', { ...getters.getPagination, page: payload.page });
};

const getInviteLink = async ({ getters }) => {
  try {
    const spreadsheetFlowUrl = _.get(getters, 'getLinks.self');
    const response = await axios.get(spreadsheetFlowUrl);
    const responseData = _.get(response, 'data');
    return _.get(responseData, 'links.invite');
  } catch (err) {
    throw err;
  }
};

const fetchSpreadsheetRows = async ({ getters, commit }) => {
  const spreadsheetFlowProcessesUrl = _.get(getters, 'getLinks.spreadsheetFlowProcesses');
  const columns = _.get(getters, 'getSpreadsheetFlow.spreadsheetFields');
  const response = await axios.get(spreadsheetFlowProcessesUrl);
  const rows = spreadsheetFlowUtils.parseSpreadsheetRowsResponse(response, columns);

  commit('SET_SPREADSHEET_DATA', rows);
};

const fetchSpreadsheetFlowProgress = async ({ commit }, spreadsheetFlow) => {
  try {
    const progressUrl = _.get(spreadsheetFlow, 'links.progress');
    const response = await axios.get(progressUrl);
    const responseData = _.get(response, 'data.spreadsheetFlow') || {};
    const { status, documents: done, rows: total } = responseData;
    const spreadsheetFlowToUpdate = { ...spreadsheetFlow, status, progress: { done, total } };
    commit('UPDATE_SPREADSHEET_FLOW', spreadsheetFlowToUpdate);
    return responseData;
  } catch (err) {
    throw err;
  }
};

const cancelSpreadsheetFlow = async ({ commit }, spreadsheetFlow) => {
  try {
    const cancelationLink = _.get(spreadsheetFlow, 'links.cancel');
    await axios.post(cancelationLink);
    const spreadsheetFlowUpdated = { ...spreadsheetFlow, status: 'canceled' };
    commit('UPDATE_SPREADSHEET_FLOW', spreadsheetFlowUpdated);
  } catch (err) {
    throw err;
  }
};

const deleteSpreadsheetFlow = async ({ commit, state }, spreadsheetFlow) => {
  const deleteLink = _.get(spreadsheetFlow, 'links.delete');
  await axios.delete(deleteLink);
  const newSpreadsheets = state.spreadsheetFlows.filter((flow) => flow.id !== spreadsheetFlow.id);
  commit('SET_SPREADSHEET_FLOWS', newSpreadsheets);
};

const updateSpreadsheetRowData = async ({ commit }, payload) => {
  try {
    const updateRowDataLink = _.get(payload, 'links.self');
    const data = _.get(payload, 'data', []);
    const response = await axios.patch(updateRowDataLink, { row: data });
    const { row, id } = _.get(response, 'data.spreadsheetFlowProcess', {});
    commit('UPDATE_SPREADSHEET_ROW', { row, id });
  } catch (err) {
    throw err;
  }
};

const updateProcesssesBinding = async ({ commit, getters }, payload) => {
  try {
    const updateProcessesBindingLink = _.get(getters, 'getLinks.updateProcessesBinding');
    const processKeys = payload.map(({ key }) => key);
    const response = await axios.post(updateProcessesBindingLink, { processKeys });
    const responseData = _.get(response, 'data.spreadsheetFlow');
    commit('SET_SPREADSHEET_DATA_ANALYSIS', responseData);
  } catch (err) {
    throw err;
  }
};

export default {
  createSpreadsheetFlow,
  fetchTemplates,
  updateSpreadSheetFlow,
  updateSpreadSheetFlowFieldMappings,
  setupFinish,
  fetchSpreadsheetFlowData,
  createSigner,
  deleteSigner,
  getSpreadsheetFlows,
  setPagination,
  getInviteLink,
  linkSigner,
  fetchSpreadsheetFlowProgress,
  cancelSpreadsheetFlow,
  deleteSpreadsheetFlow,
  fetchSpreadsheetRows,
  updateSpreadsheetRowData,
  updateProcesssesBinding,
};
