const messageTemplate = {
  createNewMessage: 'Criar nova mensagem',

  dropdownActivator: 'Mensagens salvas',
  deleteTitle: 'Excluir mensagem salva',
  deleteConfirmation: 'Tem certeza que deseja excluir a mensagem abaixo?',
  newMessageSubtitle:
    'Crie uma mensagem para reutilizá-la, ela ficará salva para usar ao enviar documentos.',
  newMessageInputName: 'Nome',
  newMessageInputMessage: 'Mensagem',
};

export default messageTemplate;
