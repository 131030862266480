import { get } from 'lodash';

const usePromise = async (promise, finallyCb = () => null) => {
  try {
    const response = await promise;
    return [null, get(response, 'data', response) || true];
  } catch (error) {
    return [error, null];
  } finally {
    finallyCb();
  }
};

export { usePromise };
