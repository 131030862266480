import { getViewProps } from '@base/utils/getViewDataset';
import store from '@/store';
import multiguard from 'vue-router-multiguard';
import { getExpressSubscription } from '@account/utils/express-subscription';
import billing from './billing';
import { verifyAdmin, onboardingNewGuard } from './navigation-guards';
import { pendingBillsGuard } from './navigation-guards/pending-bills/index';

const isAccountProcessingCancellation = () => store.state?.info.account?.processingCancellation;

const routes = [
  {
    view: 'AccountChooseView',
    component: () => import('@/modules/account/views/AccountChooseView/AccountChooseView.vue'),
  },
  {
    path: '/onboarding/new',
    name: 'onboarding',
    beforeEnter: onboardingNewGuard,
    component: () => import('@account/views/onboarding/OnboardingView/OnboardingView.vue'),
  },
  {
    path: '/onboarding/account/new',
    name: 'onboardingAccountView',
    component: () =>
      import('@account/views/onboarding/OnboardingAccountView/OnboardingAccountView.vue'),
    props: getViewProps,
  },
  {
    path: '/accounts/:accountId/plans',
    name: 'SelectPlan',
    beforeEnter: multiguard([verifyAdmin, pendingBillsGuard]),
    component: () => import('@account/views/AccountSubscriptionView.vue'),
    meta: {
      layout: 'BaseDefaultLayout',
      closeLink: {
        show: true,
        icon: getExpressSubscription() ? 'times' : 'arrow-left',
        locale: getExpressSubscription()
          ? 'layout.header.continueTrial'
          : 'layout.header.backToHome',
        gaEvent: {
          category: 'plans-app',
          name: 'checkout-dropoff',
          label: 'back-to-dashboard',
        },
      },
      hideTrial: true,
      refresh: () => true,
    },
    props: getViewProps,
    children: [
      {
        beforeEnter: pendingBillsGuard,
        path: ':planId',
        name: 'confirmChangePlan',
        meta: {
          layout: 'BaseDefaultLayout',
          closeLink: {
            show: true,
            icon: 'arrow-left',
            locale: 'layout.header.backToHome',
            gaEvent: {
              category: 'plans-app',
              name: 'checkout-dropoff',
              label: 'back-to-dashboard',
            },
          },
          hideTrial: true,
          refresh: () => true,
        },
      },
    ],
  },
  {
    beforeEnter: verifyAdmin,
    path: '/accounts/:accountId/customer/new',
    name: 'planCheckout',
    component: () => import('@account/views/AccountSubscriptionView.vue'),
    meta: {
      layout: 'BaseDefaultLayout',
      closeLink: {
        show: true,
        icon: getExpressSubscription() ? 'times' : 'arrow-left',
        locale: getExpressSubscription()
          ? 'layout.header.continueTrial'
          : 'layout.header.backToHome',
        gaEvent: {
          category: 'plans-app',
          name: 'checkout-dropoff',
          label: 'back-to-plans-selection',
        },
      },
      hideTrial: true,
      refresh: () => true,
    },
    props: getViewProps,
  },
  {
    beforeEnter: pendingBillsGuard,
    path: '/accounts/:accountId/customer/edit',
    component: () => import('@account/views/AccountSubscriptionView.vue'),
    meta: {
      layout: 'BaseDefaultLayout',
      closeLink: {
        show: true,
        icon: 'arrow-left',
        locale: 'layout.header.backToHome',
      },
      hideTrial: true,
      refresh: () => true,
    },
    props: getViewProps,
  },
  {
    beforeEnter: verifyAdmin,
    path: '/accounts/:accountId/subscription/new',
    component: () => import('@account/views/AccountSubscriptionView.vue'),
    meta: {
      layout: 'BaseDefaultLayout',
      closeLink: {
        show: true,
        icon: 'arrow-left',
        locale: 'layout.header.backToHome',
      },
      hideTrial: true,
      refresh: () => true,
    },
    props: getViewProps,
  },
  {
    path: '/accounts/:accountId/blocked',
    view: 'BlockedAccountView',
    meta: {
      layout: 'BaseDefaultLayout',
      hideHeadway: true,
      hideHubspot: true,
      hideTrial: true,
    },
    component: () => {
      if (isAccountProcessingCancellation())
        return import(
          '@account/views/blocked/BlockedAccountCancellationView/BlockedAccountCancellationView.vue'
        );

      return import('@account/views/blocked/BlockedAccountView.vue');
    },
  },
  {
    path: '/accounts/:accountId/ip_blocked',
    view: 'IpRangeBlockedView',
    component: () => {
      return import('@account/views/blocked/IpRangeBlockedView/IpRangeBlockedView.vue');
    },
  },
  {
    path: '/accounts/:accountId/denied',
    name: 'DeniedAccountView',
    component: () => import('@account/views/DeniedAccountView/DeniedAccountView.vue'),
  },
  {
    path: '/accounts/:accountId/api_settings',
    name: 'SettingsApiView',
    component: () => import('@account/views/settings/SettingsApiView/SettingsApiView.vue'),
  },
  {
    path: '/accounts/:accountId/brand',
    name: 'SettingsCustomBrandingView',
    component: () => import('@account/views/settings/SettingsCustomBrandingView.vue'),
  },
  {
    view: 'SettingsConsumptionView',
    component: () => import('@account/views/settings/SettingsConsumptionView.vue'),
  },
  {
    path: 'settingsBilling',
    component: () => import('@account/views/settings/SettingsBillingView.vue'),
  },
  {
    path: '/accounts/:accountId/security',
    name: 'securitySettings',
    component: () => import('@/modules/account/views/settings/SettingsSecurityView.vue'),
  },
  {
    view: 'SettingsMembershipsView',
    component: () => import('@account/views/settings/SettingsMembershipsView.vue'),
  },
  {
    path: '/accounts/:accountId/edit',
    name: 'accountEdit',
    component: () => import('@account/views/settings/SettingsProfileView.vue'),
  },
  {
    path: '/accounts/:accountId/audits',
    name: 'accountAudits',
    component: () => import('@account/views/audit/AccountAuditView.vue'),
  },
  {
    path: '/accounts/:accountId/beta',
    name: 'betaSettings',
    component: () =>
      import('@account/views/settings/SettingsBetaProgramView/SettingsBetaProgramView.vue'),
  },
  {
    path: '/accounts/:accountId/get_started',
    name: 'getStarted',
    component: () => import('@account/views/GetStartedView/GetStartedView.vue'),
  },
  {
    path: '/accounts/:accountId/additional_data',
    name: 'additionalData',
    component: () =>
      import(
        '@account/views/onboarding/OnboardingAdditionalDataView/OnboardingAdditionalDataView.vue'
      ),
  },
  ...billing,
];

export default routes;
