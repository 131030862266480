import { getViewProps } from '@base/utils/getViewDataset';

const routes = [
  {
    path: '/403',
    component: () => import('../PageError403.vue'),
    meta: {
      layout: 'BaseErrorLayout',
      refresh: () => true,
    },
    props: getViewProps,
  },
  {
    path: '/404',
    component: () => import('../PageError404.vue'),
    meta: {
      layout: 'BaseErrorLayout',
      refresh: () => true,
    },
    props: getViewProps,
  },
  {
    path: '/410',
    component: () => import('../PageError410.vue'),
    meta: {
      layout: 'BaseErrorLayout',
      refresh: () => true,
    },
    props: getViewProps,
  },
  {
    path: '/423',
    component: () => import('../PageError423.vue'),
    meta: {
      layout: 'BaseErrorLayout',
      refresh: () => true,
    },
    props: getViewProps,
  },
  {
    path: '/500',
    component: () => import('../PageError500.vue'),
    meta: {
      layout: 'BaseErrorLayout',
      refresh: () => true,
    },
    props: getViewProps,
  },
];

export default routes;
