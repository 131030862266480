export const TOGGLE_SCOPES = {
  // TODO: TOGGLE_MIDAS_SCOPE
  // when scope > 3 remove reportConsumption and changePlanViaMidas vars
  billing: {
    reportConsumption: 3,
    newHistoricInvoices: 3,
    changePlanViaMidas: 2,
  },
  // TOGGLE_AI_CHAT_ASSISTANT_SCOPE
  // TODO: when scope > 2 remove signerEdit const
  chat: {
    signerEdit: 2,
  },
};
