const plans = {
  integration: 'Integração',
  eventual: 'Eventual',
  essencial: 'Essencial',
  profissional: 'Profissional',
  empresarial: 'Empresarial',
  budget: 'Budget',
  basic: 'Basic',
  standard: 'Standard',
  ilimitado: 'Ilimitado',
  growth: 'Growth',
  start: 'Start',
  plus: 'Plus',
  avancado: 'Avançado',
};

const basicFeatures = {
  auth: 'Assinatura via e-mail',
  order: 'Ordenação de assinaturas',
  handwritten: 'Assinatura Manuscrita',
  pix: 'Autenticação por PIX',
  certificate: 'Autenticação por Certificado Digital',
  officialDocument: 'Autenticação por Documento Oficial',
  massive: 'Assinatura em massa',
};

const subscription = {
  planPromotionToast: 'Promoção aplicada com sucesso!',
  confirmChangePlan: {
    title: 'Confirme a alteração de plano',
  },
  successChangePlan: {
    title: 'O plano foi alterado com sucesso',
  },
  plan: {
    title: 'Plano',
    changePlan: 'Nossos planos mudaram para melhor! Se preferir, veja os {0}',
    newPlans: 'Confira os novos planos.',
    product: {
      automation: 'Automação',
      ...plans,
    },
    commercialName: {
      customized: 'Personalizado',
      ...plans,
    },
    feature: {
      monthDocuments: 'R$ 8,50/documento | {quantity} documento/mês | {quantity} documentos/mês',
      usersPerAccount: '1 usuário na conta | {quantity} usuários na conta',
      pricePerDocument: 'R$ {price} por documento finalizado',
      periodicity: '/mês',
      onDemand: 'Sob medida',
      moreDocuments: 'Mais de 150 documentos por mês',
    },
    comparePlans: {
      title: 'Compare os planos',
    },
    edit: {
      title: 'Confirme a alteração de plano',
      consumption: {
        title: 'Seu consumo',
        usage: 'Você usou {0} dos {1} dias contratados no plano atual.',
        charge:
          ' A cobrança é realizada pela disponibilização do serviço. {0} Em caso da utilização parcial o desconto proporcional será aplicado.',
        link: 'Entenda como funciona nosso cálculo.',
        invoice: 'Obs: Em caso de {0} no período atual, será gerada uma fatura adicional.',
        surplusConsumptionLink: 'consumo excedente',
      },
      new: {
        title: 'Ao confirmar, será gerada uma fatura no valor de:',
        newMonthlyPayment: 'Mensalidade do novo plano',
        discountApplied: 'Desconto aplicado',
        firstNewPayment: 'Primeira mensalidade do plano atual',
        review: 'Revise as informações antes de confirmar',
      },
    },
    changeSuccess: {
      title: 'O plano foi alterado com sucesso',
      description: 'Agora seu plano é o {0}',
      action: 'Voltar para plano e cobrança',
      eventual: {
        variety: 'Diversas autenticações',
        panel: 'Painel de gestão de conta',
        signature: 'Assinatura digital',
        reports: 'Relatórios completos',
        storage: 'Armazenamento de documentos',
      },
      start: {
        auth: 'Assinatura via e-mail',
        kind: '4 tipos de autenticação',
        api: 'Integração via API',
      },
      plus: {
        auth: 'Assinatura via e-mail, SMS e WhatsApp',
        kind: '8 tipos de autenticação',
        audit: 'Auditoria da conta',
      },
      integration: {
        features: 'Todas as funcionalidades dos planos Assinatura',
        api: 'Integração com a Clicksign via API',
      },
    },
    changeError: {
      same: 'Não é possível efetuar troca para o mesmo plano já contratado',
      generic: 'Algo deu errado, entre em contato com a',
      exceded:
        'Você excedeu o número de alterações de plano dentro do período permitido. Entre em contato com o suporte através do chat ou ',
    },
  },
  // TOGGLE_BLACK_FRIDAY_ENABLED
  // TODO: Remove all `promotion` key locale below
  promotion: {
    title: 'Click Friday',
    planCardHighlight: {
      plus: 'Garanta o dobro de documentos mensais ao contratar este plano*',
      avancado: 'Garanta até 500 documentos bônus no ano ao contratar este plano*',
    },
    rules: {
      link: 'Confira o regulamento',
      description: '* Bônus de documentos por tempo limitado. {0}',
    },
  },
  planFeature: {
    select: '{amount} documentos por mês',
    included: 'Inclui:',
    essentials: 'Recursos essenciais:',
    additionals: 'Mais recursos, sem custo adicional:',
    customizable: 'Recursos customizáveis:',
    startsWith: {
      users: 'Até {users} usuários',
      apiIntegration: 'Integração via API ou Zapier',
      periodicity: 'Contratação mensal',
    },
    start: {
      basicFeatures: {
        ...basicFeatures,
        whatsApp: 'Aceite via WhatsApp',
      },
    },
    plus: {
      basicFeatures: {
        ...basicFeatures,
        whatsApp: 'Aceite via WhatsApp',
      },
      additionalFeatures: {
        whatsAppSign: 'Assinatura via WhatsApp',
        smsSign: 'Assinatura via SMS',
        authFacial: 'Autenticação por Biometria',
        authDocumentSelfie: 'Autenticação por Selfie com Documento',
        authSelfie: 'Autenticação por Selfie Dinâmica',
        customSender: 'Remetente Customizado',
        customization: 'Personalização com logo e cores',
      },
    },
    avancado: {
      startsWith: {
        users: 'Usuários ilimitados',
        apiIntegration: 'Integração via API ou Zapier',
        periodicity: 'Contratação anual ou mensal',
      },
      basicFeatures: {
        ...basicFeatures,
        packages: 'Pacotes de aceite',
      },
      customFeatures: {
        whatsAppSign: 'Assinatura via WhatsApp',
        authFacial: 'Autenticação por Biometria',
        authDocumentSelfie: 'Autenticação por Selfie com Documento',
        authSelfie: 'Autenticação por Selfie Dinâmica',
        customSender: 'Remetente Customizado',
        customization: 'Personalização com logo e cores',
        presential: 'Assinatura presencial',
        sso: 'Single Sign On (SSO)',
        api: 'Modelos via API',
        embedded: 'Widget embedded',
        automation: 'Automação',
      },
    },
  },
  // TO-DO: Move blockedFunctionality block below to a new
  // single file in this section Subscription, called Premium
  blockedFunctionality: {
    title: 'Upgrade de plano',
    button: 'Fazer upgrade',
    availableOnPlusPlan: 'Disponível a partir do plano Plus.',
    availableOnCustomPlan: 'Disponível no plano Avançado.',
    imageAlt: 'Mulher mexendo no laptop',
    customization: {
      title: 'Faça um upgrade para personalizar',
      firstText:
        'A experiência de assinatura dos seus clientes pode ter a cara da sua empresa. Faça upgrade para personalizar os próximos envios.',
    },
    integration: {
      title: 'Solicite um upgrade para usar a API da Clicksign',
      titleAdvanced: 'Turbine seu plano Avançado adicionando API',
      text: 'Integre com a API e comece a gerar e enviar seus documentos automaticamente.',
      link: 'Saiba mais em Documentação de API',
      buttonAdvanced: 'Adicionar recurso',
    },
    batchDownload: {
      title: 'Faça um upgrade para baixar documentos em massa',
      firstText: 'Baixe vários documentos de uma só vez de forma simples e rápida.',
      lastText: 'Disponível nos Planos Start, Plus e Avançado.',
    },
    customSender: {
      title: 'Remetente customizado ',
      firstText:
        'Escolha um remetente para os e-mails enviados pela Clicksign, como por exemplo: cadastro@suaempresa.com',
      lastText: 'Disponível a partir do Plano Empresarial. {0}',
      more: 'Saiba mais.',
    },
    additionalAuths: {
      title: 'Faça um upgrade para liberar funcionalidades',
      titleMobile: 'Faça um upgrade para liberar',
      firstText:
        'Dê ainda mais segurança ao seu processo de assinatura adicionando novas camadas de validação para quem assina.',
      secondText:
        'Faça um upgrade de plano para usar Selfie com documento, Selfie dinâmica e Biometria facial.',
    },
    accountAudit: {
      title: 'Faça um upgrade para usar  auditoria da conta',
      firstText: `Acesse o relatório de auditoria da conta para monitorar as atividades dos usuários na plataforma. Mais segurança e transparência no uso da Clicksign.`,
    },
    security: {
      title: 'Faça um upgrade para usar recursos de segurança',
      firstText:
        'Escolha o tempo limite da sessão, autorize acesso apenas de IPs específicos e habilite o login com a credencial do ambiente da sua empresa (Single Sign On).',
    },
  },
  price: {
    title: 'Planos',
    planTitle: 'Plano {planName}',
    perfectPlan: 'Aqui tem um plano perfeito para você',
    categoryText: 'Planos anuais e mensais a partir de',
    chooseSubtitle:
      'Planos sem fidelidade, na medida para o seu negócio. Escolha quantos documentos quer enviar e contrate!',
    chooseEventual: {
      title: 'Ainda não envia documentos todo mês?',
      info: 'Escolha o plano eventual e pague R$ {price} por documento finalizado.',
      action: 'Contratar plano Eventual',
    },
    value: {
      currency: 'R$',
      free: 'Grátis',
      period: 'mês',
      documents: 'documento | documentos',
      periodOld: 'Mês',
      bespokePerformance: 'Perfomance sob medida',
    },
    automation: 'Comece a gerar seus documentos de forma automatizada e simples',
    noValue: 'Pague só quando usar',
    seeMore: 'Ver mais',
    seeMinor: 'Ver menos',
    action: {
      subscribe: 'Contratar',
      currentPlan: 'Plano atual',
      talkToSales: 'Falar com vendas',
      choicePlan: 'Escolher',
      choiceCustomPlan: 'Fale com o nosso time comercial',
      talkToConsultant: 'Falar com um consultor',
    },
    calculator: {
      integration: {
        title: 'Simule o valor da sua fatura',
        description: 'Quanto mais você usa, menor o preço cobrado pelo documento',
        inputLabel: 'Documentos por mês',
        pricingLabel: 'Valor da sua fatura',
        pricingTooltip: 'SMS, Whatsapp e Widget são custos adicionais',
        customPlanSuggestionLabel: 'Conheça o plano personalizado!',
        customPlanSuggestionDescription:
          'O ideal para você é um plano personalizado. {0} Fale com nosso time de vendas.',
        pricingDetailsTitle: 'Entenda o cálculo',
        resumedPricingRangeLabel: 'Faixa de documentos',
        pricingRangeLabel: 'Faixa de documentos (40, 100 ou 200)',
        pricingBaseValueLabel: 'Preço da faixa',
        pricingExceededDocumentsLabel: 'Documentos excedentes',
        pricingExceededDocumentsValueLabel: 'Preço do documento excedente',
        pricingTotalValue: 'Valor da fatura',
      },
    },
  },
  form: {
    automation: {
      challenger: {
        title: 'Quais os desafios do seu processo de assinatura atual?',
      },
      documentSystem: {
        title: 'Seus documentos são gerados por algum sistema? (CRM, ERP, Sistema Próprio, etc)',
      },
      documentModel: {
        title: 'Seus documentos seguem um modelo padrão?',
      },
      functionality: {
        title: 'Qual funcionalidade você gostaria de conhecer melhor?',
      },
      aditional: 'Deixe um comentário, dúvida ou necessidade adicional',
    },
    resume: {
      title: 'Resumo',
      monthDocuments:
        'R$8,50/documento | {quantity} documento mensal | {quantity} documentos mensais',
      usersPerAccount: '1 Usuário na conta | {quantity} Usuários na conta',
      integration: 'Precificação de acordo com o consumo',
      whatsapp: 'Assinaturas mais rápidas usando WhatsApp',
      price: 'R$ {price}/mês',
      fromValue: 'A partir de',
      button: 'Alterar plano',
      // TOGGLE_BLACK_FRIDAY_ENABLED
      // TODO: Remove blackFridayIncentiveTitle and documentBonus locale
      blackFridayIncentiveTitle: 'Oferta ClickFriday',
      documentBonus: 'Mais {documentsAmount} documentos mensais durante 3 meses',
    },
    title: 'Dados',
    subtitle: {
      info: 'Dados do cliente',
      contact: 'Contato',
      address: 'Endereço de cobrança',
    },
    field: {
      companyName: 'Nome da empresa',
      tradingName: 'Razão social',
      personName: 'Nome completo',
      cnpj: 'CNPJ',
      cpf: 'CPF',
      zipCode: 'CEP',
      dontKnowZipCode: 'Não sabe o CEP',
      // TOGGLE_BRASIL_API_ZIPCODE_ENABLED
      // TODO: Remove `zipCodeInfo` locale below
      zipCodeInfo: 'Certifique-se de que o CEP está correto',
      address: {
        label: 'Endereço',
        placeholder: 'Rua, avenida',
      },
      number: {
        label: 'Número',
        placeholder: 'Número ou s/n',
      },
      complement: {
        label: 'Complemento',
        labelOld: 'Complemento (opcional)',
        placeholder: 'Edifício, andar, sala, apartamento, etc',
      },
      district: 'Bairro',
      city: 'Cidade',
      uf: 'Estado',
      phoneNumber: 'Telefone',
      segment: 'Segmento de atuação',
      legalPerson: 'Pessoa Jurídica',
      naturalPerson: 'Pessoa Física',
      paymentEmail: {
        label: 'E-mail de cobrança',
        help: 'As faturas para pagamento serão enviadas para este endereço de e-mail.',
      },
    },
    nextStep: 'Confirmar',
  },
  checkout: {
    title: 'Pagamento',
    stepTitle: 'Pagamento',
    creditCardForm: {
      title: 'Dados do cartão de crédito',
      visaAlt: 'Bandeira Visa',
      mastercardAlt: 'Bandeira Mastercard',
      eloAlt: 'Bandeira Elo',
      americanExpressAlt: 'Bandeira American Express',
      dinersClubAlt: 'Bandeira Diners',
      hipercardAlt: 'Bandeira HiperCard',
      titleTooltip: 'Aceitamos as principais bandeiras de cartão',
      description: 'A fatura mensal será cobrada neste cartão.',
      cardNumber: 'Número do cartão',
      cardNumberPlaceHolder: '0000 0000 0000 0000',
      cardName: 'Nome impresso no cartão',
      expireDate: 'Data de validade',
      expireDatePlaceholder: 'MM/AAAA',
      cvv: 'CVV',
      cvvPlaceholder: '000',
      submit: 'Confirmar',
      success: 'Cartão adicionado com sucesso',
    },
    error: {
      creditCardInvalid:
        'Revise as informações e o limite do cartão escolhido ou tente usar um cartão diferente.',
    },
    bankSlipForm: {
      description: 'O boleto será enviado por e-mail após a confirmação.',
    },
    subscriptionLoadingModal: {
      title: 'Pagamento em processo',
    },
    creditCardErrorModal: {
      title: 'Pagamento recusado ou não processado',
      description:
        'Revise as informações e o limite do cartão escolhido ou tente usar um cartão diferente.',
    },
  },
  resume: {
    title: 'Confirmação',
    subtitleBankSlip: 'Seu plano está temporariamente ativo!',
    subtitleCreditCard: 'Sucesso, seu plano está ativo!',
    eventualSubtitle: 'Seu plano está ativo!',
    customSubtitle: 'Solicitação realizada com sucesso',
    descriptionBankSlip:
      'Efetue o pagamento para garantir o acesso! Para pagar a fatura, clique no botão abaixo ou acesse o boleto enviado por e-mail.',
    descriptionCreditCard: 'Aproveite para começar a usar os novos recursos disponíveis para você',
    eventualSubtitleBankSlip: 'Seu plano está ativo!',
    eventualDescriptionBankSlip: 'Você receberá a fatura por e-mail caso exista algum consumo',
    eventualSubtitleCreditCard: 'Sucesso, seu plano está ativo!',
    eventualDescriptionCreditCard:
      'A cobrança será lançada no cartão de crédito indicado quando houver consumo',
    eventualCreditCardHelpLink:
      'Se a fatura do mês corrente for inferior a R$ 30,00 a cobrança será feita no mês seguinte',
    customDescription:
      'Nosso time comercial entrará em contato em alguns instantes para efetivar a sua contratação.',
    help: {
      text: 'Dúvidas? Envie um e-mail para:',
      email: 'ajuda@clicksign.com',
    },
    goToPayment: 'Ir para pagamento',
    goToHome: 'Ir para o início',
    action: 'Confirmar escolha do plano',
  },
  success: {
    title: 'Plano {plan} ativo',
    description: 'Enviamos um e-mail para {0} com instruções para efetuar o pagamento.',
    action: 'Ir para minha conta',
  },
  blockedByAdminModal: {
    title: '{blockedFeatureTitle} indisponível para sua conta',
    description: 'Fale com o suporte para habilitar essa funcionalidade.',
    action: 'Entendi',
    blockedFeatureTitle: {
      acceptanceTermByWhatsapp: 'WhatsApp',
    },
  },
  // TOGGLE_BLACK_FRIDAY_ENABLED
  // TODO: Remove the locale block below
  planPromotionAlert: {
    title: 'Ainda dá tempo de garantir o dobro de documentos',
    features: 'Um bônus de documentos para você fechar mais contratos na sua Black Friday',
    action: 'Conheça as ofertas',
  },
};

export default subscription;
