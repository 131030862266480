import { getViewProps } from '@base/utils/getViewDateset';
import store from '@/store';
import { verifyAdmin } from './navigation-guards';

// TOGGLE_MIDAS_CLIENT_INTEGRATION_ENABLED
// TODO: remove midasEnabled
const midasEnabled = () => store.state.info.toggles.toggleMidasClientIntegrationEnabled;

// TOGGLE_MIDAS_SCOPE
// TODO: Remove midasScope variable
const midasScope = () => store.state.info.toggles.toggleMidasScope;

const billing = [
  {
    path: '/accounts/:accountId/consumption',
    props: getViewProps,
    meta: { refresh: () => !midasEnabled() },
    // TOGGLE_MIDAS_CLIENT_INTEGRATION_ENABLED
    // TODO: keep true condition
    component: () =>
      midasEnabled()
        ? import('@account/views/settings/SettingsBillingView.vue')
        : import('@account/views/settings/SettingsConsumptionView.vue'),
    children: [
      {
        path: '',
        name: 'settingsBillingDetails',
        meta: { layout: 'BaseNavigationLayout' },
        component: () =>
          // TOGGLE_MIDAS_SCOPE
          // TODO: keep true condition
          midasScope() >= 3
            ? import(
                '@account/containers/settings/BillingDetailsContainer/BillingDetailsContainer.vue'
              )
            : import(
                '@account/containers/settings/BillingDetailsContainer/BillingDetailsContainerOld.vue'
              ),
      },
      {
        path: 'cancel',
        beforeEnter: verifyAdmin,
        name: 'settingsAccountCancellation',
        meta: {
          backTo: {
            pathName: 'settingsBillingDetails',
          },
        },
        component: () =>
          import(
            '@account/containers/settings/AccountCancellationContainer/AccountCancellationContainer.vue'
          ),
      },
      {
        path: 'info',
        name: 'settingsBillingInfo',
        component: () =>
          import('@account/containers/settings/BillingFormContainer/BillingFormContainer.vue'),
        beforeEnter: verifyAdmin,
        meta: {
          children: true,
          hideTabs: true,
          backTo: {
            pathName: 'settingsBillingDetails',
          },
        },
      },
      {
        path: 'payments',
        component: () =>
          import(
            '@account/containers/settings/BillingChangePaymentMethodContainer/BillingChangePaymentMethodContainer.vue'
          ),
        beforeEnter: verifyAdmin,
        children: [
          {
            path: '',
            name: 'settingsBillingPaymentMethod',
            component: () =>
              import(
                '@account/containers/settings/BillingSelectPaymentMethodContainer/BillingSelectPaymentMethodContainer.vue'
              ),
            meta: {
              children: true,
              hideTabs: true,
              breadcrumb: [
                {
                  pathName: 'settingsBillingDetails',
                  locale: 'billing.plan.stepperPaths.title',
                },
                {
                  pathName: 'settingsBillingPaymentMethod',
                  locale: 'billing.plan.stepperPaths.change',
                },
              ],
              backTo: {
                pathName: 'settingsBillingDetails',
              },
            },
          },
          {
            path: 'add',
            name: 'addBillingPaymentMethod',
            component: () =>
              import(
                '@account/components/settings/BillingAddPaymentMethod/BillingAddPaymentMethod.vue'
              ),
            beforeEnter: (to, from, next) => {
              const hasCurrentPaymentMethod = to.params?.currentPaymentMethod;
              if (hasCurrentPaymentMethod) {
                next();
              } else {
                next({
                  name: 'settingsBillingPaymentMethod',
                  params: {
                    accountId: to.params.accountId,
                  },
                });
              }
            },
            meta: {
              children: true,
              hideTabs: true,
              backTo: {
                pathName: 'settingsBillingPaymentMethod',
              },
              breadcrumb: [
                {
                  pathName: 'settingsBillingDetails',
                  locale: 'billing.plan.stepperPaths.title',
                },
                {
                  pathName: 'settingsBillingPaymentMethod',
                  locale: 'billing.plan.stepperPaths.change',
                },
                {
                  pathName: 'addBillingPaymentMethod',
                  locale: 'billing.plan.stepperPaths.add',
                },
              ],
            },
          },
          {
            path: 'add/confirm',
            name: 'confirmBillingPaymentMethod',
            component: () =>
              import(
                '@account/components/settings/BillingConfirmPaymentMethod/BillingConfirmPaymentMethod.vue'
              ),
            beforeEnter: (to, from, next) => {
              const hasCurrentPaymentMethod = to.params?.currentPaymentMethod;
              const hasPaymentProfile = to.params?.newPaymentMethod;
              if (hasCurrentPaymentMethod && hasPaymentProfile) {
                next();
              } else {
                next({
                  name: 'settingsBillingPaymentMethod',
                  params: {
                    accountId: to.params.accountId,
                  },
                });
              }
            },
            meta: {
              children: true,
              hideTabs: true,
              backTo: {
                pathName: 'settingsBillingPaymentMethod',
              },
              breadcrumb: [
                {
                  pathName: 'settingsBillingDetails',
                  locale: 'billing.plan.stepperPaths.title',
                },
                {
                  pathName: 'settingsBillingPaymentMethod',
                  locale: 'billing.plan.stepperPaths.change',
                },
                {
                  pathName: 'addBillingPaymentMethod',
                  locale: 'billing.plan.stepperPaths.add',
                },
                {
                  pathName: 'confirmBillingPaymentMethod',
                  locale: 'billing.plan.stepperPaths.confirm',
                },
              ],
            },
          },
        ],
        meta: { children: true },
      },
      {
        path: '*',
        redirect: () => {
          window.location.href = '/404';
        },
      },
    ],
  },
];

export default billing;
