import wizardEdit from './wizard-edit';
import setupPreference from './setup-preference';
import signatures from './signatures';

const envelope = {
  show: {
    tabs: {
      overview: 'Información general',
      documents: 'Documentos',
      settings: 'Configuraciones',
      signatures: 'Firmas',
    },
  },
  settings: {
    successfullySaved: '¡Configuraciones guardadas con éxito!',
    title: 'Configuraciones del sobre',
    closeDocumentInfo: '¿Cuándo debe ser completado el sobre?',
    documentClose: {
      subtitle: '¿Cuándo debe finalizarse el sobre?',
    },
  },
  overview: {
    remindAll: {
      action: 'Enviar recordatorio a los firmantes',
      modalTitle: 'Enviar recordatorio de firma',
      signersWillReceiveLinkAgain:
        'Los firmantes que aún necesitan firmar recibirán de nuevo el enlace con el documento.',
      messagePlaceholder: 'Escribe un mensaje, si lo deseas',
      whatsappCost: 'Para envíos vía WhatsApp, el costo es de R$ 0,40 por recordatorio.',
      error: 'Error al enviar el mensaje.',
      success: '¡Mensaje enviado con éxito!',
    },
    header: {
      status: {
        running: 'Sobre en proceso',
        canceled: 'Sobre cancelado',
        closed: 'Sobre finalizado',
      },
      finishedAt: 'El {date} a las {time}',
      deadlineAt: 'Límite para firmar: {date} a las {time}',
    },
    documents: {
      title: 'Documentos',
      list: {
        signaturesCount: '{signatures}/{signers} firma | {signatures}/{signers} firmas',
        previewTooltip: 'Visualizar documento.',
        signatureStatus: {
          refused: 'Firma rechazada',
        },
      },
    },
    edit: {
      action: 'Editar sobre',
      disabled: {
        canceled: 'Sobre cancelado. Opción no disponible',
        closed: 'Sobre finalizado. Opción no disponible',
      },
      error: 'Error al editar el sobre.',
    },
  },
  signatures,
  setupPreference,
  wizardEdit,
};

export default envelope;
