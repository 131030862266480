const setupPreference = {
  changeError: 'Erro ao alterar tipo de envio. Confira a conexão e tente de novo.',
  enabled: {
    switch: 'Envelope habilitado',
    text: 'Você está usando o envelope, uma nova versão de envio.',
    link: 'O que muda?',
  },
  disabled: {
    switch: 'Envelope desabilitado',
    text: 'Você está no modo padrão de envio.',
    link: 'Saiba mais sobre envelope',
  },
};

export default setupPreference;
