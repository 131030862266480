const fieldCreator = {
  required: 'Campo obrigatório',
  createField: 'Criar campo',
  labelPlaceholder: 'Novo campo',
  insertFieldOnTemplate: 'Insira esse campo no seu documento modelo',
  fieldAddSuccess: 'Campo adicionado com sucesso',
  actions: {
    insertInTemplate: 'Inserir no modelo',
    move: 'Mover para {0}º formulário',
  },
  tabs: {
    form1: '1º Formulário',
    form2: '2º Formulário',
  },
  secondForm: {
    info1:
      'O 2º formulário é utilizado quando o seu documento requer informações de várias pessoas.',
    info2:
      'Exemplo: O comprador preenche os dados cadastrais e o Vendedor preenche as informações do pedido.',
    info3:
      'Os campos designados para serem preenchidos no segundo formulário serão exibidos nesta aba assim que forem inseridos.',
  },
  label: {
    field: 'Campo',
    type: 'Tipo',
  },
  items: {
    email: 'E-mail do signatário',
    whatsapp: 'Whatsapp do signatário',
    tel: 'Telefone do signatário',
    cpf: 'CPF',
    cnpj: 'CNPJ',
    file: 'Anexo',
    image: 'Imagem',
    cep: 'CEP',
    currency: 'Monetário',
    text: 'Texto curto',
    textarea: 'Texto longo',
    select: 'Lista suspensa',
    radioGroup: 'Única resposta',
    checkboxGroup: 'Múltiplas respostas',
    date: 'Data',
    header: 'Cabeçalho',
    paragraph: 'Parágrafo',
    autofill: 'Autopreenchimento',
  },
  editFields: {
    helpBalloon: {
      label: 'Balão de Ajuda',
      placeholder: 'Pode ser visualizado ao clicar no ícone "?"',
    },
    helpText: {
      label: 'Texto de Ajuda',
      placeholder: 'Texto de ajuda dentro do campo',
      defaultValue: 'Selecione uma opção',
    },
    maxCharacters: {
      label: 'Máximo de Caracteres',
    },
    numberOfLines: {
      label: 'Número de linhas',
    },
    imageInfo: {
      text: 'Arquivos aceitos: jpg, jpeg e png',
    },
    attachFileEnabled: {
      label: 'Assinar anexo',
      placeholder: 'Será automaticamente incluído no documento para ser assinado.',
      help: 'Marcando essa opção, o campo só aceitará arquivos no formato .pdf',
    },
    header: {
      label: 'Título',
      placeholder: 'Cabeçalho',
    },
    paragraph: {
      label: 'Conteúdo',
      placeholder: 'Parágrafo',
    },
    other: {
      label: 'Habilitar "Outro"',
      placeholder: 'Permita a adição de uma opção não listada',
    },
    options: {
      addOption: 'Adicionar Opção',
      label: 'Opções',
      default: {
        label: 'Opção no formulário',
        value: 'Texto para documento',
      },
    },
    cepField: {
      fields: {
        street: 'Logradouro',
        number: 'Número',
        complement: 'Complemento',
        neighborhood: 'Bairro',
        city: 'Cidade',
        state: 'Estado',
      },
    },
  },
  validations: {
    label: {
      alreadyExists: 'Nome do campo já existe no formulário {form}',
      cepAlreadyExists: 'Não é possível adicionar esse campo novamente',
    },
  },
};

export default fieldCreator;
