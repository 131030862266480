const rubric = {
  error: '¡Ocurrió un error al realizar la rúbrica!',
  documentView: 'Visualiza el documento hasta el final',
  readTooltip: 'Documento con visualización pendiente',
  readConfirmation: 'Visualizado',
  readWidgetConfirmation: 'Visualizado',
  readError: 'Visualización obligatoria',
  tooltip: 'Documento con visualización pendiente',
  modal: {
    clauses: {
      title: 'Confirmación de la lectura de las cláusulas',
      desc: 'Para concluir la firma, confirma que has leído el siguiente contenido:',
      itens: 'Cláusulas:',
      accept: 'He leído y acepto las cláusulas/elementos',
    },
    seals: {
      title: 'Rúbrica en la esquina de la página',
      desc: 'Las siguientes páginas requieren una rúbrica.',
      pages: 'Páginas:',
      all: 'Todas las páginas',
      placeholder: 'bc',
      label: 'Ingresa tus iniciales para definir la rúbrica (ej.: BC).',
    },
    agree: 'Confirmar',

    title: 'Rúbrica en la esquina de la página',
    electronicRubric: 'Rúbrica electrónica',
    multipleDocuments:
      'Para continuar, confirma tu rúbrica en las páginas del siguiente documento:|Para continuar, confirma tu rúbrica en las páginas de los siguientes documentos:',
    singleDocumentAllPages:
      'Para continuar, confirma tu rúbrica en todas las páginas del documento.',
    singleDocumentSomePages:
      'Para continuar, confirma tu rúbrica en la siguiente página:|Para continuar, confirma tu rúbrica en las siguientes páginas:',
    pages: 'Página|Páginas',
    allPages: 'Todas las páginas',
    insertInitials: 'Ingresa tus iniciales para definir la rúbrica (ej.: BC).',
    initialsPlaceholder: 'Bc',
    confirm: 'Rúbricar',
  },
};

export default rubric;
