import { axios } from '@/plugins';

const createWhatsappAcceptanceTerm = async (_, payload) => {
  const { createLink, body } = payload;

  const sanizeString = (string) => string.replace(/\s{3,}/gm, '   ');

  await axios.post(createLink, {
    ...body,
    content: sanizeString(body.content),
    senderNameOption: sanizeString(body.senderName),
    signerName: sanizeString(body.signerName),
  });
};

const enableWhatsappAcceptanceTerm = async ({ rootGetters, dispatch }) => {
  const { acceptanceTermWhatsappEnable } = rootGetters['info/getRoutes'];
  const account = rootGetters['info/getAccount'];

  await axios.post(acceptanceTermWhatsappEnable);

  dispatch(
    'info/setAccountData',
    {
      account: {
        ...account,
        acceptanceTermWhatsappEnabled: true,
      },
    },
    { root: true }
  );
};

const cancelWhatsappAcceptanceTerm = async (_, payload) => {
  const { cancelLink } = payload;
  await axios.patch(cancelLink);
};

export default {
  cancelWhatsappAcceptanceTerm,
  createWhatsappAcceptanceTerm,
  enableWhatsappAcceptanceTerm,
};
