const dashboardDetails = {
  title: 'Control de plazos',
  filter: 'Filtrar',
  placeholder: 'Selecciona una fecha...',
  list: {
    name: 'Nombre del documento',
    date: 'Fecha',
    detail: 'Detalle',
  },
  empty: {
    title: 'No hay fechas para mostrar',
    text: 'Para mostrar los plazos de los documentos, es necesario registrar una fecha en "detalles" del documento',
    button: 'Ve cómo funciona',
  },
};

export default dashboardDetails;
