const liveness = {
  title: 'Selfie dinâmica',
  carousel: {
    step1: {
      title: 'Selfie dinâmica',
      text: 'Precisamos tirar uma foto sua para provar que você é uma pessoa real',
    },
    step2: {
      title: 'Sua foto será anexada ao documento',
      text: {
        first: 'Esteja vestido',
        second: 'Não use óculos e acessórios',
        third: 'Tire seu documento do plástico',
        fourth: 'Busque um ambiente iluminado e adequado',
      },
    },
    step3: {
      title: 'Ative sua câmera',
      text: 'Quando solicitado, toque em permitir para iniciar a captura da imagem',
    },
  },
  cafModal: {
    title: 'Quase lá! Tire uma foto do seu rosto para finalizar a validação.',
    error: 'Encontramos um problema',
    content: {
      loading: 'Enviamos sua foto para análise {0} Aguarde um momento...',
      keepPosition: 'Mantenha assim',
      success: 'Foto tirada com sucesso!',
      error: 'Não identificamos o rosto de uma pessoa ainda',
      sdkError: 'Ocorreu um problema durante a captura da imagem, tente novamente.',
    },
    button: {
      success: 'Avançar',
      error: 'Tente novamente',
      sendAndNext: 'Enviar e avançar',
    },
    flipCamera: 'Virar câmera',
  },
  attemptsError: {
    title: 'Limite de tentativas alcançado',
    subtitle: 'Avise o remetente do documento',
  },
  result: {
    remaining: 'Avise o remetente do documento | Tentativas restantes: {count}',
    takeAnother: 'Tirar outra',
    isOkTitle: 'Tudo certo com a foto?',
    isOkSub:
      'Sua foto será protegida por marca d’água. Ao enviar, você autoriza o registro da imagem no documento final',
  },
  cameraError: 'Câmera não localizada.',
  actions: {
    next: 'Avançar',
    tryAgain: 'Tente novamente',
    goBack: 'Voltar',
    allowCamera: 'Ativar câmera',
    takePicture: 'Tirar foto',
  },
  knowMore: 'Saiba mais',
  consentLabel:
    'Ao clicar em "Enviar", você autoriza o registro da sua imagem no log do documento.',
  helpModal: {
    whatIsLivenessTitle: 'O que é Selfie dinâmica?',
    whatIsLivenessDescription:
      'É uma autenticação que permite validar que quem assina é uma pessoa de verdade, através da captura de sua face.',
    whyLivenessTitle: 'Porque assinar com Selfie dinâmica?',
    whyLivenessDescription:
      ' Essa autenticação reduz a chance de fraudes, tornando a assinatura eletrônica ainda mais segura.',
    authMethod: '*Esta forma de autenticação foi escolhida pelo remetente deste documento.',
    title: 'Selfie dinâmica',
    knowMore: {
      text1: 'Quer saber mais? Confira nossa',
      text2: 'e',
      link1: 'Política de Privacidade',
      link2: 'Central de Ajuda.',
    },
  },
  analyzingModal: 'Analisando a foto do seu rosto',
  verifyError: {
    reproved: {
      title: 'Foto não reconhecida',
      description:
        'O Documento não será assinado. {0} Entre em contato com o remetente do documento',
    },
    refused: {
      title: '@:steps.liveness.verifyError.reproved.title',
      description: '@:steps.liveness.verifyError.reproved.description',
    },
  },
};

export default liveness;
