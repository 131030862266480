<template>
  <XCard elevation="low">
    <div
      v-for="(item, index) in dataMappings"
      :key="`dataMapping${index}`"
      :class="$style.mapping"
    >
      <p :class="$style.text">
        {{ $t('flowModule.show.modelField') }}
        <span :class="$style.info">{{ item.templateField.name }}</span>
      </p>
      <p :class="$style.text">
        {{ $t('flowModule.show.formField') }}
        <span :class="$style.info">{{ item.field.label }}</span>
      </p>
    </div>
  </XCard>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FormFlowShowMappingInfo',
  props: {
    dataMappings: {
      type: Array,
      default: () => [],
    },
  },
});
</script>

<style lang="scss" module>
.mapping {
  margin-bottom: var(--space-regular);
  padding-bottom: var(--space-regular);
  border-bottom: 1px solid var(--color-neutral-400);

  .text {
    margin-bottom: 0px;

    .info {
      color: var(--color-neutral-800);
    }
  }

  &:last-of-type {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: none;
  }
}
</style>
