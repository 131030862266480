import { axios } from '@/plugins';
import { get } from 'lodash';

const fetchNotifications = async ({ commit, getters }, payload) => {
  try {
    const notificationsUrl = getters.getLinks.notifications;
    const page = get(payload, 'page', 1);
    const params = { params: { page } };
    const response = await axios.get(notificationsUrl, params);
    const responseData = get(response, 'data');
    commit('SET_NOTIFICATIONS_DATA', responseData);
  } catch (err) {
    throw err;
  }
};

const updateNotification = async ({ commit }, { url, notification }) => {
  try {
    const payload = { ...notification, read: true };
    commit('UPDATE_NOTIFICATIONS_DATA', payload);
    await axios.post(url);
  } catch (err) {
    throw err;
  }
};

const fetchDocuments = async ({ commit, getters }, { since }) => {
  try {
    const params = { since };
    const documentsUrl = getters.getLinks.documents;
    const response = await axios.get(documentsUrl, { params });
    const responseData = get(response, 'data');
    commit('SET_DOCUMENTS', responseData);
  } catch (err) {
    throw err;
  }
};

const fetchPlan = async ({ commit, getters }) => {
  try {
    const planUrl = getters.getLinks.consumption;
    const response = await axios.get(planUrl);
    const responseData = get(response, 'data');
    commit('SET_PLAN', responseData);
  } catch (err) {
    throw err;
  }
};

const fetchDashboardDetails = async ({ commit }, params) => {
  try {
    const url = window.location.href;
    const { data } = await axios.get(url, { params });
    commit('SET_DASHBOARD_DETAILS', data.documentDetails);
    commit('SET_PAGINATION', data.pagination);
  } catch (err) {
    throw err;
  }
};

export default {
  fetchNotifications,
  updateNotification,
  fetchDocuments,
  fetchPlan,
  fetchDashboardDetails,
};
