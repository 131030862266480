<script setup>
import { useStore } from '@/store';
import { useRouter } from '@base/hooks';
import { computed, ref } from 'vue';

defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['disable', 'close']);

const store = useStore();
const router = useRouter();
const loading = ref(false);
const modalInvalidType = ref('hasPendingProcesses');

const hasPendingProcesses = computed(() => store.getters['formFlow/getHasPendingProcesses']);
const hasWaitingSecondFormProcesses = computed(
  () => store.getters['formFlow/getHasWaitingSecondFormProcesses']
);
const selectedFlow = computed(() => store.getters['formFlow/getSelectedFlow']);
const links = computed(() => store.getters['info/getLinks']);

const verifyPendingProcesses = async () => {
  loading.value = true;
  await store.dispatch('formFlow/verifyPendingProcesses');
  if (hasWaitingSecondFormProcesses.value) {
    modalInvalidType.value = 'hasWaitingSecondFormProcesses';
  } else {
    modalInvalidType.value = 'hasPendingProcesses';
  }
  loading.value = false;
};

const handleGoToProcesses = () => {
  router.push({
    path: links.value.navigation.automation.flowProcesses,
    query: { flowFormId: [selectedFlow.value.id] },
  });
};

const handleGoToPendingAnswers = () => {
  router.push({
    path: links.value.navigation.automation.pendingAnswers,
    query: { flowFormId: [selectedFlow.value.id] },
  });
};

const handleSubmit = () => {
  if (hasPendingProcesses.value) {
    handleGoToProcesses();
  } else if (hasWaitingSecondFormProcesses.value) {
    handleGoToPendingAnswers();
  } else {
    emit('disable');
  }
};
</script>

<template>
  <XModal
    :is-open="showModal"
    :loading="loading"
    data-testid="formFlowDisableModalContainer"
    @close="$emit('close')"
    @opened="verifyPendingProcesses"
  >
    <template #header>
      {{
        hasPendingProcesses || hasWaitingSecondFormProcesses
          ? $t(`flowModule.modalDisable.${modalInvalidType}.header`)
          : $t('flowModule.modalDisable.header')
      }}
    </template>
    <template #default>
      <p :class="$style.text">
        {{
          hasPendingProcesses || hasWaitingSecondFormProcesses
            ? $t(`flowModule.modalDisable.${modalInvalidType}.title`)
            : $t('flowModule.modalDisable.title')
        }}
      </p>
    </template>
    <template #actions>
      <div :class="$style.actions">
        <XButton
          design="outlined"
          data-testid="modalCloseButton"
          @click="$emit('close')"
        >
          {{ $t('general.action.cancel') }}
        </XButton>
        <XButton
          data-testid="modalDisableButtonSubmit"
          @click="handleSubmit"
        >
          {{
            hasPendingProcesses || hasWaitingSecondFormProcesses
              ? $t(`flowModule.modalDisable.${modalInvalidType}.action`)
              : $t('general.action.disable')
          }}
        </XButton>
      </div>
    </template>
  </XModal>
</template>

<style lang="scss" module>
.text {
  margin-bottom: 0;
}

.actions {
  display: flex;
  gap: var(--space-small-xx);
}
</style>
