const signature = {
  title: 'Firmas',
  loading: 'Cargando página de firma…',
  sign: 'Firmar documentos | Firmar | Firmar {documentsCount} documentos',
  info: '{signedCount}/{signersCount} Firmas',
  waiting:
    'Firmando documento... | Firmando documento... | Firmando documento {signedDocuments} de {totalDocuments}...',
  massiveSignature: {
    signedFromTotal: 'Ya has firmado {signedDocuments} de {totalDocuments} documentos.',
    loadingNextBatch: 'Cargando los siguientes...',
  },
  security: 'Ambiente seguro de Clicksign',
  step: {
    start: 'Comenzar',
    active: 'Etapa {stepActiveIndex} de {stepsCount}',
    next: 'Siguiente',
    advance: 'Avanzar',
    finalize: 'Finalizar',
    refusal: 'Rechazar firma',
    report: 'Reportar un error',
    termsOfAgreement: {
      text: 'Al hacer click en finalizar, aceptas los {0} y {1} de Clicksign.',
      linkText: 'Términos de uso',
    },
    privacyPolicy: 'Política de privacidad - LGPD',
    index: {
      title: '¡Vamos a firmar!',
      subtitle: 'Sigue los pasos a continuación para completar la firma.',
      presentialSignatureSubtitle:
        'Sigue los pasos a continuación para completar la firma presencial.',
      signerInfo: 'Tus datos.',
      positionSign: 'Coloca tu firma.',
      selfieConfirmation: 'Selfie con documento.',
      addressProof: 'Comprobante de domicilio.',
      handWrittenConfirmation: 'Firma manuscrita.',
      tokenConfirmation: 'Token de autenticación.',
      officialDocument: 'Foto del documento oficial.',
      pixConfirmation: 'Autenticación por Pix.',
      livenessConfirmation: 'Selfie dinámica.',
      icpBrasilConfirmation: 'Certificado digital',
      facialBiometricsConfirmation: '@:steps.facialBiometrics.title',
      signerLocation: 'Ubicación',
      voiceBiometrics: 'Biometría de voz',
    },
    info: {
      title: 'Confirma tus datos',
      form: {
        fullName: 'Nombre completo',
        documentation: 'CPF',
        documentationPlaceholder: '___.___.___-__',
        birthday: 'Fecha de nacimiento (DD/MM/AAAA)',
        birthdayPlaceholder: '__/__/____',
        email: 'Recibir documento firmado por correo',
        emailInfo: 'El documento será enviado tras la conclusión de todas las firmas',
        emailPlaceholder: 'Ingresa tu correo electrónico',
      },
    },
    expiredLink: {
      errorLabel: 'error 410',
      title: 'Este enlace ha expirado',
      subtitle: 'Verifica si este documento ya fue firmado o si el plazo de firma ha expirado',
      description:
        'Es posible que este documento ya haya sido firmado por ti. Revisa si hay más de un enlace de firma en la misma conversación de correo electrónico.',
      otherCase:
        '¿No es este el caso? También puedes preguntarle al remitente del documento la razón por la que la firma ha expirado.',
      help: 'Si necesitas ayuda, visita nuestra {0} o envía un correo a {1}.',
      helpCenter: 'Centro de ayuda',
      helpCenterTitle: 'Haz click para acceder al centro de ayuda',
      helpEmail: 'ayuda@clicksign.com',
      helpEmailTitle: 'Haz clicl para enviar correo',
      altClicksignLogo: 'Logo de Clicksign',
    },
    token: {
      title: 'Token de autenticación',
      subtitle: 'Enviamos un código token a',
      label: {
        default: 'Ingresa el token para finalizar',
        insert: 'Ingresa el token y finaliza la firma',
        presetToken: 'Solo falta finalizar la firma',
        error: 'Token inválido',
      },
      tokenSent: 'Enviado a {origin}',
      invalid: 'Token inválido',
      automatic: 'Token completado automáticamente',
      tooltip: 'El token fue completado automáticamente para facilitar la firma del documento.',
      help: '¿No recibiste el token?',
      send: 'Enviar token por ',
      resending: 'Enviando token...',
      resend: 'Reenviar token por',
      resendTo: {
        whatsapp: 'WhatsApp',
        email: 'correo electrónico',
        sms: 'SMS',
      },
      timer: 'Espera {timer} para reenviar',
      remainingAttempts:
        'Queda solo {remainingAttempts} envío de token | Quedan {remainingAttempts} envíos de token',
      noMoreAttempts: 'Límite de intentos de envío de token alcanzado',
      maxAttemptsHelp: '¿Dificultades para finalizar la autenticación?',
      sentSuccess: '¡Token enviado con éxito!',
      resentSuccess: '¡Token reenviado con éxito!',
      limitExceeded: 'Límite de intentos excedido.',
      limitExceededDescription: '{0} Intenta de nuevo en {1}.',
      email: 'correo electrónico',
      sms: 'SMS',
      whatsapp: 'WhatsApp',
      finalize: 'Finalizar',
      authenticated: 'Token autenticado',
      expired: '{0} Vuelve a validar en el último paso.',
      expiredBold: 'Token expirado:',
      sentTo: {
        email: 'Enviamos un token al correo electrónico',
        sms: 'Enviamos un token por SMS a',
        whatsapp: 'Enviamos un token por WhatsApp a',
      },
    },
    presential: {
      title: 'Vamos a iniciar tu firma',
      subtitle: 'Introduce el código de acceso',
      successToken: 'Código validado con éxito',
      action: 'Iniciar firma',
      expired: {
        title: 'Este código ha expirado',
        subtitle: 'Solicita un nuevo código de firma a la persona que te atendió.',
        action: 'Regresar al inicio',
        return: 'Regresando en {time} segundos...',
      },
      validation: {
        title: 'Validando código de acceso',
      },
      termsOfAgreement: {
        text: 'Al avanzar, aceptas los {0} y {1} de Clicksign.',
      },
    },
    pix: {
      title: 'Abre la app de tu banco',
      copyAndPasteText: 'y usa la opción Pix Copiar y Pegar',
      qrCodeAlternativeText: 'O paga con código QR',
      copyLabel: 'O utiliza la opción Pix Copiar y Pegar',
      actions: {
        copy: 'Copiar código Pix',
        copied: 'Copiado',
        close: 'Cerrar',
      },
      disclaimer: 'Usa tu cuenta bancaria para pagar',
      help: '¿Dificultades con Pix?',
      whyPix: {
        action: '¿Por qué Pix?',
        modal: {
          title: 'Para firmar, necesitas tener Pix registrado en tu cuenta bancaria.',
          whatIsPixTitle: '¿Qué es Pix?',
          whatIsPixDescription:
            'Pix es un sistema de pagos instantáneos rápido y seguro creado por el Banco Central.',
          whySignWithPixTitle: '¿Por qué firmar con Pix?',
          whySignWithPixDescription:
            'Cuando pagas con Pix, podemos autenticar tus datos a través de la transacción bancaria.',
          whySignWithPixDescriptionTwo:
            'Esta autenticación reduce las posibilidades de fraude, haciendo la firma electrónica más segura.',
          senderAuthMethod:
            '*Este método de autenticación fue elegido por el remitente de este documento.',
        },
      },
      feedbackModal: {
        title: '¿Qué dificultad tienes?',
        options: {
          dontHavePIX: 'No tengo Pix',
          cantPay: 'No puedo hacer la transacción en este momento',
          dontWantToSign: 'No quiero firmar con Pix',
          cantUnderstand: 'No entendí cómo funciona',
          issuesWithMyBank: 'Tuve un problema técnico con el banco',
          other: 'Otro',
        },
        actions: {
          next: 'Avanzar',
          cancel: 'Cancelar',
        },
      },
      helpModal: {
        linkText: 'Consulta cómo proceder aquí',
        description: 'Informa al remitente si no puedes completar la firma.',
        close: 'Cerrar',
      },
      loading: 'Cargando código QR...',
      subtitle: 'y usa la opción {0}',
      qrCode: 'Código QR',
      pixCopyAndPaste: 'Pix Copiar y Pegar',
      cash: 'R$ {0}',
      alternativeSubtitle: 'O utiliza la opción {0}',
      payWith: 'O pagar con {0}',
      tutorial: {
        confirm: {
          title: 'Confirma que eres tú',
          subtitle: 'Para ello, será necesaria una autenticación bancaria.',
        },
        pay: {
          title: 'Haz un Pix de R$0,01',
          subtitle: 'Usa el Pix registrado en tu cuenta bancaria.',
        },
        sign: {
          title: 'Y firma con seguridad',
          subtitle: 'La transacción confirma tus datos y reduce el riesgo de fraudes.',
        },
      },
      next: 'Siguiente',
      signWithPix: 'Firmar con Pix',
      pixFailed: {
        title: 'Firma no autenticada',
        cpfInformed:
          'Parece que el CPF ingresado no coincide con la cuenta bancaria utilizada para Pix.',
        dontWorry: 'No te preocupes, el monto será reembolsado.',
        tryAgain: 'Inténtalo de nuevo con un Pix registrado en tu cuenta bancaria.',
        actions: {
          tryAgain: 'Intentar de nuevo',
        },
      },
    },
    selfie: {
      analyzingSelfie: 'Analizando foto de tu rostro y documento',
      prepare: {
        title: 'Foto sosteniendo el documento',
        takePhoto: 'Tomar foto',
        disclaimer: 'Tu foto estará protegida por marca de agua.',
        documentAllowed: 'El documento puede ser tu RG o CNH o cualquier otro documento oficial.',
        dontCoverYourFace: 'Ten cuidado de no cubrir tu rostro con el documento.',
      },
      photo: {
        title: 'Si la foto de tu {0} quedó clara, continúa con el siguiente paso.',
        boldTitle: 'rostro con el documento',
        face: 'Rostro',
        takeAnother: 'Tomar otra foto',
      },
      notApproved: {
        title: 'Foto no reconocida',
        newSelfie: 'Toma una nueva selfie con tu documento de identificación.',
        desc: 'Asegúrate de que la imagen esté clara y recuerda no usar accesorios.',
        tryAgain: 'Intentar de nuevo',
      },
      error: {
        browserIncompatible: {
          name: 'Tu navegador no soporta la captura de fotos.',
          description: 'Intenta firmar usando otro navegador.',
        },
        cameraMissing: {
          name: 'No se pudo encontrar una cámara.',
          description: 'Firma con otro dispositivo, prueba con un celular.',
        },
        cameraBlocked: {
          name: 'Permiso denegado para la cámara.',
          description: 'Intenta habilitarla y actualiza esta página.',
        },
        cameraBusy: {
          name: 'Tu cámara está en uso por otra aplicación.',
          description: 'Cierra otras aplicaciones e intenta de nuevo.',
        },
        selfie: {
          name: 'No se pudo capturar tu foto.',
          description: 'Firma con otro dispositivo, prueba con un celular.',
        },
      },
    },
    handwritten: {
      title: 'Dibuja tu firma manuscrita',
      clear: 'Limpiar',
      disclaimer: 'Tu firma manuscrita estará protegida por marca de agua.',
      positionSignDisclaimer:
        'Tu firma será posicionada en lugares específicos del documento y protegida por marca de agua.',
    },
    positionSign: {
      nextDocument: 'Siguiente documento',
    },
    officialDocument: {
      takePhoto: 'Tomar foto',
      next: 'Siguiente',
      takeAnother: 'Tomar otra foto',
      prepare: {
        titleFront: 'Foto del frente del documento',
        titleBack: 'Foto del reverso del documento',
        disclaimer: {
          useOfficialDocument: 'Foto del documento oficial, por ejemplo, RG o CNH.',
          removeFromPlastic: 'Si es posible, retira el plástico.',
        },
      },
      photo: {
        title: 'Si la {0} quedó clara, continúa con el siguiente paso.',
        titleBoldFront: 'foto del frente del documento',
        titleBoldBack: 'foto del reverso del documento',
        front: 'Frente',
        back: 'Reverso',
      },
    },
  },
  facematchPending: {
    title: 'Documento en proceso de firma',
    description: 'Tus imágenes están siendo procesadas. Recibirás un correo cuando sea completado.',
  },
  facematchRefused: {
    title: 'Firma no realizada',
    description: 'Aún no te hemos identificado. Inténtalo de nuevo con otro documento.',
    button: 'Intentar de nuevo',
  },
  success: {
    title: '¡Firma realizada con éxito! | Firmas realizadas con éxito.',
    dashboardArea: 'Regresar a Mi área',
    description:
      'Cuando todos hayan firmado, recibirás un correo con el documento firmado.|Cuando todos hayan firmado, recibirás un correo con los documentos firmados.',
    pix: 'Tu Pix ha sido recibido y tus datos, validados.',
    smartphoneImageAlt: 'Smartphone con página de firma de documentos de Clicksign abierta',
    cta: {
      user: {
        title: 'Tus documentos en un solo lugar',
        description:
          'Ingresa a tu cuenta en Clicksign para firmar y descargar tus documentos en un solo lugar.',
      },
      noUser: {
        title: 'Firma y solicita firmas en línea con validez legal',
        description: 'Crea una cuenta en Clicksign para firmar tus documentos en un solo lugar.',
      },
      advantagesTitle: 'Descubre las ventajas de firmar con Clicksign:',
      advantages: {
        digitalAndElectronicSignature: 'Firmas digitales y electrónicas',
        multipleAuthKinds: 'Diversos tipos de autenticación',
        whatsappSmsAndEmail: 'Envíos vía WhatsApp, SMS o correo electrónico',
        acceptancesAndDocumentsManagement: 'Administración de documentos y aceptaciones',
      },
      createAccount: 'Crear cuenta gratis',
      accessClicksign: 'Ingresa a Clicksign',
      hasPendenciesTitle: 'Aún tienes documentos por firmar',
    },
  },
  failed: {
    title: 'Firma no realizada',
    subtitle: 'Hubo un error en el documento. El remitente será notificado',
  },
  error: {
    conflict: 'No hay documentos para ser firmados.',
    connection: 'Error de conexión. ¡Intenta de nuevo más tarde!',
    forbidden: 'Token inválido',
    validation: 'Error de validación',
    default: 'Error inesperado. ¡Intenta de nuevo más tarde!',
    invalidTokenRateLimit: 'Enlace enviado. Espera un minuto antes de intentarlo de nuevo.',
    unprocessed: 'Error al procesar la firma',
    location: 'La ubicación es obligatoria.',
  },
  pending: {
    pendingDocumentsCounter: '{pendingCounter} documento | {pendingCounter} documentos',
    titleSingle: 'Aún tienes {0} esperando para ser firmado',
    titleMultiple: 'Aún tienes {0} esperando para ser firmados',
    action: 'Firmar documento | Firmar documentos',
    // FIXME: After fix component 'WidgetPendingDocumentsWarning' use in refusal feedback.
    // TODO: Remove titleOld and actionOld
    titleOld:
      'Aún hay {pendingDocumentsCount} documento esperando tu firma. | Aún hay {pendingDocumentsCount} documentos esperando tu firma.',
    actionOld: 'Ver para firmar',
  },
  kind: {
    sign: 'Firmar',
    witness: 'Firmar como testigo',
    party: 'Firmar como parte',
    intervening: 'Firmar como interviniente',
    receipt: 'Firmar para acuse de recibo.',
    approve: 'Firmar para aprobar',
    endorser: 'Firmar como endosante',
    endorsee: 'Firmar como endosatario',
    administrator: 'Firmar como administrador',
    guarantor: 'Firmar como avalista',
    transferor: 'Firmar como cedente',
    transferee: 'Firmar como cesionario',
    contractee: 'Firmar como contratada',
    contractor: 'Firmar como contratante',
    jointDebtor: 'Firmar como deudor solidario',
    issuer: 'Firmar como emisor',
    manager: 'Firmar como gestor',
    buyer: 'Firmar como comprador',
    seller: 'Firmar como vendedor',
    attorney: 'Firmar como apoderado',
    legalRepresentative: 'Firmar como representante legal',
    coResponsible: 'Firmar como responsable solidario',
    validator: 'Firmar como validador',
    surety: 'Firmar como fiador',
    lessor: 'Firmar como arrendador',
    lessee: 'Firmar como arrendatario',
    ratify: 'Firmar para homologar',
    employer: 'Firmar como empleador',
    employee: 'Firmar como empleado',
    consenting: 'Firmar como anuente',
    accountant: 'Firmar como contador',
    debtor: 'Firmar como deudor',
    franchisee: 'Firmar como franquiciado',
    creditor: 'Firmar como acreedor',
    franchisor: 'Firmar como franquiciador',
    insured: 'Firmar como asegurado',
    grantor: 'Firmar como otorgante',
    president: 'Firmar como presidente',
    partner: 'Firmar como socio',
    comforter: 'Firmar como comodante',
    accountHolder: 'Firmar como titular de cuenta',
    distracted: 'Firmar como distratada',
    distracting: 'Firmar como distratante',
    lender: 'Firmar como prestamista',
    associate: 'Firmar como asociado',
    broker: 'Firmar como corredor',
    bailee: 'Firmar como depositario fiel',
    collateralProvider: 'Firmar como caucionante',
    pledged: 'Firmar como consignado',
    consignee: 'Firmar como consignatario',
    lawyer: 'Firmar como abogado',
    grantee: 'Firmar como otorgado',
    borrower: 'Firmar como comodante',
    guarantorSpouse: 'Firmar como cónyuge del fiador',
    legalGuardian: 'Firmar como tutor legal',
    director: 'Firmar como director(a)',
    realEstateBroker: 'Firmar como corredor de bienes raíces',
    insuranceBroker: 'Firmar como corredor de seguros',
    licensor: 'Firmar como licenciante',
    licensed: 'Firmar como licenciatario(a)',
    serviceProvider: 'Firmar como prestador(a) de servicios',
    secured: 'Firmar como afianzado',
    consented: 'Firmar como anuido',
    interveningGuarantor: 'Firmar como garante interviniente',
    consentingIntervenor: 'Firmar como interviniente anuente',
    surveyor: 'Firmar como inspector',
    buildingManager: 'Firmar como síndico(a)',
    intermediary: 'Firmar como intermediario(a)',
    condominiumMember: 'Firmar como copropietario',
    owner: 'Firmar como propietario(a)',
    resident: 'Firmar como residente',
    treasurer: 'Firmar como tesorero(a)',
    secretary: 'Firmar como secretario(a)',
    signedAsSign: 'Firmó',
    signedAsApprove: 'Firmó para aprobar',
    signedAsParty: 'Firmó como parte',
    signedAsWitness: 'Firmó como testigo',
    signedAsIntervening: 'Firmó como interviniente',
    signedAsReceipt: 'Firmó para acusar recibo',
    signedAsEndorser: 'Firmó como endosante',
    signedAsEndorsee: 'Firmó como endosatario',
    signedAsAdministrator: 'Firmó como administrador',
    signedAsGuarantor: 'Firmó como avalista',
    signedAsTransferor: 'Firmó como cedente',
    signedAsTransferee: 'Firmó como cesionario',
    signedAsContractee: 'Firmó como contratada',
    signedAsContractor: 'Firmó como contratante',
    signedAsJointDebtor: 'Firmó como deudor solidario',
    signedAsIssuer: 'Firmó como emisor',
    signedAsManager: 'Firmó como gestor',
    signedAsBuyer: 'Firmó como comprador',
    signedAsSeller: 'Firmó como vendedor',
    signedAsAttorney: 'Firmó como apoderado',
    signedAsLegalRepresentative: 'Firmó como representante legal',
    signedAsCoResponsible: 'Firmó como responsable solidario',
    signedAsValidator: 'Firmó como validador',
    signedAsSurety: 'Firmó como fiador',
    signedAsLessor: 'Firmó como arrendador',
    signedAsLessee: 'Firmó como arrendatario',
    signedAsRatify: 'Firmó para homologar',
    signedAsEmployer: 'Firmó como empleador',
    signedAsEmployee: 'Firmó como empleado',
    signedAsConsenting: 'Firmó como anuente',
    signedAsAccountant: 'Firmó como contador',
    signedAsDebtor: 'Firmó como deudor',
    signedAsFranchisee: 'Firmó como franquiciado',
    signedAsCreditor: 'Firmó como acreedor',
    signedAsFranchisor: 'Firmó como franquiciador',
    signedAsInsured: 'Firmó como asegurado',
    signedAsGrantor: 'Firmó como otorgante',
    signedAsPresident: 'Firmó como presidente',
    signedAsPartner: 'Firmó como socio',
    signedAsComforter: 'Firmó como comodante',
    signedAsAccountHolder: 'Firmó como titular de cuenta',
    signedAsDistracted: 'Firmó como distratada',
    signedAsDistracting: 'Firmó como distratante',
    signedAsLender: 'Firmó como prestamista',
    signedAsAssociate: 'Firmó como asociado',
    signedAsBroker: 'Firmó como corredor',
    signedAsBailee: 'Firmó como depositario fiel',
    signedAsCollateralProvider: 'Firmó como caucionante',
    signedAsPledged: 'Firmó como consignado',
    signedAsConsignee: 'Firmó como consignatario',
    signedAsLawyer: 'Firmó como abogado',
    signedAsGrantee: 'Firmó como otorgado',
    signedAsLoanee: 'Firmó como comodante',
    signedAsBorrower: 'Firmó como comodante',
    signedAsGuarantorSpouse: 'Firmó como cónyuge del fiador',
    signedAsLegalGuardian: 'Firmó como tutor legal',
    signedAsDirector: 'Firmó como director(a)',
    signedAsRealEstateBroker: 'Firmó como corredor de bienes raíces',
    signedAsInsuranceBroker: 'Firmó como corredor de seguros',
    signedAsLicensor: 'Firmó como licenciante',
    signedAsLicensed: 'Firmó como licenciatario(a)',
    signedAsServiceProvider: 'Firmó como prestador(a) de servicios',
    signedAsSecured: 'Firmó como afianzado',
    signedAsConsented: 'Firmó como anuido',
    signedAsInterveningGuarantor: 'Firmó como garante interviniente',
    signedAsConsentingIntervenor: 'Firmó como interviniente anuente',
    signedAsSurveyor: 'Firmó como inspector',
    signedAsBuildingManager: 'Firmó como síndico(a)',
    signedAsIntermediary: 'Firmó como intermediario(a)',
    signedAsCondominiumMember: 'Firmó como copropietario',
    signedAsOwner: 'Firmó como propietario(a)',
    signedAsResident: 'Firmó como residente',
    signedAsTreasurer: 'Firmó como tesorero(a)',
    signedAsSecretary: 'Firmó como secretario(a)',
  },

  photo: {
    confirmPhoto: 'Confirmo que la foto es correcta',
  },
};

export default signature;
