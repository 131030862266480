import { CHAT } from '@ai/constants';

import { useChatMessage } from './useChatMessage';

/**
 * Represents a signer for a chat message.
 * @typedef {Object} Signer
 * @property {string} communicateBy - The method of communication.
 * @property {Array<string>} authentications - The authentication methods.
 * @property {string} [email] - The email address (required when communicateBy is email).
 * @property {string} [phoneNumber] - The phone number (required when communicateBy is whatsapp).
 * @property {string} [name] - The name.
 * @property {string} [documentation] - The documentation.
 * @property {string} [birthday] - The birthday.
 */

/**
 * Returns a chat message signer object.
 *
 * @param {Array<Signer>|Signer} signers - An array of signers.
 * @param {Object} options - Additional options for the chat message.
 * @returns {Object} - The chat message signer object.
 */
export const useChatMessageSigner = (signers, options) => {
  const type = CHAT.TYPES.SIGNER;

  return {
    ...useChatMessage('', { ...(options || {}), type }),
    signers: Array.isArray(signers) ? signers : [signers],
  };
};
