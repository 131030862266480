const betaProgram = {
  optIn: {
    title: 'Programa Clicksign Beta',
    subTitle: 'Sé uno de los primeros en probar nuevas funciones',
    description: {
      firstParagraph: '¡Has sido seleccionado para formar parte del Programa Clicksign Beta!',
      secondParagraph:
        'El programa es 100% gratuito y los participantes serán los primeros en experimentar los lanzamientos de la plataforma.',
      thirdParagraph: 'Suscríbete y empieza a probar funciones de primera mano.',
    },
    cta: 'Suscribirme',
    link: '¿Qué es una funcionalidad beta?',
    howToUse: '¿Cómo funciona?',
    cards: {
      subscription:
        'Suscríbete para participar y recibir un aviso cuando una funcionalidad entre y salga de la fase de prueba',
      experiment: 'Empieza a probar las funcionalidades disponibles',
      share:
        'Comparte tus opiniones con nosotros y ayúdanos a crear una mejor experiencia en Clicksign',
    },
    imageAlt: 'Persona feliz sosteniendo una tablet',
    modal: {
      title: 'Estás a punto de formar parte del Programa Clicksign Beta',
      description: 'Antes de finalizar tu suscripción, es importante que sepas: ',
      confirm: 'Estoy de acuerdo',
      terms: [
        'El Programa Clicksign Beta es 100% gratuito.',
        'Puedes cancelar tu suscripción en cualquier momento. Las actividades de tu cuenta no se verán afectadas.',
        'Las funcionalidades del programa están en fase de prueba, por lo que estarán disponibles por tiempo limitado y podrán mejorar durante su uso.',
      ],
      lastTerm:
        'Para reportar inestabilidad en la funcionalidad probada o compartir tus opiniones, envía un correo a',
      checkbox: 'Confirmo que he leído y acepto los términos anteriores',
      error: 'No se pudo realizar tu suscripción. Intenta nuevamente',
    },
  },
  optOut: {
    title: 'Programa Clicksign Beta',
    subscribed: 'Suscrito',
    content: [
      'Ya formas parte de nuestro Programa Clicksign Beta.',
      '¿Quieres compartir tus opiniones sobre una funcionalidad beta con nuestro equipo?',
    ],
    sendEmail: 'Envía un correo a {email}',
    cancelButton: 'Cancelar suscripción',
    modal: {
      title: 'Cancelar suscripción del Programa Clicksign Beta',
      description:
        'Lamentamos que quieras cancelar tu suscripción. Antes de confirmar, por favor comparte con nosotros el motivo de tu decisión.',
      textAreaPlaceHolder: 'Cuéntanos: ¿por qué decidiste cancelar?',
      checkbox: 'Quiero cancelar mi suscripción en el Programa Clicksign Beta',
      confirm: 'Cancelar suscripción',
    },
    toast: 'Tu suscripción fue cancelada con éxito',
    error: 'No se pudo cancelar tu suscripción. Intenta nuevamente',
  },
};

export default betaProgram;
