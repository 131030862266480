import { get, every, map } from 'lodash';

export function validTemplateFields(templateMappingsToValidate = []) {
  const requiredTemplateFieldsIds = get(this, 'templateFields', [])
    .filter(({ required }) => required)
    .map(({ id }) => id);

  const mappedFieldsIds = templateMappingsToValidate
    .filter(({ column }) => !!column)
    .map(({ value }) => value);

  const isRequiredFieldMapped = (templateFieldId) => mappedFieldsIds.includes(templateFieldId);
  return every(requiredTemplateFieldsIds, isRequiredFieldMapped);
}

export function validSignerFields(signersFieldsToValidate) {
  const requiredSignersFields = map(get(this, 'spreadsheetFlowLists', []), (signer) => {
    const signerId = get(signer, 'id');
    const requiredFields = get(signer, 'signerFields')
      .filter(({ required }) => required)
      .map(({ name }) => name);
    return { signerId, requiredFields };
  });
  return every(requiredSignersFields, (requiredSignerFields) => {
    const { signerId, requiredFields } = requiredSignerFields;
    const mappedFields = get(signersFieldsToValidate, signerId, [])
      .filter(({ column }) => !!column)
      .map(({ value }) => value);
    const isRequiredFieldMapped = (signerField) => mappedFields.includes(signerField);
    return every(requiredFields, isRequiredFieldMapped);
  });
}

export function phone(value) {
  const formattedRegex = /^\(\d{2}\)\s*(?:\d{4,5}(?:-\d{4}))$/gm;
  const unformattedRegex = /^\d{10,11}$/gm;
  return !value || formattedRegex.test(value.trim()) || unformattedRegex.test(value.trim());
}
