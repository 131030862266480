import { axios } from '@/plugins';
import { lowerCase } from 'lodash';
import { useChatMessageError, useChatMessageAction, useChatMessageSigner } from '@ai/composables';
import { CHAT } from '@ai/constants';

export default {
  async registerChatMessage({ getters, commit }, { action, dataObj }) {
    const url = getters.getLinks.session.createMessage;
    try {
      const {
        data: { chatMessage },
      } = await axios.post(url, {
        chatMessage: {
          action,
          ...dataObj,
        },
      });

      commit('SET_CHAT_DATA', chatMessage.data);
      commit('SET_LAST_MESSAGE_RECEIVED', chatMessage.body);

      if (chatMessage?.body?.formData) {
        commit('SET_FORM_DATA', chatMessage.body.formData);
      }

      return chatMessage.body;
    } catch (err) {
      if (getters.getChatThread?.length > 0) {
        const lastValidMessage = getters.getChatThread
          .filter((thread) => thread.type !== 'loading')
          .pop();
        commit('UPDATE_CHAT_THREAD', {
          targetMessage: { timestamp: lastValidMessage.timestamp },
          newMessage: useChatMessageError(lastValidMessage.message, lastValidMessage),
        });
      }
      throw err;
    }
  },

  async generateModel({ dispatch, commit, getters }) {
    try {
      const { text, actions, formData } = await dispatch('registerChatMessage', {
        action: CHAT.ACTIONS.GENERATE_TEMPLATE,
        dataObj: { bodyFormData: getters.getFormData },
      });

      commit(
        'ADD_CHAT_THREAD',
        useChatMessageAction(text, { role: CHAT.ROLES.ASSISTANT, actions, formData })
      );
    } catch (error) {
      throw error;
    }
  },

  async sendAttachment({ getters, commit }, fileMessage) {
    try {
      const formData = new FormData();

      formData.append('chat_message[action]', CHAT.ACTIONS.UPLOAD_DOCUMENT);
      formData.append('chat_message[content]', fileMessage.file);

      const { data } = await axios.post(getters.getLinks.session.createMessage, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      const { text: messageResponse, actions } = data.chatMessage.body;

      commit(
        'ADD_CHAT_THREAD',
        useChatMessageAction(messageResponse, {
          role: CHAT.ROLES.ASSISTANT,
          actions,
        })
      );
    } catch (error) {
      commit('UPDATE_CHAT_THREAD', {
        targetMessage: { timestamp: fileMessage.timestamp },
        newMessage: useChatMessageError(fileMessage),
      });

      throw error;
    }
  },

  async fetchPreviewModel({ getters }) {
    let status = 204;
    let data;

    while (status === 204) {
      try {
        const response = await axios.get(getters.getChatData.templateLink);
        data = response.data;
        status = response.status;
      } catch (error) {
        throw error;
      }

      if (status === 204) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    }

    return data.template;
  },

  async addSignerFromContact({ commit, dispatch }, { contacts }) {
    const message = useChatMessageSigner(contacts);
    try {
      commit('ADD_CHAT_THREAD', message);

      const {
        text: messageResponse,
        actions,
        formData,
      } = await dispatch('registerChatMessage', {
        action: CHAT.ACTIONS.NEW_SIGNER_CONTACT,
        dataObj: {
          bodyFormData: {
            contactIds: contacts.map((contact) => contact.id),
          },
        },
      });

      commit(
        'ADD_CHAT_THREAD',
        useChatMessageAction(messageResponse, {
          role: CHAT.ROLES.ASSISTANT,
          actions,
          formData,
        })
      );
    } catch (err) {
      commit('UPDATE_CHAT_THREAD', {
        targetMessage: { timestamp: message.timestamp },
        newMessage: useChatMessageError(message),
      });

      throw err;
    }
  },

  async generateFlowForm({ dispatch, commit, getters }) {
    const {
      text: messageResponse,
      actions,
      formData,
    } = await dispatch('registerChatMessage', {
      action: CHAT.ACTIONS.GENERATE_FLOW_FORM,
      dataObj: { bodyFormData: getters.getChatData },
    });

    commit('UPDATE_CHAT_THREAD', {
      targetMessage: { type: CHAT.TYPES.LOADING },
      newMessage: useChatMessageAction(messageResponse, {
        role: CHAT.ROLES.ASSISTANT,
        actions,
        formData,
      }),
    });
  },

  async sendMessage({ commit, dispatch, getters }, payload) {
    try {
      // FIXME: move this logic below to ChatTextInput
      const action = getters.getLastMessageReceived?.actions?.includes(
        CHAT.ACTIONS.TEMPLATE_PREVIEW
      )
        ? CHAT.ACTIONS.TEMPLATE_PREVIEW
        : 'sent_message';

      const {
        text: messageResponse,
        actions,
        formData,
      } = await dispatch('registerChatMessage', {
        action,
        dataObj: { bodyText: payload.message, bodyFormData: getters.getChatData },
      });

      if (formData?.showPreview != null) {
        commit(
          'ADD_CHAT_THREAD',
          useChatMessageAction(messageResponse, {
            role: CHAT.ROLES.ASSISTANT,
            actions: [CHAT.ACTIONS.SHOW_TEMPLATE_PREVIEW],
            formData,
          })
        );
      } else {
        commit('UPDATE_CHAT_THREAD', {
          targetMessage: { type: CHAT.TYPES.LOADING },
          newMessage: useChatMessageAction(messageResponse, {
            role: CHAT.ROLES.ASSISTANT,
            actions,
            formData,
          }),
        });
      }
    } catch (error) {
      commit('UPDATE_CHAT_THREAD', {
        targetMessage: { timestamp: payload.timestamp },
        newMessage: useChatMessageError(payload.message, payload),
      });
      throw error;
    }
  },

  async sendSelectedSignerAuths({ commit, dispatch, getters }, { messagePayload, auths }) {
    try {
      const {
        text: messageResponse,
        actions,
        formData,
      } = await dispatch('registerChatMessage', {
        action: CHAT.ACTIONS.NEW_FORM_SIGNER,
        dataObj: {
          bodyFormData: {
            contactMethod: lowerCase(getters.getFormData?.contactMethod),
            fieldName: getters.getFormData?.fieldName,
            auths,
          },
        },
      });

      commit('UPDATE_CHAT_THREAD', {
        targetMessage: { type: CHAT.TYPES.LOADING },
        newMessage: useChatMessageAction(messageResponse, {
          role: CHAT.ROLES.ASSISTANT,
          actions,
          formData,
        }),
      });
    } catch (error) {
      commit('UPDATE_CHAT_THREAD', {
        targetMessage: { timestamp: messagePayload.timestamp },
        newMessage: useChatMessageError(messagePayload.message, messagePayload),
      });
      throw error;
    }
  },

  async sendSigner({ dispatch, commit }, { messagePayload, attributes, requirements }) {
    try {
      const {
        text: messageResponse,
        actions,
        formData,
      } = await dispatch('registerChatMessage', {
        action: CHAT.ACTIONS.NEW_SIGNER,
        dataObj: {
          bodyFormData: {
            attributes,
            requirements,
          },
        },
      });

      commit('UPDATE_CHAT_THREAD', {
        targetMessage: { type: CHAT.TYPES.LOADING },
        newMessage: useChatMessageAction(messageResponse, {
          role: CHAT.ROLES.ASSISTANT,
          actions,
          formData,
        }),
      });
    } catch (error) {
      commit('UPDATE_CHAT_THREAD', {
        targetMessage: { timestamp: messagePayload.timestamp },
        newMessage: useChatMessageError(messagePayload.message, messagePayload),
      });
      throw error;
    }
  },

  async activateFormFlow({ commit, dispatch }, activateMessage) {
    try {
      const { text: messageResponse } = await dispatch('registerChatMessage', {
        action: CHAT.ACTIONS.ACTIVATE_FLOW_FORM,
      });

      commit('FINISH_CHAT_THREAD');

      return messageResponse;
    } catch (error) {
      commit('UPDATE_CHAT_THREAD', {
        targetMessage: { timestamp: activateMessage.timestamp },
        newMessage: useChatMessageError(activateMessage),
      });

      throw error;
    }
  },
};
