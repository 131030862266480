import { buildFlexibleContactRequest } from '@document/utils/signer-utils';

export default [
  {
    name: 'info',
    title: 'signer.infoStep.envelope.title',
    component: 'EnvelopeSignerInfoForm',
    form: 'signerInfo',
    props: {
      signerAuths: 'signerAuths',
      signerCommunicate: 'signerCommunicate',
      signerSignAs: 'signerSignAs',
      email: 'signerInfo.email',
      phoneNumber: 'signerInfo.phoneNumber',
      name: 'signerInfo.name',
      birthday: 'signerInfo.birthday',
      hasDocumentation: 'signerInfo.hasDocumentation',
      documentation: 'signerInfo.documentation',
      signAs: 'signerInfo.signAs',
      auths: 'signerInfo.auths',
      communicateBy: 'signerInfo.communicateBy',
      contact: 'signerInfo.contact',
      officialDocumentEnabled: 'signerInfo.officialDocumentEnabled',
      selfieEnabled: 'signerInfo.selfieEnabled',
      handwrittenEnabled: 'signerInfo.handwrittenEnabled',
      livenessEnabled: 'signerInfo.livenessEnabled',
      additionalAuths: 'additionalAuthsItems',
      facialBiometricsEnabled: 'signerInfo.facialBiometricsEnabled',
      transferSaveAsContactSwitch: 'transferSaveAsContactSwitch',
    },
    baseState: {
      email: '',
      phoneNumber: null,
      name: '',
      birthday: '',
      hasDocumentation: true,
      documentation: '',
      signAs: '',
      auths: [],
      communicateBy: '',
      officialDocumentEnabled: false,
      selfieEnabled: false,
      handwrittenEnabled: false,
      contact: true,
      livenessEnabled: false,
      facialBiometricsEnabled: false,
    },
    action: async (vm, payload) => {
      if (payload.contact) {
        const url = vm.$store.getters['info/getLinks']?.signerLinks?.contacts;

        await vm.$store.dispatch('signer/createContact', { url, payload, context: vm.context });
      }
    },
    afterAction: (vm) => {
      const signerInfo = {
        ...vm.signerInfo,
        context: vm.context,
      };
      Object.assign(vm.signAs, { signers: [signerInfo] });
      vm.$emit('save', buildFlexibleContactRequest(signerInfo));
    },
  },
];
