import { useStore } from '@/store';
import { isEmpty } from 'lodash';

export const useEmitPageDataLayer = (path) => {
  const store = useStore();
  const accountData = store.getters['info/getAccount'];
  const account = {
    current_account_customer_vindi_key: accountData.vindKey || '',
    current_account_id: String(accountData.id),
    current_account_name: accountData.name,
    current_account_segment: accountData.segment,
    current_account_plan: accountData.plan,
    current_account_created_at: accountData.createdAt,
    current_account_: accountData.createdAt,
  };
  const user = {
    current_user_email: accountData?.user.email,
    current_user_id: String(accountData?.user.id),
    current_user_name: accountData.user.name,
    current_user_email_confirmed: accountData?.user.confirmed,
  };
  const data = {
    event: 'pageView',
    virtualUrl: path,
    ...(!isEmpty(accountData) && { ...account }),
    ...(!isEmpty(accountData?.user) && { ...user }),
  };
  window.dataLayer.push(data);
};
