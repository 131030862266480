import { axios } from '@/plugins';

const fetchAudits = async ({ commit }, { url, params }) => {
  const { data } = await axios.get(url, { params });
  commit('SET_AUDITS', data.audits);
  commit('SET_PAGINATION', data.pagination);
};

export default {
  fetchAudits,
};
