import { useEventListener } from '@vueuse/core';
import { readonly, shallowRef, watch } from 'vue';

const searchParams = shallowRef(new URLSearchParams(window.location.search));
const params = shallowRef({});
let watcher = null;

export function useSearchParams(mode = 'history') {
  if (mode !== 'history') return [];

  const updateSearch = () => {
    const queryString = `?${searchParams.value.toString()}`;

    window.history.replaceState(window.history.state, '', queryString);
  };

  const setParams = (newParams) => {
    const queryParams = new URLSearchParams();

    Object.keys(newParams).forEach((key) => {
      const value = newParams[key];

      if (!value) return;
      queryParams.append(key, value);
    });

    searchParams.value = queryParams;
    updateSearch();
  };

  const updateParams = (newParams) => {
    setParams({ ...params.value, ...newParams });
  };

  const updateSearchParams = () => {
    searchParams.value = new URLSearchParams(window.location.search);
  };

  const updateSearchParamsOnEventListen = () => {
    useEventListener('popstate', updateSearchParams);
    useEventListener('pushState', updateSearchParams);
    useEventListener('replaceState', updateSearchParams);
  };

  const watchSearchParams = () => {
    if (watcher) return;

    watcher = watch(
      searchParams,
      (searchParamsValue) => {
        params.value = searchParamsValue
          .entries()
          .reduce((all, [key, value]) => ({ ...all, [key]: value }), {});
      },
      { immediate: true }
    );
  };

  watchSearchParams();
  updateSearchParamsOnEventListen();

  return { params: readonly(params), setParams, updateParams };
}
