<template>
  <XModal
    :is-open="showModal"
    data-testid="copyLinkFormModal"
    @close="$emit('close')"
  >
    <template #header>
      {{ $t('flowModule.modalCopyLink.title') }}
    </template>
    <template #default>
      <p>
        {{ $t('flowModule.modalCopyLink.description') }}
      </p>
      <BaseCopyInput
        :class="$style.formFlowLink"
        :value="formFlowLink"
      />
      <XLink
        :to="formFlowLink"
        target="_blank"
        data-testid="formFlowLink"
      >
        {{ $t('flowModule.modalCopyLink.link') }}
      </XLink>
    </template>
    <template #actions>
      <XButton
        data-testid="modalCloseButton"
        @click="$emit('close')"
      >
        {{ $t('general.action.close') }}
      </XButton>
    </template>
  </XModal>
</template>

<script>
import { useStore } from '@/store';
import { defineComponent, computed } from 'vue';
import BaseCopyInput from '@base/components/BaseCopyInput/BaseCopyInput.vue';

export default defineComponent({
  name: 'FormFlowCopyLinkModal',
  components: {
    BaseCopyInput,
  },
  props: {
    flow: {
      type: Object,
      default: () => {},
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();

    const selectedFlow = computed(() => store.getters['formFlow/getSelectedFlow']);
    const formFlowLink = computed(() => selectedFlow.value?.links?.fluxia || '');

    return {
      formFlowLink,
    };
  },
});
</script>

<style lang="scss" module>
.formFlowLink {
  margin-bottom: var(--space-regular);
}
</style>
