import { camelizeKeys } from '.';

export const getViewDataset = (removeElement) => {
  const parseDatasetToProps = (dataset) =>
    _.mapValues(dataset, (value) => {
      const isParsableString = !_.isError(_.attempt(JSON.parse.bind(null, value)));
      return isParsableString ? JSON.parse(value) : value;
    });
  const vueElement = document.getElementById('vue-element');

  const viewName = vueElement?.dataset.view;
  const pathName = vueElement?.dataset.path;
  const viewProps = vueElement?.dataset.props
    ? camelizeKeys(JSON.parse(vueElement?.dataset.props))
    : parseDatasetToProps(vueElement?.dataset);

  if (vueElement && removeElement) document.body.removeChild(vueElement);
  return { viewName, pathName, viewProps };
};

export const getViewProps = (removeElement = true) => getViewDataset(removeElement).viewProps;
