import { usePromise } from '@/modules/base/hooks/usePromise';
import { axios } from '@/plugins';

const links = {
  envelope: {
    facematch: 'facematch.self',
    documentQuality: 'facematch.appendPhoto',
    facematchAppendEvidence: 'facematch.appendPhotoEvidence',
    status: 'facematch.status',
    check: 'facematch.check',
    attemptsNotify: 'facematch.attemptsNotify',
  },
  batch: {
    facematch: 'facematch',
    ocr: 'ocr',
    documentQuality: 'facematchDocumentQuality',
    facematchAppendEvidence: 'facematchAppendEvidence',
    status: 'facematchStatus',
    check: 'facematchCheck',
    attemptsNotify: 'attemptsExceededNotify',
  },
};

const getPathLink = (getters, key) => {
  const batchOrEnvelope = _.get(getters, 'fromEnvelope') ? 'envelope' : 'batch';
  return 'getLinks.'.concat(links[batchOrEnvelope][key]);
};

const setDocumentData = ({ commit }, payload = { type: 'documentType', content: '' }) => {
  const commits = {
    documentType: 'SET_DOCUMENT_TYPE',
    documentFront: 'SET_DOCUMENT_FRONT',
    documentBack: 'SET_DOCUMENT_BACK',
  };

  commit(commits[payload.type], payload.content);
};

const initFacematch = async ({ getters }, payload) => {
  const url = _.get(getters, getPathLink(getters, 'facematch'));
  const response = await usePromise(axios.post(url, payload));
  return response;
};

const getFacematchData = async ({ getters }) => {
  const url = _.get(getters, getPathLink(getters, 'facematch'));
  const response = await usePromise(axios.get(url));
  return response;
};

const validateImageQuality = async ({ getters }, payload) => {
  const url = _.get(getters, getPathLink(getters, 'documentQuality'));
  const response = await usePromise(
    axios.post(url, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  );
  return response;
};

const appendDocumentEvidence = async ({ getters }, payload) => {
  const url = _.get(getters, getPathLink(getters, 'facematchAppendEvidence'));
  const response = await usePromise(
    axios.post(url, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  );

  return response;
};

const getFacematchStatus = async ({ getters, commit }) => {
  const url = _.get(getters, getPathLink(getters, 'status'));
  const { data } = await axios.get(url);
  commit('SET_FACEMATCH_STATUS', data.status);
  return data.status;
};

const sendFacematchEvidences = async ({ getters }, payload) => {
  const url = _.get(getters, getPathLink(getters, 'check'));
  const response = await usePromise(
    axios.post(url, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  );
  return response;
};

const sendNoAttemptsEmail = async ({ getters }) => {
  const url = _.get(getters, getPathLink(getters, 'attemptsNotify'));
  const response = await usePromise(axios.post(url));
  return response;
};

const sendFacematchDiscovery = async ({ getters }, payload) => {
  const sendEvidencesUrl = _.get(getters, getPathLink(getters, 'documentQuality'));

  try {
    await axios.post(sendEvidencesUrl, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    await new Promise((resolve) => setTimeout(resolve, 1000));
    return getFacematchData({ getters });
  } catch (err) {
    return [err, null];
  }
};

const ocrCheck = async ({ getters }, { documentation, facematchKey }) => {
  const url = _.get(getters, getPathLink(getters, 'ocr'));

  const divergencePayload = {
    ocr: {
      recognizableType: 'facematch',
      recognizableKey: facematchKey,
      signerDocumentation: documentation,
    },
  };

  const response = await usePromise(axios.post(url, divergencePayload));
  return response;
};

export default {
  setDocumentData,
  getFacematchStatus,
  validateImageQuality,
  appendDocumentEvidence,
  initFacematch,
  getFacematchData,
  sendNoAttemptsEmail,
  sendFacematchDiscovery,
  sendFacematchEvidences,
  ocrCheck,
};
