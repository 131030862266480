export default {
  actions: {
    forward: 'Avançar',
    back: 'Voltar',
  },
  fields: {
    lists: 'Signatários:',
    selectPlaceholder: 'Nenhum signatário',
  },
  seal: {
    title: 'Rubrica no canto da página',
    subtitle:
      'Informe quem precisa inserir uma rubrica no canto inferior de cada página do documento.',
    alert: 'O visto será aplicado em todas as páginas do documento.',
    fields: {
      pagesLabel: 'Páginas:',
      pagesPlaceholder: 'Ex: 1, 2, 3',
      allPagesLabel: 'Todas as páginas',
      selectedPage: 'Página',
    },
  },
  fullReadConfirmation: {
    title: 'Visualização completa do documento',
    subtitle:
      'Informe quem deve visualizar todas as páginas do documento para conseguir assiná-lo.',
  },
  clause: {
    title: 'Confirmação de leitura de cláusulas',
    subtitle:
      'Informe os signatários que devem confirmar a leitura e quais partes do documento devem ser confirmadas.',
    fields: {
      clauses: 'Li e aceito as cláusulas/itens:',
      placeholder: 'Ex: 1-4, 10, 20',
    },
  },
};
