const general = {
  back: 'Voltar',
  return: 'Voltar',
  close: 'Fechar',
  loading: 'Carregando...',
  tryAgain: 'Tentar novamente',
  download: 'Baixar',
  next: 'Avançar',
  help: 'Ajuda',
  secureEnvironment: 'Ambiente seguro Clicksign',
  signatures: 'Assinaturas',
  finalize: 'Finalizar',
  sign: 'Assinar',
  options: 'Opções',
  userActions: {
    signatures: 'Assinaturas',
    refusal: 'Recusar',
    show: 'Visualizar',
    download: 'Baixar',
    help: 'Ajuda',
    dashboard: 'Minha área de assinatura',
    nextPage: 'Próxima',
    prevPage: 'Anterior',
  },
  action: {
    conclude: 'Concluir',
  },
  feature: {
    beta: 'Beta',
  },
  unavailableWidgetEmbedded: {
    error: 'Erro: Clicksign inativo no seu site',
    title: 'Não é possível prosseguir {0} com a assinatura.',
    description: 'Verifique com o remetente do documento, {0} e informe o erro ocorrido.',
  },
  noLegalStatus: 'Ambiente {environment} - Sem valor legal',
  slowLoading: 'O carregamento desta página está demorando mais do que o esperado',
};

export default general;
