import { axios } from '@/plugins';

const setLinks = ({ commit }, links) => {
  commit('SET_LINKS', links);
};

const fetchFolderNodes = async ({ commit }) => {
  try {
    commit('SET_NODES_LOADING', true);
    const url = new URL(window.location.href);
    const { data } = await axios.get(url.toString());
    commit('SET_CURRENT_FOLDER', {
      ...data,
      nodes: [
        ...(data.folders || []),
        ...(data.envelopes || []),
        ...(data.documents || []),
        ...(data.nodes || []),
      ],
    });
    commit('SET_NODES_LOADING', false);
    // TOGGLE_ENVELOPE_SETUP
    // TODO: Remove SET_NEW_DRAFT_PACK_CURRENT_FOLDER commit
    commit('info/SET_NEW_DRAFT_PACK_CURRENT_FOLDER', data.links.newDraft, { root: true });
    commit('info/SET_NEW_ENVELOPE_CURRENT_FOLDER', data.links.newEnvelope, { root: true });
  } catch (err) {
    throw err;
  }
};

const hardDeleteNode = async ({ getters, dispatch }, payload) => {
  try {
    const url = getters.getLinks.hardDelete;
    await axios.patch(url, payload);
    dispatch('fetchFolderNodes', { isTrashBin: true });
  } catch (err) {
    throw err;
  }
};

const moveNode = async ({ getters }, payload) => {
  try {
    const url = getters.getLinks.moveNode;
    const { data } = await axios.patch(url, payload);
    return data;
  } catch (err) {
    throw err;
  }
};

const createFolder = async ({ getters, dispatch }, payload) => {
  try {
    const url = getters.getLinks.newFolder;
    await axios.post(url, payload);

    dispatch('fetchFolderNodes');
  } catch (err) {
    throw err;
  }
};

const renameFolder = async ({ getters, dispatch }, payload) => {
  try {
    const url = getters.getLinks.renameFolder;
    await axios.patch(url, payload);

    dispatch('fetchFolderNodes');
  } catch (err) {
    throw err;
  }
};

const getHasNodeRunning = async ({ getters }, params) => {
  try {
    const url = getters.getLinks.runningNodes;
    const data = await axios.get(url, { params });
    return data;
  } catch (err) {
    throw err;
  }
};

const deleteNode = async ({ getters, dispatch }, payload) => {
  try {
    const url = getters.getLinks.delete;
    await axios.patch(url, payload);
    dispatch('fetchFolderNodes');
  } catch (err) {
    throw err;
  }
};

const cancelDocument = async ({ getters, dispatch }, payload) => {
  try {
    const url = getters.getLinks.cancelDocument;
    await axios.patch(url, payload);
    dispatch('fetchFolderNodes', true);
  } catch (err) {
    throw err;
  }
};

const getFolderChildren = async (vuex, { url, filters }) => {
  try {
    const { data } = await axios.get(url, { params: { ...filters } });
    return data.nodes;
  } catch (err) {
    throw err;
  }
};

const newChildFolder = async (vuex, { url, params }) => {
  try {
    const { data } = await axios.post(url, params);
    return data.folder;
  } catch (err) {
    throw err;
  }
};

const downloadNodes = async ({ getters }, params) => {
  try {
    const url = getters.getLinks.downloadNodes;
    await axios.post(url, params);
  } catch (error) {
    throw error;
  }
};

const getFolderPermissions = async (vuex, url) => {
  try {
    const { data } = await axios.get(url);
    return data.node;
  } catch (err) {
    throw err;
  }
};

const patchFolderPermissions = async ({ dispatch }, { url, permissions }) => {
  try {
    await axios.patch(url, { permissions });
    dispatch('fetchFolderNodes');
  } catch (err) {
    throw err;
  }
};

const editEnvelope = async ({ getters, dispatch }, { id, name }) => {
  try {
    const url = getters.getNodes.find((node) => node.id === id).links.rename;

    await axios.post(url, { name }).then(() => {
      dispatch('fetchFolderNodes');
    });
  } catch (err) {
    throw err;
  }
};

export default {
  setLinks,
  fetchFolderNodes,
  hardDeleteNode,
  moveNode,
  getHasNodeRunning,
  deleteNode,
  cancelDocument,
  createFolder,
  renameFolder,
  getFolderChildren,
  newChildFolder,
  downloadNodes,
  getFolderPermissions,
  patchFolderPermissions,
  editEnvelope,
};
