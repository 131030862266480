export default {
  GET_DOCUMENT(state, payload) {
    Object.assign(state, payload);
  },
  SET_ANCESTORS(state, payload) {
    state.ancestors = payload;
  },
  SET_DOCUMENT_SIGNER(state, payload) {
    state.signers = [...state.signers, payload];
    state.pending_signers = [...state.pending_signers, payload];
    state.groups = { ...state.groups, ...payload.group };
  },
  SET_CURRENT_GROUP(state, payload) {
    state.currentGroup = payload;
  },
  SET_DOCUMENT_SIGNERS(state, payload) {
    state.groups = payload || {};
  },
  UPDATE_DOCUMENT_SIGNERS(state, payload) {
    const groups = state.groups || {};
    const newGroups = payload || {};

    Object.keys(newGroups).forEach((groupKey) => {
      if (groups[groupKey]) {
        groups[groupKey] = [...groups[groupKey], ...newGroups[groupKey]];
      } else groups[groupKey] = newGroups[groupKey];
    });

    state.groups = { ...groups };
  },
  UPDATE_DOCUMENT_SIGNER(state, signer) {
    const groupId = signer.group;
    const groupOfSigner = state.groups[groupId] || [];
    const indexSignerToUpdate = groupOfSigner.findIndex(({ key }) => key === signer.key);

    groupOfSigner.splice(indexSignerToUpdate, 1, signer);

    state.groups = {
      ...state.groups,
      [groupId]: [...groupOfSigner],
    };
  },
  UPDATE_DOCUMENT_SETTINGS(state, payload) {
    state.deadline_at = payload.deadline_at;
    state.remind_interval = payload.remind_interval;
    state.locale = payload.locale;
    state.autoclose = payload.autoclose;
  },
  DELETE_DOCUMENT_SIGNER(state, payload) {
    const listIdExist = (item) => item.list_id === payload.id;
    state.lists = {
      signatures: state.lists.signatures.filter((item) => !listIdExist(item)),
      pendingSigners: state.lists.pendingSigners.filter((item) => !listIdExist(item)),
      refusedSigners: state.lists.refusedSigners.filter((item) => !listIdExist(item)),
    };
    state.signers = state.signers.filter((item) => !listIdExist(item));
    state.refused_signers = state.refused_signers.filter((item) => !listIdExist(item));
    // TODO: Keep this until reorderGroups logic needs it
    state.pending_signers = state.pending_signers.filter((item) => !listIdExist(item));
    if (payload.groups) state.groups = payload.groups;
  },
  DOCUMENT_ORDERING(state, payload) {
    state.sequence_enabled = payload.sequence_enabled;
    state.groups = payload.groups;
  },
  SET_LINKS(state, payload) {
    state.links = payload;
  },
  SET_TOGGLES(state, payload) {
    state.toggles = payload;
  },
  SET_DOCUMENT_LISTS(state, payload) {
    const payloadRefusedSigners = payload.refusedSigners ? payload.refusedSigners : [];
    state.lists = {
      signatures: payload.signatures,
      pendingSigners: payload.pendingSigners,
      refusedSigners: payloadRefusedSigners,
    };
  },
  UPDATE_DOCUMENT_LISTS(state, payload) {
    const payloadRefusedSigners = payload.refusedSigners ? payload.refusedSigners : [];
    state.lists = {
      signatures: [...state.lists.signatures, ...payload.signatures],
      pendingSigners: [...state.lists.pendingSigners, ...payload.pendingSigners],
      refusedSigners: [...state.lists.refusedSigners, ...payloadRefusedSigners],
    };
  },
  SET_DOCUMENT_GROUPS(state, payload) {
    state.groups = payload;
  },
  SET_TOTAL_SIGNERS(state, payload) {
    state.totalSigners = payload;
  },
  SET_TOTAL_SIGNATURES(state, payload) {
    state.totalSignatures = payload;
  },
  EMPTY_DOCUMENT_LISTS(state) {
    state.lists = {
      signatures: [],
      pendingSigners: [],
      refusedSigners: [],
    };
  },
  SET_DOCUMENT_DETAILS(state, payload) {
    state.documentDetails = payload.map((data) => ({
      ...data,
      uuid: Math.random().toString(16).slice(2),
    }));
  },
  SET_DOCUMENT_DETAIL_VALIDITY_DATE(state, payload) {
    state.documentDetails = state.documentDetails.map((documentDetail) => {
      const data =
        documentDetail.default_field && documentDetail.kind === 'date'
          ? { ...payload }
          : documentDetail;
      return data;
    });
  },
  SET_DOCUMENT_DETAIL_TEXT(state, payload) {
    state.documentDetails = state.documentDetails.map((documentDetail) => {
      const data =
        documentDetail.default_field && documentDetail.kind === 'text'
          ? { ...payload }
          : documentDetail;
      return data;
    });
  },
  SET_DOCUMENT_DETAIL_EVENT(state, payload) {
    state.documentDetails = state.documentDetails.map((documentDetail) => {
      const data =
        !documentDetail.default_field && documentDetail.uuid === payload.uuid
          ? { ...payload }
          : documentDetail;
      return data;
    });
  },
  SET_DOCUMENT_DEFAULT_FIELD_DETAIL(state, kind) {
    state.documentDetails = [
      ...state.documentDetails,
      {
        id: -1,
        name: kind === 'text' ? 'Detalhes' : 'Final da vigência',
        kind,
        value: '',
        default_field: true,
      },
    ];
  },
  CREATE_DOCUMENT_DETAIL_EVENT(state) {
    state.documentDetails = [
      ...state.documentDetails,
      {
        id: -1,
        uuid: Math.random().toString(16).slice(2),
        name: '',
        kind: 'date',
        value: '',
        default_field: false,
      },
    ];
  },
  SET_DOCUMENT_EVENTS(state, payload) {
    state.events = payload;
  },
  SET_DOCUMENT_FLOW(state, payload) {
    state.flow = payload;
  },
  SET_TOTAL_PENDING_SIGNERS(state, payload) {
    state.totalPendingSigners = payload;
  },
  SET_DOCUMENT_TIMESTAMPS(state, payload) {
    state.documentTimestamps = payload;
  },
};
