import DEFAULT_SIGNER_CONTEXT from './contexts/default-signer-context';
import FLOW_FLEXIBLE_SIGNER_CONTEXT from './contexts/flow-flexible-signer-context';
import ENVELOPE_DRAFT_SIGNER_CONTEXT from './contexts/envelope-draft-signer-context';
// TOGGLE_FLOW_WITH_FLEXIBLE_AUTH_ENABLED
// TODO: remove import below
import FORM_SIGNER_CONTEXT from './contexts/form-signer-context';
import SPREADSHEET_SIGNER_CONTEXT from './contexts/spreadsheet-signer-context';
import ENVELOPE_EDIT_SIGNER_CONTEXT from './contexts/envelope-edit-signer-context';
import ENVELOPE_EDIT_FLEXIBLE_SIGNER_CONTEXT from './contexts/envelope-edit-flexible-signer-context';
import ENVELOPE_NEW_SIGNER_CHAT_CONTEXT from './contexts/envelope-new-signer-chat-context';
import FORM_SIGNER_FLEXIBLE_CONTEXT from './contexts/form-signer-flexible-context';

function GET_SIGNER_CREATION_STEPS(context) {
  const stepsByContext = {
    DEFAULT: DEFAULT_SIGNER_CONTEXT,
    ENVELOPE_DRAFT_SIGNER: ENVELOPE_DRAFT_SIGNER_CONTEXT,
    // TOGGLE_FLOW_WITH_FLEXIBLE_AUTH_ENABLED
    // TODO: remove FORM_SIGNER
    FORM_SIGNER: FORM_SIGNER_CONTEXT,
    FLOW_FLEXIBLE_SIGNER: FLOW_FLEXIBLE_SIGNER_CONTEXT,
    SPREADSHEET_SIGNER: SPREADSHEET_SIGNER_CONTEXT,
    ENVELOPE_EDIT_SIGNER: ENVELOPE_EDIT_SIGNER_CONTEXT,
    ENVELOPE_EDIT_FLEXIBLE_SIGNER: ENVELOPE_EDIT_FLEXIBLE_SIGNER_CONTEXT,
    ENVELOPE_NEW_SIGNER_CHAT: ENVELOPE_NEW_SIGNER_CHAT_CONTEXT,
    FORM_SIGNER_FLEXIBLE: FORM_SIGNER_FLEXIBLE_CONTEXT,
  };
  return stepsByContext[context] || stepsByContext.DEFAULT;
}

const SIGNER_FLEXIBLE_CONTEXTS = [
  'ENVELOPE_DRAFT_SIGNER',
  'ENVELOPE_EDIT_FLEXIBLE_SIGNER',
  'EDIT_FLEXIBLE_SIGNER',
  'ENVELOPE_NEW_SIGNER_CHAT',
  'FLOW_FLEXIBLE_SIGNER',
  'FORM_SIGNER_FLEXIBLE',
];

export default {
  SIGNER_FLEXIBLE_CONTEXTS,
  SIGNER_CREATION_STEPS: GET_SIGNER_CREATION_STEPS,
};
