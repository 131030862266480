import axios from 'axios';

const getBrand = async ({ commit }) => {
  try {
    const response = await axios.get('brand');
    const payload = response.data.brand;
    commit('SET_BRAND', payload);
    return response.data;
  } catch (error) {
    const payload = {
      logo_url: null,
      color_1: '#1474F5',
      color_2: '#FF8500',
      color_3: '#FFFFFF',
      plan: '',
    };
    commit('SET_BRAND', payload);
    throw error;
  }
};

const setBrand = async ({ commit }, obj) => {
  try {
    const response = await axios({
      method: obj.brand.method,
      url: 'brand',
      data: {
        brand: {
          logo_url: obj.brand.logo_url,
          color_1: obj.brand.color_1,
          color_2: obj.brand.color_2,
          color_3: obj.brand.color_3,
        },
      },
    });
    const payload = obj.brand;
    commit('SET_BRAND', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default {
  getBrand,
  setBrand,
};
