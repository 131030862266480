import flattenObj from '@base/utils/flatten-obj';

export default {
  SET_LINKS(state, payload) {
    Object.assign(state.links, { ...payload });
    if (payload?.navigation) state.sidebarLinks = payload.navigation;
  },
  SET_ROUTES(state, payload) {
    Object.assign(state.routes, flattenObj(payload));
  },
  SET_ACCOUNT_DATA(state, payload) {
    state.account = payload.account || {};
    state.toggles = payload.account.toggles || {};
    window.toggles = payload.account.toggles || {};
  },
  SET_ACCOUNT(state, payload) {
    state.account = payload || {};
  },
  SET_AB_TESTS_DATA(state, payload) {
    state.abTests = payload || {};
  },
  SET_USER(state, payload) {
    state.user = payload || {};
  },
  SET_USER_ACCOUNTS(state, payload) {
    state.userAccounts = payload || [];
  },
  SET_TOGGLES(state, payload) {
    state.toggles = payload || {};
  },
  SET_DOWNLOAD_PROCESSING: (state, payload) => {
    state.downloadProcessing = payload;
  },
  // TOGGLE_ENVELOPE_SETUP
  // TODO: Remove SET_NEW_DRAFT_PACK_CURRENT_FOLDER mutation
  SET_NEW_DRAFT_PACK_CURRENT_FOLDER: (state, payload) => {
    Object.assign(state.links, { newDraftPackCurrentFolder: payload });
  },
  SET_NEW_ENVELOPE_CURRENT_FOLDER: (state, payload) => {
    Object.assign(state.links, { newEnvelopeCurrentFolder: payload });
  },
  SET_EMPLOYEES_AMOUNT: (state, payload) => {
    state.employeesAmount = payload;
  },
  SET_SIDEBAR_BADGES: (state, payload) => {
    Object.assign(state.sidebarBadges, { ...payload });
  },
  SET_TOGGLE_VALUE: (state, payload) => {
    state.toggles[payload.toggle] = payload.value;
  },
  SET_CONFIG_DATA: (state, payload) => {
    state.config = payload;
  },
  SET_ROUTER: (state, payload) => {
    state.router = payload;
  },
  UPDATE_PLAN: (state, payload) => {
    state.account.plan = payload;
  },
};
