import { parseSigner } from '@document/utils/signer-utils';

export default [
  {
    name: 'info',
    title: 'signer.infoStep.spreadsheetSigner.title',
    component: 'SpreadsheetFlowSignerForm',
    form: 'signerInfo',
    props: {
      signerAuths: 'signerAuths',
      signAs: 'signerInfo.signAs',
      auths: 'signerInfo.auths',
      officialDocumentEnabled: 'signerInfo.officialDocumentEnabled',
      facialBiometricsEnabled: 'signerInfo.facialBiometricsEnabled',
      selfieEnabled: 'signerInfo.selfieEnabled',
      handwrittenEnabled: 'signerInfo.handwrittenEnabled',
      livenessEnabled: 'signerInfo.livenessEnabled',
      description: 'signerInfo.description',
      hasWhatsapp: 'signerInfo.hasWhatsapp',
      hasDocumentation: 'signerInfo.hasDocumentation',
      formField: 'signerInfo.formField',
      fieldsToShow: 'currentStepFields',
      emailFormFields: 'emailFormFields',
      additionalAuths: 'additionalAuthsItems',
      formDescription: 'infoFormDescription',
    },
    baseState: {
      description: '',
      signAs: '',
      hasDocumentation: true,
      hasWhatsapp: false,
      additionalAuths: [],
    },
    action: (vm, form) => parseSigner(_.clone(form), vm.context),
    afterAction: (vm, signer) => vm.$set(vm.signAs, 'signers', [signer]),
  },
  {
    name: 'signAs',
    title: 'signer.infoStep.signAs.label',
    component: 'SignerListSignAsStep',
    form: 'signAs',
    props: {
      signers: 'signAs.signers',
      signerSignAs: 'getSignerSignAsItems',
      signAsList: 'signAs.signAsList',
    },
    baseState: {
      signers: [],
      signAsList: {},
    },
    action: async (vm, payload) => {
      const url = _.get(vm.$store.getters, 'signer/getLinks.linkSpreadsheetSigner');
      const response = await vm.$store.dispatch('signer/linkSigner', {
        url,
        payload,
        context: vm.context,
      });
      vm.$emit('save', response);
      return response;
    },
  },
];
