export default {
  dateFormat: 'MM/DD/YYYY',
  errors: {
    requiredIf: 'Value can not be blank',
    required: 'Value can not be blank',
    phone: 'Invalid phone',
    email: 'Invalid e-mail',
    cpf: 'Input your CPF correctly',
    date: 'Invalid date',
    fullName: 'Use name and surname',
    dateInThePast: 'date should be in the past',
    minLength: 'Input at least {min} characters.',
  },
};
