const icpBrasil = {
  helpLink: '¿Cómo funciona?',
  providersLink: 'Verifica cuáles son los proveedores compatibles',
  alt: 'Proveedor del certificado digital',
  certificateTypes: {
    title: 'Firma digital',
    subtitle: 'Selecciona el tipo de certificado que deseas usar para firmar',
    local: 'Certificado Local',
    cloud: 'Certificado en la Nube',
  },
  howItWorksModal: {
    title: 'Certificado digital',
    whatIs: {
      title: '¿Qué es la Firma Digital?',
      text: 'Es una forma de que una persona o empresa pruebe su identidad en internet, utilizando un Certificado Digital.',
    },
    why: {
      title: '¿Por qué firmar con Certificado Digital?',
      authSignerRequired:
        'El Certificado Digital puede ser un requisito para la autenticación del firmante en algunos casos.',
      icpBrasilAsAuth:
        'El remitente de este documento eligió el Certificado Digital como forma de autenticación.',
    },
  },
  noDocumentationFoundModal: {
    title: 'Certificado no encontrado',
    description:
      'No se encontró ningún Certificado Digital en la Nube vinculado al CPF {0}. Si es necesario, corrige o solicita la corrección del CPF al remitente.',
    providersLink: 'Verifica si tu proveedor es compatible con Clicksign.',
  },
  cloudUnavailableModal: {
    title: 'Servicio temporalmente no disponible',
    description:
      'Espera un momento e intenta nuevamente, o contacta al proveedor de tu certificado para verificar la inestabilidad.',
  },
  webPki: {
    installModal: {
      title: 'Firma de PDF',
      text: 'Para continuar, es necesario instalar la extensión Web PKI.',
      helpLink: '¿Qué es Web PKI?',
      action: 'Instalar',
    },
    helpModal: {
      whatIs: {
        title: '¿Qué es Web PKI?',
        text: 'El Web PKI es una extensión necesaria para autenticar usuarios o firmar usando un certificado digital.',
      },
      howToUse: {
        title: '¿Cómo lo puedo usar?',
        installExtension: 'Instala la extensión en tu navegador',
        selectCertificate: 'Selecciona el certificado que deseas usar',
        authorize: 'Haz click en "Autorizar"',
        insertPin: 'Ingresa el PIN, Token o QR Code solicitado',
      },
    },
  },
  mobileAlertModal: {
    computerRequired: 'Para firmar con Certificado Digital, utiliza una computadora.',
    onlyCloud: 'Solo podrás firmar desde un dispositivo móvil si tienes un Certificado en la Nube.',
  },
  accessProblemModal: {
    allCertificatesWithExpDate: 'No se encontraron certificados válidos para completar la firma.',
    emptyCertificates: 'No se encontraron Certificados en este equipo.',
    noneCertificateLinkWithCPF:
      'No encontramos ningún Certificado Digital vinculado al CPF registrado.',
    tutorialSign: '¿Problemas para firmar?',
    title: {
      default: 'Problemas de acceso',
      certExpire: 'El certificado ha expirado',
    },
  },
  cloudCertificate: {
    title: 'Selecciona el proveedor del certificado',
    description: 'Proveedores compatibles y vinculados al CPF: {0}',
  },
  certificateLocal: {
    title: 'Selecciona un certificado',
    select: {
      placeholder: 'Certificados disponibles',
      valid: 'Válidos',
      expired: 'Expirados',
      wrongCpf: 'No relacionados con el CPF registrado',
      company: 'Empresa',
    },
    errors: {
      undefined: 'Error en el proceso de firma con Certificado.',
      general:
        'Error en el proceso de firma con Certificado. Informa el código de error al equipo de soporte de ClickSign:',
      userCancelled: 'Operación cancelada por el usuario',
      concurrentSignature:
        'Hay otra persona firmando al mismo tiempo. Inténtalo de nuevo en unos minutos',
      cspKeysetNotDefined:
        'Certificado no encontrado. Verifica si la tarjeta o token está conectado al equipo e intenta de nuevo',
      conversionPdfa: 'No fue posible convertir este documento a PDF/A',
    },
    sign: 'Firmar',
  },
  unavailable: {
    errorLabel: 'ERROR: WIDGET EMBEDDED HABILITADO',
    title: 'No es posible continuar con la firma',
    subtitle: 'Informa el error al remitente del documento',
  },
};

export default icpBrasil;
