const nodes = [
  {
    path: '/accounts/:accountId/folders/:folderId',
    name: 'nodes',
    component: () => import('@document/views/NodesListView.vue'),
    meta: {
      layout: 'BaseNavigationLayout',
    },
  },
  {
    path: '/accounts/:accountId/documents/:filterType',
    name: 'filteredDocuments',
    component: () => import('@document/views/NodesListView.vue'),
    meta: {
      layout: 'BaseNavigationLayout',
    },
  },
  {
    path: '/accounts/:accountId/notarial/links/:filterType(running|closed|canceled)',
    name: 'filteredEnvelopes',
    component: () => import('@document/views/NodesListView.vue'),
    meta: {
      layout: 'BaseNavigationLayout',
    },
  },
];

export default nodes;
