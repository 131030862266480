import { sentry } from '@/plugins/sentry';
import { useStore } from '@/store';
import { useFetchInvoices } from '@account/composables';
import { useAccountInfo } from '@base/hooks';

export const pendingBillsGuard = async (to, from, next) => {
  try {
    const store = useStore();
    const links = store.getters['info/getLinks'];

    // TOGGLE_MIDAS_CLIENT_INTEGRATION_ENABLED
    // TODO: remove the conditional bellow
    const toggles = store.getters['info/getToggles'];
    if (!toggles.toggleMidasClientIntegrationEnabled) {
      return next();
    }

    const { isAccountPlan } = useAccountInfo();

    if (isAccountPlan('trial')) return next();

    const { fetchInvoices, pendingInvoices } = useFetchInvoices();

    await fetchInvoices();

    if (pendingInvoices.value?.length && links?.consumption) {
      return next({
        path: links.consumption,
        query: { has_open_invoices: true },
      });
    }
  } catch (error) {
    sentry.captureException(error);
  }

  return next();
};
