export default {
  getLinks: (state) => state.links,
  getConsumption: (state) => state.consumption,
  getPlans: (state) => state.plans,
  getPaymentProfile: (state) => state.paymentProfile,
  getCurrentPaymentMethod: (state) => state.currentPaymentMethod,
  getNewPaymentMethod: (state) => state.newPaymentMethod,
  getVindiErrors: (state) => state.vindiErrors,
  getInvoices: (state) => state.invoices,
  getInvoicesPagination: (state) => state.invoicesPagination,
  getPeriodUsages: (state) => state.periodUsages,
  getProductUsages: (state) => state.productUsages,
  getNegotiation: (state) => state.negotiation,
  // TOGGLE_MIDAS_SCOPE
  // Remove getConsumptionInfo getter when scope >= 3
  getConsumptionInfo: (state) => state.consumptionInfo,
  getCards: (state) => state.cards,
  getCreditCardPagination: (state) => state.creditCardPagination,
  getBillingInfo: (state) => state.billingInfo,
};
