import { axios } from '@/plugins';

const checkDocumentPath = async (_, link) => {
  let status = 204;
  let data;

  while (status === 204) {
    try {
      const response = await axios.get(link);
      data = response.data;
      status = response.status;
    } catch (error) {
      throw error;
    }

    if (status === 204) {
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
  }

  return data;
};

export default {
  checkDocumentPath,
};
