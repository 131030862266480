import { camelizeKeys } from '.';

export const getViewDataset = (removeElement) => {
  const parseDatasetToProps = (dataset) =>
    _.mapValues(dataset, (value) => {
      const isParsableString = !_.isError(_.attempt(JSON.parse.bind(null, value)));
      return isParsableString ? JSON.parse(value) : value;
    });
  const vueElement = document.getElementById('vue-element');

  const { view: viewName, path: pathName, props } = vueElement?.dataset || {};

  const viewProps = props
    ? camelizeKeys(JSON.parse(props))
    : parseDatasetToProps(vueElement?.dataset);

  if (vueElement && removeElement) document.body.removeChild(vueElement);
  return { viewName, pathName, viewProps };
};

export const getViewProps = (removeElement = true) => getViewDataset(removeElement).viewProps;
