const audit = {
  title: 'Auditoría de la cuenta',
  help: {
    beta: 'Esta funcionalidad está en fase de prueba.',
    feature: 'Comienza a usarla para analizar actividades de tu cuenta.',
    link: 'Ayuda',
  },
  empty: {
    title: 'La actividad de tu cuenta se registrará aquí',
    text: 'Solo los usuarios administradores tienen acceso a esta área',
  },
  emptySearch: {
    title: 'No se encontraron resultados',
    description: 'Intenta cambiar el filtro para encontrar lo que buscas',
  },
  table: {
    name: 'Lote',
    size: 'Tamaño',
    action: 'Acción',
  },
  dateFilter: {
    placeholder: 'Selecciona una fecha...',
    button: 'Filtrar',
  },
};

export default audit;
