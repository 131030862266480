export default {
  SET_SAML: (state, payload) => {
    state.saml = { ...payload };
  },
  SET_SERVICE_PROVIDER: (state, payload) => {
    state.serviceProvider = { ...payload };
  },
  SET_ACCESS_LIMITATION: (state, payload) => {
    state.accessLimitation = { ...payload };
  },
  SET_LINKS(state, payload) {
    Object.assign(state.links, { ...payload });
  },
};
