export default {
  computed: {
    validationError() {
      const baseErrorsLocale = this.baseErrorsLocale || 'validation.errors';
      const messages = this.$t(baseErrorsLocale);
      const error = '';
      if (!this.validations || !Object.keys(this.validations).length) return error;
      const validations = Object.keys(this.validations).filter((key) =>
        Object.keys(messages).includes(key)
      );
      if (!this.validations.$dirty) return error;
      const validation = validations.find((val) => !this.validations[val]);
      if (!validation) return error;
      const params = this.validations.$params || {};
      const validationError = this.$t(`${baseErrorsLocale}.${validation}`, params[validation]);
      return validationError;
    },
  },
  methods: {
    triggerValidation() {
      if (!this.validations || !Object.keys(this.validations).length) return;
      if (this.validations.$dirty || this.validations.$model) this.validations.$touch();
    },
    resetValidation() {
      if (!this.validations || !Object.keys(this.validations).length) return;
      this.validations.$reset();
    },
  },
};
