import { axios } from '@/plugins';

const fetchFormFlow = async ({ commit }, url) => {
  const {
    data: { flow },
  } = await axios.get(url);

  commit('SET_FORM_FLOW', flow);
  commit('SET_SIGNATURE_WATCHERS', flow.signatureWatchers);
};

const updateFlowTemplate = async ({ getters }, payload) => {
  await axios.patch(getters.getFormFlow.template.links.update, payload);
};

const fetchListVariables = async ({ getters, commit }) => {
  let status = 204;
  let data;

  while (status === 204) {
    try {
      const response = await axios.get(getters.getFormFlow.links.listVariables);
      data = response.data;
      status = response.status;
    } catch (error) {
      throw error;
    }

    if (status === 204) {
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
  }

  commit('SET_LIST_VARIABLES', data.formBuildersFields);
};

const fetchSetupFormBuilders = async ({ getters, commit }, { page, name }) => {
  commit('SET_FORM_BUILDERS_LOADING', page === 1);
  const { data } = await axios.get(getters.getLinks.formBuilders, { params: { page, name } });
  const forms = page === 1 ? data.forms : [...getters.getFormBuilders, ...data.forms];

  commit('SET_FORM_BUILDERS_LOADING', false);
  commit('SET_FORM_BUILDERS', forms);
  commit('SET_FORM_BUILDERS_PAGINATION', data.pagination);
};

const fetchSetupTemplates = async ({ getters, commit }, { page, name, formTemplateId }) => {
  commit('SET_TEMPLATES_LOADING', page === 1);
  const { data } = await axios.get(getters.getLinks.templates, {
    params: { page, name, formTemplateId },
  });
  const templates = page === 1 ? data.templates : [...getters.getTemplates, ...data.templates];

  commit('SET_TEMPLATES_LOADING', false);
  commit('SET_TEMPLATES', templates);
  commit('SET_TEMPLATES_PAGINATION', data.pagination);
};

const fetchTemplatePreview = async (_, url) => {
  let status = 204;
  let data;

  while (status === 204) {
    try {
      const response = await axios.get(url);
      data = response.data;
      status = response.status;
    } catch (error) {
      throw error;
    }

    if (status === 204) {
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
  }

  return data.template;
};

const enableFormFlow = async ({ commit, getters }) => {
  try {
    const url = getters.getLinks.show;

    const { data: responseData } = await axios.patch(url, { flow: { enabled: true } });
    const isFlowEnabled = responseData.flow.enabled;

    commit('SET_ENABLED', isFlowEnabled);
    return isFlowEnabled;
  } catch (error) {
    throw error;
  }
};

const createFormFlow = async ({ getters }) => {
  try {
    const url = getters.getLinks.new;

    const { data: responseData } = await axios.post(url, {});

    return { flow: responseData.flow };
  } catch (error) {
    throw error;
  }
};

const fetchListSigners = async ({ getters, commit }) => {
  try {
    const url = getters.getSignerLinks.signers;

    const {
      data: { signersList },
    } = await axios.get(url);

    commit('SET_SIGNERS_LIST', signersList);
  } catch (error) {
    throw error;
  }
};

const createRubricates = async ({ getters }, payload) => {
  await axios.post(getters.getFormFlow.links.createRubricates, { rubricates: payload });
};

const getPositionSigns = async ({ getters, commit }) => {
  const {
    data: { rubricates },
  } = await axios.get(getters.getFormFlow.links.rubricates);

  commit('SET_RUBRICATES', rubricates);
};

const createSignatureWatcher = async ({ getters, commit }, payload) => {
  try {
    const url = getters.getFormFlow.links.createSignatureWatcher;

    const {
      data: { signatureWatcher },
    } = await axios.post(url, payload);

    commit('SET_SIGNATURE_WATCHERS', [...getters.getSignatureWatchers, signatureWatcher]);
  } catch (error) {
    throw error;
  }
};

const updateSignatureWatcher = async (_, { link, attachDocumentsEnabled }) => {
  await axios.put(link, { attachDocumentsEnabled });
};

const deleteSignatureWatcher = async ({ getters, commit }, watcher) => {
  try {
    await axios.delete(watcher.links.self);

    commit(
      'SET_SIGNATURE_WATCHERS',
      getters.getSignatureWatchers.filter((w) => w.id !== watcher.id)
    );
  } catch (error) {
    throw error;
  }
};

export default {
  fetchSetupFormBuilders,
  fetchSetupTemplates,
  fetchTemplatePreview,
  fetchFormFlow,
  updateFlowTemplate,
  fetchListVariables,
  enableFormFlow,
  createFormFlow,
  fetchListSigners,
  createRubricates,
  getPositionSigns,
  createSignatureWatcher,
  updateSignatureWatcher,
  deleteSignatureWatcher,
};
