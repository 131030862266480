import { axios } from '@/plugins';

const getBatches = async ({ getters, commit }, payload = {}) => {
  const { params, replace = true } = payload;
  const url = getters.getLinks?.batches;
  if (!url) throw new Error(`expected links.batches is defined, but is "${url}"`);

  try {
    const response = await axios.get(url, { params });
    commit('SET_LINKS', response?.data?.links);
    if (replace) commit('SET_BATCHES', response?.data?.batches);
    else commit('ADD_BATCHES', response?.data?.batches);

    return [null, response?.data];
  } catch (error) {
    return [error, null];
  }
};

const fetchReceipts = async ({ getters }, filters) => {
  const url = getters.getLinks?.receipts;

  if (!url) throw new Error(`expected links.receipts is defined, but is "${url}"`);

  const response = await axios.get(url, { params: filters });

  return response.data;
};

const checkBatch = async (_, batch) => {
  const url = batch?.links?.self;
  if (!url) throw new Error(`expected batch.links.self is defined, but is "${url}"`);

  try {
    const response = await axios.get(url);

    return [null, response?.data];
  } catch (error) {
    return [error, null];
  }
};

const startMassiveSignature = async ({ getters }, payload) => {
  try {
    const url = getters.getLinks?.startSignature;
    const response = await axios.post(url, payload);

    return [null, response?.data];
  } catch (error) {
    return [error, null];
  }
};

const updateSignerData = async ({ rootGetters, dispatch }, payload) => {
  try {
    const url = rootGetters['info/getUser']?.links?.operatorPath;
    const response = await axios.patch(url, payload);

    dispatch('updateAccountInfo', response);
    return [null, response?.data];
  } catch (error) {
    return [error, null];
  }
};

const updateAccountInfo = ({ rootGetters, commit }, payload) => {
  const account = rootGetters['info/getAccount'];
  const newUserData = {
    links: account?.user?.links,
    ...payload?.data?.data?.user,
  };

  const newAccountData = { ...account, user: newUserData };
  commit('info/SET_ACCOUNT', newAccountData, { root: true });
};

const updatePhoneNumber = async ({ rootGetters, dispatch }, payload) => {
  try {
    const url = rootGetters['info/getUser']?.links?.operatorPath;
    const response = await axios.patch(url, payload);

    dispatch('updateAccountInfo', response);
    return [null, response?.data];
  } catch (error) {
    return [error, null];
  }
};

const resendPhoneNumberConfirmation = async (_, url) => {
  try {
    await axios.get(url);
  } catch (error) {
    throw error;
  }
};

const confirmPhoneNumber = async ({ rootGetters, dispatch }, token) => {
  try {
    const account = rootGetters['info/getAccount'];
    const url = account?.user?.links?.confirmToken;
    await axios.get(url, { params: token });
    const response = await axios.get(account?.user?.links?.operatorPath);

    dispatch('updateAccountInfo', response);
  } catch (error) {
    throw error;
  }
};

const fetchNotificationConfigs = async ({ getters, commit }) => {
  try {
    const url = getters.getLinks.notifications;
    const { data: responseData } = await axios.get(url);

    const notifications = {
      ...responseData.data.user,
    };

    commit('SET_NOTIFICATIONS', notifications);
    return [null, responseData];
  } catch (error) {
    return [error, null];
  }
};

const updateNotificationConfigs = async ({ getters, commit }, user) => {
  try {
    const url = getters.getLinks.notifications;
    const { data: responseData } = await axios.patch(url, { user });

    const notifications = {
      ...responseData.data.user,
    };

    commit('SET_NOTIFICATIONS', notifications);
    return [null, responseData];
  } catch (error) {
    return [error, null];
  }
};

export default {
  getBatches,
  fetchReceipts,
  checkBatch,
  startMassiveSignature,
  updateSignerData,
  fetchNotificationConfigs,
  updateNotificationConfigs,
  updatePhoneNumber,
  resendPhoneNumberConfirmation,
  confirmPhoneNumber,
  updateAccountInfo,
};
