const formBuilder = {
  title: 'Formularios',
  new: 'Crear formulario',
  key: 'Llave',
  list: {
    title: 'Flujo:',
    loading: 'Cargando lista de formularios...',
    empty: 'Crea formularios para generar documentos con contenido personalizado',
    emptyFlow: 'Ve a {0} y selecciona este formulario para crear un flujo automatizado..',
    flowForm: 'Flujos',
    spreadsheet: 'Flujo con hoja de cálculo',
    form: 'Flujo con formulario',
    actions: {
      edit: 'Editar',
      options: 'Opciones',
    },
  },
  feedback: {
    deleteSuccess: '¡Formulario eliminado con éxito!',
    duplicateSuccess: '¡Formulario duplicado con éxito!',
    actionError: 'No fue posible completar esta acción. Inténtalo de nuevo.',
  },
  setup: {
    name: {
      title: 'Creación del formulario',
      label: 'Nombre del formulario',
      placeholder: 'Escribe un nombre para identificar este formulario.',
      helpLinkText: 'Ayuda',
    },
    info: {
      addField:
        'Arrastra y suelta aquí los campos o haz click en los campos para completar tu formulario.',
      signerField:
        "Utiliza la opción 'Correo electrónico del firmante' cuando no sepas el correo de quien va a firmar. De esta manera, una vez que se complete el formulario, el firmante recibirá el documento para firmar.",
      disclaimerSignerFields: {
        title: 'Correo electrónico y WhatsApp del firmante',
        infoText:
          'Puedes usar estos campos para vincular al firmante al momento de crear el flujo.',
        helpLinkText: 'Más información sobre el correo electrónico y WhatsApp del firmante',
      },
    },
    validation: {
      fieldAlreadyAdded: 'Este campo ya ha sido agregado y no se puede volver a agregar.',
    },
    field: {
      title: 'Título',
      type: 'Escribir',
      required: 'Obligatorio',
      maxLength: 'Máximo de caracteres',
      numberRows: 'Número de líneas',
      label: {
        header: 'Encabezado',
        paragraph: 'Párrafo',
        shortText: 'Texto corto',
        date: 'Fecha',
        longText: 'Texto largo',
        singleChoice: 'Respuesta única',
        multipleChoices: 'Múltiplas respuestas',
        dropdownList: 'Lista desplegable',
        phone: 'Teléfono del firmante',
        email: 'Correo del firmante',
        cpf: 'CPF',
        cnpj: 'CNPJ',
        attachment: 'Adjunto',
        cep: 'CEP',
        currency: 'Moneda',
        whatsapp: 'WhatsApp del firmante',
        image: 'Imagen',
      },
      value: {
        option: 'Opción en el formulario {number}',
        text: 'Texto para el documento {number}',
      },
    },
    other: {
      text: 'Otro',
      enable: 'Habilitar &quot;Otro&quot;',
      permit: 'Permitir agregar una opción que no está en la lista',
    },
    help: {
      text: 'Texto de ayuda',
      tooltip: 'Mensaje de ayuda',
      hint: 'Sugerencia: Usa en el título el mismo término que la variable de tu modelo. <br>Ej: Variable del modelo: {{Nombre del alumno}} - Título del campo: Nombre del alumno',
      imageHint:
        'Sugerencia: Usa en el título el mismo término que la variable de tu modelo. <br>Ej.: Variable del modelo: {{[imagen] Comprobante}} - Título del campo: Comprobante',
      placeholder: 'Texto de ayuda dentro del campo',
      description: 'Se puede visualizar al hacer click en el ícono. "?"',
    },
    remove: {
      question: 'Eliminar campo?',
      warning: 'Al eliminarlo, todos los campos vinculados también serán eliminados.',
    },
    option: 'Opción',
    copy: 'Copiar',
    edit: 'Editar',
    save: 'Gurdar',
    cancel: 'Cancelar',
    notification: {
      save: '¡Éxito! Formulario guardado.',
    },
    drag: 'Arrastra los campos aquí para crear el formulario',
    reviewFieldsModal: {
      title: 'Revisa los campos de tu formulario',
      firstParagraph:
        'Incluye el campo “correo del firmante” para extraer del formulario el correo de quien firmará. En el caso de firma vía WhatsApp, también incluye el campo “Número de celular del firmante".',
      secondParagraph:
        'Si no necesitas que el documento sea enviado a diferentes firmantes, ignora este mensaje.',
      actions: {
        back: 'Regresar y corregir',
        save: 'Continuar y guardar',
      },
    },
  },
  view: {
    flag: 'Previsualización',
    help: 'En el modo de previsualización las respuestas enviadas no serán guardadas.',
    submit: 'Enviar respuestas',
    success: {
      title: 'Respuestas enviadas!',
      subtitle: 'Estamos preparando un nuevo documento que pronto será enviado para firma.',
    },
    error: {
      default: 'No pudimos enviar las respuestas correctamente',
      reload: 'Volver a cardar formulario',
    },
    security: 'Ambiente seguro Clicksign',
    validation: {
      invalidCep: 'El CEP no es válido',
      cepNotFound:
        'El CEP no fue encontrado. Verifica si lo ingresaste correctamente o completa los campos manualmente.',
      filemaxsize: 'El tamaño del archivo supera el máximo permitido de %{max_size_file}mb',
      extension:
        'El formato no permitido, utiliza uno de los siguientes formatos: jpg, jpeg, png, pdf, doc y docx',
    },
    requiredFields: '* Campos obligatorios',
    attachFilePdf: 'Solo se aceptan archivos en formato .pdf',
    imageFile: 'Solo se aceptan imágenes en este campo',
    selectOption: 'Selecciona una opción',
  },
  fields: {
    autofill: {
      linkedWith: 'Autocompletado por',
      linkedWithField: 'Vinculado a la clave',
      attributeType: 'Tipo de Campo',
    },
    cep: {
      label: {
        street: 'Dirección',
        number: 'Número',
        complement: 'Referencias',
        neighborhood: 'Colonia',
        city: 'Ciudad',
        federalUnit: 'Estado',
      },
      description: 'Agrega los campos que serán autocompletados por el CEP',
    },
    required: 'Campos obligatorios',
  },
  file: {
    hint: 'Archivos aceptados: jpg, jpeg, png, pdf, doc e docx',
    attachFileEnabled: {
      label: 'Adjuntar para firmas',
      description: 'Será incluido automáticamente en el documento para firmas.',
      hint: 'Al marcar esta opción, el campo solo aceptará archivos en formato .pdf',
    },
  },
  image: {
    hint: 'Archivos aceptados: jpg, jpeg e png',
  },
};

export default formBuilder;
