const nodes = {
  rootFolder: 'Documentos',
  list: {
    signers: 'No se ha agregado firmante | {count}/{length} Firma | {count}/{length} Firmas',
    documents: '{count} Documento | {count} Documentos',
    actions: {
      selectAll: 'Seleccionar todos en la página',
      downloadOriginal: 'Descargar documento original',
      downloadSigned: 'Descargar documento firmado',
      downloadOriginalandSigned: 'Descargar documento original y firmado',
      download: 'Descargar',
      move: 'Mover',
      bringBack: 'Restaurar',
      hardDelete: 'Eliminar permanentemente',
      permissions: 'Permisos',
      new: 'Nueva carpeta',
      edit: 'Cambiar nombre',
      delete: 'Eliminar',
      cancel: 'Cancelar',
      menu: 'Acciones',
    },
    statuses: {
      canceled: 'Cancelado',
      closed: 'Finalizado',
      running: 'En proceso',
    },
    signatureStatus: {
      refused: 'Firma rechazada',
    },
    document: {
      path: 'en {0}',
      // TOGGLE_ENVELOPE_SETUP_SCOPE
      // TODO: remove pathOld
      pathOld: 'en Documentos{0}',
      default: 'en Todos',
    },
    signatures: {
      showMore: 'Ver más firmantes',
      showLess: 'Ver menos firmantes',
    },
    at: 'En {0}',
    until: 'Hasta {0}',
    createdAt: 'Creado en',
    empty: {
      title: 'Agrega y envía documentos para su firma.',
      description: 'Descarga un {link} y agrégalo para probar.',
      emptyFolder: 'Carpeta vacía.',
      document: {
        url: '/docs/Contrato de Prestación de Servicio - Ejemplo.pdf',
        example: 'documento de ejemplo',
        name: 'Contrato de Prestación de Servicio - Ejemplo.pdf',
        new: 'Agregar Documentos',
        move: 'Para mover un documento a esta carpeta, selecciona un documento y haz click en el botón Mover.',
      },
    },
  },
  search: {
    title: 'resultado para | resultados para',
    appliedFilters: 'los filtros aplicados',
    empty: {
      title: 'No se encontraron resultados en la búsqueda',
      description:
        'Intenta cambiar tu búsqueda o ajusta el filtro para encontrar lo que estás buscando',
      allDocuments: 'Ver todos los documentos',
    },
  },
  empty: {
    title: 'Agrega y envía documentos para firmar.',
    description: 'Descarga un {link} y agrégalo para probar.',
    documentExample: 'documento de ejemplo',
    documentName: 'Contrato de Prestación de Servicio - Ejemplo.pdf',
    newDocuments: 'Agregar Documentos',
    emptyFolder: 'Carpeta vacía.',
    moveDocument:
      'Para mover un documento a esta carpeta, selecciona un documento y haz click en el botón Mover.',
  },
  running: {
    title: 'En proceso',
    empty: {
      documents: {
        title: 'Los documentos en proceso se mostrarán aquí',
      },
      envelopes: {
        title: 'Los sobres en proceso se mostrarán aquí',
      },
      // TOGGLE_ENVELOPE_SETUP_SCOPE
      // TODO: When toggle is true remove title locale
      title: 'Después de enviar un documento para firmar, podrás verlo aquí.',
      description: 'Sigue el estatus de las firmas en curso',
    },
    refusedEmpty: {
      documents: {
        title: 'Los documentos con firmas rechazadas se mostrarán aquí.',
      },
      envelopes: {
        title: 'Los sobres con firmas rechazadas se mostrarán aquí.',
      },
      title: 'Los documentos con firmas rechazadas se mostrarán aquí.',
    },
  },
  closed: {
    title: 'Finalizados',
    empty: {
      documents: {
        title: 'Los documentos finalizados se mostrarán aquí',
      },
      envelopes: {
        title: 'Los sobres finalizados se mostrarán aquí',
      },
      // TOGGLE_ENVELOPE_SETUP_SCOPE
      // TODO: When toggle is true remove title locale
      title: 'Envía los documentos para firmas. Una vez finalizados, se mostrarán aquí.',
      description: 'Revisa cuáles firmas se completaron con éxito',
    },
  },
  canceled: {
    title: 'Cancelados',
    empty: {
      documents: {
        title: 'Los documentos cancelados se mostrarán aquí',
      },
      envelopes: {
        title: 'Los sobres cancelados se mostrarán aquí',
      },
      // TOGGLE_ENVELOPE_SETUP_SCOPE
      // TODO: When toggle is true remove title locale
      title: 'Los documentos cancelados se muestran aquí.',
      description: 'Solo los documentos en proceso pueden ser cancelados.',
    },
  },
  trashBin: {
    deleteInfo: '¿Estás seguro de que deseas eliminar permanentemente este(os) elemento(s)?',
    notifications: {
      restored: 'Elemento restaurado con éxito | Elementos restaurados con éxito',
      deleted:
        '¡Éxito! Elemento eliminado permanentemente | ¡Éxito! Elementos eliminados permanentemente',
    },
    empty: {
      title: 'Los documentos o carpetas eliminados se mostrarán aquí.',
      description: 'Para eliminar un documento, debe estar cancelado o finalizado.',
    },
    blockedEmpty: {
      title: 'Solo los administradores de la cuenta tienen acceso a los documentos en la Papelera.',
      description:
        'Si necesitas verificar un documento eliminado, contacta a un administrador de tu cuenta.',
    },
  },
  modalDelete: {
    header: 'Eliminar Elemento | Eliminar Elementos',
    info: '¿Estás seguro de que deseas eliminar este elemento? | ¿Estás seguro de que deseas eliminar estos elementos?',
    disallowedInfo:
      'Hay documento(s) en proceso seleccionados. {0}, debes finalizar o cancelar para eliminarlos.',
    strongInfo: 'No es posible eliminar elementos que están en proceso',
    notifications: {
      deleted:
        'Elemento movido a la papelera con éxito | Elementos movidos a la papelera con éxito',
    },
  },
  modalCancel: {
    header: '¿Cancelar documento? | ¿Cancelar documentos?',
    info: 'La acción invalidará las firmas ya efectuadas.',
    confirmation: 'Esta acción no se puede deshacer.',
    notifications: {
      success: 'Documento cancelado con éxito | Documentos cancelados con éxito',
    },
  },
  modalFolderForm: {
    placeholder: 'Escribe el nombre de la carpeta',
    editFolder: {
      header: 'Cambiar nombre de la carpet',
      action: 'Cambiar nombre',
      notifications: {
        success: 'El nombre de la carpeta se cambió con éxito',
      },
    },
    create: {
      header: 'Crear una nueva carpeta',
      action: 'Crear',
      notifications: {
        success: 'Carpeta creada con éxito',
      },
    },
  },
  modalSelectFolder: {
    description: 'Seleccione dónde se almacenarán los documentos.',
    newFolder: 'Crear carpeta',
    confirm: 'Confirmar selección',
    rootChildrenError: 'Error al listar carpetas',
  },
  emptyRootFolderContent: {
    title: 'Crea tu primera carpeta',
    description: 'Tus documentos se almacenarán dentro de la carpeta elegida.',
  },
  modalEditEnvelope: {
    header: 'Cambiar nombre del sobre',
    placeholder: 'Escribe aquí',
    rename: 'Cambiar nombre',
    notifications: {
      edited: 'El nombre de la sobre se cambió con éxito',
    },
  },
  modalMoveNodes: {
    title: 'Mover elementos a ...',
    notifications: {
      moved: 'Elemento movido con éxito | Elementos movidos con éxito',
    },
  },
  modalDownloadOptions: {
    title: '¿Qué documentos deseas descargar?',
    options: {
      signedDocuments: 'Firmados',
      originalDocuments: 'Originales',
      originalAndSignedDocuments: 'Originales y firmados',
    },
    notice: 'Los documentos que aún estén en proceso de firma no serán descargados',
  },
  modalFolderPermissions: {
    title: 'Permisos de acceso de la carpeta',
    // TOGGLE_GROUP_PERMISSIONS_ENABLED
    // TODO: remove titleOld, publicOld and privateOld
    titleOld: 'Definir permisos de acceso de la carpeta ',
    publicOld: 'Acceso para todos los usuarios de la cuenta',
    privateOld: 'Acceso solo para usuarios seleccionados',
    public: 'Todos los usuarios de la cuenta',
    private: 'Solo usuarios seleccionados',
    // TOGGLE_GROUP_PERMISSIONS_ENABLED
    // TODO: remove selectAccessTypeOld
    selectAccessTypeOld: 'Selecciona el tipo de acceso de la carpeta',
    selectAccessType: 'Selecciona quién podrá acceder a la carpeta',
    // TOGGLE_GROUP_PERMISSIONS_ENABLED
    // TODO: remove selectMembersOld
    selectMembersOld: 'Selecciona los usuarios que tendrán acceso a la carpeta',
    selectMembers: 'Usuarios',
    selectGroups: 'Grupos',
    submitSuccess: 'Configuraciones de acceso guardadas.',
    admin: 'Administrador',
    adminInfo:
      'Los administradores de la cuenta siempre tendrán acceso predeterminado a las carpeta',
    selectAll: 'Seleccionar todos',
  },
  message: {
    messageTemplates: 'Mensajes guardados',
    placeholder: 'Escribe aquí.',
    label: 'Mensaje',
  },
  original: 'Original',
  signed: 'Firmado',
  all: 'Todos',
  orderField: {
    placeholder: 'Ordenar por',
    items: {
      alphabeticOrder: 'Orden alfabético',
      createdAtDesc: 'Envíos más recientes',
      createdAtAsc: 'Envíos más antiguos',
      deadlineAt: 'Cerca de la fecha límite de firma',
      finishedAt: 'Finalizados más recientes',
      canceledAt: 'Cancelados más recientes',
      deletedAt: 'Eliminados más recientes',
    },
  },
  filter: {
    seeDocuments: 'Ver documentos',
    seeEnvelopes: 'Ver sobres',
    seeRefused: 'Ver firmas rechazadas',
    // TOGGLE_ENVELOPE_SETUP_SCOPE
    // TODO: remove showAll and onlyRefused
    showAll: 'Ver todo',
    onlyRefused: 'Ver solo firmas rechazadas',
  },
  refused: '(firma rechazada)',
  blockFinishDocumentModal: {
    title: 'No es posible finalizar el documento',
    description:
      'Primero, elimina del documento a los firmantes cuyas firmas hayan sido rechazadas antes de finalizar.',
  },
};

export default nodes;
