import { isObject } from 'lodash';

const flattenObj = (obj, keys = []) =>
  Object.keys(obj).reduce(
    (acc, key) =>
      Object.assign(
        acc,
        isObject(obj[key]) ? flattenObj(obj[key], keys.concat(key)) : { [key]: obj[key] }
      ),
    {}
  );

export default flattenObj;
