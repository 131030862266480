import { axios } from '@/plugins';

const sendVoiceBiometrics = async ({ getters }, formData) => {
  const url = getters.getLinks.voiceBiometricsVerify;
  await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export default {
  sendVoiceBiometrics,
};
