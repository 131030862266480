const chat = {
  error: 'Hubo un error al enviar: ',
  message: {
    placeholder: 'Hablar con el Asistente',
  },
  leave: {
    mustChatLeave: '¿Deseas salir de la conversación?',
    warnChatLeave: 'Si sales, perderás la conversación actual con el Asistente de automatización.',
    cancelLeave: 'Continuar conversación',
  },
  messages: {
    previewFormTemporaryMessage:
      'Tu formulario se ha generado con éxito. Haz click en el enlace a continuación para visualizarlo.',
    flowSettingsTemporaryMessage:
      'Para facilidad, agregamos estas configuraciones a los documentos que se generarán a partir de este flujo.',
  },
  flowSettings: {
    flowName: 'Nombre del flujo',
    deadline: 'Fecha límite',
    daysAfterDocumentCreation:
      '{0} día después de la creación del documento|{0} días después de la creación del documento',
    autoReminders: 'Recordatorios automáticos',
    eachDays: 'cada {0} día|cada {0} días',
    language: 'Idioma',
    portuguese: 'Portugués',
    english: 'Inglés',
    documentsFinish: 'Finalización de los documentos',
    autoDocumentFinish: 'Después de la última firma del documento',
    manualDocumentFinish: 'En la fecha límite del documento',
  },
  signersTable: {
    fetchError: 'Error al cargar los firmantes',
    nameContactOrAuth: 'Nombre, contacto o autenticación',
    emptySearch: 'No se encontraron firmantes. ¿Qué tal hacer una nueva búsqueda?',
    firmantes: 'Firmantes',
    name: 'Nombre',
    contact: 'Contacto',
    auth: 'Autenticación',
    signerFromForm: 'Firmante a través del formulario',
    contactFromEmail: 'Contacto por correo electrónico',
    contactFromWhatsapp: 'Contacto vía WhatsApp',
    actions: {
      editSuccess: 'Firmante actualizado con éxito',
      editError: 'Error al actualizar el firmante',
    },
  },
  actions: {
    generateModel: 'Generar modelo',
    generatePreview: 'Generar vista previa',
    attachment: 'Adjuntar',
    seeModel: 'Ver modelos',
    showPreview: 'Visualizar modelo',
    showAuths: 'Seleccionar autenticaciones',
    selectedAuths: 'Autenticación seleccionada: {auths}. | Autenticaciones seleccionadas: {auths}.',
    continueChatFlow: 'Continuar flujo',
    newSigner: 'Agregar firmante',
    activateFlow: 'Activar flujo',
  },
  downloadPreview: 'Descargar modelo',
};

export default chat;
