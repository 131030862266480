<template>
  <button
    :type="type"
    :class="[
      'btn cs-button',
      !_.isEmpty(color) ? `-${color}` : '-primary',
      !_.isEmpty(size) ? `-${size}` : '-normal',
      !_.isEmpty(elevation) ? `-elevation${elevation}` : '',
      full ? '-full' : '',
      link ? '-link' : '',
      outline ? '-outline' : '',
      round ? '-round' : '',
      text ? '-text' : '',
      active ? $style.activeColor : '',
      $style.button,
    ]"
    :disabled="disabled || loading"
    @click="handleClick"
  >
    <div
      :class="$style.content"
      :style="{ visibility: !loading ? 'visible' : 'hidden' }"
    >
      <XIcon
        v-if="!_.isEmpty(icon) && !_.isEmpty(iconPosition) && iconPosition !== 'right'"
        :icon="icon"
        :class="$style.icon"
        :size="sizes[iconSize]"
        left
      />

      <span :class="[$style.text, `${textBold ? '-bold' : ''}`]">
        <slot />
      </span>

      <XIcon
        v-if="!_.isEmpty(icon) && !_.isEmpty(iconPosition) && iconPosition === 'right'"
        :icon="icon"
        :size="sizes[iconSize]"
        :class="$style.icon"
        right
      />
    </div>

    <Spinner
      v-if="loading"
      class="spinner-button"
      :size="20"
    />
  </button>
</template>

<script>
import Spinner from 'vue-simple-spinner';

export default {
  name: 'BaseButton',
  components: {
    Spinner,
  },
  props: {
    type: {
      type: String,
      default: 'button',
      validator: (type) => /button|submit/.test(type),
    },
    color: {
      type: String,
      default: 'primary',
      validator: (color) => /primary|secondary|danger|warning|dark|black|pink/.test(color),
    },
    size: {
      type: String,
      default: 'normal',
    },
    icon: {
      type: String,
      default: '',
    },
    iconPosition: {
      type: String,
      default: 'left',
    },
    iconSize: {
      type: String,
      default: 'normal',
    },
    elevation: {
      type: String,
      default: '',
    },
    textBold: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
    text: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sizes: {
        small: 'xs',
        normal: 'sm',
        large: 'lg',
      },
    };
  },
  methods: {
    handleClick() {
      if (this.href) window.open(this.href, this.target);
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" module>
:global {
  .spinner-button {
    position: absolute;
    top: 20%;
    left: calc(50%);
    transform: translateX(-50%);
  }
}

.button {
  position: relative;

  > .content {
    display: flex;
    align-items: center;

    > .icon {
      margin-top: var(--space-small-xxx);
      margin-bottom: var(--space-small-xxx);
    }
  }
}

.activeColor {
  background: var(--color-brand-primary-50) !important;
}
</style>
