export default {
  SET_DOCUMENTS: (state, payload) => {
    state.documents = payload;
  },
  SET_NOTIFICATIONS_DATA: (state, payload) => {
    state.notifications.data = [...state.notifications.data, ...payload.notifications];
    state.notifications.pagination = payload.pagination;
  },
  UPDATE_NOTIFICATIONS_DATA(state, payload) {
    const notifications = [...state.notifications.data];
    const notificationToUpdate = notifications.find((item) => item.key === payload.key);
    Object.assign(notificationToUpdate, payload);
    state.notifications.data = notifications;
  },
  SET_PLAN: (state, payload) => {
    state.plan = payload;
  },
  SET_LINKS: (state, payload) => {
    state.links = payload;
  },
  SET_DASHBOARD_DETAILS: (state, payload) => {
    state.dashboardDetails = payload;
  },
  SET_PAGINATION: (state, payload) => {
    state.pagination = payload;
  },
};
