export default {
  links: {
    signerArea: undefined,
  },
  routes: {},
  abTests: {},
  account: {},
  userAccounts: [],
  user: {},
  toggles: {},
  sidebarLinks: {},
  downloadProcessing: {},
  newDraftPackLink: '',
  employeesAmount: [],
  sidebarBadges: {
    downloads: undefined,
    emailBounces: false,
  },
  config: {},
  router: {
    path: '',
    component: {
      constructor: null,
      props: {},
    },
  },
};
