import { sentry } from '@/plugins/sentry';

export default function useGA(hitType, hitName, hitCategory, options) {
  const { ga } = window;

  if (!ga || typeof ga === 'undefined') return;

  const onGaReady = () => {
    try {
      const [tracker] = ga.getAll();

      if (!tracker) return;

      if (hitCategory) {
        tracker.send(hitType, hitCategory, hitName, options);
        window.dataLayer.push({
          event: 'ga-send',
          ga_event_category: hitCategory,
          ga_options: options,
          ga_event_label: options,
          ga_event_name: hitName,
        });
      } else {
        tracker.send(hitType, hitName, options);
        window.dataLayer.push({
          event: 'ga-send',
          ga_options: options,
          ga_event_label: options,
          ga_event_name: hitName,
        });
      }
    } catch (error) {
      sentry.captureException(error);
    }
  };

  // Updates the tracker to use `navigator.sendBeacon` if available.
  ga('set', 'transport', 'beacon');
  ga(onGaReady);
}
