export default {
  dateFormat: 'DD/MM/YYYY',
  errors: {
    requiredIf: 'O campo é obrigatório',
    required: 'O campo é obrigatório',
    phone: 'Digite um telefone válido',
    email: 'Preencha um e-mail válido',
    cpf: 'Informe seu CPF corretamente',
    date: 'Informe uma data válida',
    fullName: 'Utilize nome e sobrenome',
    dateInThePast: 'Deve ser anterior à data atual',
    minLength: 'Insira pelo menos {min} caracteres.',
  },
};
