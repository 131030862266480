import { axios } from '@/plugins';

const fetchUserData = async ({ getters }) => {
  try {
    const url = _.get(getters, 'getLinks.self');
    const { data } = await axios.get(url);
    return data.user;
  } catch (err) {
    throw err;
  }
};

const fetchSendLink = async ({ getters, commit }) => {
  try {
    const url = _.get(getters, 'getLinks.self');
    const { data } = await axios.get(url);
    commit('SET_LINKS', _.get(data, 'links'));
  } catch (err) {
    throw err;
  }
};

export default {
  fetchUserData,
  fetchSendLink,
};
