import { ref, computed } from 'vue';
import { useStore } from '@/store';

export const useFetchInvoices = () => {
  const loading = ref(true);
  const store = useStore();
  const invoices = computed(() => store.getters['consumption/getInvoices']);
  const pendingInvoices = computed(() =>
    invoices.value?.filter((invoice) => invoice.status === 'pending')
  );
  const billing = computed(() => store.getters['consumption/getConsumption']?.billing);
  const hasOpenInvoices = computed(() => billing.value?.billsPending > 0);

  const fetchInvoices = async (page = 1) => {
    try {
      await store.dispatch('consumption/fetchInvoices', {
        params: {
          page,
          perPage: 12,
        },
      });
    } finally {
      loading.value = false;
    }
  };

  const billsPending = computed(() => billing.value?.billsPending);

  return {
    fetchInvoices,
    invoices,
    hasOpenInvoices,
    billsPending,
    loading,
    pendingInvoices,
  };
};
