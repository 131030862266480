const tracking = {
  title: 'Correos electrónicos enviados',
  description: 'Haz seguimiento al envío de los correos electrónicos para firma a los firmantes',
  delivered: 'Entregado',
  spam: 'Correo no deseado',
  bounce: 'Rechazado',
  loading: 'Buscando...',
  date: 'Fecha y hora de envío',
  email: 'Correo electrónico del firmante',
  kind: 'Tipo de correo electrónico',
  status: 'Estatus del correo electrónico',
  subject: 'Asunto',
  modal: {
    details: {
      title: 'Progreso del envío',
      sendedEmails: 'Correos electrónicos enviados',
      signerEmail: 'Correo electrónico del firmante: {email}',
      changeEmail: 'Cambiar el correo electrónico aquí.',
    },
  },
  titles: {
    signatureRequest: 'Solicitud de firma',
    signatureReminder: 'Recordatorio de firma',
    token: 'Token',
    documentCanceled: 'Notificación de documento cancelado',
    secondForm: 'Formulario para completar',
    signedDocument: 'Confirmación de firma',
    expiring: 'Recordatorio para descargar el documento firmado',
    receipt: 'Comprobante de firma',
    refusal: 'Notificación de documento pausado',
    facematchRejected: 'Notificación de firma no procesada',
  },
  statuses: {
    processing: 'Procesando el envío',
    delivery: 'Enviado',
    spam: 'Correo no deseado',
    bounce: 'No recibido',

    v2: {
      processing: 'Enviado',
      delivery: 'Entregado',
      spam: 'Recibido como correo no deseado',
      bounce: 'No entregado',
    },

    descriptions: {
      processing: 'El envío del correo electrónico está siendo procesado.',
      delivery: 'El correo electrónico fue enviado con éxito.',
      spam: 'El correo electrónico fue identificado como correo no deseado.',
      bounce: 'El correo electrónico no llegó al firmante.',
    },
  },
  providerErrors: {
    hardBounce: 'Verifica si el correo electrónico es correcto o si todavía existe.',
    softBounce:
      'Un problema temporal impidió la recepción del mensaje. {0} Verifica con el firmante si la bandeja de entrada está llena',
    spamComplaint:
      'Verifica con el firmante si el correo electrónico está en la bandeja de correo no deseado.',
    spamNotification:
      'Verifica con el firmante si el correo electrónico está en la bandeja de correo no deseado.',
  },
  kinds: {
    signatureRequest: 'Firmar documento',
    signatureReminder: 'Recordatorio de firma',
    token: 'Token',
    documentCanceled: 'Documento cancelado',
    secondForm: 'Completar el formulario',
    signedDocument: 'Documento firmado',
    expiring: 'Plazo de expiración',
    receipt: 'Comprobante de firma',
    refusal: 'Documento pausado',
    facematchRejected: 'Firma no procesada',
    signatureWatcherEnvelopeClosed: 'Documento firmado',
    signatureWatcherDocumentSigned: 'Comprobante de firma',
    signatureWatcherDocumentSent: 'Incluir firma',
  },
  filters: {
    inputPlaceholder: 'Buscar por correo electrónico del firmante',
    apply: 'Aplicar',
    label: 'Filtro',
    clean: 'Limpiar filtros',
    select: 'Seleccionar',
    appliedFilters: 'FILTROS APLICADOS',
    date: 'Fecha',
    dateRange: 'Fecha de envío',
    dateShortcuts: {
      thisWeek: 'Esta semana',
      lastWeek: 'Semana pasada',
      thisMonth: 'Este mes',
      lastMonth: 'Mes pasado',
      last7Days: 'Últimos 7 días',
      last30Days: 'Últimos 30 días',
      last120Days: 'Últimos 120 días',
    },
    dateHint: 'Historial de 120 días',
  },
  list: {
    empty: {
      title: 'Todavía no se han enviado documentos para firma.',
      details: 'Aquí se mostrarán los correos electrónicos de los documentos enviados.',
      description: 'Después de realizar un envío, podrás hacer el seguimiento de su recepción aqu.',
      searchTitle: 'No se encontraron envíos en esta búsqueda.',
      searchDescription: 'Prueba buscar con otros filtros.',
    },
  },
  details: {
    loading: 'Cargando detalles...',
    title: 'Detalles',
    email: 'Correo electrónico del firmante',
  },
  bounceDetails: 'Detalles técnicos:',
};

export default tracking;
