<template>
  <transition
    name="showModalAnimation"
    enter-active-class="animated slideInUp"
    leave-active-class="animated slideOutDown"
  >
    <div
      v-show="isVisible"
      :class="[$style.modal, ...modalClasses]"
    >
      <header
        v-show="$slots.header || closeIcon"
        :class="[$style.header, ...headerClasses]"
      >
        <slot name="header" />
        <div
          v-show="closeIcon"
          @click="$emit('close')"
        >
          <XIcon
            icon="times"
            :class="$style.closeIcon"
          />
        </div>
      </header>

      <div :class="[$style.content, ...contentClasses]">
        <slot name="default" />
      </div>

      <footer
        v-show="$slots.actions"
        :class="[$style.actions, ...actionsClasses]"
      >
        <slot name="actions" />
      </footer>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BaseFullscreenModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    modalClasses: {
      type: Array,
      default: () => [],
    },
    headerClasses: {
      type: Array,
      default: () => [],
    },
    contentClasses: {
      type: Array,
      default: () => [],
    },
    actionsClasses: {
      type: Array,
      default: () => [],
    },
    closeIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: this.value,
    };
  },
  watch: {
    value(isOpen) {
      this.isVisible = isOpen;
      if (isOpen) {
        document.body.classList.add(this.$style.removeScrollbar);
        this.$emit('opened');
      } else {
        document.body.classList.remove(this.$style.removeScrollbar);
      }
    },
  },
  beforeDestroy() {
    document.body.classList.remove(this.$style.removeScrollbar);
  },
};
</script>

<style lang="scss" module>
body {
  &.removeScrollbar {
    overflow: hidden;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-bold);
  color: var(--color-neutral-800);
}

.closeIcon {
  color: var(--color-neutral-400);
  cursor: pointer;
}

.modal {
  position: fixed;
  z-index: 780;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-wrap: break-word; /* added to break very too long words */
  width: 100vw;
  height: 100vh;
  word-break: normal; /* keep words without break at line end */
  background: var(--color-neutral-0);
  animation-duration: 0.5s;

  > .header {
    padding: var(--space-medium) var(--space-medium) 0;
  }

  > .content {
    flex-grow: 1;
    overflow: auto;
    padding: var(--space-medium);
  }

  > .actions {
    display: flex;
    justify-content: flex-end;
    padding: 0 var(--space-medium) var(--space-medium);
  }
}
</style>
