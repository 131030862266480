const workspaces = {
  title: 'Olá, {name}!',
  subtitle: 'O que deseja fazer hoje?',
  list: {
    header: 'Contas',
    // TOGGLE_ORGANIZATION_OWNER_ENABLED
    // TODO: Remove headerOld
    headerOld: 'Conta',
    options: {
      title: 'Opções',
      editName: 'Editar nome',
      moveAccount: 'Mover conta',
      linkAccount: 'Vincular conta',
      deleteAccount: 'Excluir conta',
    },
    emptyWorkspace: {
      title: 'Nenhuma conta vinculada a este workspace',
      action: 'Mover uma conta',
    },
  },
  signerAreaType: 'Assinar documentos',
  signerArea: 'Área de assinatura',
  signersCard: {
    title: 'Documentos para você assinar',
    emptySignatures: 'Sem assinaturas pendentes',
    titleOld: 'Assinar documentos',
    info: 'Sem assinaturas pendentes para você, por enquanto',
    pendingSignatures: 'assinatura pendente | assinaturas pendentes',
    button: 'Acessar área de assinatura',
  },
  operatorCard: {
    title: 'Workspaces',
    // TOGGLE_ORGANIZATION_OWNER_ENABLED
    // TODO: Remove titleOld
    titleOld: 'Enviar documentos',
    hasAccount: {
      info: 'Acesse a conta para enviar e acompanhar seus documentos e aceites',
    },
    tooltip: 'Você é gestor deste ambiente',
    customize: 'Personalizar',
    createWorkspace: 'Criar workspace',
  },
  createWorkspaceModal: {
    title: 'Criar workspace',
    form: {
      name: {
        label: 'Escolha um nome para o workspace',
        placeholder: 'Exemplo: projeto beta',
      },
    },
    success: 'Workspace criado com sucesso',
    error: 'Erro ao criar workspace',
  },
  editWorkspaceModal: {
    title: 'Editar nome do Workspace',
    form: {
      name: {
        label: 'Escolha um nome',
        placeholder: 'Exemplo: projeto beta',
      },
    },
    success: 'Workspace editado com sucesso',
    error: 'Erro ao editar workspace',
  },
  deleteWorkspaceModal: {
    title: 'Excluir o workspace {name}',
    subtitle:
      'O workspace será excluído, mas as contas continuarão existindo. Escolha o workspace para o qual as contas deverão ser movidas.',
    form: {
      workspace: {
        label: 'Mover contas para o workspace',
      },
    },
    error: 'Erro ao excluir workspace',
    success: 'O workspace {name} foi excluído com sucesso',
  },
  deleteEmptyWorkspaceModal: {
    title: 'Excluir o workspace {name}?',
    subtitle: 'A ação não poderá ser desfeita.',
    error: 'Erro ao excluir workspace',
    success: 'O workspace {name} foi excluído com sucesso',
  },
  moveAccountModal: {
    title: 'Mover uma conta para {name}',
    form: {
      accounts: {
        label: 'Contas',
      },
    },
    move: {
      success: 'Contas movidas para o workspace {name} com sucesso',
      error: 'Erro ao mover contas',
    },
    search: {
      error: 'Erro ao buscar contas',
    },
  },
  workspaceCollapse: {
    editWorkspaceName: 'Editar nome',
    moveAccount: 'Mover conta',
    linkNewAccount: 'Vincular conta nova',
    deleteWorkspace: 'Excluir workspace',
  },
  editOrganizationNameModal: {
    title: 'Personalizar ambiente',
    changeLogo: 'Alterar logo',
    form: {
      organizationName: {
        label: 'Nome do ambiente',
        placeholder: 'Exemplo: Acme Inc.',
      },
    },
    disclaimer:
      'O padrão será aplicado no ambiente, nos e-mails e nas páginas de assinatura de todas as contas.',
    success: 'Ambiente personalizado com sucesso',
    errors: {
      default: 'Erro ao personalizar ambiente',
      brand: {
        logo: 'Logo {message}',
        color_1: 'Cor de fundo do cabeçalho {message}',
        color_2: 'Cor de fundo do botão {message}',
        color_3: 'Cor do texto do botão {message}',
      },
      name: 'Nome {message}',
    },
  },
  sideBarOrganizationAccountsMenu: {
    backToWorkspaceList: 'Voltar para painel de workspaces',
  },
};

export default workspaces;
