const messageTemplate = {
  createNewMessage: 'Crear nuevo mensaje',
  dropdownActivator: 'Mensajes guardados',
  deleteTitle: 'Eliminar mensaje guardado',
  deleteConfirmation: '¿Estás seguro de que deseas eliminar el mensaje a continuación?',
  newMessageSubtitle:
    'Crea un mensaje para reutilizarlo; se guardará y estará disponible para usar al enviar documentos.',
  newMessageInputName: 'Nombre',
  newMessageInputMessage: 'Mensaje',
};

export default messageTemplate;
