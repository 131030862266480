import { axios } from '@/plugins';

const PER_PAGE = 10;

const mountFetchGroupInfoRequestUrl = (endpoint, page, perPage) => {
  const searchParams = new URLSearchParams();
  searchParams.append('page', page);
  searchParams.append('per_page', perPage || PER_PAGE);
  const requestUrl = `${endpoint}?${searchParams.toString()}`;

  return requestUrl;
};

const fetchGroups = async ({ rootGetters, commit }, payload = { query: '', excludingIds: [] }) => {
  const { query, excludingIds } = payload;

  const groupsUrl = rootGetters['info/getLinks']?.groups;
  const searchParams = new URLSearchParams();
  if (query?.length > 0) {
    searchParams.append('q', query);
  }

  excludingIds?.forEach((id) => {
    searchParams.append('excluding_ids[]', id);
  });

  const formattedQueryString = window.decodeURI(searchParams.toString());

  const requestUrl = `${groupsUrl}?${formattedQueryString}`;

  try {
    const response = await axios.get(requestUrl);
    commit('SET_GROUPS', response.data?.groups);
  } catch (err) {
    throw err;
  }
};

const fetchGroupMembers = async ({ commit, getters, dispatch }, payload) => {
  const group = getters.getGroup(payload.id);
  const endpoint = payload.links?.memberships;
  // Can't extract page and perPage from payload because it is not always present
  const page = payload?.membershipsPagination?.page;
  const perPage = payload?.membershipsPagination?.perPage;

  const requestUrl = mountFetchGroupInfoRequestUrl(endpoint, page, perPage);

  try {
    const response = await axios.get(requestUrl);

    // If the page is greater than the pageCount, we need to fetch the previous page
    if (response.data?.pagination?.pageCount < page) {
      const newPayload = {
        ...payload,
        membershipsPagination: {
          ...payload.membershipsPagination,
          page: page - 1,
        },
      };
      dispatch('fetchGroupMembers', newPayload);

      return;
    }
    commit('SET_GROUP', {
      ...group,
      name: payload.name || group.name,
      memberships: response.data?.memberships,
      membershipsPagination: response.data?.pagination,
      links: { ...payload.links, membership: { ...response.data?.links } },
    });
  } catch (err) {
    throw err;
  }
};

const createGroup = async ({ rootGetters }, payload) => {
  const requestUrl = rootGetters['info/getLinks']?.createGroup;
  try {
    await axios.post(requestUrl, payload);
  } catch (err) {
    throw err;
  }
};

const fetchUsers = async ({ rootGetters, commit }, payload = { query: '', excludingUsers: [] }) => {
  const { excludingUsers, query } = payload;
  const usersUrl = rootGetters['info/getLinks']?.users?.index;
  const searchParams = new URLSearchParams();

  if (query.length > 0) {
    searchParams.append('q', query);
  }

  excludingUsers.forEach((id) => {
    searchParams.append('excluding_users[]', id);
  });

  const formattedQueryString = window.decodeURI(searchParams.toString());

  const requestUrl = `${usersUrl}?${formattedQueryString}`;

  try {
    const response = await axios.get(requestUrl);
    commit('SET_USERS', response.data?.users);
  } catch (err) {
    throw err;
  }
};

const editGroup = async ({ commit }, payload) => {
  const requestUrl = payload.url;
  try {
    await axios.put(requestUrl, payload);
    commit('SET_GROUP', { id: payload.group?.id, name: payload.group?.name });
  } catch (err) {
    throw err;
  }
};

const removeGroup = async ({ commit }, payload) => {
  const requestUrl = payload.url;
  try {
    await axios.delete(requestUrl);
    commit('REMOVE_GROUP', payload);
  } catch (err) {
    throw err;
  }
};

const updateGroupsMember = ({ commit }, payload) => {
  commit('UPDATE_GROUPS_MEMBER', payload);
};

const removeGroupsMember = ({ commit }, payload) => {
  commit('REMOVE_GROUPS_MEMBER', payload);
};

const fetchGroupInvites = async ({ commit, getters, dispatch }, payload) => {
  const group = getters.getGroup(payload.id);
  const endpoint = payload.links?.invites;
  // Can't extract page and perPage from payload because it is not always present
  const page = payload.invitesPagination?.page;
  const perPage = payload.invitesPagination?.perPage;

  const requestUrl = mountFetchGroupInfoRequestUrl(endpoint, page, perPage);

  try {
    const response = await axios.get(requestUrl);

    // If the page is greater than the pageCount, we need to fetch the previous page
    if (response.data?.pagination?.pageCount < page) {
      const newPayload = {
        ...payload,
        invitesPagination: {
          ...payload.invitesPagination,
          page: page - 1,
        },
      };

      dispatch('fetchGroupInvites', newPayload);
      return;
    }
    commit('SET_GROUP', {
      ...group,
      name: payload.name || group.name,
      invites: response.data?.invites.map((invite) => ({ ...invite, invite: true })),
      invitesPagination: response.data?.pagination,
      links: { ...payload.links },
    });
  } catch (err) {
    throw err;
  }
};

const updateGroupsInvite = ({ commit }, payload) => {
  commit('UPDATE_GROUPS_INVITE', payload);
};

const removeGroupsInvite = ({ commit }, payload) => {
  commit('REMOVE_GROUPS_INVITE', payload);
};

export default {
  createGroup,
  editGroup,
  fetchUsers,
  fetchGroups,
  fetchGroupMembers,
  fetchGroupInvites,
  removeGroup,
  removeGroupsMember,
  updateGroupsMember,
  updateGroupsInvite,
  removeGroupsInvite,
};
