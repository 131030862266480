export default {
  SET_MEMBERSHIPS: (state, payload) => {
    state.memberships = { ...payload };
  },
  SET_LINKS: (state, payload) => {
    state.links = { ...state.links, ...payload };
  },
  SET_PAGINATION: (state, payload) => {
    state.pagination = { ...payload };
  },
};
