const rubric = {
  error: 'Ocorreu um erro ao realizar a rubrica!',
  documentView: 'Please scroll through all pages',
  readTooltip: 'The mandatory scroll is pending',
  readConfirmation: 'Seen',
  readWidgetConfirmation: 'All pages have been seen',
  readError: 'Scrolling through all pages is mandatory',
  tooltip: 'The mandatory scroll is pending',
  modal: {
    title: 'Initials in the corner of the page',
    multipleDocuments:
      'To continue, confirm your initials on the pages of the following document:|To continue, confirm your initials on the pages of the following documents:',
    singleDocumentAllPages: 'To continue, confirm your initials on all pages of the document.',
    singleDocumentSomePages:
      'To continue, confirm your initials on the following page:|To continue, confirm your initials on the following pages:',
    pages: 'Page|Pages',
    allPages: 'All pages',
    insertInitials: 'Define your initials below (eg BC).',
    initialsPlaceholder: 'Bc',
    confirm: 'Sign and initial',
  },
};

export default rubric;
