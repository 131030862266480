const group = {
  title: 'Grupos',
  create: {
    title: 'Grupo',
  },
  groupModal: {
    create: {
      title: 'Agregar grupo',
    },
    edit: {
      title: 'Editar grupo',
    },
    alert: {
      title: 'Ahora puedes organizar usuarios en grupos',
      description:
        'Más eficiencia a la hora de gestionar personas y controlar el acceso a las carpetas',
    },
    form: {
      group: {
        label: 'Nombre del grupo',
      },
      users: {
        label: 'Usuarios',
      },
      searchPlaceholder: 'Buscar...',
      action: 'Finalizar',
      cancel: 'Cancelar',
    },
    toast: {
      create: {
        success: '{name} fue creado con éxito',
        error: 'Error al crear el grupo',
      },
      edit: {
        success: '{name} fue actualizado con éxito',
        error: 'Error al actualizar el grupo',
      },
      removeGroupUser: {
        success: 'Usuario eliminado del grupo',
        error: 'Error al eliminar usuario del grupo',
      },
    },
  },
  groupCollapse: {
    optionsDropdown: {
      actionButton: 'Más opciones',
      edit: 'Editar grupo',
      remove: 'Eliminar grupo',
    },
    pagination: {
      previous: 'Anterior',
      next: 'Siguiente',
    },
    emptyState: {
      title: 'No hay usuarios en este grupo',
      action: 'Agregar usuario',
    },
  },
  removeGroupModal: {
    title: '¿Eliminar el grupo {name} de esta cuenta?',
    description:
      'Los permisos concedidos al grupo {name} serán retirados de los usuarios inmediatamente. Ningún usuario será eliminado de la cuenta.',
    action: 'Eliminar',
    cancel: 'Cancelar',
    toast: {
      success: 'Grupo {name} eliminado con éxito',
      error: 'Error al eliminar grupo {name}',
    },
  },
};

export default group;
