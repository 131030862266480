export default {
  id: '',
  key: '',
  name: '',
  color: '',
  account_id: '',
  form_builder_id_one: '',
  form_builder_id_two: '',
  template_id: '',
  enabled: '',
  base_url: '',
  message: '',
  compliance_process: '',
  auto_close: '',
  locale: '',
  remind_interval: '',
  default_remind_interval: '',
  default_deadline: '',
  max_deadline: '',
  membership_id: '',
  deadline_in: '',
  sequence_enabled: '',
  forms: [],
  templates: [],
  template_fields: [],
  data_sources: [],
  form_fields: [],
  fields_mappings: [],
  folder: [],
  signers: [],
  form_signers: [],
  flow_results: [],
  document_name: [],
  path: [],
  remind_intervals: [],
  groups: {},
  flow_memberships: [],
  memberships: [],
  template: {},
};
