import VueI18n from '@/plugins/vue-i18n';
import moment from 'moment';
import { useI18n as useI18nExcalibur } from '@clicksign/excalibur-vue2/composables';
import ptBr from './pt_BR/ptBr';
import enUs from './en_US/enUs';
import esMx from './es_MX/esMx';
import { dateTimeFormats as dateTimeFormatsPtBr } from './pt_BR/dateTimeFormats';
import { dateTimeFormats as dateTimeFormatsEsMx } from './es_MX/dateTimeFormats';

const messages = {
  enUs,
  ptBr,
  esMx,
};

const dateTimeFormats = {
  'pt-BR': dateTimeFormatsPtBr,
  'es-MX': dateTimeFormatsEsMx,
};

const defaultLocale = 'ptBr';

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: {
    ptBr: ['pt-BR', 'pt'],
    'es-MX': ['esMx'],
    default: [defaultLocale],
  },
  messages,
  dateTimeFormats,
  silentFallbackWarn: true,
});

export default i18n;

export function setLocale(locale) {
  if (!locale) return;
  i18n.locale = locale;
  const { setLocale: setExcaliburLocale } = useI18nExcalibur();
  setExcaliburLocale(locale);
  moment.locale(locale);
}

export const useI18n = () => ({
  t: (key, values, locale) => i18n.t(key, locale, values),
  tc: (key, choice, values, locale) => i18n.tc(key, choice, locale, values),
  te: (key, locale) => i18n.te(key, locale),
  d: (value, key, locale) => i18n.d(value, key, locale),
  n: (value, format, locale) => i18n.n(value, format, locale),
  locale: i18n.locale,
  setLocale,
});
