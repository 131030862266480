import rubric from './rubric';

const draft = {
  errorWhenSendEnvelope: 'Hubo un problema al enviar, intenta nuevamente.',
  wizard: {
    addDocuments: 'Agregar documentos',
    addSigners: 'Agregar firmantes',
    rubric: 'Firma electrónica',
    position: 'Posicionamiento',
    settings: 'Configuraciones',
    message: 'Mensaje y envío',
    settingsTooltip: {
      title: 'Para mayor facilidad, hemos dejado todo listo aquí.',
      content: 'Si lo deseas, puedes cambiar las configuraciones de envío.',
      action: 'Entendido',
    },
  },
  uploadArchives: {
    title: 'Documentos',
    dragAndDrop: 'Arrastrar y soltar aquí',
    dragAndDropShort: 'Seleccionar archivos',
    or: 'o',
    select: 'Seleccionar desde la computadora',
    fromComputer: 'Desde la computadora...',
    fromDrive: 'Desde Google Drive...',
    doubtsHelp: 'Dudas sobre el envío',
    actualFolder: 'Carpeta actual:',
    acceptedTypes: 'Formatos aceptados: .pdf, .docx, .txt, .png, .jpg y .jpeg',
    sizeLimit:
      'Tamaño máximo del archivo: {docLimit}Mb para .docx y pdf y {imgLimit}Mb para .txt, .png, .jpg y .jpeg',
    googleDriveSizeLimit: 'Tamaño máximo del archivo: {limit}Mb',
    unlimitedPlan: 'Tu plan permite {limit} documentos por envío',
    limitQuantity: 'Límite máximo de envío: {limit} documentos',
    changeFolder: 'Cambiar carpeta',
    errorFolder: 'Sin acceso a la carpeta',
    errorFolderTip:
      'Puede que haya sido eliminada, movida a la papelera o no tienes permiso de acceso',
    chooseNewFolder: 'Seleccionar nueva carpeta',
    defaultError: 'Error al cargar uno o más archivos',
    googleDrive: {
      select: 'Seleccionar desde Google Drive',
      errors: {
        permission: 'Sin permiso de acceso. Por favor, selecciona otro archivo.',
        default: 'Hubo un error al procesar el archivo. Inténtalo de nuevo.',
      },
    },
  },
  folderSelection: {
    title: 'Carpeta',
    action: 'Seleccionar carpeta',
    restrictedFolder: '(carpeta restringida)',
    publicFolder: '(visible para todos)',
  },
  deleteArchives: {
    defaultError: 'Error al eliminar uno o más archivos',
  },
  modalSelectFolder: {
    header: 'Seleccionar Carpeta',
    description: 'Selecciona dónde se crearán los documentos.',
    newFolderTip: 'Después de seleccionar una carpeta, podrás crear una nueva.',
  },
  archives: {
    uploading: 'Cargando documento...',
    loadingPreview: 'Cargando vista previa...',
    emptyList: 'Visualiza documentos para firmar aquí.',
    updatingDocuments: 'Actualizando lista de documentos',
    deleteDocumentDisabled: 'No es posible eliminar un documento en proceso',
    envelopeList: {
      uploading: 'Cargando...',
      loadingPreview: 'Procesando...',
      uploadSuccess: 'Carga completada',
      seeMore: 'Ver más {count} documento | Ver más {count} documentos',
      seeLess: 'Ver menos',
    },
    actions: {
      cancel: 'Cancelar documento',
      delete: 'Eliminar documento',
    },
  },
  signatures: {
    title: 'Agregar firmantes',
    archivesList: 'Documentos',
    loadingPreview: 'Cargando vista previa...',
    preview: 'Previsualizar',
    showAll: 'Ver todos',
    showLess: 'Ver menos',
    empty: 'Agrega un {0} o un {1}',
    emptyShort: 'Haz click en {0} para agregar un firmante a tu documento',
    newSigners: 'Nuevo firmante',
    signersContact: 'Firmante de la agenda',
    sequenceEnabled: {
      alert:
        '"La orden de firmas ha sido activada. No será posible realizar modificaciones una vez enviada.',
      link: 'Ve cómo funciona',
      signAllDocuments: 'Firma todos los documentos',
      tooltip: 'Desactiva la opción "Ordenar firmas" para modificar',
    },
    blockOrderingAfterSend: 'No será posible ordenar las firmas después del envío.',
    autoSign: {
      text: 'Firmar automáticamente en mi nombre',
      badge: 'Nuevo',
    },
    orphanDocuments:
      'Vincula al menos un firmante al documento a continuación: | Vincula al menos un firmante a los documentos a continuación:',
    orderingAlert: 'Todos los firmantes firmarán todos los documentos.',
    noDocumentsSelectedError: 'Selecciona al menos un documento',
    blockOrderingModal: {
      title: 'La función de ordenar no está disponible',
      orderingCondition:
        'La función de ordenar solo puede activarse si todos los firmantes firman todos los documentos.',
      actionRequirement: 'Edita la lista de firmas para usar esta función.',
    },
    deleteSignerModal: {
      title: '¿Eliminar firmante de la lista?',
      description: 'El firmante será eliminado de la lista de firmas de este envío.',
    },
    setup: {
      title: 'Firmantes',
    },
  },
  envelope: {
    archivesList: 'Sobre',
    help: 'Aprende más sobre el sobre',
    message: {
      send: 'Enviar',
    },
    success: {
      seeEnvelope: 'Ver sobre',
    },
    tooltipSettings: {
      title: 'Posicionamiento de firma, fechas límite y otras opciones',
      configuredForYou: 'Lo configuramos todo para ti.',
      configSaved: 'Tus configuraciones serán recordadas en próximos envíos.',
    },
  },
  settings: {
    language: 'Idioma',
    closeDocument: 'Finalización',
    closeDocumentInfo: '¿Cuándo deben ser finalizados los documentos enviados?',
    autoClose: 'Después de que todos firmen, automáticamente.',
    manualClose:
      'Manualmente o hasta la fecha límite. Esto te permite revisar antes de finalizar el proceso.',
    deadlineDate: 'FECHA LÍMITE PARA FIRMAS',
    deadlineDateInfo: 'No se podrán realizar firmas una vez alcanzada la fecha límite.',
    autoReminders: 'RECORDATORIOS AUTOMÁTICOS',
    autoRemindersInfo:
      'Envío de hasta 3 recordatorios por correo electrónico a firmantes que aún no hayan firmado.',
    sendReminders: 'Enviar recordatorios automáticamente cada',
    documentLanguage: 'IDIOMA DEL DOCUMENTO',
    deadlineError: 'Debe ser posterior a la fecha de hoy',
    documentLanguageInfo:
      'La página de firma y los correos electrónicos se mostrarán en el idioma seleccionado.',
    deadlineTooltip:
      'Cuando se alcance la fecha límite del documento, el documento se finalizará automáticamente. Si no se ha realizado ninguna firma o si hay firmas rechazadas, será cancelado. | Cuando se alcance la fecha límite de los documentos, estos se finalizarán automáticamente. Si no se ha realizado ninguna firma o si hay firmas rechazadas, serán cancelados.',
    english: 'Inglés (EE.UU.)',
    portuguese: 'Portugués',
    modalDeadline: {
      header: 'Fecha límite para firmas',
      info: '¿Deseas mantener la fecha actual?',
      keep: 'Mantener',
      change: 'Cambiar',
    },
    blockOnRefusal: {
      title: 'PAUSAR FIRMA DESPUÉS DE LA RECHAZO',
      tooltip: 'Los demás firmantes no podrán firmar cuando alguien rechace la firma',
      checkbox: 'Pausar el proceso de firma cuando un firmante rechace',
    },
  },
  premium: {
    text: 'y envíar más de un documento a la vez.',
    action: 'Cambia tu Plan',
  },
  message: {
    titleNew: 'Mensaje',
    title: 'Mensaje y envío',
    uploading: 'Cargando documento... | Cargando documentos...',
    info: 'Este mensaje será enviado a todos los firmantes. Si se responde, será enviado directamente a tu correo electrónico.',
    send: 'Enviar documento | Enviar {count} documentos',
  },
  success: {
    ready: '¡Listo!',
    envelopeSentToSigners: '¡Tus documentos serán enviados para su firma!',
    documentSentToSigners:
      '¡Tu documento será enviado para su firma! | ¡Tus documentos serán enviados para su firma!',
    seeDocument: 'Seguir documento | Ver lista de documentos',
  },
  list: {
    drafts: 'Borradores',
    expirationWarning: 'Los borradores estarán disponibles por 7 días.',
    signers: 'No se ha agregado firmante | Firmante | Firmantes',
    actions: {
      selectAll: 'Seleccionar todo',
    },
    status: 'Actualizado en {at}',
    showMoreDocuments: 'Ver más documentos',
    showLessDocuments: 'Ver menos documentos',
    showMoreSigners: 'Ver más firmantes',
    showLessSigners: 'Ver menos firmantes',
    modalDelete: {
      header: '¿Deseas eliminar el borrador? | ¿Deseas eliminar los borradores?',
      info: '¿Estás seguro de que deseas eliminar {count} del borrador? | ¿Estás seguro de que deseas eliminar {count} borradores?',
      hasTrialLimit:
        'Este borrador ya ha sido contado como uno de los 30 envíos gratuitos del período de prueba. El saldo de envíos no cambiará si lo eliminas. | Estos borradores ya han sido contabilizados como parte de los 30 envíos gratuitos del período de prueba. El saldo de envíos no cambiará si los eliminas.',
    },
    emptyDocuments: 'No se ha agregado ningún documento',
    successfulDeleted: 'Borrador eliminado | Borradores eliminados',
    empty: 'Los documentos no enviados para firmar se mostrarán aquí',
  },
  preview: {
    download: 'Descargar',
    page: 'Página',
  },
  rubric,
  firstSendingSuccess: {
    title: '¡Has enviado tu primer documento!',
    description: 'Y además ganaste seguridad, redujiste costos y ahorraste tiempo',
    goToDocument: 'Seguir documento',
    goToDashboard: 'Ir al inicio',
  },
  selfSignerSuccess: {
    text: '¿Deseas firmar el documento ahora?',
    actions: {
      followEnvelope: 'Seguir sobre',
      sendAnother: 'Enviar otro',
      seeToSign: 'Ver para firmar',
    },
  },
  alreadySigned: {
    text: 'Documentos firmados por ti.',
  },
  watcher: {
    title: 'Observadores',
    button: 'Agregar',
    email: {
      placeholder: 'Introduce el correo electrónico',
      label: 'Quién seguirá con la firma',
    },
    kind: {
      label: 'Cuándo será notificado',
      options: {
        allSteps: 'En cada firma del documento',
        onFinished: 'Solo al finalizar el documento',
      },
    },
    item: {
      allSteps: 'Serás notificado en cada firma del documento',
      onFinished: 'Serás notificado solo al finalizar el documento',
      checkbox: 'Recibir el documento finalizado',
    },
  },
};

export default draft;
