export default {
  SET_LINKS: (state, payload) => {
    state.links = payload;
  },
  SET_PAGINATION: (state, payload) => {
    state.pagination = payload;
  },
  SET_BACKUP_PROCESSING: (state, payload) => {
    state.backupProcessing = payload;
  },
  SET_DOWNLOADS: (state, payload) => {
    Object.assign(state, { ...state, downloads: payload.packs, ...payload });
  },
};
