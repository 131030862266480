export default {
  getState: (state) => state,
  getLists: (state) => state.lists,
  getGroups: (state) => state.groups,
  getSigners: (state) => state.signers,
  getLinks: (state) => state.links,
  getToggles: (state) => state.toggles,
  getTotalSigners: (state) => state.totalSigners,
  getDocumentDetailEvents: (state) =>
    state.documentDetails.filter(
      (documentDetail) => !documentDetail.default_field && !documentDetail.deleted
    ),
  getDocumentValidityDate: (state) =>
    state.documentDetails.find(
      (documentDetail) => documentDetail.default_field && documentDetail.kind === 'date'
    ),
  getDocumentDetailText: (state) =>
    state.documentDetails.find(
      (documentDetail) => documentDetail.default_field && documentDetail.kind === 'text'
    ),
  getCreatedEvents: (state) =>
    state.documentDetails.filter(
      (event) => !event.default_field && event.created && event.name !== '' && event.value !== ''
    ),
  getUpdatedEvents: (state) =>
    state.documentDetails.filter(
      (documentDetail) =>
        !documentDetail.default_field &&
        documentDetail.updated &&
        documentDetail.name !== '' &&
        documentDetail.value !== ''
    ),
  getDeletedEvents: (state) =>
    state.documentDetails
      .filter((documentDetail) => !documentDetail.default_field && documentDetail.deleted)
      .map((event) => event.id),
  getFromEnvelope: (state) => state.from_envelope,
  getCurrentGroup: (state) => state.currentGroup,
  getTotalPendingSigners: (state) => state.totalPendingSigners,
  getDocumentStatus: (state) => state.status,
  getDocumentTimestamps: (state) => state.documentTimestamps,
};
