const voiceBiometrics = {
  allowVoice: {
    title: 'Allow microphone access',
    description: 'This is a security requirement of this signing process.',
    next: 'Allow access',
    error: 'Activate the microphone in your browser',
  },
  captureVoice: {
    helpTitle: 'Voice biometrics',
    helpDescription: 'Record your voice so we can recognize it based on our database.',
    title: 'Click the button and speak the followinh phrase:',
    phrase: '"I am recognized by my voice"',
    sending: 'Sending audio for recognition...',
    recording: 'Recording...',
    helpLink: 'Learn more',
  },
  feedback: {
    success: {
      title: 'Voice recognized successfully!',
    },
    error: {
      title: "We still don't recognize your voice",
    },
    nextButton: 'Next',
    tryAgainButton: 'Try again',
  },
};

export default voiceBiometrics;
