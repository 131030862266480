import { computed, defineComponent } from 'vue';
import { useToggle } from '@base/hooks';

export default defineComponent({
  name: 'FeatureFlag',
  props: {
    name: {
      type: String,
      default: '',
    },
    scope: {
      type: [String, Number],
      default: undefined,
    },
    off: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { slots }) {
    const { isEnabled } = useToggle(props.name, { scope: props.scope });

    const renderSlotDefault = computed(() => (props.off ? !isEnabled.value : isEnabled.value));

    return () => (renderSlotDefault.value ? slots.default?.() : slots.off?.());
  },
});
