const flowModule = {
  title: 'Flujos',
  new: 'Crear flujo',
  header: {
    filter: {
      label: 'Nombre del flujo',
      placeholder: 'Buscar un flujo',
      clear: 'Limpiar',
      button: 'Filtrar',
    },
  },
  list: {
    loading: 'Cargando la lista de flujos...',
    empty: 'Crea flujos vinculando formularios a plantillas y automatiza el proceso de firma.',
    usedTemplate: 'Plantilla utilizada:',
    usedForm: 'Formularios utilizados:',
    undefined: 'No definido',
    enabled: 'Activado',
    disabled: 'Desactivado',
    flowInfo: 'Ver información del flujo',
    private: 'Privado',
    actions: {
      copyLink: 'Copiar enlace',
      options: 'Opciones',
      editLink: 'Editar para activar',
      privacy: 'Permisos',
      disableToEdit: 'Desactivar para editar',
    },
    notifications: {
      disable: '¡Flujo desactivado con éxito!',
      delete: '¡Flujo activado con éxito!',
      duplicated: '¡Flujo duplicado con éxito!',
    },
  },
  modalAlert: {
    header: 'Atención',
    title: 'Desactiva los flujos conectados',
    description: 'Para editar o eliminar, desactiva los {0} conectados a este elemento.',
    flows: 'flujos',
  },
  modalDelete: {
    header: 'Eliminar elemento',
    title: '¿Estás seguro de que deseas eliminar este elemento?',
    hasPendingProcesses:
      'Atención: Al eliminar la plantilla, los datos en proceso de aprobación se perderán. ¿Deseas continuar?',
  },
  modalFormFlowDelete: {
    header: 'Eliminar flujo',
    title:
      'El informe y las aprobaciones pendientes de este flujo también serán eliminados. Para guardar una copia de las respuestas del flujo, ingresa al informe y descárgalo antes de eliminarlo',
  },
  modalDisable: {
    header: 'Desactivar flujo',
    title:
      'Si desactivas el flujo, no podrá ser utilizado, pero podrás activarlo en cualquier momento.',
    hasPendingProcesses: {
      header: 'Flujo en proceso de aprobación',
      title:
        'Este flujo contiene respuestas pendientes de aprobación. Aprueba o rechaza las respuestas de este flujo antes de desactivarlo.',
      action: 'Ir al flujo en aprobación',
    },
    hasWaitingSecondFormProcesses: {
      header: 'Este flujo tiene respuestas pendientes',
      title:
        'Este flujo tiene respuestas pendientes segundo formulario. Aprueba o rechaza las respuestas de este flujo antes de desactivarlo.',
      action: 'Ir para respuestas pendentes',
    },
  },
  modalPrivacy: {
    title: 'Definir permisos de acceso del flujo',
    selectAccessType: {
      label: 'Selecciona el tipo de acceso del flujo',
      public: 'Acceso para todos los usuarios de la cuenta',
      private: 'Acceso solo para usuarios seleccionados',
    },
    selectMembers: {
      label: 'Selecciona los usuarios que tendrán acceso al flujo',
      admin: 'Administrador',
      adminInfo:
        'Por defecto, los administradores de la cuenta tendrán acceso a todas las carpetas',
      operator: 'Operador del flujo',
      operatorInfo:
        'Este usuario no puede ser eliminado ya que está vinculado como operador del flujo',
    },
    notification: {
      updated: 'Permisos de acceso actualizados con éxito.',
      error: 'No fue posible actualizar los permisos de acceso',
    },
  },
  modalCopyLink: {
    title: 'Copiar enlace',
    description:
      'Envía este enlace a la persona que debe completar el formulario. Una vez completado, se generará y se enviará automáticamente un nuevo documento para la firma.',
    copy: 'Copiar',
    popper: '¡Copiado!',
    popperError: 'No se pudo copiar, por favor copia manualmente.',
    link: 'No se pudo copiar, por favor copia manualmente',
    close: 'Cerrar',
  },
  setup: {
    loading: 'Cargando información del flujo...',
  },
  modalEdit: {
    header: 'Editar elemento',
    title: '¿Estás seguro de que deseas editar este elemento??',
    hasPendingProcesses:
      'Atención: Al editar la plantilla, los datos en proceso de aprobación se perderán. ¿Deseas continuar?',
  },
  show: {
    enabled: 'Habilitado',
    disabled: 'Desabilitado',
    activated: 'Activado',
    deactivated: 'Desactivado',
    disableForEdit: 'Desastivar para editar',
    editToActivate: 'Editar para activar',
    ordernationEnabled: 'Clasificación activada',
    ordernationDisabled: 'Clasificación desactivada',
    message: 'Mensaje',
    modelField: 'Campo de texto: ',
    formField: 'Campo del formulario: ',
    generalInfo: {
      firstForm: 'Formulario 1',
      secondForm: 'Formulario 2',
      approvation: 'Aprobación',
      secondFormEmail: 'Correo electrónico del segundo formulario',
      template: 'Plantilla',
      membershipName: 'Operador del flujo',
      flowMemberships: 'Responsables de la aprobación',
    },
    signerInfo: {
      title: 'Firmantes',
      email: 'Correo electrónico',
      name: 'Nombre completo',
      foreignerSigner: 'Firmante extranjero',
      signer: 'Firmante',
      phoneNumber: 'Número do celular',
      birthday: 'Fecha de nacimiento',
      send: 'Envio',
      auth: 'Autenticaciones',
      flowSigner: 'Del formulario',
      fixed: 'Fijo',
      refusable: 'Firma rechazada',
    },
    storageFile: {
      title: 'Almacenamiento de archivos',
      documentName: 'Nombre del documento',
      storage: 'Carpeta donde será almacenado',
    },
    configInfo: {
      title: 'Configuraciones',
      deadlineIn: 'Fecha límite de firma',
      locale: 'Idioma',
      remindInterval: 'Recordatorios automáticos',
      autoClose: 'Finalización del  documento',
    },
    tabs: {
      config: 'Configuraciones',
      fields: 'Campos de la plantilla y formulario',
    },
  },
};

export default flowModule;
