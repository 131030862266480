const error500 = {
  name: 'Erro 500',
  title: '¡Uups! Error inesperado',
  subtitle: 'Por favor, actualiza tu página o intenta nuevamente más tarde.',
  firstParagraph: {
    content:
      'Si este mensaje continúa apareciendo, te pedimos que vuelvas a la {0} e intentes ingresar de nuevo. Si prefieres, también puedes consultar nuestra {1} para saber si tenemos alguna indisponibilidad en nuestros sistemas.',
    homePage: 'página de inicio',
    statusPage: 'página de estatus',
  },
};

export default error500;
