import error403 from './error-403';
import error404 from './error-404';
import error410 from './error-410';
import error423 from './error-423';
import error500 from './error-500';
import errorGeneral from './error-general';

const errors = {
  error403,
  error404,
  error410,
  error423,
  error500,
  errorGeneral,
};

export default errors;
