import { axios } from '@/plugins';
import * as reportService from '@/services/reports';
import { TOGGLE_SCOPES } from '@base/constants/toggles-scope';

const fetchData = async ({ commit }, url) => {
  try {
    const { data } = await axios.get(url);
    const { links, permissions } = data.report;
    commit('SET_LINKS', links);
    commit('SET_PERMISSIONS', permissions);
  } catch (err) {
    throw err;
  }
};

// TOGGLE_MIDAS_SCOPE
// TODO: when scope > TOGGLE_SCOPES.billing.reportConsumption remove rootGetters param
const fetchConsumptionReport = async ({ commit, rootGetters }, { url, params }) => {
  // TOGGLE_MIDAS_SCOPE
  // TODO: when scope > TOGGLE_SCOPES.billing.reportConsumption remove isToggleEnabled var
  const isToggleEnabled = rootGetters['featureFlags/isToggleEnabled'];

  // TOGGLE_MIDAS_SCOPE
  // TODO: when scope > TOGGLE_SCOPES.billing.reportConsumption remove isToggleMidasScopeEnabled var
  const isToggleMidasScopeEnabled = isToggleEnabled('TOGGLE_MIDAS_SCOPE', {
    scope: TOGGLE_SCOPES.billing.reportConsumption,
  });

  // TOGGLE_MIDAS_SCOPE
  // TODO: when scope > TOGGLE_SCOPES.billing.reportConsumption keep inner if code
  if (isToggleMidasScopeEnabled) {
    commit('SET_CONSUMPTION_REPORT_URL', url);
  }

  try {
    const response = await reportService.getConsumptionReport({
      url,
      params,
    });

    commit('SET_CONSUMPTION_REPORT', response.items);
    commit('SET_CONSUMPTION_REPORT_COUNTERS', {
      total: response.items_count,
      items: response.items.length,
    });
    commit('SET_CONSUMPTION_REPORT_FILTERS', params);
    commit('SET_CONSUMPTION_REPORT_PAGINATION', response.pagination);
    return response;
  } catch (error) {
    throw error;
  }
};

const setFilterLimit = ({ commit }, payload) => {
  commit('SET_CONSUMPTION_REPORT_FILTERS_LIMIT', payload);
};

const downloadConsumptionReport = async (_, url) => {
  await axios.get(`${url}&resource=consumption`);
};

const fetchConsumptionReportUsages = async ({ commit }, { url, params }) => {
  const {
    data: { usages, links },
  } = await axios.get(url, { params });
  commit('SET_REPORT_CONSUMPTION_USAGES', usages);
  commit('SET_CONSUMPTION_REPORT_USAGES_DOWNLOAD_URL', links.consumptionUsageDetailDownload);
};

const fetchConsumptionReportUsagesDetails = async ({ commit }, { url, perPage, page }) => {
  const link = `${url}&per_page=${perPage || 10}&page=${page || 1}`;

  const {
    data: { items, pagination },
  } = await axios.get(link);
  commit('SET_REPORT_CONSUMPTION_USAGES_DETAILS', items);
  commit('SET_REPORT_CONSUMPTION_USAGES_DETAILS_PAGINATION', pagination);
};

const downloadConsumptionReportUsages = async (_, url) => {
  await axios.get(url);
};

export default {
  fetchData,
  fetchConsumptionReport,
  setFilterLimit,
  downloadConsumptionReport,
  fetchConsumptionReportUsages,
  fetchConsumptionReportUsagesDetails,
  downloadConsumptionReportUsages,
};
