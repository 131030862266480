import { isArray, isObject, snakeCase, isEmpty } from 'lodash';

export const buildURLParams = (initialData) => {
  if (isEmpty(initialData)) return '';

  const reducer =
    (data, parentPrefix = null) =>
    (previous, key) => {
      const value = data[key];

      const encodedKey = encodeURIComponent(snakeCase(key));
      const prefix = parentPrefix ? `${parentPrefix}[${encodedKey}]` : encodedKey;

      if (value === undefined || value === null || typeof value === 'function') {
        return previous;
      }

      if (['number', 'boolean', 'string'].includes(typeof value)) {
        previous.push(`${prefix}=${encodeURIComponent(value)}`);
        return previous;
      }

      if (isArray(value)) {
        if (value.length === 0) return previous;
        previous.push(
          value
            .map((item) => {
              if (isObject(item))
                return Object.keys(item)
                  .reduce(reducer(item, `${prefix}[]`), [])
                  .join('&');
              return `${prefix}[]=${encodeURIComponent(item)}`;
            })
            .join('&')
        );
        return previous;
      }
      previous.push(Object.keys(value).reduce(reducer(value, prefix), []).join('&'));
      return previous;
    };

  const urlParams = Object.keys(initialData).reduce(reducer(initialData), []).join('&');

  if (urlParams.charAt(urlParams.length - 1) === '&') urlParams.slice(-1);

  return `?${urlParams}`;
};
