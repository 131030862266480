export default {
  SET_LINKS: (state, payload) => {
    Object.assign(state.links, { ...payload });
  },
  SET_ONBOARDING: (state, payload) => {
    state.onboarding = payload;
  },
  SET_REASONS: (state, payload) => {
    state.reasons = payload;
  },
};
