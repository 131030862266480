import wizardEdit from './wizard-edit';
import setupPreference from './setup-preference';
import signatures from './signatures';

const envelope = {
  show: {
    tabs: {
      overview: 'Visão geral',
      documents: 'Documentos',
      settings: 'Configurações',
      signatures: 'Assinaturas',
    },
  },
  settings: {
    successfullySaved: 'Configurações salvas com sucesso!',
    title: 'Configurações do envelope',
    closeDocumentInfo: 'Quando o envelope deve ser finalizado?',
    documentClose: {
      subtitle: 'Quando o envelope deve ser finalizado?',
    },
  },
  overview: {
    remindAll: {
      action: 'Enviar lembrete aos signatários',
      modalTitle: 'Enviar lembrete de assinatura',
      signersWillReceiveLinkAgain:
        'Os signatários que ainda precisam assinar receberão novamente o link com o documento.',
      messagePlaceholder: 'Escreva uma mensagem, se desejar',
      whatsappCost: 'Para envios via WhatsApp, há um custo de R$ 0,40 por lembrete.',
      error: 'Erro ao enviar a mensagem.',
      success: 'Mensagem enviada com sucesso!',
    },
    header: {
      status: {
        running: 'Envelope em processo',
        canceled: 'Envelope cancelado',
        closed: 'Envelope finalizado',
      },
      finishedAt: 'Em {date} às {time}',
      deadlineAt: 'Limite para assinar: {date} às {time}',
    },
    documents: {
      title: 'Documentos',
      list: {
        signaturesCount: '{signatures}/{signers} assinatura | {signatures}/{signers} assinaturas',
        previewTooltip: 'Visualizar documento.',
        signatureStatus: {
          refused: 'Assinatura recusada',
        },
      },
    },
    edit: {
      action: 'Editar envelope',
      disabled: {
        canceled: 'Envelope cancelado. Opção indisponível',
        closed: 'Envelope finalizado. Opção indisponível',
      },
      error: 'Erro ao editar o envelope.',
    },
  },
  signatures,
  setupPreference,
  wizardEdit,
};

export default envelope;
