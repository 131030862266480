import { canUseSessionStorage } from '@base/utils/can-use-storage';
import Vue from 'vue';

function AfterRedirect() {
  this.push = (url, action) => {
    const config = JSON.stringify(action);

    sessionStorage.setItem('afterRedirect', config);
    window.location = url;
  };
  this.reset = () => {
    this.message = null;
    this.type = 'default';
    this.options = {};
  };
  this.getFromSessionStorage = () => {
    const config = JSON.parse(sessionStorage.getItem('afterRedirect')) || {};
    this.message = config.message;
    this.type = config.type || 'default';
    this.options = config.options;

    sessionStorage.removeItem('afterRedirect');

    return !!config.message;
  };
}

const VueAfterRedirectToast = {
  install(_Vue) {
    const afterRedirect = new AfterRedirect();

    /* eslint-disable */
    _Vue.$afterRedirect = afterRedirect;
    _Vue.prototype.$afterRedirect = afterRedirect;
    /* eslint-enable */

    _Vue.mixin({
      mounted() {
        if (!canUseSessionStorage()) return;

        const isRootVue = this === this.$root && this.$el && this.$toast;

        if (!isRootVue || !this.$afterRedirect.getFromSessionStorage()) return;

        const { message, type, options } = this.$afterRedirect;

        const toasts = {
          default: this.$toast,
          success: this.$toast.success,
          info: this.$toast.info,
          warning: this.$toast.warning,
          error: this.$toast.error,
        };

        const toastAction = toasts[type] || toasts.default;
        toastAction(message, options);
        this.$afterRedirect.reset();
      },
    });
  },
};

Vue.use(VueAfterRedirectToast);

export default VueAfterRedirectToast;
