export default {
  links: {},
  formBuilders: [],
  formBuildersPagination: {},
  formBuildersLoading: true,
  templates: [],
  templatesPagination: {},
  templatesLoading: true,
  formFlow: {},
  listVariables: [],
  signerLinks: {},
  signersList: {},
  rubricates: [],
  signatureWatchers: [],
};
