import templateModule from '@flow/locales/es_MX/template';
import flowModule from '@flow/locales/es_MX/formFlow';
import disabledFlow from '@flow/locales/es_MX/disabledFlow';
import spreadsheetFlow from '@flow/locales/es_MX/spreadsheetFlow';
import pendingAnswers from '@flow/locales/es_MX/pendingAnswers';
import compliance from '@flow/locales/es_MX/compliance';
import fieldCreator from '@field-creator/locales/es_MX/fieldCreator';
import tracking from '@tracking/locales/es_MX/tracking';
import nodes from '@document/locales/es_MX/nodes';
import documentShow from '@document/locales/es_MX/documentShow';
import signer from '@document/locales/es_MX/signer';
import draft from '@document/locales/es_MX/draft';
import envelope from '@envelope/locales/es_MX';
import downloads from '@document/locales/es_MX/downloads';
import accountModule from '@account/locales/es_MX/account';
import subscription from '@account/locales/es_MX/subscription';
import operators from '@operators/locales/es_MX/operators';
import membership from '@account/locales/es_MX/membership';
import group from '@account/locales/es_MX/group';
import billing from '@account/locales/es_MX/billing';
import audit from '@account/locales/es_MX/audit';
import betaProgram from '@/modules/account/locales/es_MX/beta-program';
import report from '@report/locales/es_MX/report';
import auth from '@auth/locales/es_MX/auth';
import layout from '@base/locales/es_MX/layout';
import documentPreview from '@base/locales/es_MX/documentPreview';
import dashboard from '@dashboard/locales/es_MX/dashboard';
import dashboardDetails from '@dashboard/locales/es_MX/dashboardDetails';
import acceptanceTerm from '@acceptance-term/locales/es_MX/acceptance-term';
import signerArea from '@signer-area/locales/es_MX/signer-area';
import signature from '@widget/locales/es_MX/signature';
import workspaces from '@workspaces/locales/es_MX';
import branding from '@account/locales/es_MX/branding';
import chat from '@ai/locales/es_MX/chat';
import positionStamp from '@position-stamp/locales/es_MX/positionStamp.json';
import formBuilder from '@/modules/flow/locales/es_MX/formBuilder';
import general from './general';
import account from './account';
import base from './base';
import document from './document';
import modal from './modal';
import flow from './flow';
import template from './template';
import consumption from './consumption';
import validation from './validation';
import validator from './validator';
import links from './links';
import errors from '../../modules/errors/locales/es_MX';

const esMx = {
  general,
  account,
  base,
  document,
  documentShow,
  modal,
  flow,
  template,
  consumption,
  report,
  auth,
  validation,
  validator,
  templateModule,
  flowModule,
  disabledFlow,
  spreadsheetFlow,
  pendingAnswers,
  tracking,
  formBuilder,
  accountModule,
  nodes,
  signer,
  draft,
  envelope,
  downloads,
  operators,
  subscription,
  membership,
  billing,
  layout,
  dashboard,
  dashboardDetails,
  acceptanceTerm,
  signerArea,
  audit,
  betaProgram,
  documentPreview,
  signature,
  workspaces,
  group,
  compliance,
  branding,
  chat,
  fieldCreator,
  positionStamp,
  errors,
  links,
};

export default esMx;
