import { useStore } from '@/store';
import { computed, reactive } from 'vue';
import { useLimitInTrialCheck } from '@base/hooks/useLimitInTrialCheck';
import useGA from './useGA';

const modalData = reactive({
  currentModalContext: '',
  currentActionModal: '',
  currentModalStep: 'initial',
  functionality: '',
});

export const useValidateAccountActions = () => {
  const store = useStore();
  const account = computed(() => store.getters['info/getAccount'] || {});
  const user = computed(() => store.getters['info/getUser'] || {});

  const handleOpenActionModal = (action, context, step) => {
    modalData.currentActionModal = action;
    modalData.currentModalContext = context;
    modalData.currentModalStep = step;
  };

  const openBlockedFunctionalityModal = (functionality) => {
    modalData.currentActionModal = 'showBlockedFunctionality';
    modalData.functionality = functionality;
  };

  const handleRemoveAction = () => {
    modalData.currentActionModal = '';
  };

  const validateAccountActions = (
    fn,
    { validations = ['isUserConfirmed', 'isAccountEnabled'], action = '' } = {}
  ) => {
    if (!user.value?.confirmed && validations.includes('isUserConfirmed')) {
      handleOpenActionModal('showEmailVerificationModal');

      useGA('event', 'email-unconfirmed-send-document-attempt');
      return false;
    }
    if (!account.value?.enabled && validations.includes('isAccountEnabled')) {
      handleOpenActionModal('showTrialExpiredModal');

      return false;
    }

    if (['createDocument', 'createWhatsappAcceptance'].includes(action)) {
      const limitByActionCheck = {
        createDocument: 'hasExceededDocumentLimit',
        createWhatsappAcceptance: 'hasExceededWhatsappAcceptanceLimit',
      };
      const { [limitByActionCheck[action]]: limitCheck } = useLimitInTrialCheck();

      if (limitCheck?.value) {
        handleOpenActionModal('limitExceddedInTrialModal', action);

        return false;
      }
    }

    if (fn) fn();

    return true;
  };

  return {
    openBlockedFunctionalityModal,
    validateAccountActions,
    handleOpenActionModal,
    handleRemoveAction,
    modalData,
  };
};
