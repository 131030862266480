<template>
  <div :class="$style.container">
    <div :class="$style.header">
      <div :class="$style.title">
        <XIcon
          icon="code-branch"
          size="2x"
          :style="`color: ${flow.color || defaultColor}`"
          data-testid="iconBolt"
        />
        <h4 data-testid="flowName">
          {{ flow.name }}
        </h4>
      </div>
      <XBadge
        v-if="flow.private"
        icon="lock"
        color="var(--color-neutral-500)"
        data-testid="flowPrivateBadge"
      >
        {{ $t('flowModule.list.private') }}
      </XBadge>
    </div>
    <div :class="$style.body">
      <div :class="$style.contentInfo">
        <div :class="$style.info">
          <XIcon
            icon="file-invoice"
            left
            :style="`color: ${templateColor}`"
            size="lg"
          />
          <div :class="$style.content">
            <span> {{ $t(`flowModule.list.usedTemplate`) }} </span>
            <div :class="$style.name">
              {{ templateName }}
            </div>
          </div>
        </div>
        <div
          v-if="flow?.dataSources?.length > 0"
          :class="$style.info"
          data-testid="dataSourcesInfo"
        >
          <XIcon
            icon="clipboard"
            left
            :style="`color: ${defaultColor}`"
            size="lg"
          />
          <div :class="$style.content">
            <span> {{ $t(`flowModule.list.usedForm`) }} </span>
            <div
              v-for="(dataSource, index) in flow.dataSources"
              :key="index"
              :class="$style.name"
            >
              {{ dataSource.name }}
            </div>
          </div>
        </div>
      </div>
      <XLink
        :to="flow.links.self"
        :class="$style.link"
        data-testid="showFlowLink"
        @click.native="handleViewFlow"
      >
        {{ $t(`flowModule.list.flowInfo`) }}
      </XLink>
    </div>
    <div :class="$style.actions">
      <div :class="$style.buttons">
        <XButton
          v-if="flow.enabled"
          design="outlined"
          data-testid="copyLinkButton"
          data-ga-event="button-copy-link-form-out-click"
          @click="handleAction('open-copy-link-modal')"
        >
          {{ $t('flowModule.list.actions.copyLink') }}
        </XButton>
        <XButton
          v-else
          design="outlined"
          data-ga-event="link-edit-activate-click"
          data-testid="editButton"
          @click="handleAction('open-edit', 'edit')"
        >
          {{ $t('flowModule.list.actions.editLink') }}
        </XButton>
        <XDropdownMenu
          :options="optionsDropdown"
          min-width="213px"
          placement="bottom-end"
          :data-testid="camelCase(`flowForm${flow.name}OptionsButton`)"
        >
          <template #activator="{ on: openDropdown }">
            <XButton
              :data-testid="getDropdownTestId"
              design="outlined"
              icon-position="right"
              @click="openDropdown"
            >
              {{ $t('flowModule.list.actions.options') }}
              <XIcon
                icon="chevron-down"
                right
              />
            </XButton>
          </template>
        </XDropdownMenu>
      </div>
      <XBadge
        :icon="flow.enabled ? 'check-circle' : 'minus-octagon'"
        :color="flow.enabled ? 'var(--color-feedback-success-300)' : 'var(--color-neutral-500)'"
      >
        {{ getEnabledText }}
      </XBadge>
    </div>
  </div>
</template>

<script>
import { useI18n } from '@/locales';
import { useGA, useRole } from '@base/hooks';
import { camelCase, isEmpty } from 'lodash';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'FormFlowCardLayout',
  props: {
    flow: {
      type: Object,
      default: () => {},
    },
    currentUser: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { isAdmin: userIsAdmin } = useRole(props.currentUser);
    const defaultColor = 'var(--color-brand-primary-400)';

    const getEnabledText = computed(() =>
      props.flow.enabled ? t('flowModule.list.enabled') : t('flowModule.list.disabled')
    );

    const templateName = computed(() => {
      if (!isEmpty(props.flow.template)) {
        return props.flow.template.name || t('flowModule.list.undefined');
      }
      return t('flowModule.list.undefined');
    });

    const templateColor = computed(() => {
      if (!isEmpty(props.flow.template)) {
        return props.flow.template.color || defaultColor;
      }
      return defaultColor;
    });

    const getDropdownTestId = computed(() => {
      return `${camelCase(`${props.flow.name}Dropdown`)}`;
    });

    const dispatchGA = (label) =>
      useGA('event', 'list-flow-options-click', 'automation-forms-app', label);

    const handleAction = (emitValue, gaEvent) => {
      emit(emitValue, props.flow);
      if (gaEvent) dispatchGA(gaEvent);
    };

    const optionsDropdown = computed(() => {
      const options = {
        disabled: {
          text: t('flowModule.list.actions.disableToEdit'),
          icon: 'minus-octagon',
          action: () => handleAction('open-disable-modal', 'deactivate'),
          dataTestId: 'formFlowDisableOption',
        },
        privacy: {
          text: t('flowModule.list.actions.privacy'),
          icon: 'lock',
          action: () => handleAction('open-privacy-modal', 'permissions'),
          dataTestId: 'formFlowPrivacyOption',
        },
        delete: {
          text: t('general.action.delete'),
          icon: 'trash',
          action: () => handleAction('open-delete-modal', 'delete'),
          dataTestId: 'formFlowDeleteOption',
        },
        duplicate: {
          text: t('general.action.duplicate'),
          icon: 'clone',
          action: () => handleAction('open-duplicate', 'duplicate'),
          dataTestId: 'formFlowDuplicateOption',
        },
      };

      const optionsArray = [
        userIsAdmin.value ? options.privacy : false,
        props.flow.enabled && options.disabled,
        options.delete,
        options.duplicate,
      ];

      return optionsArray.filter(Boolean);
    });

    const handleViewFlow = () => {
      useGA('event', 'button-view-config-flow', 'automation-form-flows-app');
    };

    return {
      optionsDropdown,
      getEnabledText,
      templateName,
      templateColor,
      getDropdownTestId,
      defaultColor,
      handleAction,
      camelCase,
      handleViewFlow,
    };
  },
});
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-regular);
    gap: var(--space-small-xx);

    .title {
      display: flex;
      align-items: center;
      gap: var(--space-small-xx);

      h4 {
        margin-bottom: 0;
      }
    }

    @include breakpoint('extra-small') {
      flex-direction: column;
    }
  }

  .body {
    display: flex;
    align-items: flex-start;
    flex: 1;
    flex-direction: column;

    .contentInfo {
      flex: 1;

      .info {
        display: flex;

        .content {
          flex: 1;

          .name {
            color: var(--color-neutral-800);
          }
        }

        &:first-of-type {
          margin-bottom: var(--space-small-xx);
        }
      }
    }

    .link {
      margin-top: var(--space-medium);
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--space-large-x);
    gap: var(--space-regular);

    .buttons {
      display: flex;
      align-items: center;
      gap: var(--space-regular);
    }

    @include breakpoint('extra-small') {
      flex-direction: column;

      .buttons {
        flex-direction: column;
      }
    }
  }
}
</style>
