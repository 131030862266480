import axios from '@/plugins/axios';

const saveRubricsData = async ({ dispatch }, payload) => {
  let addResponse;
  let editResponse;
  let removeResponse;
  if (payload.add) {
    const response = await axios.post(payload.postUrl, payload.add);
    addResponse = response;
  }
  if (payload.edit) {
    const response = await axios.patch(payload.edit.links.self, payload.edit);
    editResponse = response;
  }
  if (payload.remove) {
    const response = await axios.delete(payload.remove.links.self);
    removeResponse = response;
  }
  await dispatch('fetchDraft');
  return [addResponse, editResponse, removeResponse];
};

const fetchRubricsData = async ({ commit }, { archives, linkType }) => {
  const savedData = await Promise.all(
    archives.map(async (archive) => {
      const url = {
        clauses: archive?.links?.clauses,
        readReceipts: archive?.links?.readReceipts,
        seals: archive?.links?.seals,
      };
      const { data } = await axios.get(url[linkType]);
      let parsedData;
      if (linkType === 'clauses') {
        parsedData = {
          id: data.clauses[0]?.id,
          clauses: data.clauses[0]?.identifier,
          archive,
          selectedSigners: data.clauses[0]?.signerIds || [],
          links: { self: data.clauses[0]?.links.self },
        };
      } else if (linkType === 'readReceipts') {
        parsedData = {
          id: data.readReceipts[0]?.id,
          archive,
          selectedSigners: data.readReceipts[0]?.signerIds || [],
          links: { self: data.readReceipts[0]?.links.self },
        };
      } else {
        parsedData = {
          id: data.seals[0]?.id,
          archive,
          pages: data.seals[0]?.pages,
          selectedSigners: data.seals[0]?.signerIds || [],
          links: { self: data.seals[0]?.links.self },
        };
      }
      return parsedData;
    })
  );
  commit('SET_RUBRICS', savedData);
  return savedData;
};

const fetchEnvelopeRubric = async ({ getters }) => {
  const archives = getters.getDraft?.archives || [];
  const fetchedData = await Promise.all(
    archives.map(async (archive) => {
      const url = archive.links.rubrics;
      const { data } = await axios.get(url);
      return {
        archive,
        ...data,
      };
    })
  );

  return fetchedData;
};

const saveEnvelopeRubric = async (_, rubrics) => {
  const savedData = await Promise.all(
    rubrics.map(async (rubric) => {
      const { data } = await axios.patch(rubric.url, rubric.payload);
      return data;
    })
  );
  return savedData;
};

const saveEnvelopePositionSign = async (_, payloadByRubricsUrl) => {
  const entries = Object.entries(payloadByRubricsUrl);

  const savedData = await Promise.all(
    entries.map(async ([url, payload]) => {
      const { data } = await axios.patch(url, payload);
      return data;
    })
  );
  return savedData;
};

const fetchEnvelopePositionSign = async ({ getters }) => {
  const archives = getters.getDraft?.archives || [];
  const fetchedData = await Promise.all(
    archives.map(async (archive) => {
      const url = archive.links.rubrics;
      const { data } = await axios.get(url);
      const dataWithDocument = data.map((item) => ({ ...item, documentKey: archive.key }));

      return dataWithDocument;
    })
  );

  return fetchedData.flat();
};

export default {
  saveRubricsData,
  fetchRubricsData,
  fetchEnvelopeRubric,
  saveEnvelopeRubric,
  saveEnvelopePositionSign,
  fetchEnvelopePositionSign,
};
