const flowModule = {
  title: 'Fluxos',
  new: 'Criar fluxo',
  header: {
    filter: {
      label: 'Nome do fluxo',
      placeholder: 'Busque por um fluxo',
      clear: 'Limpar',
      button: 'Filtrar',
    },
  },
  list: {
    loading: 'Carregando lista de fluxos...',
    empty:
      'Crie fluxos conectando formulários à modelos para criar um processo de assinatura automático',
    usedTemplate: 'Modelo utilizado:',
    usedForm: 'Formulários utilizados:',
    undefined: 'Não definido',
    enabled: 'Ativado',
    disabled: 'Desativado',
    flowInfo: 'Ver informações do fluxo',
    private: 'Privado',
    actions: {
      copyLink: 'Copiar Link',
      options: 'Opções',
      editLink: 'Edite para ativar',
      privacy: 'Permissões',
      disableToEdit: 'Desativar para editar',
    },
    notifications: {
      disable: 'Fluxo desativado com sucesso!',
      delete: 'Fluxo excluído com sucesso!',
      duplicated: 'Fluxo duplicado com sucesso!',
    },
  },
  modalAlert: {
    header: 'Atenção',
    title: 'Desative os fluxos conectados',
    description: 'Para editar ou excluir desative os {0} conectados a este item.',
    flows: 'fluxos',
  },
  modalDelete: {
    header: 'Excluir item',
    title: 'Tem certeza que deseja excluir este item?',
    hasPendingProcesses:
      'Atenção: Ao remover o modelo, os dados em aprovação serão perdidos! Deseja continuar?',
  },
  modalFormFlowDelete: {
    header: 'Excluir fluxo',
    title:
      'O relatório e as aprovações pendentes deste fluxo também serão excluídos. Para salvar uma cópia das respostas do fluxo, acesse o relatório e baixe-o antes de excluir.',
  },
  modalDisable: {
    header: 'Desativar fluxo',
    title:
      'Se você desativar o fluxo ele não poderá ser utilizado, mas você poderá ativá-lo a qualquer momento.',
    hasPendingProcesses: {
      header: 'Fluxo em Aprovação',
      title:
        'Este fluxo contém respostas pendentes de aprovação. Aprove ou reprove as respostas deste fluxo antes de desativá-lo.',
      action: 'Ir para fluxo em aprovação',
    },
    hasWaitingSecondFormProcesses: {
      header: 'Fluxo com respostas pendentes',
      title:
        'Este fluxo contém respostas pendentes do segundo formulário. Aprove ou reprove as respostas deste fluxo antes de desativá-lo.',
      action: 'Ir para respostas pendentes',
    },
  },
  modalPrivacy: {
    title: 'Definir permissões de acesso do fluxo ',
    selectAccessType: {
      label: 'Selecione o tipo de acesso do fluxo',
      public: 'Acesso para todos os usuários da conta',
      private: 'Acesso apenas para usuários selecionados',
    },
    selectMembers: {
      label: 'Selecione os usuários que terão acesso ao fluxo',
      admin: 'Administrador',
      adminInfo: 'Administradores da conta sempre terão acesso as pastas por padrão',
      operator: 'Operador do fluxo',
      operatorInfo: 'Este usuário não pode ser removido por estar vinculado como operador do Fluxo',
    },
    notification: {
      updated: 'Definições de permissões atualizadas com sucesso.',
      error: 'Não foi possível atualizar as definições de permissões',
    },
  },
  modalCopyLink: {
    title: 'Copiar link',
    description:
      'Envie este link para a pessoa que deve preencher o formulário. Após preenchido, um novo documento será gerado e enviado para a assinatura automaticamente.',
    copy: 'Copiar',
    popper: 'Copiado!',
    popperError: 'Não foi possível copiar, por favor copie manualmente.',
    link: 'Abrir link deste fluxo',
    close: 'Fechar',
  },
  setup: {
    loading: 'Carregando informações do fluxo...',
  },
  modalEdit: {
    header: 'Editar item',
    title: 'Tem certeza que deseja editar este item?',
    hasPendingProcesses:
      'Atenção: Ao editar o modelo, os dados em aprovação serão perdidos! Deseja continuar?',
  },
  show: {
    enabled: 'Habilitada',
    disabled: 'Desabilitada',
    activated: 'Ativado',
    deactivated: 'Desativado',
    disableForEdit: 'Desativar para editar',
    editToActivate: 'Editar para ativar',
    ordernationEnabled: 'Ordenação ativada',
    ordernationDisabled: 'Ordenação desativada',
    message: 'Mensagem',
    modelField: 'Campo do modelo: ',
    formField: 'Campo do formulário: ',
    generalInfo: {
      firstForm: 'Formulário 1',
      secondForm: 'Formulário 2',
      approvation: 'Aprovação',
      secondFormEmail: 'E-mail do segundo formulário',
      template: 'Modelo',
      membershipName: 'Operador do fluxo',
      flowMemberships: 'Responsáveis pela aprovação',
    },
    signerInfo: {
      title: 'Signatários',
      email: 'E-mail',
      name: 'Nome completo',
      foreignerSigner: 'Signatário estrangeiro',
      signer: 'Signatário',
      phoneNumber: 'Nùmero do celular',
      birthday: 'Data de nascimento',
      send: 'Envio',
      auth: 'Autenticações',
      flowSigner: 'Do formulário',
      fixed: 'Fixo',
      refusable: 'Recusa de assinatura',
    },
    storageFile: {
      title: 'Armazenamento dos arquivos',
      documentName: 'Nome do documento',
      storage: 'Pasta onde será armazenado',
    },
    configInfo: {
      title: 'Configurações',
      deadlineIn: 'Data limite de assinatura',
      locale: 'Idioma',
      remindInterval: 'Lembretes automáticos',
      autoClose: 'Finalização do documento',
    },
    tabs: {
      config: 'Configurações',
      fields: 'Campos do modelo e formulário',
    },
  },
};

export default flowModule;
