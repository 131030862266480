import { computed, watch } from 'vue';
import { useCtxStore } from '@base/hooks/useCtxStore';

import { checkIsToggleEnabled } from '@base/utils/toggle-utils';

export const useToggle = (toggleName, callbackOrOpt, options) => {
  const store = useCtxStore();

  const hasCallback = callbackOrOpt && typeof callbackOrOpt === 'function';
  const { off, scope } = options || (!hasCallback && callbackOrOpt) || {};

  const isEnabled = computed(() =>
    checkIsToggleEnabled(toggleName, store.getters['featureFlags/getToggles'], { scope })
  );

  watch(
    isEnabled,
    () => {
      const shouldRunCallback = off ? !isEnabled.value : isEnabled.value;

      if (hasCallback && shouldRunCallback) callbackOrOpt();
    },
    { immediate: true }
  );

  return { isEnabled };
};
