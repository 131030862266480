import { camelCase } from 'lodash';

export const getToggleState = (name, toggles) => {
  const state = toggles?.[camelCase(name)];

  if (state === undefined) {
    throw new Error(`Toggle with name '${name}' not be defined`);
  }
  return state;
};

export const getScopeNumber = (toggleState) =>
  parseFloat(String(toggleState).replace(/^scope_/, ''));

export const checkIsToggleEnabled = (name, toggles, options) => {
  const { scope, preventDefaultError = false } = options || {};

  try {
    const toggleState = getToggleState(name, toggles);

    if (typeof toggleState === 'boolean' || scope === undefined) return !!toggleState;

    return scope <= getScopeNumber(toggleState);
  } catch (error) {
    if (preventDefaultError) throw error;

    /* eslint-disable-next-line */
    console.warn(error.message);
    return false;
  }
};
