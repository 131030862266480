import { getViewProps } from '@base/utils/getViewDataset';
import nodes from './nodes';
import envelope from './envelope';

const routes = [
  {
    path: '/accounts/:accountId/contacts',
    name: 'contacts',
    meta: {
      layout: 'BaseNavigationLayout',
    },
    component: () => import('@document/views/ContactsListView.vue'),
  },
  {
    path: '/accounts/:accountId/folders/:folderId/documents/:documentKey',
    name: 'showDocument',
    meta: {
      layout: 'BaseNavigationLayout',
    },
    component: () => import('@document/views/DocumentEditView.vue'),
  },
  {
    path: '/accounts/:accountId/download/packs',
    name: 'downloadsPacks',
    meta: {
      layout: 'BaseNavigationLayout',
    },
    component: () => import('@document/views/DocumentsDownloadsView.vue'),
  },
  {
    path: '/accounts/:accountId/draft/packs',
    name: 'draftPacks',
    meta: {
      layout: 'BaseNavigationLayout',
    },
    component: () => import('@document/views/DocumentsDraftsListView.vue'),
  },
  {
    path: '/accounts/:accountId/folders/:folderId/documents/:documentKey/setup',
    name: 'draftPacksSetup',
    props: getViewProps,
    component: () => import('@document/views/DocumentSetupView.vue'),
    meta: {
      layout: 'BaseDefaultLayout',
      closeLink: {
        show: true,
      },
      refresh: () => true,
    },
  },
  {
    path: '/accounts/:accountId/draft/packs/new',
    alias: '/accounts/:accountId/notarial/compat/envelopes/new',
    name: 'DocumentsDraftView',
    component: () => import('@document/views/DocumentsDraftView.vue'),
    props: getViewProps,
    meta: {
      layout: 'BaseDefaultLayout',
      additionalDataSurveyTrackingId: 'documentsDraft',
      closeLink: {
        show: true,
      },
      refresh: () => true,
    },
  },
  {
    path: '/accounts/:accountId/draft/packs/:key',
    alias: '/accounts/:accountId/notarial/compat/envelopes/:key',
    name: 'DocumentsDraftShow',
    component: () => import('@document/views/DocumentsDraftView.vue'),
    props: getViewProps,
    meta: {
      layout: 'BaseDefaultLayout',
      additionalDataSurveyTrackingId: 'documentsDraft',
      closeLink: {
        show: true,
        path: 'draftPacks',
      },
      refresh: () => true,
    },
  },
  ...nodes,
  ...envelope,
];

export default routes;
