const compliance = {
  title: 'Aprobación de las respuestas de los flujos',
  approve: 'Aprobar',
  disapprove: 'Rechazar',
  loading: 'Cargando lista de respuestas de los flujos....',
  header: {
    title: 'Aprobación de las respuestas de los flujos.',
    help: 'Ayuda',
    alert: 'Revisa las respuestas pendientes de aprobación y decide si deseas aprobarlas.',
    filter: {
      label: 'Flujos',
      placeholder: 'Selecciona el flujo',
      clear: 'Limpar',
      button: 'Filtrar',
    },
  },
  comfirmModal: {
    approve: 'Sí, aprobar!',
    disapprove: 'Rechazar',
  },
  empty: {
    title: 'Todavía no tienes respuestas pendientes de aprobación',
    description:
      'Activa la aprobación de respuestas en tu flujo y revisa la información aquí antes de generar el documento.',
  },
  form: 'Formulario | Formularios',
  filledAt: 'Completado en',
  open: 'Abrir',
  cancel: 'Cancelar',
  notificationSuccess: '¡Éxito! El flujo fue aprobado y el documento fue enviado para firma.',
  notificationDisapprove: '¡Aviso! El flujo fue rechazado y el documento ya no será generado.',
  modal: {
    title: 'Aprobar flujo',
    actionsInfo: 'Al hacer click en una de las opciones, aparecerá una pantalla de confirmación.',
  },
  confirmAction: {
    approve: '¿Estás seguro de que deseas aprobar??',
    approveInfo:
      'Al aprobar, el documento se enviará para firma. Esta acción no se podrá deshacer.',
    disapprove: '¿Estás seguro de que deseas rechazar',
    disapproveInfo:
      'Al rechazar, el documento no podrá ser generado ni enviado para firma. Esta acción no se podrá deshacer.',
  },
  unanswered: 'No informado',
  identifier: 'Identificador',
  flowName: 'Nombre del flujo',
};

export default compliance;
