<template>
  <div :class="$style.container">
    <h4>{{ $t('flowModule.show.storageFile.title') }}</h4>
    <XCard
      elevation="low"
      :class="$style.content"
    >
      <p v-if="documentName.length > 0">
        {{ $t('flowModule.show.storageFile.documentName') }} <br />
        <span :class="$style.info">{{ getDocumentName }}</span>
      </p>
      <p v-if="path.length > 0">
        {{ $t('flowModule.show.storageFile.storage') }} <br />
        <span :class="$style.info">{{ getPath }}</span>
      </p>
    </XCard>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'FormFlowStorageFileInfo',
  props: {
    documentName: {
      type: Array,
      default: () => [],
    },
    path: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const getDocumentName = computed(() => props.documentName.map((item) => item.value).join('/'));
    const getPath = computed(() => props.path.map((item) => item.value).join('/'));

    return {
      getPath,
      getDocumentName,
    };
  },
});
</script>

<style lang="scss" module>
.container {
  .content {
    margin-bottom: var(--space-large-x);

    .info {
      color: var(--color-neutral-800);
    }
  }
}
</style>
