export default [
  {
    name: 'info',
    title: 'signer.infoStep.envelope.title',
    component: 'EnvelopeSignerInfoForm',
    form: 'signerInfo',
    props: {
      signerAuths: 'signerAuths',
      signerCommunicate: 'signerCommunicate',
      signerSignAs: 'signerSignAs',
      email: 'signerInfo.email',
      phoneNumber: 'signerInfo.phoneNumber',
      name: 'signerInfo.name',
      birthday: 'signerInfo.birthday',
      hasDocumentation: 'signerInfo.hasDocumentation',
      documentation: 'signerInfo.documentation',
      signAs: 'signerInfo.signAs',
      auths: 'signerInfo.auths',
      communicateBy: 'signerInfo.communicateBy',
      contact: 'signerInfo.contact',
      officialDocumentEnabled: 'signerInfo.officialDocumentEnabled',
      selfieEnabled: 'signerInfo.selfieEnabled',
      handwrittenEnabled: 'signerInfo.handwrittenEnabled',
      livenessEnabled: 'signerInfo.livenessEnabled',
      additionalAuths: 'additionalAuthsItems',
      facialBiometricsEnabled: 'signerInfo.facialBiometricsEnabled',
      transferSaveAsContactSwitch: 'transferSaveAsContactSwitch',
    },
    baseState: {
      email: '',
      phoneNumber: null,
      name: '',
      birthday: '',
      hasDocumentation: true,
      documentation: '',
      signAs: '',
      auths: [],
      communicateBy: '',
      officialDocumentEnabled: false,
      selfieEnabled: false,
      handwrittenEnabled: false,
      contact: true,
      livenessEnabled: false,
      facialBiometricsEnabled: false,
    },
    action: async (vm, payload) => {
      if (payload.contact) {
        const url = vm.$store.getters['signer/getLinks']?.contacts;
        const response = await vm.$store.dispatch('signer/createContact', {
          url,
          payload,
          context: vm.context,
        });
        return response;
      }
      return payload;
    },
    afterAction: (vm, signer) => {
      Object.assign(vm.signAs, { signers: [signer] });
    },
  },
  {
    name: 'signAs',
    title: 'signer.infoStep.signAs.label',
    component: 'SignerListSignAsStep',
    form: 'signAs',
    props: {
      signers: 'signAs.signers',
      signerSignAs: 'getSignerSignAsItems',
      signAsList: 'signAs.signAsList',
    },
    baseState: {
      signers: [],
      signAsList: {},
      lists: {},
    },
    action: async (vm, payload) => {
      const url = _.get(vm.$store.getters, 'signer/getLinks.linkSigner');
      const response = await vm.$store.dispatch('signer/linkSigner', {
        url,
        payload,
        context: vm.context,
      });
      vm.$emit('save', response);
      return response;
    },
    afterAction: async (vm, payload) => {
      const signers = _.get(payload, 'signers');
      const lists = _.get(payload, 'lists');
      vm.$set(vm.messageToSend, 'signers', signers);
      vm.$set(vm.messageToSend, 'lists', lists);

      if (!vm.steps.includes('message')) return;

      const activeGroup = _.get(payload, 'current_group');
      const isSequenceEnabled = signers.some((signer) => signer.group !== activeGroup);

      vm.$set(vm.messageToSend, 'isSequenceEnabled', isSequenceEnabled);
    },
  },
  {
    name: 'message',
    component: 'SignerMessageStep',
    form: 'messageToSend',
    props: {
      signerSignAs: 'signerSignAs',
      signers: 'messageToSend.signers',
      message: 'messageToSend.message',
      messageTemplates: 'messageTemplates',
    },
    baseState: { message: '' },
    action: async (vm, form) => {
      const signers = _.get(form, 'signers');
      const lists = _.get(form, 'lists');
      const message = _.get(form, 'message');
      const isSequenceEnabled = _.get(vm, '$store.state.document.sequence_enabled', false);

      if (isSequenceEnabled) {
        await Promise.all(
          lists.map(async (list) => {
            const url = _.get(list, 'links.self');
            await vm.$store.dispatch('signer/updateList', { url, message });
          })
        );
      } else {
        await Promise.all(
          signers.map(async (signer) => {
            const url = _.get(signer, 'links.notifySignature');
            await vm.$store.dispatch('signer/notifySignature', { url, message });
          })
        );
      }
    },
    abort: (vm) => {
      vm.openModal('cancelSigner');
    },
  },
];
