// TODO: Padronizar nomes das mutations
export default {
  GET_FLOW(state, payload) {
    state.id = payload.id;
    state.key = payload.key;
    state.name = payload.name;
    state.color = payload.color;
    state.account_id = payload.account_id;
    state.data_sources = payload.data_sources;
    state.form_builder_id_one = payload.form_builder_id_one;
    state.form_builder_id_two = payload.form_builder_id_two;
    state.template_id = payload.template_id;
    state.enabled = payload.enabled;
    state.message = payload.message;
    state.compliance_process = payload.compliance_process;
    state.deadline_in = payload.deadline_in;
    state.default_remind_interval = payload.default_remind_interval;
    state.remind_interval = payload.remind_interval;
    state.default_deadline = payload.default_deadline;
    state.max_deadline = payload.max_deadline;
    state.membership_id = payload.membership_id;
    state.locale = payload.locale;
    state.auto_close = payload.auto_close;
    state.base_url = payload.links.base_url;
    state.sequence_enabled = payload.sequence_enabled;
    state.template_fields = payload.template_fields;
    state.form_fields = payload.form_fields;
    state.flow_results = payload.flow_results;
    state.remind_intervals = payload.remind_intervals;
    state.document_name = payload.document_name;
    state.path = payload.path;
    // TODO: state.signers to state.lists
    state.signers = payload.flow_lists;
    state.form_signers = payload.flow_signers;
    state.groups = payload.groups;
    state.flow_memberships = payload.flow_memberships;
    state.memberships = payload.memberships;
    state.template = payload.template;
  },
  GET_FORMS_FLOW(state, payload) {
    state.forms = payload;
  },
  SET_FORM_FLOW_ONE(state, payload) {
    state.form_builder_id_one = payload.form_builder_id_one;
    state.data_sources = payload.data_sources;
    state.document_name = payload.document_name;
    state.path = payload.path;
  },
  SET_FORM_FLOW_TWO(state, payload) {
    state.form_builder_id_two = payload.form_builder_id_two;
    state.data_sources = payload.data_sources;
    state.document_name = payload.document_name;
    state.path = payload.path;
  },
  GET_TEMPLATES_FLOW(state, payload) {
    state.templates = payload;
  },
  UPDATE_TEMPLATE_FLOW(state, payload) {
    state.template_id = payload;
  },
  GET_FIELDS_MAPPINGS_FLOW(state, payload) {
    state.template_fields = payload.template_fields;
    state.form_fields = payload.form_fields;
    state.data_sources = payload.data_sources;
    state.fields_mappings = payload.fields_mappings;
  },
  UPDATE_FIELDS_MAPPINGS_FLOW(state, payload) {
    state.fields_mappings = payload;
  },
  UPDATE_FOLDER_FLOW(state, payload) {
    state.document_name = payload.document_name;
    state.path = payload.path;
  },
  GET_SIGNERS_FLOW(state, payload) {
    // TODO: state.signers to state.lists
    state.signers = payload.flow_lists;
    state.form_signers = payload.flow_signers;
    state.groups = payload.groups;
  },
  SET_SIGNER_FLOW(state, payload) {
    state.signers = [...state.signers, payload];
    state.groups = { ...state.groups, ...payload.group };
  },
  DELETE_SIGNER_FLOW(state, payload) {
    const signerIndex = state.signers.map((item) => item.id).indexOf(payload.id);
    state.signers.splice(signerIndex, 1);

    state.groups = payload.groups;
  },
  SET_FORM_SIGNER_FLOW(state, payload) {
    state.form_signers = [...state.form_signers, payload];
    state.groups = { ...state.groups, ...payload.group };
  },
  UPDATE_ENABLED_FLOW(state, payload) {
    state.enabled = payload;
  },
  UPDATE_SETTINGS_FLOW(state, payload) {
    state.compliance_process = payload.compliance_process;
    state.flow_memberships = payload.flow_memberships;
    state.deadline_in = payload.deadline_in;
    state.remind_interval = payload.remind_interval;
    state.locale = payload.locale;
    state.auto_close = payload.auto_close;
  },
  UPDATE_MESSAGE_FLOW(state, payload) {
    state.message = payload;
  },
  FLOW_ORDERING(state, payload) {
    state.sequence_enabled = payload.sequence_enabled;
    state.groups = payload.groups;
  },
};
