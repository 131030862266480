export default {
  getLinks: (state) => state.links,
  getAccessTokens: (state) => state.accessTokens,
  getMemberships: (state) => state.memberships,
  getApiUserId: (state) => state.apiUserId,
  getHooks: (state) => state.hooks,
  getPlan: (state) => state.plan,
  getLinkUpgradePlan: (state) => state.linkUpgradePlan,
  getEvents: (state) => state.events,
  getWidgetEmbedded: (state) => state.widgetEmbedded,
};
