export const defaultState = () => ({
  links: {},
  chatThread: [],
  chatData: {},
  formData: {},
  lastMessageReceived: {},
  isFinished: false,
});

export default {
  ...defaultState(),
};
