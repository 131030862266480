export default {
  brand: {
    colors: undefined,
    logo: undefined,
  },
  serverCurrentTime: '',
  locale: '',
  pagination: {},
  documents: [],
  viewableDocuments: [],
  documentsSize: 0,
  signer: {},
  links: {},
  clauses: [],
  readReceipts: [],
  seals: [],
  hasSummary: false,
  embedded: false,
  environment: '',
  photoEvidences: [],
  cafToken: '',
  fromEnvelope: false,
  cafEnvironment: null,
  batchKey: null,
  tokenInfo: {
    remaningAttempts: 0,
    blockedUntil: null,
  },
  signableDocumentsCount: {
    batch: 0,
    pendingDocuments: 0,
  },
  hasClauses: false,
  hasReadReceipts: false,
  callToActionEnabled: false,
  hasSeals: false,
  vivoEnabled: false,
  documentsCount: 1,
  pendingDocumentsCount: 0,
  signedDocumentsCount: 0,
  fromSignerArea: false,
  hasRubrics: false,
  rubrics: [],
  facematchCheckStatusTimeout: {},
  cafEvidences: {},
  livenessKey: {},
};
