import { useI18n } from '@/locales';

const { t } = useI18n();

export default {
  BLOCKED_FUNCTIONALITY_MODAL_GA_EVENTS: {
    category: 'block-modal-app',
    integrationFeatures: {
      moreInfo: {
        action: 'cross-sell-API-more-info',
      },
      upgrade: {
        action: 'cross-sell-API-request-feature',
      },
      close: {
        action: 'cross-sell-api-close',
      },
    },
    samlLogin: {
      moreInfo: {
        action: 'cross-sell-security-more-info',
      },
      upgrade: {
        action: 'cross-sell-security',
      },
      close: {
        action: 'cross-sell-security-close',
      },
    },
    customizationOfLogosAndColors: {
      moreInfo: {
        action: 'cross-sell-customization-more-info',
      },
      upgrade: {
        action: 'cross-sell-customization',
      },
      close: {
        action: 'cross-sell-customization-close',
      },
    },
    audit: {
      moreInfo: {
        action: 'cross-sell-audit-more-info',
      },
      upgrade: {
        action: 'cross-sell-audit',
      },
      close: {
        action: 'cross-sell-audit-close',
      },
    },
    additionalAuth: {
      moreInfo: {
        action: 'cross-sell-additional-auth-more-info',
      },
      upgrade: {
        action: 'cross-sell-additional-auth',
      },
      close: {
        action: 'cross-sell-additional-auth-close',
      },
    },
  },
  BLOCKED_FUNCTIONALITY_MODAL_LINKS: {
    default: {
      moreInfo: t('links.help.account.config'),
    },
    samlLogin: {
      moreInfo: t('links.help.account.config'),
    },
    batchDownload: {
      moreInfo: t('links.help.billing.plans'),
    },
    customizationOfLogosAndColors: {
      moreInfo: t('links.help.account.customize'),
    },
    audit: {
      moreInfo: t('links.help.account.accountAudit'),
    },
    additionalAuth: {
      moreInfo: t('links.help.docs.auths.aditional'),
    },
    integrationFeatures: {
      moreInfo: t('links.help.other.api'),
    },
  },
};
