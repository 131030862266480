const template = {
  setup: {
    title: 'Plantilla del documento',
    info: 'Define tu plantilla a partir de un archivo Word (.docx) con variables',
    nameLabel: 'Nombre de la plantilla',
    uploadLabel: 'Selecciona un archivo Word (.docx) con las variables',
    documentExample: 'Descargar documento de ejemplo con variables',
    colorLabel: 'Elige un color para identificar esta plantilla',
    save: 'Guardar',
    editTemplate: 'Editar plantilla',
    fatalError: {
      title: 'Error al cargar los datos de la plantilla',
      message:
        'Inténtalo nuevamente. Si el problema persiste, envía un correo a ayuda@clicksign.com.',
    },
    notifications: {
      update: 'Plantilla actualizado con éxito.',
      create: 'Plantilla creado con éxito.',
    },
    create: {
      documentsTemplates: 'Plantilla de documentos',
      createTemplate: 'Crear plantilla',
    },
    edit: {
      documentsTemplates: 'Plantilla de documentos',
      editTemplate: 'Editar plantilla',
      successMessage: 'Plantilla actualizada con éxito',
      errorMessage: 'Ocurrió un error al actualizar la plantilla',
    },
    templateByDocuments: {
      title: 'Plantilla inteligente (IA)',
      label: 'Selecciona un documento en PDF',
      send: 'Generar',
      alert:
        'Crea tu plantilla a partir de dos archivos PDF ya existentes, para generar una automatización',
    },
  },
};

export default template;
