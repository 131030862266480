import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

import {
  state as fbState,
  actions as fbActions,
  mutations as fbMutations,
  getters as fbGetters,
} from './facialBiometrics';

import { actions as vbActions } from './voiceBiometrics';

import {
  state as icpCloudState,
  actions as icpCloudActions,
  mutations as icpCloudMutations,
  getters as icpCloudGetters,
} from './icpCloud';

import {
  state as selfieState,
  actions as selfieActions,
  mutations as selfieMutations,
  getters as selfieGetters,
} from './selfie';

export default {
  namespaced: true,
  state: { ...state, ...fbState, ...icpCloudState, ...selfieState },
  mutations: { ...mutations, ...fbMutations, ...icpCloudMutations, ...selfieMutations },
  actions: {
    ...actions,
    ...fbActions,
    ...icpCloudActions,
    ...selfieActions,
    ...vbActions,
  },
  getters: { ...getters, ...icpCloudGetters, ...fbGetters, ...selfieGetters },
};
