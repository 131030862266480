import { getSubdomains, createURL } from '../uri';

/**
 * Not recoginized subdomains will be considered development enviroment
 */
export const getSentryEnviroment = (uri) => {
  const url = createURL(uri);
  const [subdomain] = getSubdomains(url);
  const isNumberSubdomain = !Number.isNaN(+subdomain);
  const isStaging = isNumberSubdomain && url.hostname.includes('clicksign.dev');

  const subdomainToEnviroment = {
    app: 'production',
    sandbox: 'sandbox',
  };

  return isStaging ? `staging-${subdomain}` : subdomainToEnviroment[subdomain] || 'development';
};
