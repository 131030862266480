import {
  parseContactsToSigners,
  parseFlexibeContactsToSigners,
} from '@document/utils/signer-utils';

export default [
  {
    name: 'contacts',
    component: 'ContactsTable',
    form: 'signersFromContacts',
    props: {
      contacts: 'getContacts',
      selectedContacts: 'signersFromContacts.selectedContacts',
      pagination: 'pagination',
    },
    isNextStepButtonDisabled: (vm) => vm.signersFromContacts.selectedContacts.length === 0,
  },
  {
    name: 'signAs',
    component: 'SignerListSignAsStep',
    form: 'signAs',
    props: {
      signers: 'signAs.signers',
      signAsList: 'signAs.signAsList',
      signerSignAs: 'getSignerSignAsItems',
      hasValidationError: 'hasValidationError',
    },
    isNextStepButtonDisabled: () => false,
    setupAction: (vm) => {
      const selectedContacts = _.get(vm, 'signersFromContacts.selectedContacts');
      let signers;
      if (vm.flexibleContexts.includes(vm.context))
        signers = parseFlexibeContactsToSigners(selectedContacts, vm.context);
      else signers = parseContactsToSigners(selectedContacts);
      vm.$set(vm.signAs, 'signers', signers);
    },
    action: async (vm, payload) => {
      const url = _.get(vm.$store.getters, 'signer/getLinks.linkSigner');
      const response = await vm.$store.dispatch('signer/linkSigner', {
        url,
        payload,
        context: vm.context,
      });
      vm.$emit('save', response);
      return response;
    },
    afterAction: (vm, payload) => {
      const signers = _.get(payload, 'signers');
      const lists = _.get(payload, 'lists');
      vm.$set(vm.messageToSend, 'signers', signers);
      vm.$set(vm.messageToSend, 'lists', lists);

      if (!vm.steps.includes('message')) return;

      const activeGroup = _.get(payload, 'current_group');
      const isSequenceEnabled = signers.some((signer) => signer.group !== activeGroup);

      vm.$set(vm.messageToSend, 'isSequenceEnabled', isSequenceEnabled);
    },
    errorHandle: (vm, err) => {
      if (!(err && err.response && err.response.status === 422)) return;
      const { data } = err.response;
      const errors = data.filter((error) => !_.isEmpty(error.signers.errors));
      const signersErrors = _.reduce(
        errors,
        (map, value) => {
          const { signerId, signAs, contactId } = value.signers;
          if (vm.flexibleContexts.includes(vm.context) && contactId) {
            const signAsList = { [contactId]: map[contactId] || [] };
            const signerSignAsErrors = _.get(signAsList, contactId);
            return Object.assign(map, { [contactId]: [...signerSignAsErrors, signAs] });
          }
          const signAsList = { [signerId]: map[signerId] || [] };
          const signerSignAsErrors = _.get(signAsList, signerId);
          return Object.assign(map, { [signerId]: [...signerSignAsErrors, signAs] });
        },
        {}
      );
      vm.$set(vm, 'errorMessages', signersErrors);
      vm.$nextTick(() => {
        vm.hasCurrentStepValidationError();
      });
    },
  },
  {
    name: 'message',
    component: 'SignerMessageStep',
    form: 'messageToSend',
    props: {
      signerSignAs: 'signerSignAs',
      signers: 'messageToSend.signers',
      message: 'messageToSend.message',
      messageTemplates: 'messageTemplates',
    },
    isNextStepButtonDisabled: () => false,
    action: async (vm, form) => {
      const signers = _.get(form, 'signers');
      const lists = _.get(form, 'lists');
      const message = _.get(form, 'message');
      const isSequenceEnabled = _.get(vm, '$store.state.document.sequence_enabled', false);

      if (isSequenceEnabled) {
        await Promise.all(
          lists.map(async (list) => {
            const url = _.get(list, 'links.self');
            await vm.$store.dispatch('signer/updateList', { url, message });
          })
        );
      } else {
        await Promise.all(
          signers.map(async (signer) => {
            const url = _.get(signer, 'links.notifySignature');
            await vm.$store.dispatch('signer/notifySignature', { url, message });
          })
        );
      }
    },
    abort: (vm) => {
      vm.openModal('cancelSigner');
    },
  },
];
