<template>
  <XModal
    close-button
    :is-open="showModal"
    :actions-classes="[reverseActions && $style.reverseRow]"
    :modal-classes="modalClasses"
    data-testid="confirmationModal"
    @close="$emit('close')"
  >
    <template #header>
      <slot
        name="title"
        data-testid="confirmationModalTitle"
      >
        {{ title }}
      </slot>
    </template>

    <slot>
      <span>{{ description }}</span>
    </slot>

    <template #actions>
      <!-- TODO: Remove utility class -->
      <XButton
        class="mr-1"
        design="outlined"
        :data-ga-event="gaEventCancel"
        data-testid="confirmationModalClose"
        @click="$emit('close')"
      >
        {{ cancelText || $t('general.action.close') }}
      </XButton>

      <XButton
        :data-testid="actionTestid"
        :disabled="disableAction"
        :loading="isLoading"
        :data-ga-event="gaEventAction"
        @click="$emit('confirm')"
      >
        {{ action }}
      </XButton>
      <slot name="action" />
    </template>
  </XModal>
</template>

<script>
export default {
  name: 'BaseConfirmationModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
    actionTestid: {
      type: String,
      default: 'confirmationModalConfirm',
    },
    disableAction: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    gaEventAction: {
      type: String,
      default: '',
    },
    gaEventCancel: {
      type: String,
      default: '',
    },
    modalClasses: {
      type: Array,
      default: () => [],
    },
    reverseActions: Boolean,
  },
};
</script>

<style lang="scss" module>
/* Hack for increase specificity by one class. */
/* Needed for override the default justify-content without !important */

.reverseRow:not(.\9) {
  justify-content: flex-start;
  flex-direction: row-reverse;
  gap: var(--space-regular);
}
</style>
