export default {
  SET_LINKS(state, payload) {
    Object.assign(state.links, { ...payload });
  },
  SET_CONSUMPTION(state, payload) {
    state.consumption = payload;
  },
  SET_PLANS(state, payload) {
    state.plans = payload;
  },
  SET_PAYMENT_PROFILE(state, payload) {
    state.paymentProfile = payload;
  },
  SET_VINDI_ERRORS(state, payload) {
    state.vindiErrors = payload;
  },
  SET_INVOICES(state, payload) {
    state.invoices = payload;
  },
  SET_INVOICES_PAGINATION(state, payload) {
    state.invoicesPagination = payload;
  },
  SET_PRODUCT_USAGES(state, payload) {
    state.productUsages = payload;
  },
  SET_PERIOD_USAGES(state, payload) {
    state.periodUsages = payload;
  },
  SET_NEGOTIATION(state, payload) {
    state.negotiation = payload;
  },
  SET_CARDS(state, payload) {
    state.cards = payload;
  },
  SET_CREDIT_CARD_PAGINATION(state, payload) {
    state.creditCardPagination = payload;
  },
  SET_BILLING_INFO(state, payload) {
    state.billingInfo = payload;
  },
  // TOGGLE_MIDAS_SCOPE
  // Remove SET_CONSUMPTION_INFO mutation when scope >= 3
  SET_CONSUMPTION_INFO(state, payload) {
    state.consumptionInfo = payload;
  },
};
