import axios from 'axios';

export const getDocumentsReport = async (obj) => {
  try {
    const response = await axios({
      method: 'get',
      url: 'documents.json',
      params: obj.filters,
    });

    const payload = response.data.documents;
    return payload;
  } catch (error) {
    throw error;
  }
};

export const getSignersReport = async (obj) => {
  try {
    const response = await axios({
      method: 'get',
      url: 'signers.json',
      params: obj.filters,
    });

    const payload = response.data.signers;
    return payload;
  } catch (error) {
    throw error;
  }
};

export const getFlowsReport = async (obj) => {
  try {
    const response = await axios({
      method: 'get',
      url: 'flows.json',
      params: obj.filters,
    });

    const payload = response.data;
    return payload;
  } catch (error) {
    throw error;
  }
};

export const getConsumptionReport = async ({ params, url }) => {
  try {
    const response = await axios({
      method: 'get',
      url,
      params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
