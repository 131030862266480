import { axios } from '@/plugins';

const fetchSecurity = async ({ rootGetters, getters, commit }) => {
  try {
    const requestUrl = rootGetters['info/getLinks'].securitySettings;
    const currentServiceProviderInfos = getters?.getServiceProvider;
    const { data } = await axios.get(requestUrl);
    commit('SET_SAML', data.saml);
    commit('SET_SERVICE_PROVIDER', {
      ...data.clicksign,
      domainRecordTxt: currentServiceProviderInfos?.domainRecordTxt,
    });
    commit('SET_ACCESS_LIMITATION', data.accessLimitation);
    commit('SET_LINKS', data.links);
    return data;
  } catch (err) {
    throw err;
  }
};

const sendDomainAndObtainDomainRecord = async ({ getters, commit }, domain) => {
  try {
    const url = getters?.getLinks.newSamlConfig;
    const urlWithParams = `${url}?config[identity_provider]=${domain}`;
    const { data } = await axios.get(urlWithParams);
    const { domainRecordTxt, identityProvider } = data;
    const currentServiceProviderInfos = getters?.getServiceProvider;
    const newServiceProviderInfos = {
      ...currentServiceProviderInfos,
      identityProvider,
      domainRecordTxt,
    };
    commit('SET_SERVICE_PROVIDER', newServiceProviderInfos);
  } catch (error) {
    throw error;
  }
};

const createSamlConfig = async ({ getters }, formData) => {
  try {
    const url = getters?.getLinks.createSamlConfig;
    await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    throw error;
  }
};

const updateSamlConfig = async ({ getters }, formData) => {
  try {
    const url = getters?.getLinks.updateSamlConfig;
    await axios.patch(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    throw error;
  }
};

const deleteSamlConfig = async ({ getters }) => {
  try {
    const url = getters?.getLinks.destroySamlConfig;
    await axios.delete(url);
  } catch (error) {
    throw error;
  }
};

const createIpRangeLimitation = async ({ getters, dispatch }, ipRangeData) => {
  try {
    const url = getters?.getLinks.ipRange;
    const { payload } = ipRangeData;
    await axios.post(url, payload);
    await dispatch('fetchSecurity');
  } catch (error) {
    throw error;
  }
};

const updateIpRangeLimitation = async ({ dispatch }, ipRangeData) => {
  try {
    const { url, payload } = ipRangeData;
    await axios.patch(url, payload);
    await dispatch('fetchSecurity');
  } catch (error) {
    throw error;
  }
};

const deleteIpRangeLimitation = async ({ dispatch }, url) => {
  try {
    await axios.delete(url);
    await dispatch('fetchSecurity');
  } catch (error) {
    throw error;
  }
};

const createAccessLimitation = async ({ getters, dispatch }, payload) => {
  try {
    const url = getters?.getLinks.accessLimitation;
    await axios.post(url, payload);
    await dispatch('fetchSecurity');
  } catch (error) {
    throw error;
  }
};

const updateAccessLimitation = async ({ getters, dispatch }, payload) => {
  try {
    const url = getters?.getLinks.accessLimitation;
    await axios.patch(url, payload);
    await dispatch('fetchSecurity');
  } catch (error) {
    throw error;
  }
};

export default {
  fetchSecurity,
  createSamlConfig,
  sendDomainAndObtainDomainRecord,
  updateSamlConfig,
  deleteSamlConfig,
  createAccessLimitation,
  updateAccessLimitation,
  createIpRangeLimitation,
  updateIpRangeLimitation,
  deleteIpRangeLimitation,
};
