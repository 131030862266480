const acceptanceTerm = {
  sentAt: 'Enviado em',
  createNew: 'Criar novo',
  characteresCounter: '{charactersCount} caracteres disponíveis',
  sentDate: 'Data de envio',
  acceptanceModels: 'Modelos de Aceite',
  noAcceptanceModels: 'Nenhum modelo cadastrado',
  date: 'Data',
  title: 'Título',
  ordenation: 'Ordenação',

  sentDatePlaceholder: 'Selecione...',

  status: {
    sent: 'Em processo de aceite',
    completed: 'Finalizado com sucesso',
    canceled: 'Cancelado pelo Remetente',
    refused: 'Encerrado pelo destinatário',
    expired: 'Prazo de aceite expirado',
    error: 'Envio falhou',
    enqueued: 'Aguardando envio',
  },

  list: {
    pageTitle: 'Aceite via WhatsApp',
    remittee: 'Destinatário',
    contact: 'Contato',
    acceptanceTitle: 'Título do Aceite',
    status: 'Status',
    createdAt: 'Envio',
    noDate: 'Sem data',
    searchPlaceholder: 'Destinatário, contato ou título',
    chargingTooltip: 'O Aceite via Whatsapp possui custo adicional',
    alert: 'O Aceite agora é um recurso premium. Faça um upgrade de plano para continuar a usá-lo.',

    empty: {
      search: {
        title: 'Nenhum resultado encontrado na busca',
        subtitle: 'Experimente alterar sua pesquisa ou retirar os filtros ',
      },

      registers: {
        title: 'Envie um novo conteúdo para aceite via WhatsApp',
        subtitle: 'Os status dos seus envios serão exibidos aqui',
      },

      actions: {
        create: 'Criar novo',
        receiveTest: 'Testar Aceite',
        upgrade: 'Fazer upgrade',
      },
    },
  },

  create: {
    title: 'Criar Aceite via WhatsApp',
    chargingWarning: 'O Aceite via Whatsapp',
    chargingLink: 'possui um custo adicional.',
    help: 'Ajuda',
    addSigner: 'Novo destinatario',
    totalOf: '{x} de {total}',
    rejectedAcceptancesTotalOf: '@:acceptanceTerm.create.totalOf aceites não foram criados',
    creatingAcceptanceTerm: 'Criando aceite',
    creatingAcceptanceTermFailedModalMessage:
      'Não conseguimos criar os aceites dos destinatários abaixo. Você pode tentar criá-los novamente agora.',
    tryLater: 'Tentar depois',
    errorProccesingAcceptance: 'Erro ao processar o Aceite',
    verifyConnection: 'Verifique sua conexão antes de prosseguir.',
    contactUpdateError: 'Não foi possível atualizar algum dos contatos da agenda',
    limitReached: 'Limite de Aceites gratuitos atingido. Precisa experimentar mais?',
    limitReachedLinks: '{0} ou {1}.',
    chooseAPlan: 'Contrate um plano',
    contactSupport: 'fale com nossos especialistas',
    wai: {
      removeSigner: 'Remover destinatário',
    },

    acceptanceTermInfo: {
      title: 'Aceite',
      label: {
        acceptanceTermTitle: 'Título',
        content: 'Conteúdo',
      },
      placeholder: {
        acceptanceTermTitle: 'Ex: Acordo de prestação de serviços',
        content:
          'Ex: Estou de acordo com a prestação de serviços realizada, no valor de R$ x, prestada na data y, às x horas.',
      },
      selectTemplate: 'Selecionar modelo',
      selectTemplateDefaultOption: 'Modelos salvos',
      saveContentTemplate: 'Salvar como modelo',
      saveContentTemplateHelp: 'Veja como utilizar modelos',
    },
    senderInfo: {
      title: 'Seus dados',
      label: {
        senderName: 'Nome do Remetente',
        senderPhone: 'Telefone (opcional)',
      },
      placeholder: {
        senderPhone: '(__)_____-____',
      },
      senderPhoneHint: 'Será exibido ao destinatário',
    },
    receiverInfo: {
      title: 'Destinatários',
      label: {
        signerName: 'Nome completo  ',
        signerPhone: 'WhatsApp',
      },
      placeholder: {
        signerName: 'Nome',
        signerPhone: '(__)_____-____',
      },
      contactPhoneHint: 'Será salvo na agenda',
    },
    actions: {
      send: 'Enviar Aceite',
    },
    warning: '@:acceptanceTerm.create.totalOf aceites não puderam ser criados',
    success: 'Aceite criado com sucesso!',
    error: 'Erro ao criar o aceite',
  },
  optIn: {
    title: 'Aceite via WhatsApp',
    subtitle: 'Formalize seus acordos via WhatsApp pela Clicksign!',
    freeTest: 'Criar um Aceite',
    receiveAcceptance: 'Testar Aceite',
    firstDescriptionParagraph:
      'Sabe aquela conversa pelo WhatsApp para fechar um negócio? Com o Aceite, você traz ainda mais segurança para esse acordo, sem precisar criar um documento.',
    secondDescriptionParagraph: 'Faça um teste gratuito para conhecer essa novidade!',
    howToUse: 'Como funciona?',
    cards: {
      tasks: 'Adicione nome e telefone do seu cliente e o conteúdo a ser enviado.',
      receiving:
        'O cliente recebe um WhatsApp da Clicksign, confirma os dados, analisa e aceita o conteúdo.',
      finished: 'Aceite concluído! Você pode consultar o histórico sempre que precisar.',
    },
    modal: {
      confirm: {
        title: 'Informações importantes',
        confirm: 'Estou ciente',
        error: 'Ocorreu um erro ao confirmar o aceite',
        description:
          'Aceite via WhatsApp é uma funcionalidade nova e está em fase de testes. Por isso, é importante informar que: ',
        terms: [
          'Podem existir atualizações e melhorias durante seu período de uso.',
          'Algumas funções como o envio de múltiplos aceites e o envio automatizado ainda não estão disponíveis.',
        ],
      },
    },
    sendTestModal: {
      header: 'Experimente na prática o Aceite via WhatsApp',
      title: 'Exemplo de Aceite',
      subtitle:
        'Receba um exemplo de Aceite no seu WhatsApp e veja como é simples e rápido aceitar um conteúdo enviado.',
      form: {
        receiverInfo: 'Seus dados',
        label: {
          name: 'Nome completo',
          whatsapp: 'WhatsApp',
        },
        placeholder: {
          whatsapp: '(__)_____-____',
        },
      },
      actions: {
        receiveExample: 'Receber exemplo',
      },
      sendOptinRequestError: 'Erro ao criar exemplo de aceite',
      imageAlt: 'Mão segurando smartphone com envio de aceite via WhatsApp na tela',
    },
  },
  show: {
    details: {
      createdAt: 'Criado em {createdAt}',
      cancel: 'Cancelar',
      openPreview: 'Abrir registro de aceite',
      previewTooltip: 'Disponível após finalizar',
    },
    history: {
      title: 'Histórico dos eventos',
      emptyStateTitle: 'Acompanhe seu Aceite por aqui',
      emptyStateSubtitle: 'Quando o envio for concluído, o primeiro evento será registrado',
    },
    document: {
      description: 'Documento criado após aceite do destinatário',
    },
    cancelAcceptanceTerm: {
      title: 'Cancelar este Aceite?',
      description: 'Esta ação é irreversível e o destinatário não poderá mais aceitar.',
      checkboxLabel: 'Selecione para confirmar o cancelamento',
      cancel: 'Fechar e manter',
      confirm: 'Cancelar',
      error: 'Erro ao cancelar o aceite',
      success: 'Aceite cancelado',
    },
  },
  documentsBalanceAlert: {
    message:
      'Você ainda possui {0} Aceite via WhatsApp para usar no seu período de teste. | Você ainda possui {0} Aceites via WhatsApp para usar no seu período de teste.',
  },
  requestTrialExtension: {
    header: 'Você atingiu o limite de Aceites no período de teste',
    message: {
      reachLimit: {
        firstParagraph:
          'Você já usou os 30 Aceites gratuitos do período de teste. Para seguir aproveitando os benefícios desse recurso, conheça nossos planos e contrate a melhor opção para você.',
        secondParagraph: 'Precisa experimentar um pouco mais? Fale com um de nossos especialistas.',
      },
    },
  },
  contentTemplate: {
    create: {
      pageTitle: 'Modelo de Aceite',
      back: 'Voltar',
      label: {
        title: 'Título',
        content: 'Conteúdo',
      },
      titleError: 'Este título já está em uso',
      save: 'Salvar',
      delete: 'Excluir modelo',
      success: 'Modelo salvo com sucesso!',
      error: 'Erro ao salvar o modelo!',
    },
    edit: {
      error: 'Erro ao carregar o modelo!',
    },
    error: 'Erro ao criar Mensagem recorrente',
    success: 'Mensagem recorrente criada com sucesso!',
    list: {
      pageTitle: '@:acceptanceTerm.acceptanceModels',
      create: 'Criar novo',
      table: {
        headers: {
          contentTitle: 'Título da mensagem',
          content: 'Conteúdo',
        },
      },
      empty: {
        title: 'Salve modelos de aceite para facilitar seus envios',
        subtitle: 'Os seus modelos de aceite salvos serão exibidos aqui',
      },
    },
    delete: {
      title: 'Excluir este modelo de aceite',
      description:
        'Modelos de aceites excluídos não podem ser recuperados. Esta ação é instantânea.',
      success: 'Modelo excluído com sucesso!',
      error: 'Erro ao excluir modelo!',
    },
    modal: {
      title: 'Selecionar modelo',
    },
    validations: {
      maxLengthMessage: 'A quantidade limite de caracteres é de {0} incluindo título e conteúdo',
    },
  },
  confirmAcceptanceIsSentModal: {
    title: 'Aceite enviado',
    description:
      'Aguarde alguns instantes e confira se o Aceite chegou no seu WhatsApp. Siga as instruções para concluir o fluxo.',
  },
};

export default acceptanceTerm;
