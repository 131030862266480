<template>
  <div>
    <span>
      {{ message }}
      <XLink
        :to="link"
        no-padding
      >
        <span>{{ linkText }}</span>
      </XLink>
    </span>
  </div>
</template>

<script>
export default {
  name: 'BaseToasterLinkError',
  props: {
    message: {
      type: String,
      default: '',
    },
    linkText: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" module></style>
