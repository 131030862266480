import axios from 'axios';
import { isEmpty } from 'lodash';
import { TOGGLE_SCOPES } from '@base/constants/toggles-scope';
import * as consumptionService from '../../../services/consumption';

const getConsumption = async ({ commit }) => {
  try {
    const response = await axios.get('consumption');
    const payload = response.data;

    commit('GET_CONSUMPTION', payload);
    return payload;
  } catch (error) {
    throw error;
  }
};

// TOGGLE_MIDAS_SCOPE
// TODO: when scope > TOGGLE_SCOPES.billing.reportConsumption remove rootGetters param
const fetchConsumptionPeriods = async ({ commit, rootGetters }, url) => {
  try {
    const response = await consumptionService.fetchConsumptionPeriods(url);

    if (isEmpty(response)) commit('SET_CONSUMPTION_PERIODS', []);

    // TOGGLE_MIDAS_SCOPE
    // TODO: when scope > TOGGLE_SCOPES.billing.reportConsumption remove isToggleEnabled var
    const isToggleEnabled = rootGetters['featureFlags/isToggleEnabled'];

    // TOGGLE_MIDAS_SCOPE
    // TODO: when scope > TOGGLE_SCOPES.billing.reportConsumption remove isToggleMidasActivated var
    const isToggleMidasScopeEnabled = isToggleEnabled('TOGGLE_MIDAS_SCOPE', {
      scope: TOGGLE_SCOPES.billing.reportConsumption,
    });

    // TOGGLE_MIDAS_SCOPE
    // TODO: when scope > TOGGLE_SCOPES.billing.reportConsumption keep inner if code
    if (isToggleMidasScopeEnabled) {
      commit('SET_CONSUMPTION_PERIODS', response.invoices);
    } else {
      commit('SET_CONSUMPTION_PERIODS', response.periods);
    }

    return response;
  } catch (error) {
    throw error;
  }
};

export default {
  getConsumption,
  fetchConsumptionPeriods,
};
